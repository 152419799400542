import { TD, DD } from '../types';
import { app } from '../config';
import dayjs from 'dayjs';

// ----------------

export const tdMapper = {
  string(ddValue?: DD.String): TD.String | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.String = ddValue;
    return tdValue;
  },

  // --------

  stringList(ddValue?: DD.StringList): TD.StringList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.StringList = ddValue;
    return tdValue;
  },

  // --------

  number(ddValue?: DD.Number): TD.Number | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.Number = ddValue;
    return tdValue;
  },

  // --------

  numberList(ddValue?: DD.NumberList): TD.NumberList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.NumberList = ddValue;
    return tdValue;
  },

  // --------

  boolean(ddValue?: DD.Boolean): TD.Boolean | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.Boolean = ddValue;
    return tdValue;
  },

  // --------

  booleanList(ddValue?: DD.BooleanList): TD.BooleanList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.BooleanList = ddValue;
    return tdValue;
  },

  // --------

  glossaryId(ddValue?: DD.GlossaryId): TD.GlossaryId | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.GlossaryId = ddValue;
    return tdValue;
  },

  // --------

  glossaryIdList(ddValue?: DD.GlossaryIdList): TD.GlossaryIdList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.GlossaryIdList = ddValue;
    return tdValue;
  },

  // --------

  date(ddValue?: DD.Date): TD.Date | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.Date = dayjs(ddValue).format(app.ISODateFormat);
    return tdValue;
  },

  // --------

  dateList(ddValue?: DD.DateList): TD.DateList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.DateList = ddValue.map((item) => dayjs(item).format(app.ISODateFormat));
    return tdValue;
  },

  // --------

  time(ddValue?: DD.Time): TD.Time | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.Time = dayjs(ddValue).format(app.ISOTimeFormat);
    return tdValue;
  },

  // --------

  timeList(ddValue?: DD.TimeList): TD.TimeList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.TimeList = ddValue.map((item) => dayjs(item).format(app.ISOTimeFormat));
    return tdValue;
  },

  // --------

  dateTime(ddValue?: DD.DateTime): TD.DateTime | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.DateTime = dayjs(ddValue).format(app.ISODateTimeFormat);
    return tdValue;
  },

  // --------

  dateTimeList(ddValue?: DD.DateTimeList): TD.DateTimeList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.DateTimeList = ddValue.map((item) => dayjs(item).format(app.ISODateTimeFormat));
    return tdValue;
  },

  // --------

  id(ddValue?: DD.Id): TD.Id | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.Id = ddValue;
    return tdValue;
  },

  // --------

  idList(ddValue?: DD.IdList): TD.IdList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.IdList = ddValue;
    return tdValue;
  },

  // --------

  uuId(ddValue?: DD.UUId): TD.UUId | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.UUId = ddValue;
    return tdValue;
  },

  // --------

  uuIdList(ddValue?: DD.UUIdList): TD.UUIdList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.UUIdList = ddValue;
    return tdValue;
  },

  // --------

  url(ddValue?: DD.URL): TD.URL | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.URL = ddValue;
    return tdValue;
  },

  // --------

  urlList(ddValue?: DD.URLList): TD.URLList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.URLList = ddValue;
    return tdValue;
  },

  // --------

  enum(ddValue?: DD.Enum): TD.Enum | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.Enum = ddValue;
    return tdValue;
  },

  // --------

  enumList(ddValue?: DD.EnumList): TD.EnumList | undefined {
    if (ddValue === undefined) return undefined;

    let tdValue: TD.EnumList = ddValue;
    return tdValue;
  },
};
