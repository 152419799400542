import { blueMoonBranchInitialState, BlueMoonBranch } from './blueMoonBranch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { BlueMoonDO } from '../../../entity/blueMoon';

// ----------------

export const blueMoonSlice = createSlice({
  name: `${reduxActionTypePrefix}/blueMoon`,
  initialState: blueMoonBranchInitialState,

  // -------- Reducers --------

  reducers: {
    set: (state, action: PayloadAction<{ list?: BlueMoonDO.$[]; meta?: Partial<BlueMoonBranch['meta']> }>) => {
      if (action.payload.list) {
        state.list = action.payload.list;
      }

      if (action.payload.meta) {
        state.meta = { ...state.meta, ...action.payload.meta };
      }
    },

    // --------

    setItem: (state, action: PayloadAction<{ data: BlueMoonDO.$ | null }>) => {
      state.item = action.payload.data;
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return blueMoonBranchInitialState;
    });
  },
});
