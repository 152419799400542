export type Type = 'undefined' | 'null' | 'boolean' | 'string' | 'number' | 'bigint' | 'symbol' | 'object' | 'array' | 'function';

type Result<T> = { value: T; isValid: boolean; type: Type };

/**
 * This service checks if a value matches a certain type. If it does, the value is returned unchanged.
 * Otherwise, a default value for a certain type is returned.
 *
 * It also always returns information about whether the value is valid or not and what type it has.
 */

export const typeCheckService = {
  string(value: any): Result<string> {
    const type = getType(value);

    return type === 'string' ? { value, isValid: true, type } : { value: '', isValid: false, type };
  },

  // --------

  stringOrNull(value: any): Result<string | null> {
    const type = getType(value);

    return type === 'string' || type === 'null' ? { value, isValid: true, type } : { value: null, isValid: false, type };
  },

  // --------

  number(value: any): Result<number> {
    const type = getType(value);

    return type === 'number' ? { value, isValid: true, type } : { value: 0, isValid: false, type };
  },

  // --------

  numberOrNull(value: any): Result<number | null> {
    const type = getType(value);

    return type === 'number' || type === 'null' ? { value, isValid: true, type } : { value: null, isValid: false, type };
  },

  // --------

  boolean(value: any): Result<boolean> {
    const type = getType(value);

    return type === 'boolean' ? { value, isValid: true, type } : { value: false, isValid: false, type };
  },

  // --------

  array<T>(value: any, arrayOf?: 'boolean' | 'number' | 'string'): Result<Array<T>> {
    const type = getType(value);
    let isValid = false;

    if (type === 'array') {
      if (arrayOf) {
        isValid = value.every((item) => typeof item === arrayOf);
      } else {
        isValid = true;
      }
    }

    return isValid ? { value, isValid, type } : { value: [], isValid, type };
  },

  // --------

  arrayOrNull<T>(value: any, arrayOf?: 'boolean' | 'number' | 'string'): Result<Array<T> | null> {
    const type = getType(value);
    let isValid = false;

    if (type === 'null') {
      isValid = true;
    } else if (type === 'array') {
      if (arrayOf) {
        isValid = value.every((item) => typeof item === arrayOf);
      } else {
        isValid = true;
      }
    }

    return isValid ? { value, isValid, type } : { value: null, isValid, type };
  },

  // --------

  objectOrNull(value: any): Result<Object | null> {
    const type = getType(value);

    return type === 'object' || type === 'null' ? { value, isValid: true, type } : { value: null, isValid: false, type };
  },
};

/**
 * `check` is a shorthand version for `typeCheckService`.
 */

export const check = typeCheckService;

/**
 * Returns type of the given value.
 */

export function getType(value: any): Type {
  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return 'array';
    } else if (value === null) {
      return 'null';
    }
    return 'object';
  } else {
    return typeof value;
  }
}
