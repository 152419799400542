import { put } from 'redux-saga/effects';

// Redux

import txFilesSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* getTXFilesSaga(action: ReturnType<typeof txFilesSlice.actions.getTXFiles$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.query],
    service: txService.getTXFiles,
    context: txService,
    timeout: 300,
    onSuccess: function* (data) {
      yield put(txFilesSlice.actions.setList(data.list, data.totalPages));
    },
  });
}
