const uiEN = {
  field: {
    digitsLimitPlaceholder: (from: number, to: number) => `Enter ${from} to ${to} digits`,
    confirmNewPassword: 'Confirm new password',
    examplePlaceholder: 'Example',
    newPassword: 'New password',
    password: 'Password',
    email: 'Email',
    totpCode:'Enter Code',
    invalidOTP:'Invalid OTP, please try again.',
    scanQR: 'Scan this QR code with your authentication app',
    checkOTP: 'Run the Google Authenticator application on your mobile device ' +
      'and enter the verification in the input box below.'
  },

  button: {
    create: 'Create',
    login: 'Login',
    verify:'Verify',
    send: 'Send',
    generateQrCode:'Generate Qr Code'
  },

};

export default uiEN;
