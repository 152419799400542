import { DD } from '../../types';

// ----------------

export namespace BaseDO {
  /**
   * @deprecated
   */
  export type PaginatedList<ListItem> = {
    count: number; // Total count of items
    limit: number; // Items on one page
    pages: number; // Total count of pages
    list: ListItem[];
  };

  // --------

  export type _PaginatedList<ListItem> = {
    count: DD.Number; // Total count of items
    limit: DD.Number; // Items on one page
    pages: DD.Number; // Total count of pages
    list: ListItem[];
  };
}
