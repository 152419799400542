import { useState } from 'react';

// Types

import { PopupState } from '../../types/component';

// -------- Types --------

export interface IReturn<N> {
  setOpenPopup: (value: PopupState<N>) => void;
  closePopup: () => void;
  openPopup: PopupState<N>;
}

// ----------------

const usePopupState = <N>(): IReturn<N> => {
  const [openPopup, setOpenPopup] = useState<PopupState<N>>(null);

  const closePopup = () => {
    setOpenPopup(null);
  };

  return { setOpenPopup, closePopup, openPopup };
};

export default usePopupState;
