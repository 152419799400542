import { put, delay } from 'redux-saga/effects';

// Redux

import snackbarSlice from '../../../snackbar';
import txNotesSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* addTXNoteSaga(action: ReturnType<typeof txNotesSlice.actions.addTXNote$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.data],
    service: txService.addTXNote,
    context: txService,
    onSuccess: function* (data) {
      yield put(txNotesSlice.actions.addToList(data));

      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }

      yield delay(500);
      yield put(snackbarSlice.actions.show('success', 'Note successfully added'));
    },
  });
}
