import * as validate from './validate';
import * as i18n from './i18n';
import * as app from './app';
import * as api from './api';
import env from './env';

// ----------------

export default {
  validate,
  i18n,
  app,
  api,
  env,
};

export { validate, i18n, app, api, env };
