import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

// Context

import { I18nContext } from '../../components/contextProviders/i18n/i18nProvider';

// Config

import { i18n } from '../../config';

// ----------------

const useI18n = (): i18n.T => {
  const value = useContext(I18nContext);
  const lan = useSelector((state: RootState) => state.app.lan);

  const t = useMemo(() => value[lan], [lan]);

  return t;
};

export default useI18n;
