export namespace EnumDTO {
  export type $ = {
    display_name: string;
    value: string;
  };

  export type List = $[];

  export type OptionsResponse<DTOKey extends string> = {
    actions: {
      POST: Record<
        DTOKey,
        {
          choices?: List;
        }
      >;
    };
  };
}
