import { CartographyDO } from '../../../entity/cartography';

// -------- Types --------

export type CartographyBranch = {
  change: {
    list: CartographyDO.Change[];
    meta: {
      totalCount: number;
    };
  };
  comparing: {
    list: CartographyDO.Comparing[];
    meta: {
      totalCount: number;
    };
  };
  pendingCount: number;
};

// -------- Initial state of branch --------

export const cartographyBranchInitialState: CartographyBranch = {
  change: {
    list: [],
    meta: {
      totalCount: 0,
    },
  },
  comparing: {
    list: [],
    meta: {
      totalCount: 0,
    },
  },
  pendingCount: 0,
};
