import { UserCommon } from '../../entity/user';
import { globalObj } from '../../config/app';
import { RootState } from '../../redux';

/**
 * Checks if the user has all the required permissions.
 *
 * @param permissionList - List of permissions to check
 */

export default function hasPermissions(permissionList?: UserCommon.PermissionList): boolean {
  if (!permissionList) {
    return true;
  }

  const reduxState: RootState = globalObj.reduxStore?.getState();
  const userPermissions = reduxState.user.data.dynamic_permissions;

  return permissionList.every((permission) => userPermissions.includes(permission));
}
