import { Condition, FiltersQueriesConfig } from '../../../../../../services/queryService';
import { Values } from './AdvancedFilters';

// ----------------

export const advancedFiltersMapper = {
  toFiltersQueriesConfig<QueryName extends string = string>(values: Partial<Values>): FiltersQueriesConfig<QueryName>[] {
    const queriesConfig: FiltersQueriesConfig<QueryName>[] = [];

    if (values.filters) {
      values.filters.forEach((filter) => {
        queriesConfig.push({ name: filter.name as QueryName, value: filter.value, condition: filter.condition as Condition, type: 'advanced' });
      });
    }

    return queriesConfig;
  },
};
