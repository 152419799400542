import HTTP from '../http';

// Config

import { env, api } from '../../config';

// Entities

import { TXMicrowaveSynopsisDTO, txMicrowaveSynopsisMapper } from '../../entity/txMicrowaveSynopsis';

// ----------------

class TXMicrowaveSynopsisAPIService extends HTTP {
  getList(txId: number) {
    return this.request<TXMicrowaveSynopsisDTO[]>({ url: api.txMicrowaveSynopsis.txId(txId) }).then((data) => {
      return data!.map((item) => txMicrowaveSynopsisMapper.toDO(item));
    });
  }
}

export default new TXMicrowaveSynopsisAPIService(`${env.API}api/`);
