import { EnumDO } from '../../../entity/enum';

// -------- Types --------

export type EnumBranch = {
  field: EnumDO.FieldsByEntities;
};

// -------- Initial state of branch --------

export const enumBranchInitialState: EnumBranch = {
  field: {},
};
