import HTTP from '../http';

// Config

import { user as userApi } from '../../config/api';
import env from '../../config/env';
import otplib from 'otplib/otplib-browser';
import QRCode from 'qrcode';

// Services

// import nameTransformer from 'src/modern/services/transformers';

// Types

import { User } from '../../types';

// ----------------

class UserAPIService extends HTTP {
  getToken(data: User.Api.IUserToken$BodyReqInner): Promise<User.Api.IUserToken$BodyResInner | void> {
    return this.request<User.Api.IUserToken$BodyRes>({ url: userApi.token, method: 'POST', data }).then((data) => {
      return data;
    });
  }

  resetPassword(data: User.Api.IUserResetPassword$BodyReq): Promise<User.Api.IUserResetPassword$BodyRes | void> {
    return this.request<User.Api.IUserResetPassword$BodyRes>({ url: userApi.resetPassword, method: 'POST', data }).then((data) => {
      return data;
    });
  }

  resetPasswordValidateToken(data: User.Api.IResetPasswordValidateToken$BodyReq): Promise<User.Api.IResetPasswordValidateToken$BodyRes | void> {
    return this.request<User.Api.IResetPasswordValidateToken$BodyRes>({
      url: userApi.resetPasswordValidateToken,
      method: 'POST',
      data,
    }).then((data) => {
      return data;
    });
  }

  resetPasswordConfirm(data: User.Api.IResetPasswordConfirm$BodyReq): Promise<User.Api.IResetPasswordConfirm$BodyRes | void> {
    return this.request<User.Api.IResetPasswordConfirm$BodyRes>({
      url: userApi.resetPasswordConfirm,
      method: 'POST',
      data,
    }).then((data) => {
      return data;
    });
  }

  getData(): Promise<User.Api.UserMe$Get$Res | void> {
    return this.request<User.Api.UserMe$Get$Res>({ url: userApi.me });
  }

  updateData(data: User.Api.UserMe$Patch$Req): Promise<User.Api.UserMe$Patch$Res | void> {
    return this.request<User.Api.UserMe$Patch$Res>({ url: userApi.me, method: 'patch', data });
  }

  generateOtpSecret(email: string) {
    return this.request({
      url: userApi.generateOtpSecret,
      method: 'GET',
      params: { email }
    });
  }
  verifyOtp(data) {
    return this.request({ url: userApi.verifyOtp, method: 'POST', data: { otp_code: data.otp } });
  }
}


export default new UserAPIService(`${env.API}api/`);
