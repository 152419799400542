import { call, put } from 'redux-saga/effects';
import { generateOtpFailure, generateOtpSuccess } from '../../index';
import userService from '../../../../../services/api/user';

export default function* generateQrCodeSaga(action) {
  try {
    const response = yield call([userService, userService.generateOtpSecret], action.payload);
    if (response && response.otp_auth_url) {
      yield put(generateOtpSuccess({ otpAuthUrl: response.otp_auth_url }));
    } else {
      throw new Error("OTP URL not received");
    }
  } catch (error) {
    if (error instanceof Error) {
      yield put(generateOtpFailure({ error: error.message }));
    } else {
      yield put(generateOtpFailure({ error: "Failed to generate QR Code" }));
    }
  }
}
