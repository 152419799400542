import { TD } from '../../types';

// ----------------

export namespace BaseDTO {
  /**
   * @deprecated
   */
  export type PaginatedList<ListItem> = {
    results: ListItem[];
    count: number; // Total count of items
    limit: number; // Items on one page
    pages: number; // Total count of pages
  };

  // --------

  export type _PaginatedList<ListItem> = {
    results: ListItem[];
    count: TD.Number; // Total count of items
    limit: TD.Number; // Items on one page
    pages: TD.Number; // Total count of pages
  };
}
