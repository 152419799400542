import { toast } from 'react-toastify';
import debounce from 'debounce';
import load from 'audio-loader';
import play from 'audio-play';
import WS from './ws';

// Config

import { globalObj, localStorageKeys } from '../../config/app';
import env from '../../config/env';

// Redux

import { notificationsSlice } from '../../redux';

// Entity

import { NotificationDTO, notificationMapper, NotificationCommon } from '../../entity/notification';

// Statics

// @ts-ignore
import mp3Notify from '../../statics/sounds/base-notify.mp3';

// -------- Types --------

export type SocketMessageType = 'notification';

// ----------------

class MainStream extends WS {
  newNotificationCount = 0;

  constructor() {
    super(`${env.API.replace('https:', 'wss:')}ws/`, (socket) => {
      socket.send(
        JSON.stringify({
          type: 'authenticate',
          jwt: localStorage.getItem(localStorageKeys.token),
        })
      );
    });

    this.toastAndSoundForNotification = debounce(this.toastAndSoundForNotification, 2000);
    this.handleMessages = this.handleMessages.bind(this);
  }

  private handleMessages(e) {
    const data = JSON.parse(e.data);
    const type: SocketMessageType = data.type as SocketMessageType;

    switch (type) {
      case 'notification': {
        const message = notificationMapper.toDO(data.message as NotificationDTO.NotificationDTO);

        switch (true) {
          case message.type === NotificationCommon.NotificationType.actionUpdateFiber:
          case message.type === NotificationCommon.NotificationType.actionUpdateTransmission: {
            globalObj.reduxStore?.dispatch(notificationsSlice.actions.addItemToList(message, 'action'));

            break;
          }

          case message.type === NotificationCommon.NotificationType.updateFiber:
          case message.type === NotificationCommon.NotificationType.updateTransmission: {
            globalObj.reduxStore?.dispatch(notificationsSlice.actions.addItemToList(message, 'general'));

            break;
          }
        }

        this.newNotificationCount++;
        this.toastAndSoundForNotification();

        break;
      }
    }
  }

  initMessages() {
    this.socket.onmessage = this.handleMessages;
  }

  toastAndSoundForNotification() {
    toast.info(`You have ${this.newNotificationCount} new ${this.newNotificationCount > 1 ? 'notifications' : 'notification'}`);
    load(mp3Notify).then(play);
    this.newNotificationCount = 0;
  }
}

export default new MainStream();
