import { B2BDO } from '../../../entity/b2b';

// -------- Types --------

export type B2BBranch = {
  list: B2BDO[];
  item: B2BDO;
  meta: {
    totalCount: number;
  };
};

// -------- Initial state of branch --------

export const b2bBranchInitialState: B2BBranch = {
  list: [],
  item: {} as B2BDO,
  meta: { totalCount: 0 },
};
