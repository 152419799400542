import React from 'react';

// Components

import MUIButton, { ButtonProps as MUIButtonProps } from '@mui/material/Button';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

// -------- Types --------

export type ButtonProps = MUIButtonProps & {
  circularProgressProps?: CircularProgressProps;
  loading?: boolean;
};

// ----------------

const Button: React.FC<ButtonProps> = (props) => {
  // prettier-ignore
  const progressSizes: Record<'small' | 'medium' | 'large', number> = {
    small : 18,
    medium: 22,
    large : 26,
  };
  const progressSize = progressSizes[props.size!];

  const buttonProps = { ...props };
  delete buttonProps.circularProgressProps;
  delete buttonProps.loading;

  return (
    <MUIButton
      disableElevation
      {...buttonProps}
      disabled={props.loading || buttonProps.disabled}
      sx={{ borderRadius: '6px', fontWeight: 600, lineHeight: 1, textTransform: 'none', boxShadow: 'none', ...buttonProps.sx }}
    >
      {props.loading ? (
        <>
          <CircularProgress
            color={props.color}
            size={progressSize}
            sx={{
              position: 'absolute',
              right: '50%',
              left: '50%',
              ml: `-${progressSize / 2}px`,
              mr: `-${progressSize / 2}px`,
            }}
            {...props.circularProgressProps}
          />
          {props.children}
        </>
      ) : (
        props.children
      )}
    </MUIButton>
  );
};

Button.defaultProps = {
  variant: 'contained',
  color: 'primary',
  size: 'large',
};

export default Button;
