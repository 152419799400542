import { cartographyActionCreators } from './cartographyActionCreators';
import { cartographySlice as slice } from './cartographySlice';

const cartographySlice = {
  ...slice,
  actions: { ...slice.actions, ...cartographyActionCreators },
};

export { cartographySlice };
export * from './cartographyBranch';
export * from './saga';
