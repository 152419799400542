import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { historySlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';

// ----------------

export function* historySaga() {
  yield all([takeEvery([historySlice.actions.getPaginatedList$Req.type], requestActionSaga)]);
}
