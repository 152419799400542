const leasedLineEN = {
  // prettier-ignore
  label: {
    siteReadyForInstallationTarget: 'Site ready for installation target',
    siteReadyForInstallation      : 'Site ready for installation',
    lineTerminationCompleted      : 'Line termination completed',
    lineTerminationPlanned        : 'Line termination planned',
    authorizationReceived         : 'Authorization received',
    fiberWorksCompleted           : 'Fiber works completed',
    targetAccessProfile           : 'Target access profile',
    activeAccessProfile           : 'Active access profile',
    finalTestCompleted            : 'Final test completed',
    trenchingCompleted            : 'Trenching completed',
    activeAccessSpeed             : 'Active access speed',
    fiberWorksPlanned             : 'Fiber works planned',
    targetAccessSpeed             : 'Target access speed',
    deliveryForecast              : 'Delivery forecast',
    provisioningType              : 'Provisioning type',
    trenchingPlanned              : 'Trenching planned',
    readyForBilling               : 'Ready for billing',
    omsActionStatus               : 'OMS action status',
    activeAccessCPE               : 'Active access CPE',
    targetAccessCPE               : 'Target access CPE',
    npsAppointment                : 'NPS appointment',
    pmRFBForecast                 : 'PM RFB forecast',
    omsActionType                 : 'OMS action type',
    siteScenario                  : 'Site scenario',
    ssvCompleted                  : 'SSV completed',
    pmExtraCost                   : 'PM extra cost',
    projectName                   : 'Project name',
    statusList                    : 'Status list',
    ssvPlanned                    : 'SSV planned',
    pmRemarks                     : 'PM remarks',
    siteReady                     : 'Site ready',
    omsStatus                     : 'OMS status',
    pmProject                     : 'PM project',
    siteName                      : 'Site name',
    omsOrder                      : 'OMS order',
    pmStatus                      : 'PM status',
    dsid                          : 'DSID',
  },

  section: {
    siteReadinessAndBilling: 'Site Readiness & Billing',
    equipmentAndProfiles: 'Equipment & Profiles',
    fiberWorksPlanning: 'Fiber works planning',
    pmInformation: 'PM Information',
    lineReference: 'Line reference',
  },
};

export default leasedLineEN;
