import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { glossaryBranchInitialState } from './glossaryBranch';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { GlossariesDO } from '../../../entity/glossary';
import { GlossaryDO } from '../../../entity/glossary_n';

// ----------------

export const glossarySlice = createSlice({
  name: `${reduxActionTypePrefix}/glossary`,
  initialState: glossaryBranchInitialState,

  // -------- Reducers --------

  reducers: {
    merge: (state, action: PayloadAction<{ data: Partial<GlossariesDO> }>) => {
      state.glossaries = { ...state.glossaries, ...action.payload.data };
    },

    // --------

    merge_: (state, action: PayloadAction<{ data: GlossaryDO.ListByType }>) => {
      state.glossaries_ = { ...state.glossaries_, ...action.payload.data };
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return glossaryBranchInitialState;
    });
  },
});
