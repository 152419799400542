import { all, takeEvery } from 'redux-saga/effects';

// Slice

import txFilesSlice from '../index';

// Saga

import deleteTXFileSaga from './deleteTXFileSaga/deleteTXFileSaga';
import getTXFilesSaga from './getTXFilesSaga/getTXFilesSaga';
import addTXFileSaga from './addTXFileSaga/addTXFileSaga';

// ----------------

export default function* () {
  yield all([takeEvery(txFilesSlice.actions.deleteTXFile$Req.type, deleteTXFileSaga)]);
  yield all([takeEvery(txFilesSlice.actions.getTXFiles$Req.type, getTXFilesSaga)]);
  yield all([takeEvery(txFilesSlice.actions.addTXFile$Req.type, addTXFileSaga)]);
}
