import breakpoints from './breakpoints';
import typography from './typography';
import components from './components';
import palette from './palette';
import spacing from './spacing';

// ----------------

export default {
  breakpoints,
  typography,
  components,
  palette,
  spacing,
};

export { breakpoints, typography, components, palette, spacing };
