/**
 * Return object without keys that has undefined values
 *
 * @param object
 */

export default function omitUndefined<T extends object>(object: T): T {
  const objectKeys = Object.keys(object);
  const newObjet = {} as T;

  objectKeys.forEach((key) => {
    if (object[key] !== undefined) {
      newObjet[key] = object[key];
    }
  });

  return newObjet;
}
