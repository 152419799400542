// Slice

import transmissionSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import transmissionService from '../../../../../services/api/transmission';

// Entity

import { mapper as transmissionMapper } from '../../../../../entity/transmission';

// ----------------

export default function* getByIdSaga(action: ReturnType<typeof transmissionSlice.actions.getById$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.id],
    service: transmissionService.getById,
    context: transmissionService,
    onSuccess: function* (data) {
      const dataDO = transmissionMapper.toDO(data);

      if (action.meta.onSuccess) {
        action.meta.onSuccess(dataDO);
      }
    },
  });
}
