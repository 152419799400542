import { Glossary } from '../../../types';

// ----------------

const glossaryTransformer = {
  // ======== Base ========

  glossary(data: Glossary.Base.IGlossaryAPI): Glossary.Base.IGlossary {
    return {
      label: data.value,
      value: data.id,
    };
  },

  // ======== API ========

  // -------- glossary.root --------

  glossaryRoot$Get$Response$API(data: Glossary.Api.GlossaryRoot$Get$Response$API): Glossary.Api.GlossaryRoot$Get$Response {
    return {
      radiositetype: data.radiositetype.map((item) => this.glossary(item)),
      subcontractor: data.subcontractor.map((item) => this.glossary(item)),
      clustermwingz: data.clustermwingz.map((item) => this.glossary(item)),
      splicepartner: data.splicepartner.map((item) => this.glossary(item)),
      fibernetwork: data.fibernetwork.map((item) => this.glossary(item)),
      ringatnname: data.ringatnname.map((item) => this.glossary(item)),
      radiostatus: data.radiostatus.map((item) => this.glossary(item)),
      fiberring: data.fiberring.map((item) => this.glossary(item)),
      microwave: data.microwave.map((item) => this.glossary(item)),
      bmstatus: data.bmstatus.map((item) => this.glossary(item)),
      status: data.status.map((item) => this.glossary(item)),
      family: data.family.map((item) => this.glossary(item)),
      techno: data.techno.map((item) => this.glossary(item)),
      pmobe: data.pmobe.map((item) => this.glossary(item)),
      city: data.city.map((item) => this.glossary(item)),
    };
  },
};

export default glossaryTransformer;
