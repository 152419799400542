import { Router as Router5, State } from 'router5';
import { provider } from '../../../index';
import { Router } from '../../../../types';

// Config

import { globalObj } from '../../../../config/app';

// Utils

import createSeparateRoutesNames from '../../utils/createSeparateRoutesNames/createSeparateRoutesNames';

// -------- Types --------

type Imports = Pick<Router.IRoute, 'branches'>;

/**
 * This middleware is responsible for the asynchronous
 * loading of branches required for a specific route.
 *
 * Only after success loading, route will be change.
 *
 * @param data - Data for middleware
 */

export default function asyncMiddleware(data: Router.IMiddlewareData) {
  return (router: Router5) => (toState: State, fromState: State, done: Router.DoneFN) => {
    const splittedName = toState.name.split('.');
    let separateRoutesNames: string[] = [];

    if (splittedName.length === 1) {
      separateRoutesNames.push(splittedName[0]);
    } else {
      separateRoutesNames = createSeparateRoutesNames(splittedName);
    }

    const imports: Imports = {
      branches: [],
    };

    separateRoutesNames.forEach((routeName) => {
      if (data[routeName].branches && data[routeName].branches?.length) {
        imports.branches!.push(...data[routeName].branches!);

        initializedRoutes.add(routeName);
      }
    });

    if (initializedRoutes.size > initializedRoutesLength && globalObj.loadingBar) {
      globalObj.loadingBar?.continuousStart(40, 20);
    }

    Promise.all(imports.branches!.map((b) => b[1]().then((res) => [b[0], res])))
      .then((res) => {
        // Branches

        res.forEach((chunk) => {
          // @ts-ignore
          provider.branch[chunk[0]] = chunk[1].default;
        });

        if (initializedRoutes.size > initializedRoutesLength) {
          globalObj.loadingBar?.complete();

          initializedRoutesLength = initializedRoutes.size;
        }

        done();
      })
      .catch((err) => {
        console.log('Imports error:', err);
      });
  };
}

const initializedRoutes = new Set();
let initializedRoutesLength = 0;
