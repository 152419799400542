// Redux

import txSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* uploadTXDocumentSaga(action: ReturnType<typeof txSlice.actions.uploadTXDocument$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    successMess: 'File successfully uploaded',
    serviceArgs: [action.payload.file],
    service: txService.uploadTXDocument,
    context: txService,
    onSuccess: function () {
      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
  });
}
