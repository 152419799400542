// -------- Types --------

export interface ISnackbarBranch {
  isOpen: boolean;
  text: string;
  type: 'success' | 'error' | 'warning' | 'info';
}

// -------- Initial state of branch --------

const snackbarBranchInitialState: ISnackbarBranch = {
  isOpen: false,
  text: '',
  type: 'success',
};

export default snackbarBranchInitialState;
