import { put } from 'redux-saga/effects';

// Redux

import txSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* getTXByIdSaga(action: ReturnType<typeof txSlice.actions.getTXById$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.params.id],
    service: txService.getTXById,
    context: txService,
    onSuccess: function* (data) {
      yield put(txSlice.actions.setSelected(data));

      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
  });
}
