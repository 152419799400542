import { DefaultFieldType } from '../components/AdvancedFiltersValueField';
import { Condition } from '../../../../../../../services/queryService';
import { Option } from '../../../../../../../types/component';

// ----------------

export function getConditionOptions(defaultFieldType: DefaultFieldType): Option<Condition>[] {
  const allConditionOptions: Option<Condition>[] = [
    { value: 'equal', label: 'Equal' },
    { value: 'not-equal', label: 'Does not equal' },
    { value: 'begin-with', label: 'Begins with' },
    { value: 'not-begin-with', label: 'Does not begin with' },
    { value: 'contain', label: 'Contains' },
    { value: 'not-contain', label: 'Does not contain' },
    { value: 'end-with', label: 'Ends with' },
    { value: 'not-end-with', label: 'Does not end with' },
    { value: 'less-than', label: 'Less than' },
    { value: 'greater-than', label: 'Greater than' },
    { value: 'less-than-or-equal', label: 'Less than or equal' },
    { value: 'greater-than-or-equal', label: 'Greater than or equal' },
    { value: 'empty', label: 'Empty' },
    { value: 'not-empty', label: 'Not empty' },
  ];

  switch (defaultFieldType) {
    case 'text': {
      return allConditionOptions;
    }

    case 'select':
    case 'multi-select': {
      const availableOptions: Array<Condition> = ['equal', 'not-equal', 'empty', 'not-empty'];

      return allConditionOptions.filter((item) => availableOptions.includes(item.value));
    }

    case 'date-picker': {
      const availableOptions: Array<Condition> = ['equal', 'not-equal', 'less-than', 'greater-than', 'empty', 'not-empty'];

      return allConditionOptions.filter((item) => availableOptions.includes(item.value));
    }

    case 'switch': {
      const availableOptions: Array<Condition> = ['equal', 'not-equal'];

      return allConditionOptions.filter((item) => availableOptions.includes(item.value));
    }

    default: {
      return [];
    }
  }
}
