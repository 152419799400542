import { LeasedLineDO } from '../../../entity/leasedLine';

// -------- Types --------

export type LeasedLineBranch = {
  item: LeasedLineDO.$ | null;
};

// -------- Initial state of branch --------

export const leasedLineBranchInitialState: LeasedLineBranch = {
  item: null,
};
