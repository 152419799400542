import { GlossaryDTO, GlossaryDO, GlossariesDTO, GlossariesDO, GlossaryTypeDTO, GlossaryTypeDO } from './';

// ----------------

const glossaryMapper = {
  toDO(data: GlossaryDTO): GlossaryDO {
    return {
      region: data.region,
      value: data.id,
      label: data.value,
      code: data.code,
    };
  },

  // --------

  glossariesToPartialDO(data: Partial<GlossariesDTO>): Partial<GlossariesDO> {
    const glossaries = {} as Partial<GlossariesDO>;
    const keys = Object.keys(data) as GlossaryTypeDTO[];

    keys.forEach((key) => {
      const type = this.glossaryTypeToDO(key);

      if (type) {
        glossaries[type] = data[key]?.map((glossary) => this.toDO(glossary));
      }
    });

    return glossaries;
  },

  // --------

  glossaryTypeToDO(glossaryType: GlossaryTypeDTO): GlossaryTypeDO {
    const glossaryTypeMap: Record<GlossaryTypeDTO, GlossaryTypeDO> = {
      reconstructionfinancialstatus: 'reconstructionFinancialStatus',
      reconstructionsubcontractor: 'reconstructionSubcontractor',
      reconstructioncheckasbuilt: 'reconstructionCheckAsBuilt',
      reconstructionstatus: 'reconstructionStatus',
      smallworkssubcontractor: 'smallWorksSubcontractor',
      smallworkscheckasbuilt: 'smallWorksCheckAsBuilt',
      b2bsubcontractor: 'b2bSubcontractor',
      deliveryforecast: 'deliveryForecast',
      consoyearmwingz: 'consoYearMwingz',
      radiositetype: 'radioSiteType',
      clustermwingz: 'clusterMwingz',
      subcontractor: 'subcontractor',
      splicepartner: 'splicePartner',
      fibernetwork: 'fiberNetwork',
      b2bequipment: 'b2bEquipment',
      ringatnname: 'ringAtnName',
      radiostatus: 'radioStatus',
      b2bnetwork: 'b2bNetwork',
      b2bpmfiber: 'b2bPmfiber',
      microwave: 'microwave',
      fiberring: 'fiberRing',
      b2bstatus: 'b2bStatus',
      accesscpe: 'accessCPE',
      bmstatus: 'bmStatus',
      project: 'project',
      mwingz: 'mwingz',
      status: 'status',
      family: 'family',
      techno: 'techno',
      region: 'region',
      pmobe: 'pmobe',
      speed: 'speed',
      city: 'city',
    };

    return glossaryTypeMap[glossaryType];
  },
};

export default glossaryMapper;
