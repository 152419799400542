// Redux

import { txSlice } from '../';

// Saga

import requestSaga from '../../../baseSaga/request';

// Services

import { txAPIService } from '../../../../services/api/txAPIService';

// ----------------

export function* bulkActionsSaga(action: ReturnType<typeof txSlice.actions.bulkActions$Req>) {
  let data: Partial<Parameters<typeof txAPIService.bulkUpdate>[0]['data']> = {};

  switch (action.payload.data.action) {
    case 'productionPlanReadinessLock': {
      data = {
        isProductionPlanReadinessLocked: true,
      };
      break;
    }

    case 'productionPlanReadinessUnlock': {
      data = {
        isProductionPlanReadinessLocked: false,
      };
      break;
    }
  }

  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [{ ids: action.payload.data.ids, data }],
    successMess: 'Sites successfully updated',
    service: txAPIService.bulkUpdate,
    context: txAPIService,
    onSuccess: function* () {
      action.meta.onSuccess();
    },
  });
}
