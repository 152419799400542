// -------- Types --------

export interface IRequestBranch {
  [key: string]: boolean | number;
}

// -------- Initial state of branch --------

const requestBranchInitialState: IRequestBranch = {};

export default requestBranchInitialState;
