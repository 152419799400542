const validateEN = {
  stringMaxLength: (count) => `Please enter a maximum of ${count} characters`,
  stringMinLength: (count) => `Please enter at least of ${count} characters`,
  confirmPassword: 'Password does not match',
  password: 'Please enter a valid password',
  required: 'Required field',
  regExp: 'Please follow pattern',
  digits: 'Please enter only digits',
  email: 'Please enter a valid email',
  int: 'Please enter integer digits',
};

export default validateEN;
