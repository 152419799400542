import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Entity

import { NotificationDO, NotificationDTO } from '../../../entity/notification';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/notifications/${actionPiece}`; // Create action type

export default {
  getList$Req: createAction(
    cat('getList$Req'),
    (props: {
      requestActionTypeData?: number;
      onSuccess?: (data: NotificationDO.NotificationListDO) => void;
      query?: NotificationDTO.NotificationListQueryDTO & Partial<{ is_general: boolean; is_call_to_action: boolean }>;
    }) => ({
      payload: {
        query: props.query,
      },
      meta: {
        requestActionTypeData: props.requestActionTypeData,
        onSuccess: props.onSuccess,
      },
    })
  ),

  // --------

  update$Req: createAction(
    cat('update$Req'),
    (props: { id: number; data: Partial<NotificationDTO.NotificationDTO>; onSuccess?: (data: NotificationDO.NotificationDO) => void }) => ({
      payload: {
        data: props.data,
        id: props.id,
      },
      meta: {
        onSuccess: props.onSuccess,
      },
    })
  ),

  // --------

  delete$Req: createAction(cat('delete$Req'), (props: { id: number; onSuccess?: () => void; message?: string }) => ({
    payload: {
      id: props.id,
    },
    meta: {
      onSuccess: props.onSuccess,
      message: props.message,
    },
  })),

  // --------

  deleteAll$Req: createAction(
    cat('deleteAll$Req'),
    (props: { onSuccess?: () => void; message?: string; query?: Partial<{ is_general: boolean; is_call_to_action: boolean }> }) => ({
      payload: {
        query: props.query,
      },
      meta: {
        onSuccess: props.onSuccess,
        message: props.message,
      },
    })
  ),

  // --------

  seeAll$Req: createAction(
    cat('seeAll$Req'),
    (props: { onSuccess?: () => void; message?: string; query?: Partial<{ is_general: boolean; is_call_to_action: boolean }> }) => ({
      payload: {
        query: props.query,
      },
      meta: {
        onSuccess: props.onSuccess,
        message: props.message,
      },
    })
  ),
};
