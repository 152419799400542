// Utils

import hasPermissions from '../../utils/hasPermissions/hasPermissions';

// Entities

import { UserCommon } from '../user';
import { B2BDO } from './';

// ----------------

export const b2bAnalyzer = {
  canEdit(data: B2BDO) {
    return hasPermissions([UserCommon.Permission.B2B_EDIT]);
  },

  // --------

  canDelete(data: B2BDO) {
    return hasPermissions([UserCommon.Permission.B2B_DELETE]);
  },

  // --------

  canArchive(data: B2BDO) {
    return !data.isArchived && hasPermissions([UserCommon.Permission.B2B_ARCHIVE]);
  },

  // --------

  canUnarchive(data: B2BDO) {
    return data.isArchived && hasPermissions([UserCommon.Permission.B2B_UNARCHIVE]);
  },
};
