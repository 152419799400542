import { DocumentDO } from '../../../entity/document';

// -------- Types --------

export type DocumentBranch = {
  list: DocumentDO.$[];
  meta: {
    totalCount: number;
  };
};

// -------- Initial state of branch --------

export const documentBranchInitialState: DocumentBranch = {
  list: [],
  meta: { totalCount: 0 },
};
