// Types

import { TableProps } from '../';

// -------- Types --------

export type TableLocalStorage<ColumnName extends string> = {
  sortDirection: TableProps<ColumnName>['sortDirection'];
  sortByColumn: TableProps<ColumnName>['sortByColumn'];
  rowsPerPage: TableProps<ColumnName>['rowsPerPage'];
  height?: number;
};

// ----------------

export function getTableLocalStorage<ColumnName extends string = string>(name: string) {
  let tableLocalStorage = {} as TableLocalStorage<ColumnName>;
  const tableId = `table-${name}`;

  try {
    tableLocalStorage = JSON.parse(localStorage.getItem(tableId) || '');
  } catch (err) {}

  return tableLocalStorage;
}
