import { NoteDO } from '../../../entity/note';

// -------- Types --------

export type NoteBranch = {
  list: NoteDO.$[];
  meta: {
    totalCount: number;
  };
};

// -------- Initial state of branch --------

export const noteBranchInitialState: NoteBranch = {
  list: [],
  meta: { totalCount: 0 },
};
