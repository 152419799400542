import React from 'react';

// Components

import Typography, { TypographyProps } from '../Typography';
import Box, { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// -------- Types --------

export type SectionProps = {
  /**
   * Typography props for the title
   */
  typographyProps?: TypographyProps;

  /**
   * If `true`, adding border to the bottom
   */
  border?: boolean;

  /**
   * If `true`, adding left and right spacing for the children
   */
  indent?: boolean;

  /**
   * Title of the section
   */
  title: string;

  /**
   * Affects the spacing and size of the title
   */
  size?: 'small' | 'medium' | 'large';

  children?: React.ReactNode;

  titleEndAdornment?: React.ReactNode;
};

// ----------------

const Section: React.FC<SectionProps> = (props) => {
  const propsBySize: Record<'small' | 'medium' | 'large', { title: TypographyProps; children: { sx: BoxProps['sx'] } }> = {
    small: {
      title: {
        variant: 'body1',
        weight: 600,
      },
      children: {
        sx: [{ [props.border ? 'py' : 'pt']: 1 }, props.indent! && { px: 1 }],
      },
    },

    medium: {
      title: {
        variant: 'title2',
      },
      children: {
        sx: [{ [props.border ? 'py' : 'pt']: '24px' }, props.indent! && { px: 2 }],
      },
    },

    large: {
      title: {
        variant: 'title1',
      },
      children: {
        sx: [{ [props.border ? 'py' : 'pt']: 3 }, props.indent! && { px: 2 }],
      },
    },
  };

  return (
    <Box sx={[props.border! && { borderBottom: '1px solid', borderColor: 'grey.300' }]}>
      <Stack direction="row" alignItems="center">
        <Typography {...propsBySize[props.size!].title} {...props.typographyProps}>
          {props.title}
        </Typography>
        {props.titleEndAdornment && <Box>{props.titleEndAdornment}</Box>}
      </Stack>
      <Box sx={propsBySize[props.size!].children.sx}>{props.children}</Box>
    </Box>
  );
};

Section.defaultProps = {
  border: false,
  indent: false,
  size: 'large',
};

export default Section;
