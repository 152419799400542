import initialState, { INotificationsBranch, GroupName } from './branch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';
import AC from './actionCreators';

// Entity

import { NotificationDO, NotificationListDO } from '../../../entity/notification/DO';

// Saga

import notificationsSaga from './saga';

// ----------------

const notificationsSlice = createSlice({
  name: `${reduxActionTypePrefix}/notifications`,
  initialState,

  // -------- Reducers --------

  reducers: {
    setList: {
      prepare: (data: NotificationListDO, group: GroupName) => ({
        payload: {
          group,
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: NotificationListDO; group: GroupName }>) => {
        const group = action.payload.group;

        state.groups[group] = {
          unreadCount: action.payload.data.unreadCount,
          loadedItems: state.groups[group].loadedItems + action.payload.data.list.length,
          totalItems: action.payload.data.count,
          list: action.payload.data.list,
        };
      },
    },

    // --------

    addItemToList: {
      prepare: (data: NotificationDO, group: GroupName) => ({
        payload: {
          data,
          group,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: NotificationDO; group: GroupName }>) => {
        const group = action.payload.group;

        state.groups[group].unreadCount++;
        state.groups[group].loadedItems++;
        state.groups[group].totalItems++;
        state.groups[group].list.unshift(action.payload.data);
      },
    },

    // --------

    addItemsToList: {
      prepare: (data: NotificationListDO, group: GroupName) => ({
        payload: {
          group,
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: NotificationListDO; group: GroupName }>) => {
        const group = action.payload.group;

        state.groups[group].list.push(...action.payload.data.list);
        state.groups[group].totalItems = action.payload.data.count;
        state.groups[group].loadedItems = state.groups[group].loadedItems + action.payload.data.list.length;
        state.groups[group].unreadCount = action.payload.data.unreadCount;
      },
    },

    // --------

    updateListItem: {
      prepare: (data: Partial<NotificationDO>, group: GroupName) => ({
        payload: {
          group,
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: Partial<NotificationDO>; group: GroupName }>) => {
        const group = action.payload.group;

        state.groups[group].list = state.groups[group].list.map((item) =>
          item.id !== action.payload.data.id ? item : { ...item, ...action.payload.data }
        );
      },
    },

    // --------

    deleteListItem: {
      prepare: (id: number, group: GroupName) => ({
        payload: {
          group,
          id,
        },
      }),

      reducer: (state, action: PayloadAction<{ id: number; group: GroupName }>) => {
        const group = action.payload.group;

        const deletedItem = state.groups[group].list.find((item) => item.id === action.payload.id);

        state.groups[group].list = state.groups[group].list.filter((item) => item.id !== action.payload.id);
        state.groups[group].totalItems--;
        state.groups[group].loadedItems--;

        if (!deletedItem?.isSeen) {
          state.groups[group].unreadCount--;
        }
      },
    },

    // --------

    deleteAll: (state, action: PayloadAction<{ group: GroupName }>) => {
      const group = action.payload.group;

      state.groups[group].list = [];
      state.groups[group].totalItems = 0;
      state.groups[group].loadedItems = 0;
      state.groups[group].unreadCount = 0;
    },

    // --------

    seeAll: (state, action: PayloadAction<{ group: GroupName }>) => {
      const group = action.payload.group;

      state.groups[group].list = state.groups[group].list.map((item) => ({ ...item, isSeen: true }));
      state.groups[group].unreadCount = 0;
    },

    // --------

    decrementUnreadCount: (state, action: PayloadAction<{ group: GroupName }>) => {
      const group = action.payload.group;

      state.groups[group].unreadCount--;
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      return initialState;
    });
  },
});

export { initialState, notificationsSaga };
export type { INotificationsBranch };
export default { ...notificationsSlice, actions: { ...notificationsSlice.actions, ...AC } };
