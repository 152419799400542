import { reconstructionActionCreators } from './reconstructionActionCreators';
import { reconstructionSlice as slice } from './reconstructionSlice';

const reconstructionSlice = {
  ...slice,
  actions: { ...slice.actions, ...reconstructionActionCreators },
};

export { reconstructionSlice };
export * from './reconstructionBranch';
export * from './saga';
