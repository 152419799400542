import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { documentSlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';

// ----------------

export function* documentSaga() {
  yield all([
    takeEvery(
      [
        documentSlice.actions.getPaginatedList$Req.type,
        documentSlice.actions.create$Req.type,
        documentSlice.actions.update$Req.type,
        documentSlice.actions.delete$Req.type,
      ],
      requestActionSaga
    ),
  ]);
}
