import { IConfig } from '../../useForm';

/**
 * @param values - Form values for submitting
 */

export default function prepareValuesForSubmit<V extends object>(values: V, fields: IConfig<V>['fields']): Record<keyof V, any> {
  const preparetedValues = {} as Record<keyof V, any>;
  const valuesKeys = Object.keys(values);

  valuesKeys.forEach((key) => {
    const field = fields.find((item) => item.name === key);

    if (field?.prepareValueForSubmit) {
      preparetedValues[key] = field.prepareValueForSubmit(values);
    } else {
      switch (typeof values[key]) {
        case 'string': {
          if (values[key] === '') {
            preparetedValues[key] = values[key];
          } else {
            preparetedValues[key] = values[key].trim();
          }

          break;
        }

        default: {
          preparetedValues[key] = values[key];
        }
      }
    }
  });

  return preparetedValues;
}
