import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { ChartDTO, chartMapper } from '../../entity/chart';
interface SubcontractorData {
  name: string;
  value: number;
}

interface StatusChartData {
  status: string;
  data: SubcontractorData[];
}
export namespace ChartAPIService {
  // ----------------

  class ChartAPIService extends HTTP {
    getNetworkRatioFttn() {
      return this.request<Array<{ fiberdata__network: number; count: number }>>({
        url: api.chart.networkRatioFttn,
      }).then((data) => {
        return data!.map((item) => ({
          network: item.fiberdata__network,
          value: item.count,
        }));
      });
    }

    getB2BStatusData(): Promise<StatusChartData[]> {
      return this.request<{ [key: string]: { [subcontractor: string]: number } }>({
        url: api.chart.b2bStatusData,
      }).then(response => {
        if (typeof response !== 'object' || response === null) {
          console.error("Expected an object, received:", response);
          return []; // Handling unexpected formats gracefully
        }
        return Object.entries(response).map(([status, subcontractors]) => ({
          status,
          data: Object.entries(subcontractors)
            .filter(([subcontractorName]) => subcontractorName.trim() !== "null")
            .map(([subcontractorName, count]) => ({
              name: subcontractorName.trim(), // Clean the names and remove extra spaces
              value: count
            }))
        }));
      });
    }



    // --------

    getSubcontractorRatioFttn() {
      return this.request<Array<{ fiberdata__subcontractor: number; count: number }>>({
        url: api.chart.subcontractorRatioFttn,
      }).then((data) => {
        return data!.map((item) => ({
          subcontractor: item.fiberdata__subcontractor,
          value: item.count,
        }));
      });
    }

    // --------

    getFttnDSOngoingNetwork() {
      return this.request<Array<{ fiberdata__network: number; double_stream: number; count_implemented: number; count_not_implemented: number }>>({
        url: api.chart.fttnDSOngoingNetwork,
      }).then((data) => {
        return data!.map((item) => ({
          notImplementedValue: item.count_not_implemented,
          implementedValue: item.count_implemented,
          network: item.fiberdata__network,
        }));
      });
    }

    // --------

    getFttnDSOngoingSubcontractor() {
      return this.request<
        Array<{ fiberdata__subcontractor: number; double_stream: number; count_implemented: number; count_not_implemented: number }>
      >({
        url: api.chart.fttnDSOngoingSubcontractor,
      }).then((data) => {
        return data!.map((item) => ({
          notImplementedValue: item.count_not_implemented,
          implementedValue: item.count_implemented,
          subcontractor: item.fiberdata__subcontractor,
        }));
      });
    }

    // --------

    getTargetStreamFttnDistributionNetwork() {
      return this.request<Array<{ fiberdata__network: number; target_techno: number; count_implemented: number; count_not_implemented: number }>>({
        url: api.chart.targetStreamFttnDistributionNetwork,
      }).then((data) => {
        return data!.map((item) => ({
          notImplementedValue: item.count_not_implemented,
          implementedValue: item.count_implemented,
          network: item.fiberdata__network,
        }));
      });
    }

    // --------

    getTargetStreamFttnDistributionSubcontractor() {
      return this.request<
        Array<{ fiberdata__subcontractor: number; target_techno: number; count_implemented: number; count_not_implemented: number }>
      >({
        url: api.chart.targetStreamFttnDistributionSubcontractor,
      }).then((data) => {
        return data!.map((item) => ({
          notImplementedValue: item.count_not_implemented,
          implementedValue: item.count_implemented,
          subcontractor: item.fiberdata__subcontractor,
        }));
      });
    }

    // --------

    getSplicePartnerRatioFttn() {
      return this.request<Array<{ fiberdata__splice_partner: number | null; count: number }>>({
        url: api.chart.splicePartnerRatioFttn,
      }).then((data) => {
        return data!.map((item) => ({
          splicePartner: item.fiberdata__splice_partner,
          value: item.count,
        }));
      });
    }

    // --------

    getAverageTimeBetweenFiberWorksStartAndFinish() {
      return this.request<Array<{ fiberdata__subcontractor: number; average_time: number; count: number }>>({
        url: api.chart.averageTimeBetweenFiberWorksStartAndFinish,
      }).then((data) => {
        return data!.map((item) => ({
          subcontractor: item.fiberdata__subcontractor,
          averageTime: item.average_time,
          value: item.count,
        }));
      });
    }

    // --------

    getAverageTimeBetweenFiberInsertedRing() {
      return this.request<Array<{ fiberdata__subcontractor: number; average_time: number; count: number }>>({
        url: api.chart.averageTimeBetweenFiberInsertedRing,
      }).then((data) => {
        return data!.map((item) => ({
          subcontractor: item.fiberdata__subcontractor,
          averageTime: item.average_time,
          value: item.count,
        }));
      });
    }

    // --------

    getAverageTimeBetweenFiberCivilStartAndFinish() {
      return this.request<Array<{ fiberdata__subcontractor: number; average_time: number; count: number }>>({
        url: api.chart.averageTimeBetweenFiberCivilStartAndFinish,
      }).then((data) => {
        return data!.map((item) => ({
          subcontractor: item.fiberdata__subcontractor,
          averageTime: item.average_time,
          value: item.count,
        }));
      });
    }




    // --------

    getCurrenToDSFttnChange() {
      return this.request<Array<{ current_techno: number; count: number }>>({
        url: api.chart.currenToDSFttnChange,
      }).then((data) => {
        return data!.map((item) => ({
          currentTechno: item.current_techno,
          value: item.count,
        }));
      });
    }

    // --------

    getSwappedSites() {
      return this.request<{ microwave: number; fiberized: number }>({
        url: api.chart.swappedSites,
      }).then((data) => {
        return { microwave: data!.microwave, fiberized: data!.fiberized };
      });
    }

    // --------

    getTargetTxDeployed() {
      return this.request<{ microwave: number; fiberized: number }>({
        url: api.chart.targetTxDeployed,
      }).then((data) => {
        return { microwave: data!.microwave, fiberized: data!.fiberized };
      });
    }

    // --------

    getTxReadinessFiber(queries: { includeObeUnilateral: boolean }, year: number) {
      return this.request<ChartDTO.TXBaseline>({
        params: queries,
        url: api.chart.txReadinessFiber(year),
      }).then((data) => {
        return chartMapper.txBaselineToDO(data!);
      });
    }

    // --------

    getTxReadinessMicrowave(queries: { includeObeUnilateral: boolean }, year: number) {
      return this.request<ChartDTO.TXBaseline>({
        params: queries,
        url: api.chart.txReadinessMicrowave(year),
      }).then((data) => {
        return chartMapper.txBaselineToDO(data!);
      });
    }

    // --------

    getTxTargetDeployedFiber(queries: { includeObeUnilateral: boolean }, year: number) {
      return this.request<ChartDTO.TXBaseline>({
        params: queries,
        url: api.chart.txTargetDeployedFiber(year),
      }).then((data) => {
        return chartMapper.txActualsToDO(data!);
      });
    }

    getTxTargetDeployedMicrowave(queries: { includeObeUnilateral: boolean }, year: number) {
      return this.request<ChartDTO.TXBaseline>({
        params: queries,
        url: api.chart.txTargetDeployedMicrowave(year),
      }).then((data) => {
        return chartMapper.txActualsToDO(data!);
      });
    }


    // --------

    exportNetworkRatioFttn() {
      return this.request({
        method: 'post',
        url: api.chart.exportNetworkRatioFttn,
      });
    }

    // --------

    exportSubcontractorRatioFttn() {
      return this.request({
        method: 'post',
        url: api.chart.exportSubcontractorRatioFttn,
      });
    }

    // --------

    exportFttnDSOngoingNetwork() {
      return this.request({
        method: 'post',
        url: api.chart.exportFttnDSOngoingNetwork,
      });
    }

    // --------

    exportFttnDSOngoingSubcontractor() {
      return this.request({
        method: 'post',
        url: api.chart.exportFttnDSOngoingSubcontractor,
      });
    }

    // --------

    exportTargetStreamFttnDistributionNetwork() {
      return this.request({
        method: 'post',
        url: api.chart.exportTargetStreamFttnDistributionNetwork,
      });
    }

    // --------

    exportTargetStreamFttnDistributionSubcontractor() {
      return this.request({
        method: 'post',
        url: api.chart.exportTargetStreamFttnDistributionSubcontractor,
      });
    }

    // --------

    exportSplicePartnerRatioFttn() {
      return this.request({
        method: 'post',
        url: api.chart.exportSplicePartnerRatioFttn,
      });
    }

    // --------

    exportAverageTimeBetweenFiberWorksStartAndFinish() {
      return this.request({
        method: 'post',
        url: api.chart.exportAverageTimeBetweenFiberWorksStartAndFinish,
      });
    }

    // --------

    exportAverageTimeBetweenFiberInsertedRing() {
      return this.request({
        method: 'post',
        url: api.chart.exportAverageTimeBetweenFiberInsertedRing,
      });
    }

    // --------

    exportAverageTimeBetweenFiberCivilStartAndFinish() {
      return this.request({
        method: 'post',
        url: api.chart.exportAverageTimeBetweenFiberCivilStartAndFinish,
      });
    }

    // --------

    exportCurrenToDSFttnChange() {
      return this.request({
        method: 'post',
        url: api.chart.exportCurrenToDSFttnChange,
      });
    }

    // --------

    exportSwappedSites() {
      return this.request({
        method: 'post',
        url: api.chart.exportSwappedSites,
      });
    }

    // --------

    exportTargetTxDeployed() {
      return this.request({
        method: 'post',
        url: api.chart.exportTargetTxDeployed,
      });
    }

    // --------

    getFiberRing(txId: number) {
      return this.request<ChartDTO.FiberRing>({
        url: api.chart.fiberRing(txId),
      }).then((data) => {
        return chartMapper.fiberRingToDO(data!);
      });
    }

    // --------

    getTechnologiesDistributionHistory() {
      return this.request<ChartDTO.TechnologiesDistributionHistory>({
        url: api.chart.technologiesDistributionHistory,
      }).then((data) => chartMapper.technologiesDistributionHistoryToDO(data!));
    }

    // --------

    getRanSharingBackhaulEvolution() {
      return this.request<ChartDTO.RanSharingBackhaulEvolution>({
        url: api.chart.ranSharingBackhaulEvolution,
      }).then((data) => chartMapper.ranSharingBackhaulEvolutionToDO(data!));
    }
  }

  export const chartAPIService = new ChartAPIService(`${env.API}api/`);
}

export const chartAPIService = ChartAPIService.chartAPIService;
