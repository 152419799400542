import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { LeasedLineDTO, LeasedLineDO, leasedLineMapper } from '../../entity/leasedLine';
import { TXDTO, TXDO, txMapper } from '../../entity/tx';
import { EnumDTO, enumMapper } from '../../entity/enum';

// ----------------

class EnumAPIService extends HTTP {
  getLeasedLine() {
    return this.request<EnumDTO.OptionsResponse<keyof LeasedLineDTO.$>>({
      method: 'options',
      url: api.tx.leasedLine,
    }).then((data) => {
      if (data) {
        return enumMapper.optionsResponseToListByFields<keyof LeasedLineDTO.$, keyof LeasedLineDO.$>(data, leasedLineMapper.getDOKeysMap());
      }
    });
  }

  // --------

  getTx() {
    return this.request<EnumDTO.OptionsResponse<keyof TXDTO.$>>({
      method: 'options',
      url: api.tx.root,
    }).then((data) => {
      if (data) {
        return enumMapper.optionsResponseToListByFields<keyof TXDTO.$, keyof TXDO.$>(data, txMapper.getDTOKeysMap());
      }
    });
  }
}

export const enumAPIService = new EnumAPIService(`${env.API}api/`);
