import { put } from 'redux-saga/effects';

// Slice

import userSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import userService from '../../../../../services/api/user';

// Types

import { User } from '../../../../../types';

// ----------------

export default function* updateDataSaga(action: ReturnType<typeof userSlice.actions.updateData$Req>) {
  if (action.meta.optimistic) {
    yield put(userSlice.actions.updateData(action.payload.data));
  }

  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.data],
    service: userService.updateData,
    context: userService,
    onSuccess: function* (res) {
      if (!action.meta.optimistic) {
        yield put(userSlice.actions.setData(res));
      }
    },
  });
}
