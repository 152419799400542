export namespace GlossaryCommon {
  export namespace Type {
    export type Item = Common;

    export type List = Array<Item>;

    // --------

    export enum Common {
      accessCPE = 'accesscpe',
      speed = 'speed',
    }
  }

  export namespace Code {
    export enum Family {
      leasedLine = 'leased_line',
      microwave = 'microwave',
      ongoing = 'ongoing',
      fttn = 'fttn',
      ftts = 'ftts',
    }

    // --------

    export enum RadioStatus {
      dismantled = 'dismantled',
      live = 'live',
    }

    // --------

    export enum Status {
      up = 'up',
    }

    // --------

    export enum BmStatus {
      keep = 'keep',
    }
  }
}

/**
 * `G` is shorthand for `GlossaryCommon.Type`
 */

export const G = GlossaryCommon.Type;
