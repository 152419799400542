import { txActionCreators } from './txActionCreators';
import { txSlice as slice } from './txSlice';

const txSlice = {
  ...slice,
  actions: { ...slice.actions, ...txActionCreators },
};

export { txSlice };
export * from './txBranch';
export * from './saga';
