import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { enumSlice } from '../index';

// Saga

import { getEnumsSaga } from './getEnumsSaga';

// ----------------

export function* enumSaga() {
  yield all([takeEvery(enumSlice.actions.getEnums$Req.type, getEnumsSaga)]);
}
