import React from 'react';

// Components

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '../TextField';
import Paper, { PaperProps } from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grow from '@mui/material/Grow';

// Types

import { StandardTextFieldProps } from '@mui/material/TextField';
import { Option } from '../../../..//types/component';

// -------- Types --------

export type MultiSelectProps = {
  /**
   *  Props for MUI `Autocomplete` component
   */
  autocompleteProps?: AutocompleteProps<Option, true, false, false>;

  /**
   *  Props for MUI `TextField` component
   */
  textFieldProps?: TextFieldProps;

  /**
   * @deprecated Use 'textFieldProps'
   */
  textField?: StandardTextFieldProps;

  /**
   *  List of options
   */
  options: Option[];

  /**
   *  Selected value
   */
  value: MultiSelectValue;

  onChange: (value: MultiSelectValue) => void;
};

export type MultiSelectValue = Array<Option['value']>;

// ----------------

const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  const sortedOptions = React.useMemo(() => {
    const options = [...props.options];

    options.sort((a, b) => {
      const firstValue = a.label.toLocaleLowerCase();
      const secondValue = b.label.toLocaleLowerCase();

      if (firstValue > secondValue) {
        return 1;
      }
      if (firstValue < secondValue) {
        return -1;
      }
      return 0;
    });

    return options;
  }, [props.options]);

  return (
    <Autocomplete<Option, true, false, false>
      noOptionsText={'It looks like there is no such option'}
      renderInput={(p) => (
        <TextField {...p} placeholder={'Select options from list'} label="Multi select" {...props.textField} {...props.textFieldProps} />
      )}
      renderOption={(props, option) => (
        <li {...props} style={{ padding: '8px 16px' }} key={option.value}>
          {option.label}
        </li>
      )}
      onChange={(e, option) => {
        props.onChange(option.map((item) => item.value));
      }}
      disableCloseOnSelect
      fullWidth
      multiple
      PaperComponent={PaperComponent}
      popupIcon={<ExpandMoreIcon />}
      options={sortedOptions}
      value={sortedOptions.filter((option) => props.value.some((value) => value == option.value))}
      ChipProps={{
        color: 'primary',
        size: 'medium',
      }}
      {...props.autocompleteProps}
    />
  );
};

export default MultiSelect;

/**
 * @deprecated Use 'multiSelectDefaultValue'
 */
export const MultiSelectDefaultValue = [];
export const multiSelectDefaultValue = [];

// -------- Additional components --------

const PaperComponent = (props: PaperProps) => (
  <Grow in timeout={300} style={{ transformOrigin: '50% 0 0' }}>
    <Paper {...props} variant="outlined" sx={{ my: 1, bgcolor: 'rgb(253,253,253)' }} />
  </Grow>
);
