import { put } from 'redux-saga/effects';

// Redux

import txSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* addTXSaga(action: ReturnType<typeof txSlice.actions.addTX$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    successMess: 'Site successfully created',
    serviceArgs: [action.payload.data],
    service: txService.addTX,
    context: txService,
    onSuccess: function* (data) {
      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
    onError: (err) => {
      if (action.meta.onError) {
        action.meta.onError(err?.response?.data);
      }
    },
  });
}
