import initialState, { IMicrowaveBranch } from './branch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';
import AC from './actionCreators';

// Entity

import { MicrowaveDO } from '../../../entity/microwave';

// Saga

import microwaveSaga from './saga';

// ----------------

const microwaveSlice = createSlice({
  name: `${reduxActionTypePrefix}/microwave`,
  initialState,

  // -------- Reducers --------

  reducers: {
    setData: {
      prepare: (data: MicrowaveDO.MicrowaveDO | null) => ({
        payload: {
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: MicrowaveDO.MicrowaveDO | null }>) => {
        state.data = action.payload.data;
      },
    },

    // --------

    updateData: {
      prepare: (data: Partial<MicrowaveDO.MicrowaveDO>) => ({
        payload: {
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: Partial<MicrowaveDO.MicrowaveDO> }>) => {
        if (state.data) {
          state.data = { ...state.data, ...action.payload.data };
        }
      },
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      return initialState;
    });
  },
});

export { initialState, microwaveSaga };
export type { IMicrowaveBranch };
export default { ...microwaveSlice, actions: { ...microwaveSlice.actions, ...AC } };
