// Slice

import slice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import notificationService from '../../../../../services/api/notification';

// ----------------

export default function* updateSaga(action: ReturnType<typeof slice.actions.update$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.id, action.payload.data],
    service: notificationService.update,
    context: notificationService,
    onSuccess: (data) => {
      if (action.meta.onSuccess) {
        action.meta.onSuccess(data);
      }
    },
  });
}
