import { put, call, all } from 'redux-saga/effects';

// Slice

import { userSlice as userSlice_n } from '../../../user_n';
import { glossarySlice } from '../../../glossary';
import _glossarySlice from '../../../_glossary';
import { coreSlice } from '../../../core';
import requestSlice from '../../../request';
import userSlice from '../../index';

// Services

import { glossaryAPIService } from '../../../../../services/api/glossaryAPIService';
import { coreAPIService } from '../../../../../services/api/coreAPIService';
import userService from '../../../../../services/api/user';

// Entities

import { GlossariesDO } from '../../../../../entity/glossary';
import { userMapper } from '../../../../../entity/user';
import { CoreDO } from '../../../../../entity/core';

// Types

import { User, Glossary } from '../../../../../types';
import { AxiosError } from 'axios';

// ----------------

export default function* getBaseDataSaga(action: ReturnType<typeof userSlice.actions.getBaseData$Req>) {
  // Add information about the new request action to the request branch

  yield put(requestSlice.actions.add(action.type));

  try {
    const res: [User.Base.IUser, Glossary.Base.IFullGlossary, GlossariesDO, CoreDO.EntityType[]] = yield all(
      [userService.getData, glossaryAPIService.getList, glossaryAPIService.getGlossaries, coreAPIService.getEntityTypeList].map((m) =>
        call([userService, m])
      )
    );

    // @ts-ignore
    yield put(userSlice_n.actions.set({ data: userMapper.toDO(res[0]) }));
    yield put(userSlice.actions.setData(res[0]));
    yield put(_glossarySlice.actions.setData(res[1]));
    yield put(glossarySlice.actions.merge({ data: res[2][0] }));
    yield put(glossarySlice.actions.merge_({ data: res[2][1] }));
    yield put(coreSlice.actions.setEntityType({ list: res[3] }));

    if (action.meta.onSuccess) {
      // @ts-ignore
      action.meta.onSuccess(userMapper.toDO(res[0]));
    }
  } catch (err) {
    if (action.meta.onError) {
      action.meta.onError(err as AxiosError);
    }
  } finally {
    // Delete information about the request action from the request branch

    yield put(requestSlice.actions.delete(action.type));
  }
}
