import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { SmallWorksDTO, SmallWorksDO, smallWorksMapper } from '../../entity/smallWorks';
import { baseMapper } from '../../entity/base';

// Types

import { FreeQueries, PaginatedListQueries } from '../queryService';

// -------- Types --------

export type GetPaginatedListQueries = PaginatedListQueries | FreeQueries | Partial<{ is_archived: boolean }>;

export type SendData = Partial<SmallWorksDO.$>;
export type SendDataErr = Partial<Record<keyof SmallWorksDTO.$, [string]>>;

// ----------------

class SmallWorksAPIService extends HTTP {
  getPaginatedList(queries: GetPaginatedListQueries) {
    return this.request<SmallWorksDTO.PaginatedList>({
      params: queries,
      url: api.smallWorks.root,
    }).then((data) => {
      return baseMapper.paginatedListToDO(data!, smallWorksMapper.toDO);
    });
  }

  // --------

  getItem(id: number) {
    return this.request<SmallWorksDTO.$>({
      url: api.smallWorks.id(id),
    }).then((data) => {
      return smallWorksMapper.toDO(data!);
    });
  }

  // --------

  export(fields: Array<keyof SmallWorksDO.$>, filters?: FreeQueries) {
    const keysMap = smallWorksMapper.getDOKeysMap();

    return this.request<null>({
      method: 'post',
      data: {
        fields: fields.map((field) => keysMap[field]),
        model: 'smallWorks.models.SmallWorks',
      },
      params: filters,
      url: api.core.export,
    });
  }

  // --------

  create(data: SendData) {
    return this.request<SmallWorksDTO.$>({
      method: 'post',
      data: smallWorksMapper.toDTO(data),
      url: api.smallWorks.root,
    }).then((data) => {
      return smallWorksMapper.toDO(data!);
    });
  }

  // --------

  update(data: SendData, id: number) {
    return this.request<SmallWorksDTO.$>({
      method: 'patch',
      data: smallWorksMapper.toDTO(data),
      url: api.smallWorks.id(id),
    }).then((data) => {
      return smallWorksMapper.toDO(data!);
    });
  }

  // --------

  delete(id: number) {
    return this.request<void>({
      method: 'delete',
      url: api.smallWorks.id(id),
    });
  }
}

export const smallWorksAPIService = new SmallWorksAPIService(`${env.API}api/`);
