import { TXMicrowaveSynopsisDTO } from './DTO';
import { TXMicrowaveSynopsisDO } from './DO';
import { microwaveMapper } from '../microwave';

// ----------------

const txMicrowaveSynopsisMapper = {
  toDO(data: TXMicrowaveSynopsisDTO): TXMicrowaveSynopsisDO {
    return {
      consoYearMwingz: data.conso_year_mwingz,
      microwaveData: microwaveMapper.toDO(data.microwave_data),
      radioStatus: data.radio_status,
      bmStatus: data.bm_status,
      siteId: data.site_id,
      status: data.status,
      path: data.path,
      id: data.id,

      site2g: data.site_2g,
      site3g: data.site_3g,
      site4g: data.site_4g,
      site5g: data.site_5g,
    };
  },

  // --------

  toPartialDTO(data: Partial<TXMicrowaveSynopsisDO>): Partial<TXMicrowaveSynopsisDTO> {
    return {
      radio_status: data.radioStatus,
      bm_status: data.bmStatus,
      site_id: data.siteId,
      status: data.status,
      path: data.path,
      id: data.id,

      site_2g: data.site2g,
      site_3g: data.site3g,
      site_4g: data.site4g,
      site_5g: data.site5g,
    };
  },
};

export default txMicrowaveSynopsisMapper;
