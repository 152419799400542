import { SmallWorksDO } from '../../../entity/smallWorks';

// -------- Types --------

export type SmallWorksBranch = {
  list: SmallWorksDO.$[];
  item: SmallWorksDO.$;
  meta: {
    totalCount: number;
  };
};

// -------- Initial state of branch --------

export const smallWorksBranchInitialState: SmallWorksBranch = {
  list: [],
  item: {} as SmallWorksDO.$,
  meta: { totalCount: 0 },
};
