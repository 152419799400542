import { glossarySlice } from '../';
import requestSaga from '../../../baseSaga/request';
import { put } from 'redux-saga/effects';

// Services

import { glossaryAPIService } from '../../../../services/api/glossaryAPIService';

// ----------------

export function* getListByTypeSaga(action: ReturnType<typeof glossarySlice.actions.getListByType$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    service: glossaryAPIService.getListByType,
    context: glossaryAPIService,
    onSuccess: function* (data) {
      if (action.payload.onSuccess) {
        action.payload.onSuccess(data);
      }

      yield put(glossarySlice.actions.merge_({ data }));
    },
  });
}
