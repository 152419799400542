import { b2bBranchInitialState, B2BBranch } from './b2bBranch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { B2BDO } from '../../../entity/b2b';

// ----------------

export const b2bSlice = createSlice({
  name: `${reduxActionTypePrefix}/b2b`,
  initialState: b2bBranchInitialState,

  // -------- Reducers --------

  reducers: {
    set: (state, action: PayloadAction<{ list?: B2BDO[]; meta?: Partial<B2BBranch['meta']> }>) => {
      if (action.payload.list) {
        state.list = action.payload.list;
      }

      if (action.payload.meta) {
        state.meta = { ...state.meta, ...action.payload.meta };
      }
    },

    // --------

    setItem: (state, action: PayloadAction<{ data: B2BDO }>) => {
      state.item = action.payload.data;
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return b2bBranchInitialState;
    });
  },
});
