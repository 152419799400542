import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { txAPIService, GetPaginatedListQueries, ExportQueries, BulkUpdateSendData } from '../../../services/api/txAPIService';

// Entities

import { TXDO } from '../../../entity/tx';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/tx/${actionPiece}`; // Create action type

export const txActionCreators = {
  getItem$Req: createAction(cat('getItem$Req'), (props: { onSuccess?: (data: TXDO.$) => void; onError?: (err: AxiosError) => void; id: number }): {
    payload: Partial<RequestSagaConfig<TXDO.$>>;
  } => {
    return {
      payload: {
        serviceArgs: [props.id],
        onSuccess: props.onSuccess,
        onError: props.onError,
        service: txAPIService.getItem,
        context: txAPIService,
        timeout: 300,
      },
    };
  }),

  // --------

  getPaginatedList$Req: createAction(
    cat('getPaginatedList$Req'),
    (props: {
      requestActionTypeData?: number;
      onSuccess?: (data: TXDO.PaginatedList) => void;
      onError?: (err: AxiosError) => void;
      queries?: GetPaginatedListQueries;
    }): { payload: Partial<RequestSagaConfig<TXDO.PaginatedList>> } => {
      return {
        payload: {
          requestActionTypeData: props.requestActionTypeData,
          serviceArgs: [props.queries],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.getPaginatedList,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  create$Req: createAction(
    cat('create$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: TXDO.$) => void;
      onError?: (err: AxiosError) => void;
      data: Partial<TXDO.$>;
    }): { payload: Partial<RequestSagaConfig<TXDO.$>> } => {
      return {
        payload: {
          serviceArgs: [props.data],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.create,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  update$Req: createAction(
    cat('update$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: TXDO.$) => void;
      onError?: (err: AxiosError) => void;
      data: Partial<TXDO.$>;
      id: number;
    }): { payload: Partial<RequestSagaConfig<TXDO.$>> } => {
      return {
        payload: {
          serviceArgs: [props.data, props.id],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.update,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  delete$Req: createAction(
    cat('delete$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      id: number;
    }): { payload: Partial<RequestSagaConfig<void>> } => {
      return {
        payload: {
          serviceArgs: [props.id],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.delete,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  dismantled$Req: createAction(
    cat('dismantled$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: TXDO.$) => void;
      onError?: (err: AxiosError) => void;
      id: number;
    }): { payload: Partial<RequestSagaConfig<TXDO.$>> } => {
      return {
        payload: {
          serviceArgs: [props.id],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.dismantled,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  cancelMove$Req: createAction(
    cat('cancelMove$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: TXDO.$) => void;
      onError?: (err: AxiosError) => void;
      id: number;
    }): { payload: Partial<RequestSagaConfig<TXDO.$>> } => {
      return {
        payload: {
          serviceArgs: [props.id],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.cancelMove,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  export$Req: createAction(
    cat('export$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      queries: ExportQueries;
    }): { payload: Partial<RequestSagaConfig<void>> } => {
      return {
        payload: {
          serviceArgs: [props.queries],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.export,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  uploadCandidates$Req: createAction(
    cat('uploadCandidates$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      file: File;
    }): { payload: Partial<RequestSagaConfig<void>> } => {
      return {
        payload: {
          serviceArgs: [props.file],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.uploadCandidates,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  upload$Req: createAction(
    cat('upload$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      file: File;
    }): { payload: Partial<RequestSagaConfig<void>> } => {
      return {
        payload: {
          serviceArgs: [props.file],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.upload,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  bulkUpdateFiber$Req: createAction(
    cat('bulkUpdateFiber$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      file: File;
    }): { payload: Partial<RequestSagaConfig<void>> } => {
      return {
        payload: {
          serviceArgs: [props.file],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.bulkUpdateFiber,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  bulkUpdateBlueMoon$Req: createAction(
    cat('bulkUpdateBlueMoon$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      file: File;
    }): { payload: Partial<RequestSagaConfig<void>> } => {
      return {
        payload: {
          serviceArgs: [props.file],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.bulkUpdateBlueMoon,
          context: txAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  getCandidateCount$Req: createAction(
    cat('getCandidateCount$Req'),
    (props: { onSuccess?: (count: number) => void; onError?: (err: AxiosError) => void }): { payload: Partial<RequestSagaConfig<number>> } => {
      return {
        payload: {
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: txAPIService.getCandidateCount,
          context: txAPIService,
        },
      };
    }
  ),

  // --------

  bulkActions$Req: createAction(cat('bulkActions$Req'), (data: { ids: number[]; action: TXDO.TableSelectedRowAction }, onSuccess: () => void) => ({
    payload: {
      data,
    },
    meta: {
      onSuccess,
    },
  })),
};
