import React from 'react';

// Components

import Popup, { PopupProps } from '../Popup';
import Button from '../Button';
import Stack from '@mui/material/Stack';

// -------- Types --------

export type Props = {
  /**
   * Sets the current variant for the form
   */
  variant?: Variant;

  /**
   * If `true`, shows the submitting process
   */
  submitLoading?: boolean;

  /**
   * Form component for the `create` and `edit` variant
   */
  form: React.ReactNode;

  /**
   * Read component for the `read` variant
   */
  read?: React.ReactNode;

  /**
   * Additional buttons to be displayed in the footer of the popup from the left
   */
  leftButtons?: Partial<Record<Variant, React.ReactNode[]>>;

  /**
   * If `true`, allows switching between `read` and `edit` variant
   */
  editable?: boolean;

  submitButtonText?: string;
  disabledSubmit?: boolean;
  popupProps?: Partial<PopupProps>;
  onSubmit?: () => void;
};

type Variant = 'read' | 'create' | 'edit';

// ----------------

const PopupForm: React.FC<Props> = (props) => {
  const [variant, setVariant] = React.useState<Variant>(props.variant!);

  const baseButtons: React.ReactNode[] = [];

  switch (variant) {
    case 'create': {
      baseButtons.push(
        <Button onClick={props.onSubmit} loading={props.submitLoading} disabled={props.disabledSubmit}>
          {props.submitButtonText || 'Save'}
        </Button>
      );

      break;
    }

    // --------

    case 'edit': {
      baseButtons.push(
        <Button onClick={props.onSubmit} loading={props.submitLoading} disabled={props.disabledSubmit}>
          {props.submitButtonText || 'Save'}
        </Button>
      );

      baseButtons.push(
        <Button
          variant="outlined"
          onClick={() => {
            if (props.editable) {
              setVariant('read');
            } else {
              props?.popupProps?.onClose && props.popupProps.onClose();
            }
          }}
        >
          Cancel
        </Button>
      );

      break;
    }

    // --------

    case 'read': {
      if (props.editable) {
        baseButtons.push(
          <Button
            onClick={() => {
              setVariant('edit');
            }}
          >
            Edit
          </Button>
        );
      }

      break;
    }
  }

  const footerComponents: React.ReactNode[] = [];

  if (baseButtons.length) {
    footerComponents.push(
      <Stack direction="row-reverse" spacing={2} key="base">
        {baseButtons.map((button, i) => (
          <div key={`base-${variant}-${i}`}>{button}</div>
        ))}
      </Stack>
    );
  }

  if (props.leftButtons && props.leftButtons[variant]?.length) {
    footerComponents.push(
      <Stack direction="row" spacing={2} key="left">
        {props.leftButtons[variant]?.map((button, i) => (
          <div key={`left-${i}`}>{button}</div>
        ))}
      </Stack>
    );
  }

  return (
    <Popup
      footerComponents={footerComponents.length ? footerComponents : undefined}
      footerStackProps={{ justifyContent: 'space-between' }}
      maxWidth="large"
      onClose={() => {}}
      title=""
      {...props.popupProps}
    >
      {variant === 'read' ? props.read : props.form}
    </Popup>
  );
};

PopupForm.defaultProps = {
  variant: 'create',
};

export default PopupForm;
export { Props as PopupFormProps };
