import { UserDO, UserDTO, UIStorage, userData } from './';
import deepmerge from 'deepmerge';

// ----------------

export const userMapper = {
  toDO(data: UserDTO): UserDO {
    return {
      isSuperUser: data.is_superuser,
      permission: data.dynamic_permissions,
      isVerified: data.is_verified,
      uiStorage: this.uiStorageToDO(data.ui_storage_v1),
      firstName: data.first_name,
      lastName: data.last_name,
      isActive: data.is_active,
      language: data.language,
      isStaff: data.is_staff,
      email: data.email,
      id: data.id,
    };
  },

  // --------

  toPartialDTO(data: Partial<UserDO>): Partial<UserDTO> {
    return {
      ui_storage_v1: data.uiStorage ? JSON.stringify(data.uiStorage) : undefined,
    };
  },

  // --------

  uiStorageToDO(json: string): UIStorage {
    let uiStorage = userData.uiStorageInit;

    try {
      const currentUIStorage = JSON.parse(json);
      uiStorage = deepmerge.all([uiStorage, currentUIStorage]) as UIStorage;
    } catch (err) {}

    return uiStorage;
  },
};
