import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Types

import { TX } from '../../../types';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/txNotes/${actionPiece}`; // Create action type

export default {
  getTXNotes$Req: createAction(cat('getTXNotes$Req'), (query: TX.Api.TXNotes$ReqQuery) => ({
    payload: {
      query,
    },
  })),

  addTXNote$Req: createAction(cat('addTXNote$Req'), (data: TX.Api.TXNotes$ReqBody, onSuccess?: () => void) => ({
    payload: {
      data,
    },
    meta: {
      onSuccess,
    },
  })),

  updateTXNote$Req: createAction(cat('updateTXNote$Req'), (data: TX.Api.TXNotes$Patch$ReqAPI, noteId: number, onSuccess?: () => void) => ({
    payload: {
      noteId,
      data,
    },
    meta: {
      onSuccess,
    },
  })),

  deleteTXNote$Req: createAction(cat('deleteTXNote$Req'), (id: number) => ({
    payload: {
      id,
    },
  })),
};
