import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { ReconstructionDTO, ReconstructionDO, reconstructionMapper } from '../../entity/reconstruction';
import { baseMapper } from '../../entity/base';

// Types

import { FreeQueries, PaginatedListQueries } from '../queryService';

// -------- Types --------

export type GetPaginatedListQueries = PaginatedListQueries | FreeQueries | Partial<{ is_archived: boolean }>;

export type SendData = Partial<ReconstructionDO.$>;
export type SendDataErr = Partial<Record<keyof ReconstructionDTO.$, [string]>>;

// ----------------

class ReconstructionAPIService extends HTTP {
  getPaginatedList(queries: GetPaginatedListQueries) {
    return this.request<ReconstructionDTO.PaginatedList>({
      params: queries,
      url: api.reconstruction.root,
    }).then((data) => {
      return baseMapper.paginatedListToDO(data!, reconstructionMapper.toDO);
    });
  }

  // --------

  getItem(id: number) {
    return this.request<ReconstructionDTO.$>({
      url: api.reconstruction.id(id),
    }).then((data) => {
      return reconstructionMapper.toDO(data!);
    });
  }

  // --------

  export(fields: Array<keyof ReconstructionDO.$>, filters?: FreeQueries) {
    const keysMap = reconstructionMapper.getDOKeysMap();

    return this.request<null>({
      method: 'post',
      data: {
        fields: fields.map((field) => keysMap[field]),
        model: 'reconstruction.models.Reconstruction',
      },
      params: filters,
      url: api.core.export,
    });
  }

  // --------

  create(data: SendData) {
    return this.request<ReconstructionDTO.$>({
      method: 'post',
      data: reconstructionMapper.toDTO(data),
      url: api.reconstruction.root,
    }).then((data) => {
      return reconstructionMapper.toDO(data!);
    });
  }

  // --------

  update(data: SendData, id: number) {
    return this.request<ReconstructionDTO.$>({
      method: 'patch',
      data: reconstructionMapper.toDTO(data),
      url: api.reconstruction.id(id),
    }).then((data) => {
      return reconstructionMapper.toDO(data!);
    });
  }

  // --------

  delete(id: number) {
    return this.request<void>({
      method: 'delete',
      url: api.reconstruction.id(id),
    });
  }
}

export const reconstructionAPIService = new ReconstructionAPIService(`${env.API}api/`);
