import { ToastContainer, ToastContainerProps, cssTransition } from 'react-toastify';
import React from 'react';

// Components

import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

// Hooks

import useIsMobile from '../../../../hooks/useIsMobile/useIsMobile';

// Styles

import 'react-toastify/dist/ReactToastify.css';
import useStyles from './Toast.styles';

// ----------------

const Toast: React.FC<ToastContainerProps> = (props) => {
  const isMobile = useIsMobile();
  const classes = useStyles(props);

  const Transition = cssTransition({
    appendPosition: false,
    collapse: false,
    enter: classes.showAnim,
    exit: classes.hideAnim,
  });

  return (
    <div className={classes.root}>
      <ToastContainer
        hideProgressBar={true}
        closeOnClick={false}
        newestOnTop={isMobile ? false : true}
        transition={Transition}
        draggable={false}
        autoClose={5000}
        position={isMobile ? 'top-center' : 'bottom-left'}
        closeButton={({ closeToast }) => (
          <IconButton className={classes.closeButton} onClick={closeToast}>
            <CancelIcon />
          </IconButton>
        )}
        limit={3}
        {...props}
      />
    </div>
  );
};

export default Toast;
