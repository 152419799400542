import { ThemeOptions } from '@mui/material/styles';

// ----------------

declare module '@mui/material/styles' {
  interface TypographyVariants {
    title1: React.CSSProperties;
    title2: React.CSSProperties;
  }

  // Allow configuration using `createTheme`

  interface TypographyVariantsOptions {
    title1?: React.CSSProperties;
    title2?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title1: true;
    title2: true;
  }
}

// ----------------

const typography: ThemeOptions['typography'] = {
  fontFamily: ['Apercu Pro', 'sans-serif'].join(','),
  fontSize: 14,

  body2: { lineHeight: 1.4 },

  body1: { lineHeight: 1.4 },

  subtitle2: {
    fontWeight: 600,
  },

  subtitle1: {
    fontWeight: 600,
  },

  title2: {
    fontWeight: 600,
    fontSize: 18,
  },

  title1: {
    fontWeight: 600,
    fontSize: 22,
  },

  h1: {
    fontWeight: 600,
    fontSize: 26,
  },
};

export default typography;
