import { createRoot } from 'react-dom/client';
import jwtDecode from 'jwt-decode';
import router from './router';

// Config

import { app } from './config';

// Components

import AppMaintenance from './components/view/template/AppMaintenance';
import App from './App';

// Redux

import createStore, { userSlice } from './redux';
import { Provider } from 'react-redux';

// Types

import { AxiosError } from 'axios';

// ----------------

(async () => {
  const store = createStore();
  app.globalObj.reduxStore = store;

  const splashScreen = document.getElementById('splashScreen');
  const container = document.getElementById('root') as HTMLElement;
  const root = createRoot(container);

  // Verification of authorization

  let tokenIsValid = true;
  const token = localStorage.getItem(app.localStorageKeys.token);

  if (!token) {
    tokenIsValid = false;
  } else {
    try {
      const jwt = jwtDecode(token);

      // @ts-ignore
      if (new Date(jwt?.exp * 1000) <= new Date()) {
        tokenIsValid = false;
      }
    } catch (err) {
      tokenIsValid = false;
    }
  }

  if (tokenIsValid) {
    try {
      await getRequiredData();
    } catch (err) {
      const error = err as AxiosError;

      if (error.response?.status === 503) {
        document.body.removeChild(splashScreen!);
        root.render(<AppMaintenance />);
      }

      return;
    }
  }

  // Normal render

  router.start(() => {
    root.render(
      <Provider store={store}>
        <App />
      </Provider>
    );

    setTimeout(() => {
      document.body.removeChild(splashScreen!);
    }, 300);
  });
})();

function getRequiredData() {
  return new Promise((res, rej) => {
    app.globalObj.reduxStore?.dispatch(
      userSlice.actions.getBaseData$Req(
        (data) => {
          res(data);
        },
        (err) => {
          rej(err);
        }
      )
    );
  });
}
