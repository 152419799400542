import Switch, { IRoute as ISwitchRoute } from './Switch';
import React, { useMemo } from 'react';
import { RouterProvider } from 'react-router5';
import { IRoute } from '../../../types/router';
import routes from '../../routes';
import router from '../../index';

// ----------------

const Router: React.FC = () => {
  const createSwitchedRoutes = (arr: IRoute[] = routes, base = '') => {
    const switchRoutes: ISwitchRoute[] = [];

    arr.forEach((route) => {
      let subRoutes;

      if (route.children) {
        subRoutes = createSwitchedRoutes(route.children, base ? `${base}.${route.name}` : route.name);
      }

      const switchRoute: ISwitchRoute = {
        branch: route.view,
        props: route.props,
        name: route.name,
        subRoutes,
        router,
      };

      switchRoutes.push(switchRoute);
    });

    return React.memo(() => <Switch base={base} routes={switchRoutes} />);
  };

  const SwitchedRoutes = useMemo(createSwitchedRoutes, []);

  return (
    <RouterProvider router={router}>
      <SwitchedRoutes />
    </RouterProvider>
  );
};

export default Router;
