import { timelineBranchInitialState, TimelineBranch } from './timelineBranch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { TimelineDO } from '../../../entity/timeline';

// ----------------

export const timelineSlice = createSlice({
  name: `${reduxActionTypePrefix}/timeline`,
  initialState: timelineBranchInitialState,

  // -------- Reducers --------

  reducers: {
    set: (state, action: PayloadAction<{ list: TimelineDO[]; meta?: TimelineBranch['meta'] }>) => {
      state.list = action.payload.list;

      if (action.payload.meta) {
        state.meta = action.payload.meta;
      }
    },

    // --------

    addMany: (state, action: PayloadAction<{ list: TimelineDO[] }>) => {
      state.list.push(...action.payload.list);
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return timelineBranchInitialState;
    });
  },
});
