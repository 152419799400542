import routeAlias, { topLevelRoutes } from './alias';
import browserPlugin from 'router5-plugin-browser';
import createRouter from 'router5';
import routes from './routes';

// Utils

import createSeparateRoutesNames from './modules/utils/createSeparateRoutesNames/createSeparateRoutesNames';
import initRouter from './modules/utils/initRouter/initRouter';

// Middlewares

import asyncMiddleware from './modules/middlewares/async';

// Components

import Router from './modules/components/Router';
import Link from './modules/components/Link';

// Config

import { app } from '../config';

// Types

import { IRouterProvider } from '../types/router';
import * as Query from './query';

// ----------------

export const provider: IRouterProvider = {
  branch: {},
};

// Router base options

const routerOptions = {
  queryParamsMode: 'default',
  defaultRoute: 'auth',
};

// Create and initialize router object

// @ts-ignore
const router = createRouter([], routerOptions);
const middlewareData = initRouter(routes, router);

// Sets plugins

router.usePlugin(browserPlugin());

// Sets middlewares

router.useMiddleware(asyncMiddleware(middlewareData));

// This function is called after each route change

router.subscribe(({ route, previousRoute }) => {
  let isSameRoute = false;

  if (previousRoute) {
    isSameRoute = route.name === previousRoute.name;
  }

  if (isSameRoute) {
    const onEnterOrChangeQuery = middlewareData[route.name].onEnterOrChangeQuery;
    const onChangeQuery = middlewareData[route.name].onChangeQuery;

    if (onChangeQuery) {
      onChangeQuery(app.globalObj.reduxStore!, route);
    }

    if (onEnterOrChangeQuery) {
      onEnterOrChangeQuery(app.globalObj.reduxStore!, route, false);
    }
  } else {
    const subRoutes = createSeparateRoutesNames(route.name.split('.'));

    if (previousRoute) {
      const prevSubRoutes = createSeparateRoutesNames(previousRoute.name.split('.'));

      subRoutes.forEach((routeName, index) => {
        if (routeName !== prevSubRoutes[index]) {
          const onEnterOrChangeQuery = middlewareData[routeName].onEnterOrChangeQuery;
          const onEnter = middlewareData[routeName].onEnter;

          if (onEnter) {
            onEnter(app.globalObj.reduxStore!, route);
          }

          if (onEnterOrChangeQuery) {
            onEnterOrChangeQuery(app.globalObj.reduxStore!, route, true);
          }
        }
      });
    } else {
      subRoutes.forEach((routeName) => {
        const onEnterOrChangeQuery = middlewareData[routeName].onEnterOrChangeQuery;
        const onEnter = middlewareData[routeName].onEnter;

        if (onEnter) {
          onEnter(app.globalObj.reduxStore!, route);
        }

        if (onEnterOrChangeQuery) {
          onEnterOrChangeQuery(app.globalObj.reduxStore!, route, true);
        }
      });
    }
  }
});

export { Router, Link, routeAlias, middlewareData, topLevelRoutes, Query };
export default router;
