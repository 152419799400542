import { put } from 'redux-saga/effects';
import router from '../../../../../router';

// Config

import { localStorageKeys, redirectRoutes } from '../../../../../config/app';

// Redux

import userSlice from '../../index';
import globalAC from '../../../../actionCreators';

// Services

import mainStream from '../../../../../services/ws/mainStream';

// ----------------

export default function* logoutSaga(action: ReturnType<typeof userSlice.actions.logout>) {
  console.log('logoutSaga start');
  localStorage.removeItem(localStorageKeys.token);
  localStorage.removeItem(localStorageKeys.otpValidated);
  router.navigate(redirectRoutes.defaultForNonAuth);
  put(globalAC.setDefaultState());
  mainStream.stop();
  console.log('logoutSaga finish');
}
