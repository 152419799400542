import { put } from 'redux-saga/effects';
import router from '../../../../../router';

// Slice

import userSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import userService from '../../../../../services/api/user';

// Config

import { localStorageKeys, redirectRoutes } from '../../../../../config/app';

// Types

import { User } from '../../../../../types';

// ----------------

export default function* getTokenSaga(action: ReturnType<typeof userSlice.actions.getToken$Req>) {
  yield requestSaga<User.Api.IUserToken$BodyResInner>({
    requestActionType: action.type,
    serviceArgs: [action.payload.data],
    service: userService.getToken,
    context: userService,
    onSuccess: function* (res) {
      localStorage.setItem(localStorageKeys.token, res.access);
      localStorage.setItem(localStorageKeys.refreshToken, res.refresh);

      yield put(
        userSlice.actions.getBaseData$Req(
          () => {
            router.navigate('auth.mfa');
          },
          (err) => {
            if (err.response?.status === 503) {
              window.location.reload();
            }
          }
        )
      );
    },
    onError: (err) => {
      if (err?.response?.status === 401) {
        action.meta.onError(err?.response?.data);
      }
    },
  });



}
