import initialState, { IRequestBranch } from './branch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';

// ----------------

const requestSlice = createSlice({
  name: `${reduxActionTypePrefix}/request`,
  initialState,

  // -------- Reducers --------

  reducers: {
    add: {
      prepare: (actionType: string, data?: number) => ({
        payload: {
          actionType,
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ actionType: string; data?: number }>) => {
        state[action.payload.actionType] = action.payload.data || action.payload.data === 0 ? action.payload.data : true;
      },
    },

    // --------

    delete: {
      prepare: (actionType: string) => ({
        payload: {
          actionType,
        },
      }),

      reducer: (state, action: PayloadAction<{ actionType: string }>) => {
        delete state[action.payload.actionType];
      },
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      state = initialState;
    });
  },
});

export type { IRequestBranch };
export { initialState };
export default requestSlice;
