import { API } from '../../../types';

// ----------------

const apiTransformer = {
  withPagination$ReqQueryToApi(query: API.IWithPagination$ReqQuery): API.IWithPagination$ReqQueryAPI {
    const count = query.count || 10;
    const page = query.page || 1;

    return { offset: page * count - count, limit: count };
  },

  withPagination$ResFromApi<F extends object, T extends object>(
    res: API.IWithPagination$ResAPI<F>,
    transformer: (data: F) => T,
    limit: number = 10
  ): API.IWithPagination$Res<T> {
    return {
      totalPages: Math.ceil(res.count / limit),
      list: res.results.map((item) => transformer(item)),
    };
  },
};

export default apiTransformer;
