import { TX } from '../../../types';

// -------- Types --------

export interface ITXBranch {
  totalPages: number;
  selected: TX.Base.ITX;
  count: number;
  list: TX.Base.ITX[];
}

// -------- Initial state of branch --------

const txBranchInitialState: ITXBranch = {
  totalPages: 0,
  count: 0,
  // prettier-ignore
  selected: {
    candidate_2g      : '',
    candidate_3g      : '',
    candidate_4g      : '',
    candidate_5g      : '',
    site_2g           : '',
    site_3g           : '',
    site_4g           : '',
    site_5g           : '',
    conso_year_mwingz : 0,
    microwave         : null,
    radio_site_type   : null,
    cluster_mwingz    : null,
    current_techno    : null,
    double_stream     : null,
    target_techno     : null,
    radio_status      : null,
    is_archived       : 0,
    bm_status         : null,
    longitude         : null,
    is_draft          : 0,
    latitude          : null,
    site_id           : '',
    address           : '',
    target            : null,
    family            : null,
    status            : null,
    city              : null,
    zip               : null,
    id                : 0
  },
  list: [],
};

export default txBranchInitialState;
