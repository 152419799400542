import { CommonDO } from '../common';

// ----------------

export enum MicrowaveDOKeys {
  physicalLocationsAggregated = 'physicalLocationsAggregated',
  maxEthUtilization = 'maxEthUtilization',
  linkCapacity = 'linkCapacity',
  transmission = 'transmission',
  linkHops = 'linkHops',
  bEndNode = 'bEndNode',
  aEndNode = 'aEndNode',
  gneCode = 'gneCode',
  linkId = 'linkId',
  length = 'length',
  band = 'band',
  xpic = 'xpic',
}

export type MicrowaveDO = {
  [MicrowaveDOKeys.physicalLocationsAggregated]: CommonDO.NumberDOValue;
  [MicrowaveDOKeys.maxEthUtilization]: CommonDO.NumberDOValue;
  [MicrowaveDOKeys.linkCapacity]: CommonDO.NumberDOValue;
  [MicrowaveDOKeys.transmission]: CommonDO.NumberDOValue;
  [MicrowaveDOKeys.linkHops]: CommonDO.NumberDOValue;
  [MicrowaveDOKeys.bEndNode]: string;
  [MicrowaveDOKeys.aEndNode]: string;
  [MicrowaveDOKeys.gneCode]: string;
  [MicrowaveDOKeys.linkId]: string;
  [MicrowaveDOKeys.length]: CommonDO.NumberDOValue;
  [MicrowaveDOKeys.band]: CommonDO.NumberDOValue;
  [MicrowaveDOKeys.xpic]: string;
};
