import { BaseDTO } from '../base';
import { TD } from '../../types';

// ----------------

export namespace LeasedLineDTO {
  // prettier-ignore
  export type $ = {
    site_ready_for_installation_target: TD.Date;
    site_ready_for_installation       : TD.Date;
    line_termination_completed        : TD.Date;
    line_termination_planned          : TD.Date;
    authorization_received            : TD.Date;
    fiber_works_completed             : TD.Date;
    target_access_profile             : TD.GlossaryId;
    active_access_profile             : TD.GlossaryId;
    final_test_completed              : TD.Date;
    trenching_completed               : TD.Date;
    active_access_speed               : TD.GlossaryId;
    fiber_works_planned               : TD.Date;
    target_access_speed               : TD.GlossaryId;
    delivery_forecast                 : TD.Date;
    ready_for_billing                 : TD.Date;
    trenching_planned                 : TD.Date;
    oms_action_status                 : TD.Enum;
    active_access_cpe                 : TD.GlossaryId;
    target_access_cpe                 : TD.GlossaryId;
    provisioning_type                 : TD.Enum;
    pm_rfb_forecast                   : TD.Date;
    oms_action_type                   : TD.Enum;
    nps_appointment                   : TD.Date;
    site_scenario                     : TD.Enum;
    pm_extra_cost                     : TD.Number;
    ssv_completed                     : TD.Date;
    project_name                      : TD.GlossaryId;
    transmission                      : TD.Id;
    status_list                       : TD.String;
    ssv_planned                       : TD.Date;
    pm_remarks                        : TD.String;
    site_ready                        : TD.Date;
    oms_status                        : TD.Enum;
    pm_project                        : TD.Enum;
    site_name                         : TD.String;
    oms_order                         : TD.Number;
    pm_status                         : TD.Enum;
    dsid                              : TD.Number;
  };

  export type PaginatedList = BaseDTO._PaginatedList<$>;
}
