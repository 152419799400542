import { all, takeEvery } from 'redux-saga/effects';

// Slice

import slice from '../index';

// Saga

import getListSaga from './getListSaga';

// ----------------

export default function* () {
  yield all([takeEvery(slice.actions.getList$Req.type, getListSaga)]);
}
