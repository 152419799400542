import { BaseDO, GlossaryId, DateTime, Date, Number } from '../base';
import { LeasedLineDO } from '../leasedLine';
import { MicrowaveDO } from '../microwave';
import { BlueMoonDO } from '../blueMoon';
import { FiberDO } from '../fiber';

// ----------------

export namespace TXDO {
  export type $ = {
    isProductionPlanReadinessLocked: boolean;
    productionYearEstimation: Number;
    productionPlanReadiness: string | null;
    deliveryForecastPublic: Date;
    mwingzCodeFromCarto: string | null;
    familyMpProjected: GlossaryId;
    familyBmProjected: GlossaryId;
    overallReadiness: string | null;
    consoYearMwingz: number | null;
    decommRequestDate: Date;
    decommActualDate: Date;
    familyBmLegacy: GlossaryId;
    leasedLineData: LeasedLineDO.$ | null;
    radioSiteType: GlossaryId;
    microwaveData: MicrowaveDO.MicrowaveDO | null;
    clusterMwingz: GlossaryId;
    currentTechno: GlossaryId;
    doubleStream: GlossaryId;
    targetTechno: GlossaryId;
    radioStatus: GlossaryId;
    isArchived: boolean;
    tempTechno: GlossaryId;
    mwingzCode: string;
    updateDate: DateTime;
    familyTemp: GlossaryId;
    deletedAt: DateTime;
    deletedBy: null;
    isDeleted: boolean;
    fiberData: FiberDO | null;
    microwave: GlossaryId;
    longitude: Number;
    originals: GlossaryId[];
    blueMoon: BlueMoonDO.$ | null;
    category: Category;
    bmStatus: GlossaryId;
    latitude: Number;
    isDraft: boolean;
    address: string;
    siteId: string;
    status: GlossaryId;
    family: GlossaryId;
    target: GlossaryId;
    region: string;
    city: GlossaryId;
    zip: Number;
    id: number;

    candidate2g: string;
    candidate3g: string;
    candidate4g: string;
    candidate5g: string;

    site2g: string;
    site3g: string;
    site4g: string;
    site5g: string;

    swapActualDate: Date;
    swapForecastDate: Date;
    consolidationDate: Date;
    nocFlmTransferDate: Date;
    physicalAcceptanceDate: Date;
  };

  export type PaginatedList = BaseDO.PaginatedList<$>;

  export type Category = 'fiber' | 'leasedLine' | 'microwave';

  // --------

  export type ZipCode = {
    city: string;
    code: number;
  };

  export type ZipCodePaginatedList = BaseDO.PaginatedList<ZipCode>;

  // --------

  export type TableSelectedRowAction = 'productionPlanReadinessLock' | 'productionPlanReadinessUnlock';
}
