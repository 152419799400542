import HTTP from '../http';

// Config

import { env, api } from '../../config';

// Entity

import { MicrowaveDO, MicrowaveDTO, microwaveMapper } from '../../entity/microwave';

// ----------------

class MicrowaveAPIService extends HTTP {
  add(data: Partial<MicrowaveDTO.MicrowaveDTO>): Promise<MicrowaveDO.MicrowaveDO | void> {
    return this.request<MicrowaveDTO.MicrowaveDTO>({ url: api.tx.microwave, method: 'post', data }).then(
      (data) => data && microwaveMapper.toDO(data)
    );
  }

  update(id: number, data: Partial<MicrowaveDTO.MicrowaveDTO>): Promise<MicrowaveDO.MicrowaveDO | void> {
    return this.request<MicrowaveDTO.MicrowaveDTO>({ url: api.tx.microwaveId(id), method: 'patch', data }).then(
      (data) => data && microwaveMapper.toDO(data)
    );
  }
}

export default new MicrowaveAPIService(`${env.API}api/`);
