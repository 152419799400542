import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { cartographySlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';

// ----------------

export function* cartographySaga() {
  yield all([
    takeEvery(
      [
        cartographySlice.actions.getComparingPaginatedList$Req.type,
        cartographySlice.actions.getChangePaginatedList$Req.type,
        cartographySlice.actions.getPendingCount$Req.type,
        cartographySlice.actions.updateChange$Req.type,
        cartographySlice.actions.export$Req.type,
      ],
      requestActionSaga
    ),
  ]);
}
