import * as yup from 'yup';
import { IConfig } from '../../useForm';

// Config

import { i18n, validate } from '../../../../config';

/**
 * Converts the form config according to the formik format.
 *
 * @param fields              - Form fields config
 * @param standardValidations - Object with standard validations
 * @param t                   - Object with translations for validation
 * @param initialValues       - Object with initial values
 * @param storageValues       - Object with values from local storage
 *
 * @typeParam V - Values type
 */

const createFormikConfig = <V>(
  fields: IConfig<V>['fields'],
  standardValidations: validate.StandardValidations,
  t: i18n.T['common']['validate'],
  initialValues?: IConfig<V>['initialValues'],
  storageValues?: Partial<Record<keyof V, any> | null>
) => {
  const formikInitialValues = {} as V;
  const validationSchema = {} as Record<keyof V, yup.SchemaOf<any>>;

  fields.forEach((field) => {
    const fieldName = field.name;
    let initValue;

    if (initialValues && initialValues[fieldName] !== undefined) {
      initValue = initialValues[fieldName];

      if (initValue === null) {
        if (field.nullValue !== undefined) {
          initValue = field.nullValue;
        } else if (field.defaultValue === '') {
          initValue = '';
        }
      }
    } else {
      if (storageValues && storageValues[fieldName] !== undefined) {
        initValue = storageValues[fieldName];
      } else {
        initValue = field.defaultValue;
      }
    }

    formikInitialValues[fieldName] = initValue;

    if (field.validate) {
      validationSchema[fieldName] = field.validate(standardValidations, yup, t);
    }
  });

  return {
    validationSchema: yup.object(validationSchema),
    initialValues: formikInitialValues,
  };
};

export default createFormikConfig;
