import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialState from './branch';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';
import actionCreators from './actionCreators';

// Saga

import txMicrowaveSynopsisSaga from './saga';

// Entities

import { TXMicrowaveSynopsisDO } from '../../../entity/txMicrowaveSynopsis';

// ----------------

const txMicrowaveSynopsisSlice = createSlice({
  name: `${reduxActionTypePrefix}/txMicrowaveSynopsis`,
  initialState,

  // -------- Reducers --------

  reducers: {
    set: (state, action: PayloadAction<{ list: TXMicrowaveSynopsisDO[] }>) => {
      state.list = action.payload.list;
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return initialState;
    });
  },
});

export default { ...txMicrowaveSynopsisSlice, actions: { ...txMicrowaveSynopsisSlice.actions, ...actionCreators } };
export { txMicrowaveSynopsisSaga, initialState };
export * from './branch';
