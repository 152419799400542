import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { smallWorksSlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';

// ----------------

export function* smallWorksSaga() {
  yield all([
    takeEvery(
      [
        smallWorksSlice.actions.getPaginatedList$Req.type,
        smallWorksSlice.actions.getItem$Req.type,
        smallWorksSlice.actions.export$Req.type,
        smallWorksSlice.actions.create$Req.type,
        smallWorksSlice.actions.update$Req.type,
        smallWorksSlice.actions.delete$Req.type,
      ],
      requestActionSaga
    ),
  ]);
}
