import { put } from 'redux-saga/effects';

// Redux

import txNotesSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* getTXNotesSaga(action: ReturnType<typeof txNotesSlice.actions.getTXNotes$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.query],
    service: txService.getTXNotes,
    context: txService,
    timeout: 300,
    onSuccess: function* (data) {
      yield put(txNotesSlice.actions.setList(data.list, data.totalPages));
    },
  });
}
