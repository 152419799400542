import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { TimelinePaginatedListDTO, timelineMapper } from '../../entity/timeline';
import { commonMapper } from '../../entity/common';

// ----------------

class TimelineAPIService extends HTTP {
  getPaginatedList(txId: number) {
    return this.request<TimelinePaginatedListDTO>({
      url: api.timeline.txId(txId),
    }).then((data) => {
      return commonMapper.paginatedListToDO(data!, timelineMapper.toDO);
    });
  }
}

export const timelineAPIService = new TimelineAPIService(`${env.API}api/`);
