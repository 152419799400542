import initialState, { IGlossaryBranch } from './branch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';
import AC from './actionCreators';

// Saga

import glossarySaga from './saga';

// Types

import { Glossary } from '../../../types';

// ----------------

const glossarySlice = createSlice({
  name: `${reduxActionTypePrefix}/glossary`,
  initialState,

  // -------- Reducers --------

  reducers: {
    setData: {
      prepare: (data: Glossary.Base.IFullGlossary) => ({
        payload: {
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: Glossary.Base.IFullGlossary }>) => {
        state.data = action.payload.data;
      },
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      return initialState;
    });
  },
});

export { initialState, glossarySaga };
export type { IGlossaryBranch };
export default { ...glossarySlice, actions: { ...glossarySlice.actions, ...AC } };
