import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Types

import { TX } from '../../../types';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/tx/${actionPiece}`; // Create action type

export default {
  getTXList$Req: createAction(cat('getTXList$Req'), (query?: TX.Api.TXRoot$Get$Request) => ({
    payload: {
      query,
    },
  })),

  getTXById$Req: createAction(cat('getTXById$Req'), (id: number, onSuccess?: () => void) => ({
    payload: {
      params: {
        id,
      },
    },
    meta: {
      onSuccess,
    },
  })),

  addTX$Req: createAction(cat('addTX$Req'), (data: TX.Api.TXRoot$Post$Req, onSuccess?: () => void, onError?: (data: any) => void) => ({
    payload: {
      data,
    },
    meta: {
      onSuccess,
      onError,
    },
  })),

  updateTX$Req: createAction(
    cat('updateTX$Req'),
    (id: number, data: TX.Api.TXRoot$Patch$Req, onSuccess?: () => void, onError?: (data: any) => void, updateSelectedData?: boolean) => ({
      payload: {
        data,
        id,
      },
      meta: {
        updateSelectedData,
        onSuccess,
        onError,
      },
    })
  ),

  archiveTX$Req: createAction(cat('archiveTX$Req'), (id: number, isArchive: boolean, onSuccess?: () => void, onError?: () => void) => ({
    payload: {
      isArchive,
      id,
    },
    meta: {
      onSuccess,
      onError,
    },
  })),

  deleteTX$Req: createAction(cat('deleteTX$Req'), (id: number, onSuccess?: () => void) => ({
    payload: {
      id,
    },
    meta: { onSuccess },
  })),

  uploadTXDocument$Req: createAction(cat('uploadTXDocument$Req'), (file: File, onSuccess?: () => void) => ({
    payload: {
      file,
    },
    meta: { onSuccess },
  })),

  exportTXList$Req: createAction(cat('exportTXList$Req')),
};
