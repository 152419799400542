import { Link as ReactRouter5Link } from 'react-router5';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import cn from 'classnames';

// -------- Types --------

interface IProps {
  activeClassName?: string;
  className?: string;
  withStyle?: boolean;
  routeName: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
  active?: boolean;
  routeParams?: {
    [key: string]: any;
  };
}

// -------- Styles --------

const useStyles = makeStyles((t) => ({
  root: (props: IProps) => ({
    textDecoration: 'none',

    ...(props.withStyle
      ? {
          transition: '0.2s color',
          padding: '6px',
          margin: '-6px',
          color: props.active ? t.palette.primary.main : t.palette.text.secondary,
          ...t.typography.body1,

          '&:hover': {
            ...(props.active
              ? {
                  textDecoration: 'underline',
                }
              : {
                  color: t.palette.primary.main,
                }),
          },
        }
      : {}),
  }),
}));

// ----------------

const Link: React.FC<IProps> = (props) => {
  const classes = useStyles(props);

  const reactRouter5LinkProps = { ...props };
  delete reactRouter5LinkProps.withStyle;
  delete reactRouter5LinkProps.active;

  return (
    <ReactRouter5Link {...reactRouter5LinkProps} className={cn(classes.root, props.className)}>
      {props.children}
    </ReactRouter5Link>
  );
};

export default Link;
