import initialState, { ITXNotesBranch } from './branch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';
import AC from './actionCreators';

// Saga

import txNotesSaga from './saga';

// Types

import { TX } from '../../../types';

// ----------------

const txNotesSlice = createSlice({
  name: `${reduxActionTypePrefix}/txNotes`,
  initialState,

  // -------- Reducers --------

  reducers: {
    setList: {
      prepare: (list: TX.Base.ITXNote[], totalPages: number) => ({
        payload: {
          totalPages,
          list,
        },
      }),

      reducer: (state, action: PayloadAction<{ list: TX.Base.ITXNote[]; totalPages: number }>) => {
        state.totalPages = action.payload.totalPages;
        state.list = action.payload.list;
      },
    },

    // --------

    addToList: {
      prepare: (data: TX.Base.ITXNote) => ({
        payload: {
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: TX.Base.ITXNote }>) => {
        state.list.unshift(action.payload.data);
      },
    },

    // --------

    replaceListItem: {
      prepare: (data: TX.Base.ITXNote) => ({
        payload: {
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: TX.Base.ITXNote }>) => {
        state.list = state.list.map((note) => (note.id === action.payload.data.id ? action.payload.data : note));
      },
    },

    // --------

    deleteFromList: {
      prepare: (id: number) => ({
        payload: {
          id,
        },
      }),

      reducer: (state, action: PayloadAction<{ id: number }>) => {
        state.list = state.list.filter((item) => item.id !== action.payload.id);
      },
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      return initialState;
    });
  },
});

export { initialState, txNotesSaga };
export type { ITXNotesBranch };
export default { ...txNotesSlice, actions: { ...txNotesSlice.actions, ...AC } };
