import initialState, { IGraphBranch, DataKeys } from './branch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';
import AC from './actionCreators';

// Entity

import { IGraphDO } from '../../../entity/graph/DO';

// Saga

import graphSaga from './saga';

// ----------------

const graphSlice = createSlice({
  name: `${reduxActionTypePrefix}/graph`,
  initialState,

  // -------- Reducers --------

  reducers: {
    setData: {
      prepare: (dataKey: DataKeys, data: IGraphDO) => ({
        payload: {
          dataKey,
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ dataKey: DataKeys; data: object }>) => {
        // @ts-ignore
        state.data[action.payload.dataKey] = action.payload.data;
      },
    },

    // --------

    setDataKit: {
      prepare: (kit: Partial<Record<DataKeys, IGraphDO>>) => ({
        payload: {
          kit,
        },
      }),

      reducer: (state, action: PayloadAction<{ kit: Partial<Record<DataKeys, IGraphDO>> }>) => {
        Object.keys(action.payload.kit).forEach((key) => (state.data[key] = action.payload.kit[key]));
      },
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      return initialState;
    });
  },
});

export type { IGraphBranch };
export { initialState, graphSaga };
export default { ...graphSlice, actions: { ...graphSlice.actions, ...AC } };
