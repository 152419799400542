import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { CartographyDTO, CartographyDO, cartographyMapper, CartographyCommon } from '../../entity/cartography';
import { baseMapper } from '../../entity/base';

// Types

import { PaginatedListQueries, SearchQueries, FreeQueries } from '../queryService';

// -------- Types --------

export type GetChangePaginatedListQueries = PaginatedListQueries &
  SearchQueries &
  Partial<{
    element__comparing__report_previous__period_end: string;
    element__comparing__report_next__period_end: string;
    status: StatusQuery | StatusQuery[];
    type: TypeQuery | TypeQuery[];
  }>;

export type GetComparingPaginatedListQueries = PaginatedListQueries;

type StatusQuery = keyof typeof CartographyCommon.ChangeStatus;

type TypeQuery = keyof typeof CartographyCommon.ChangeType;

// ----------------

class CartographyAPIService extends HTTP {
  getChangePaginatedList(queries: GetChangePaginatedListQueries) {
    let status;
    let type;

    if (queries.status) {
      if (Array.isArray(queries.status)) {
        status = queries.status.map((value) => CartographyCommon.ChangeStatus[value]).join(',');
      } else {
        status = CartographyCommon.ChangeStatus[queries.status];
      }
    }

    if (queries.type) {
      if (Array.isArray(queries.type)) {
        type = queries.type.map((value) => CartographyCommon.ChangeType[value]).join(',');
      } else {
        type = CartographyCommon.ChangeType[queries.type];
      }
    }

    const params = { ...queries, status, type };

    return this.request<CartographyDTO.ChangePaginatedList>({
      params,
      url: api.cartography.applicable,
    }).then((data) => {
      return baseMapper.paginatedListToDO(data!, cartographyMapper.changeToDO);
    });
  }

  // --------

  updateChange(data: Partial<CartographyDO.Change>, id: number) {
    return this.request<CartographyDTO.Change>({
      method: 'patch',
      data: cartographyMapper.changeToDTO(data),
      url: api.cartography.applicableId(id),
    }).then((data) => {
      return cartographyMapper.changeToDO(data!);
    });
  }

  // --------

  getPendingCount() {
    return this.request<CartographyDTO.ChangePaginatedList>({
      params: { status: 'pending' },
      url: api.cartography.applicable,
    }).then((data) => {
      return data!.count;
    });
  }

  // --------

  getComparingPaginatedList(queries: GetComparingPaginatedListQueries) {
    return this.request<CartographyDTO.ComparingPaginatedList>({
      params: queries,
      url: api.cartography.comparing,
    }).then((data) => {
      return baseMapper.paginatedListToDO(data!, cartographyMapper.comparingToDO);
    });
  }

  // --------

  export(queries: GetChangePaginatedListQueries) {
    let status;
    let type;

    if (queries.status) {
      if (Array.isArray(queries.status)) {
        status = queries.status.map((value) => CartographyCommon.ChangeStatus[value]).join(',');
      } else {
        status = CartographyCommon.ChangeStatus[queries.status];
      }
    }

    if (queries.type) {
      if (Array.isArray(queries.type)) {
        type = queries.type.map((value) => CartographyCommon.ChangeType[value]).join(',');
      } else {
        type = CartographyCommon.ChangeType[queries.type];
      }
    }

    const params = { ...queries, status, type };

    return this.request<null>({
      method: 'post',
      params: params,
      url: api.cartography.export,
    });
  }
}

export const cartographyAPIService = new CartographyAPIService(`${env.API}api/`);
