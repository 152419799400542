import { IGraphDTO, GraphGroupDTO } from './DTO';
import { IGraphDO, GraphGroupDO } from './DO';

// ----------------

export default {
  toDO(data: IGraphDTO): IGraphDO {
    const piecesKeys = Object.keys(data.pieces);

    return { total: data.total, pieces: piecesKeys.map((key) => ({ name: key, value: data.pieces[key].value })) };
  },

  groupToDO(data: GraphGroupDTO): GraphGroupDO {
    return data;
  },
};
