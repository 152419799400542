export type PermissionList = Permission[];

export enum Permission {
  // -------- View --------

  VIEW_PRODUCTION_PLAN_READINESS_FIELD = 'can_user_see_production_plan_readiness_field',
  VIEW_DASHBOARD_TX_TARGET_DEPLOYED = 'can_user_see_target_deployed_graphs',
  VIEW_FIBERIZATION_TARGET_CHART = 'can_view_fiberization_target',
  VIEW_RECONSTRUCTION_DETAILS = 'reconstruction_can_click_record',
  VIEW_SMALLWORKS_DETAILS = 'smallWorks_can_click_record',
  VIEW_DASHBOARD_TX_READINESS = 'can_view_management_dashboards',
  VIEW_FIBERIZATION_CHART = 'can_view_fiberization_graph',
  VIEW_FIBER_DASHBOARD = 'can_view_fiber_dashboard',
  VIEW_LEASED_LINE_TAB = 'can_user_view_ll_data_tab',
  VIEW_RECONSTRUCTION = 'can_view_reconstruction_module',
  VIEW_SMALLWORKS = 'can_view_smallWorks_module',
  VIEW_BLUE_MOON_TAB = 'can_user_view_bluemoon_data_tab',
  VIEW_MICROWAVE_TAB = 'can_user_view_mw_data_tab',
  VIEW_NOTIFICATIONS = 'can_view_notifications',
  VIEW_DOCUMENTS_TAB = 'can_user_view_documents_tab',
  VIEW_TIMELINE_TAB = 'can_user_view_timeline_tab',
  VIEW_SYNOPTIC_TAB = 'can_user_view_mw_synoptic_tab',
  VIEW_CARTOGRAPHY = 'can_view_cartography',
  VIEW_LEASED_LINE = 'can_view_leased_line',
  VIEW_B2B_DETAILS = 'b2b_can_click_record',
  VIEW_FIBER_RING = 'can_view_fiber_ring',
  VIEW_MICROWAVE = 'can_view_microwave',
  VIEW_NOTES_TAB = 'can_user_view_notes_tab',
  VIEW_CANDIDATE = 'can_view_candidate',
  VIEW_FIBER_TAB = 'can_user_view_fiber_data_tab',
  VIEW_DASHBOARD = 'can_view_dashboard',
  VIEW_BLUE_MOON = 'can_view_bluemoon_module',
  VIEW_ARCHIVE = 'can_view_archive',
  VIEW_ALL_TX = 'can_view_all_tx',
  VIEW_FIBER = 'can_view_fiber',
  VIEW_B2B = 'can_view_b2b_module',

  // -------- All transmissions --------

  ALL_TX_CHOOSE_COLUMNS = 'all_tx_can_choose_columns',
  ALL_TX_ADD_DOCUMENTS = 'all_tx_can_add_documents',
  ALL_TX_VIEW_ARCHIVE = 'all_tx_can_view_archive',
  ALL_TX_ADD_NOTES = 'all_tx_can_add_notes',
  ALL_TX_CLICK_USI = 'all_tx_can_click_usi',
  ALL_TX_ADD_USI = 'all_tx_can_add_usi',
  ALL_TX_ARCHIVE = 'all_tx_can_archive',
  ALL_TX_FILTERS = 'all_tx_can_filters',
  ALL_TX_EXPORT = 'all_tx_can_export',
  ALL_TX_DELETE = 'all_tx_can_delete',
  ALL_TX_SEARCH = 'all_tx_can_search',
  ALL_TX_EDIT = 'all_tx_can_edit',

  // -------- Fiber transmissions --------

  FIBER_INITIATE_FIBER_PROCESS = 'fiber_can_initiate_fiber_process',
  FIBER_CHOOSE_COLUMNS = 'fiber_can_choose_columns',
  FIBER_ADD_DOCUMENTS = 'fiber_can_add_documents',
  FIBER_VIEW_ARCHIVE = 'fiber_can_view_archive',
  FIBER_ALL_CHARTS = 'can_user_see_all_graphs_in_fiber_dashboard',
  FIBER_ADD_NOTES = 'fiber_can_add_notes',
  FIBER_CLICK_USI = 'fiber_can_click_usi',
  FIBER_EDIT_DATA = 'can_edit_fiber_data',
  FIBER_ARCHIVE = 'fiber_can_archive',
  FIBER_FILTERS = 'fiber_can_filters',
  FIBER_DELETE = 'fiber_can_delete',
  FIBER_EXPORT = 'fiber_can_export',
  FIBER_SEARCH = 'fiber_can_search',
  FIBER_EDIT = 'fiber_can_edit',

  // -------- Leased line transmissions --------

  LEASED_LINE_INITIATE_FIBER_PROCESS = 'll_can_initiate_fiber_process',
  LEASED_LINE_CHOOSE_COLUMNS = 'll_can_choose_columns',
  LEASED_LINE_ADD_DOCUMENTS = 'll_can_add_documents',
  LEASED_LINE_VIEW_ARCHIVE = 'll_can_view_archive',
  LEASED_LINE_CLICK_USI = 'll_can_click_usi',
  LEASED_LINE_EDIT_DATA = 'can_edit_ll_data',
  LEASED_LINE_ADD_NOTE = 'll_can_add_note',
  LEASED_LINE_FILTERS = 'll_can_filters',
  LEASED_LINE_ARCHIVE = 'll_can_archive',
  LEASED_LINE_EXPORT = 'll_can_export',
  LEASED_LINE_SEARCH = 'll_can_search',
  LEASED_LINE_DELETE = 'll_can_delete',
  LEASED_LINE_EDIT = 'll_can_edit',

  // -------- Microwave transmissions --------

  MICROWAVE_INITIATE_FIBER_PROCESS = 'mw_can_initiate_fiber_process',
  MICROWAVE_CHOOSE_COLUMNS = 'mw_can_choose_columns',
  MICROWAVE_ADD_DOCUMENTS = 'mw_can_add_documents',
  MICROWAVE_VIEW_ARCHIVE = 'mw_can_view_archive',
  MICROWAVE_CLICK_USI = 'mw_can_click_usi',
  MICROWAVE_EDIT_DATA = 'can_edit_mw_data',
  MICROWAVE_ADD_NOTE = 'mw_can_add_note',
  MICROWAVE_ARCHIVE = 'mw_can_archive',
  MICROWAVE_FILTERS = 'mw_can_filters',
  MICROWAVE_SEARCH = 'mw_can_search',
  MICROWAVE_EXPORT = 'mw_can_export',
  MICROWAVE_DELETE = 'mw_can_delete',
  MICROWAVE_EDIT = 'mw_can_edit',

  // -------- Archive transmissions --------

  ARCHIVE_CHOOSE_COLUMNS = 'archive_can_choose_columns',
  ARCHIVE_UN_ARCHIVE = 'archive_can_un_archive',
  ARCHIVE_FILTERS = 'archive_can_filters',
  ARCHIVE_EXPORT = 'archive_can_export',
  ARCHIVE_DELETE = 'archive_can_delete',
  ARCHIVE_SEARCH = 'archive_can_search',
  ARCHIVE_EDIT = 'archive_can_edit',

  // -------- Candidate transmissions --------

  CANDIDATE_MOVE_TO_MASTER_PLAN = 'candidate_can_move_to_master_plan',
  CANDIDATE_CHOOSE_COLUMNS = 'candidate_can_choose_columns',
  CANDIDATE_FILTERS = 'candidate_can_filters',
  CANDIDATE_SEARCH = 'candidate_can_search',
  CANDIDATE_IMPORT = 'candidate_can_import',
  CANDIDATE_DELETE = 'candidate_can_delete',
  CANDIDATE_EXPORT = 'candidate_can_export',

  // -------- B2B --------

  B2B_CHOOSE_COLUMNS = 'b2b_can_choose_columns',
  B2B_ADD_DOCUMENT = 'b2b_can_add_documents',
  B2B_VIEW_ARCHIVE = 'b2b_can_view_archive',
  B2B_UNARCHIVE = 'can_unarchive_b2b',
  B2B_ADD_NOTE = 'b2b_can_add_notes',
  B2B_FILTERS = 'b2b_can_filters',
  B2B_ARCHIVE = 'can_archive_b2b',
  B2B_DELETE = 'can_delete_b2b',
  B2B_SEARCH = 'b2b_can_search',
  B2B_EXPORT = 'b2b_can_export',
  B2B_EDIT = 'can_edit_b2b_records',
  B2B_ADD = 'can_create_b2b_data',
  VIEW_B2B_DASHBOARD = 'can_user_see_b2b_dashboard',

  // -------- Reconstruction --------

  RECONSTRUCTION_CHOOSE_COLUMNS = 'reconstruction_can_choose_columns',
  RECONSTRUCTION_VIEW_ARCHIVE = 'reconstruction_can_view_archive',
  RECONSTRUCTION_ADD_DOCUMENT = 'reconstruction_can_add_documents',
  RECONSTRUCTION_UNARCHIVE = 'can_unarchive_reconstruction',
  RECONSTRUCTION_ADD_NOTE = 'reconstruction_can_add_notes',
  RECONSTRUCTION_ARCHIVE = 'can_archive_reconstruction',
  RECONSTRUCTION_FILTERS = 'reconstruction_can_filters',
  RECONSTRUCTION_DELETE = 'can_delete_reconstruction',
  RECONSTRUCTION_SEARCH = 'reconstruction_can_search',
  RECONSTRUCTION_EXPORT = 'reconstruction_can_export',
  RECONSTRUCTION_EDIT = 'can_edit_reconstruction_records',
  RECONSTRUCTION_ADD = 'can_create_reconstruction_records',

  // -------- BlueMoon --------

  BLUE_MOON_CHOOSE_COLUMNS = 'bluemoon_can_choose_columns',
  BLUE_MOON_VIEW_ARCHIVE = 'bluemoon_can_view_archive',
  BLUE_MOON_ADD_DOCUMENT = 'bluemoon_can_add_documents',
  BLUE_MOON_UNARCHIVE = 'can_unarchive_bluemoon',
  BLUE_MOON_ADD_NOTE = 'bluemoon_can_add_notes',
  BLUE_MOON_ARCHIVE = 'can_archive_bluemoon',
  BLUE_MOON_FILTERS = 'bluemoon_can_filters',
  BLUE_MOON_DELETE = 'can_delete_bluemoon',
  BLUE_MOON_SEARCH = 'bluemoon_can_search',
  BLUE_MOON_EXPORT = 'bluemoon_can_export',
  BLUE_MOON_EDIT = 'can_edit_bluemoon_records',
  BLUE_MOON_ADD = 'can_create_bluemoon_data',

  // -------- Small Works --------

  SMALL_WORKS_CHOOSE_COLUMNS = 'smallWorks_can_choose_columns',
  SMALL_WORKS_VIEW_ARCHIVE = 'smallWorks_can_view_archive',
  SMALL_WORKS_ADD_DOCUMENT = 'smallWorks_can_add_documents',
  SMALL_WORKS_UNARCHIVE = 'can_unarchive_smallWorks',
  SMALL_WORKS_ADD_NOTE = 'smallWorks_can_add_notes',
  SMALL_WORKS_ARCHIVE = 'can_archive_smallWorks',
  SMALL_WORKS_FILTERS = 'smallWorks_can_filters',
  SMALL_WORKS_DELETE = 'can_delete_smallWorks',
  SMALL_WORKS_SEARCH = 'smallWorks_can_search',
  SMALL_WORKS_EXPORT = 'smallWorks_can_export',
  SMALL_WORKS_EDIT = 'can_edit_smallWorks_records',
  SMALL_WORKS_ADD = 'can_create_smallWorks_records',

  // -------- Common --------

  COMMON_VIEW_SENSITIVE_DATA = 'can_user_see_sensitive_data',
  COMMON_VIEW_CONSOLE_LINK = 'can_user_view_admin_console_link',
  COMMON_BULK_ACTIONS = 'can_user_view_bulk_actions',
  COMMON_DISMANTLE_TX = 'can_user_dismantle_site',
  COMMON_UNLOCK_TX = 'can_user_unlock_objects',
  COMMON_MOVE_TX = 'can_move_transmission',
  COMMON_LOCK_TX = 'can_user_lock_objects',
}
