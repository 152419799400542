import { Glossary } from '../../../types';

// -------- Types --------

export interface IGlossaryBranch {
  data: Glossary.Base.IFullGlossary;
}

// -------- Initial state of branch --------

const glossaryBranchInitialState: IGlossaryBranch = {
  data: {
    splicepartner: [],
    radiositetype: [],
    clustermwingz: [],
    subcontractor: [],
    fibernetwork: [],
    ringatnname: [],
    radiostatus: [],
    fiberring: [],
    microwave: [],
    bmstatus: [],
    family: [],
    status: [],
    techno: [],
    pmobe: [],
    city: [],
  },
};

export default glossaryBranchInitialState;
