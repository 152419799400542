import React from 'react';

// Components

import Stack, { StackProps } from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// -------- Types --------

type Props = {
  stackProps?: StackProps;
  children?: React.ReactNode;
  onDelete: React.MouseEventHandler;
  id: string;
};

// ----------------

const DynamicRow: React.FC<Props> = (props) => {
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="center" {...props.stackProps}>
      {props.children}
      <IconButton id={props.id} onClick={props.onDelete}>
        <CloseIcon />
      </IconButton>
    </Stack>
  );
};

export default DynamicRow;
export { Props as DynamicRowProps };
