import { TimelineDTO, TimelineTypeDTO, TimelineAdditionalDTO, TimelineAdditionalTXUpdatedDTO } from './timelineDTO';
import { TimelineDO, TimelineTypeDO, TimelineAdditionalDO, TimelineAdditionalTXUpdatedDO } from './timelineDO';
import { TransmissionDOKeys } from '../transmission/DO';
import transmissionMapper from '../transmission/mapper';

// ----------------

export const timelineMapper = {
  toDO(data: TimelineDTO): TimelineDO {
    return {
      additional: timelineMapper.additionalToDO(data.additional, data.type),
      createdAt: data.created_at,
      datetime: data.datetime,
      type: timelineMapper.typeToDO(data.type),
      txId: data.transmission,
      user: data.user
        ? {
            firstName: data.user.first_name,
            lastName: data.user.last_name,
            email: data.user.email,
          }
        : null,
      id: data.id,
    };
  },

  // --------

  typeToDO(type: TimelineTypeDTO): TimelineTypeDO {
    const typeMap: Record<TimelineTypeDTO, TimelineTypeDO> = {
      'transmission-field-updated': 'tx-updated',
      'transmission-note-created': 'tx-note-created',
      'transmission-file-added': 'tx-file-added',
      'transmission-created': 'tx-created',
      unarchived: 'tx-unarchived',
      archived: 'tx-archived',
    };

    return typeMap[type];
  },

  // --------

  additionalToDO(additional: TimelineAdditionalDTO, type: TimelineTypeDTO): TimelineAdditionalDO {
    switch (type) {
      case 'transmission-field-updated': {
        const additionalDTO = additional as TimelineAdditionalTXUpdatedDTO;

        const additionalDO: TimelineAdditionalTXUpdatedDO = {
          changes: additionalDTO.field_changes.map((item) => ({
            previous: item.previous,
            next: item.next,
            key: transmissionMapper.DTOKeyToEquallyDOKey(item.field) as keyof typeof TransmissionDOKeys,
          })),
        };

        return additionalDO;
      }

      default: {
        return null;
      }
    }
  },
};
