import { IGraphDO, GraphGroupDO } from '../../../entity/graph/DO';

// -------- Types --------

export interface IGraphBranch {
  data: {
    technologiesDistribution: IGraphDO;
    blueMoonFiberization: IGraphDO;
    fiberization: IGraphDO;
    futureRemain: IGraphDO;
    cartography: GraphGroupDO;
  };
}

export type DataKeys = 'technologiesDistribution' | 'blueMoonFiberization' | 'fiberization' | 'futureRemain' | 'cartography';

// -------- Initial state of branch --------

const graphBranchInitialState: IGraphBranch = {
  data: {
    technologiesDistribution: {} as IGraphDO,
    blueMoonFiberization: {} as IGraphDO,
    fiberization: {} as IGraphDO,
    futureRemain: {} as IGraphDO,
    cartography: {} as GraphGroupDO,
  },
};

export default graphBranchInitialState;
