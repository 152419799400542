import { Lan, Palette, IGlobalObj } from '../types';

// ----------------

/**
 * Version of the application
 */

export const appVersion: number = 0.1;

/**
 * App display name.
 */

export const appDisplayName = '360TxVision';

/**
 * Version of the API
 */

export const apiVersion = { v1: '1' };

/**
 * Default version of the API
 */

export const defApiVersion = apiVersion.v1;

/**
 * This prefix serves as a namespace for the redux action types.
 *
 * This allows to create uniqueness for action types when working
 * with third-party libraries that also work with redux.
 */

export const reduxActionTypePrefix = '@fm';

/**
 * Default language
 */

export const defLan: Lan = 'en';

/**
 * Default palette
 */

export const defPalette: Palette = 'light';

/**
 * Keys for the work with local storage
 */

export const localStorageKeys = {
  refreshToken: 'fm_refresh_token',
  token: 'fm_token',
  form: 'fm_form-',
  lan: 'fm_lan',
  email: 'fm_email',
  otpValidated:'fm_otpValidated'
};

/**
 * Routes for redirect
 */

export const redirectRoutes = {
  defaultForNonAuth: 'auth.login',
  defaultForNonAccess: 'auth.login',
  defaultForAuth: 'main.dashboard',
  defaultForMFA: 'auth.mfa',
};


/**
 * Global object
 */

export const globalObj: Partial<IGlobalObj> = {};

/**
 * Amount of information
 */

export const sizes = {
  MB: 1048576,
};

/**
 * MIME types
 */

export const mimeTypes = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  json: 'application/json',
  jpeg: 'image/jpeg',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  doc: 'application/msword',
  gif: 'image/gif',
  jpg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
  ppt: 'application/vnd.ms-powerpoint',
  xls: 'application/vnd.ms-excel',
  xml: 'application/xml',
  zip: 'application/zip',
  rtf: 'application/rtf',
  txt: 'text/plain',
  rar: 'application/vnd.rar',
  pps: ' application/vnd.ms-powerpoint',
  msg: 'application/vnd.bbf.usp.msg',
  eml: 'message/rfc822',
  outlook_msg: 'application/vnd.ms-outlook',
  dwg: 'image/vnd.dxf'
};

/**
 * Types of files that available to upload by default
 */

export const defaultAvailableFileTypesToUpload: Array<keyof typeof mimeTypes> = ['pdf', 'doc', 'ppt', 'xls', 'xlsx', 'png', 'jpg', 'json','dwg','docx'];

/**
 * Default max size of upload file
 */

export const defaultMaxSizeFile = sizes.MB * 5; // 5MB

/**
 * Date and time default format
 */

export const dateTimeFormat = 'D MMM YYYY, HH:mm';

/**
 * Date default format
 */

export const dateFormat = 'DD-MM-YYYY';

/**
 * Date format in ISO 8601 standard
 */

export const ISODateFormat = 'YYYY-MM-DD';

/**
 * Time format in ISO 8601 standard
 */

export const ISOTimeFormat = 'HH:mm:ss';

/**
 * Date and time format in ISO 8601 standard
 */

export const ISODateTimeFormat = 'YYYY-MM-DDTHH:mm:ssZ';

/**
 * Ids for different types of family
 */

export const familyId = { microwave: 1, fiber: 2, 'leased-line': 3 };

/**
 * Notifications limit
 */

export const notificationsLimit = 5;

/**
 * All available categories for storybook
 */

export const storybookCategories = {
  commonComponents: 'Components/Common/',
  entityComponents: 'Components/Entities/',
};
