import HTTP from '../http';

// Config

import { env, api } from '../../config';

// Entity

import { NotificationDTO, NotificationDO, notificationMapper } from '../../entity/notification';
import { commonMapper } from '../../entity/common';

// ----------------

class NotificationAPIService extends HTTP {
  getList(
    query?: NotificationDTO.NotificationListQueryDTO & Partial<{ is_general: boolean; is_call_to_action: boolean }>
  ): Promise<NotificationDO.NotificationListDO | void> {
    return this.request<NotificationDTO.NotificationListDTO>({ url: api.notifications.root, params: query }).then(
      (data) => data && { ...commonMapper.listToDO(data, notificationMapper.toDO), unreadCount: data.unread }
    );
  }

  update(id: number, data: Partial<NotificationDTO.NotificationDTO>): Promise<NotificationDO.NotificationDO | void> {
    return this.request<NotificationDTO.NotificationDTO>({ url: api.notifications.id(id), method: 'patch', data }).then(
      (data) => data && notificationMapper.toDO(data)
    );
  }

  delete(id: number): Promise<void> {
    return this.request({ url: api.notifications.id(id), method: 'delete' });
  }

  deleteAll(query?: Partial<{ is_general: boolean; is_call_to_action: boolean }>): Promise<void> {
    return this.request({ url: api.notifications.deleteAll, method: 'post', params: query });
  }

  seeAll(query?: Partial<{ is_general: boolean; is_call_to_action: boolean }>): Promise<void> {
    return this.request({ url: api.notifications.seeAll, method: 'post', params: query });
  }
}

export default new NotificationAPIService(`${env.API}api/`);
