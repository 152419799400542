import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Utils

import { objectToFormData } from '../../utils/objectToFormData';

// Entities

import { TXDO, TXDTO, txMapper } from '../../entity/tx';
import { baseMapper } from '../../entity/base';

// Types

import { PaginatedListQueries, SearchQueries, FreeQueries } from '../queryService';

// -------- Types --------

export type GetPaginatedListQueries = PaginatedListQueries & SearchQueries & FreeQueries & Partial<{ has_originals: boolean; in_move: boolean }>;

export type ExportQueries = { show: string; name: 'all_tx' | 'leased_lines' | 'microwave' | 'fiber' | 'blue_moon' } & FreeQueries;

export type BulkUpdateSendData = {
  data: Pick<TXDO.$, 'isProductionPlanReadinessLocked'>;
  ids: number[];
};

// ----------------

class TXAPIService extends HTTP {
  getItem(id: number) {
    return this.request<TXDTO.$>({
      url: api.tx.id(id),
    }).then((data) => {
      return txMapper.toDO(data!);
    });
  }

  // --------

  getPaginatedList(queries: GetPaginatedListQueries) {
    return this.request<TXDTO.PaginatedList>({
      params: queries,
      url: api.tx.root,
    }).then((data) => {
      return baseMapper.paginatedListToDO(data!, txMapper.toDO);
    });
  }

  // --------

  create(data: TXDO.$) {
    return this.request<TXDTO.$>({
      method: 'post',
      data: txMapper.toDTO(data),
      url: api.tx.root,
    }).then((data) => {
      return txMapper.toDO(data!);
    });
  }

  // --------

  update(data: TXDO.$, id: number) {
    return this.request<TXDTO.$>({
      method: 'patch',
      data: txMapper.toDTO(data),
      url: api.tx.id(id),
    }).then((data) => {
      return txMapper.toDO(data!);
    });
  }

  // --------

  delete(id: number) {
    return this.request<void>({
      method: 'delete',
      url: api.tx.id(id),
    });
  }

  // --------

  dismantled(id: number) {
    return this.request<TXDTO.$>({
      method: 'post',
      url: api.tx.dismantled(id),
    }).then((data) => {
      return txMapper.toDO(data!);
    });
  }

  // --------

  cancelMove(id: number) {
    return this.request<TXDTO.$>({
      method: 'post',
      url: api.tx.movingСancel(id),
    }).then((data) => {
      return txMapper.toDO(data!);
    });
  }

  // --------

  export(queries: ExportQueries) {
    return this.request<void>({
      method: 'post',
      params: queries,
      url: api.tx.export,
    });
  }

  // --------

  uploadCandidates(file: File): Promise<void> {
    const formData = new FormData();
    formData.append('file', file);

    return this.request({ url: api.tx.import, method: 'post', data: formData });
  }

  // --------

  upload(file: File) {
    return this.request<void>({
      method: 'post',
      data: objectToFormData({ file }),
      url: api.tx.upload,
    });
  }

  // --------

  bulkUpdateFiber(file: File) {
    return this.request<void>({
      method: 'post',
      data: objectToFormData({ file }),
      url: api.tx.bulkUpdateFiber,
    });
  }

  // --------

  bulkUpdateBlueMoon(file: File) {
    return this.request<void>({
      method: 'post',
      data: objectToFormData({ file }),
      url: api.tx.bulkUpdateBlueMoon,
    });
  }

  // --------

  getCandidateCount() {
    return this.request<TXDTO.PaginatedList>({
      params: { is_archived: 0, is_draft: 1, is_deleted: false },
      url: api.tx.root,
    }).then((data) => {
      return data!.count;
    });
  }

  // --------

  getZipCodes() {
    return this.request<TXDTO.ZipCodePaginatedList>({
      params: { limit: 3000 },
      url: api.tx.zipCode,
    }).then((data) => {
      return data!.results.map((item) => txMapper.zipCodeToDO(item));
    });
  }

  // --------

  bulkUpdate(data: BulkUpdateSendData) {
    return this.request<void>({
      method: 'patch',
      data: { ids: data.ids, data: txMapper.toDTO(data.data) },
      url: api.tx.bulkUpdate,
    });
  }
}

export const txAPIService = new TXAPIService(`${env.API}api/`);
