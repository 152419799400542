// ========================
// ======== Common ========
// ========================

export type PaginationQuery = Partial<{
  offset: string;
  limit: string;
}>;

export type SearchQuery = Partial<{
  search: string;
}>;

export type SortQuery = Partial<{
  sort: string;
}>;

// ========================
// ======== Routes ========
// ========================

// -------- main.dashboard --------

export type DashboardQuery = Partial<{
  date: string;
}>;

export const dashboardQueryList: Array<keyof DashboardQuery> = ['date'];
