import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/glossary/${actionPiece}`; // Create action type

export default {
  getList$Req: createAction(cat('getList$Req'), (props: { onSuccess?: () => void }) => ({
    payload: {},
    meta: {
      onSuccess: props.onSuccess,
    },
  })),
};
