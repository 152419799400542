import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { B2BPaginatedListDTO, B2BDO, B2BDTO, b2bMapper } from '../../entity/b2b';
import { commonMapper } from '../../entity/common';

// Types

import { FreeQueries, PaginatedListQueries } from '../queryService';

// -------- Types --------

export type GetPaginatedListQueries = PaginatedListQueries | FreeQueries | Partial<{ is_archived: boolean }>;

export type SendData = Partial<B2BDO>;
export type SendDataErr = Partial<Record<keyof B2BDTO, [string]>>;

// ----------------

class B2BAPIService extends HTTP {
  getPaginatedList(queries: GetPaginatedListQueries) {
    return this.request<B2BPaginatedListDTO>({
      params: queries,
      url: api.b2b.root,
    }).then((data) => {
      return commonMapper.paginatedListToDO(data!, b2bMapper.toDO);
    });
  }

  // --------

  getItem(id: number) {
    return this.request<B2BDTO>({
      url: api.b2b.id(id),
    }).then((data) => {
      return b2bMapper.toDO(data!);
    });
  }

  // --------

  export(fields: Array<keyof B2BDO>, filters?: FreeQueries) {
    const keysMap = b2bMapper.getKeysMap();

    return this.request<null>({
      method: 'post',
      data: {
        fields: fields.map((field) => keysMap[field]),
        model: 'b2b.models.B2B',
      },
      params: filters,
      url: api.core.export,
    });
  }

  // --------

  create(data: SendData) {
    return this.request<B2BDTO>({
      method: 'post',
      data: b2bMapper.toDTO(data),
      url: api.b2b.root,
    }).then((data) => {
      return b2bMapper.toDO(data!);
    });
  }

  // --------

  update(data: SendData, id: number) {
    return this.request<B2BDTO>({
      method: 'patch',
      data: b2bMapper.toDTO(data),
      url: api.b2b.id(id),
    }).then((data) => {
      return b2bMapper.toDO(data!);
    });
  }

  // --------

  delete(id: number) {
    return this.request<void>({
      method: 'delete',
      url: api.b2b.id(id),
    });
  }
}

export const b2bAPIService = new B2BAPIService(`${env.API}api/`);
