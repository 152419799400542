import HTTP from '../http';

// Config

import { env, api } from '../../config';

// Entity

import {
  TransmissionFiberDTOPartial,
  TransmissionListQueryDTO,
  TransmissionDTOPartial,
  TransmissionFiberDTO,
  TransmissionListDTO,
  ITransmissionDTO,
} from '../../entity/transmission/DTO';
import { FiberDTO } from '../../entity/fiber';

// ----------------

class TransmissionAPIService extends HTTP {
  getList(query: TransmissionListQueryDTO): Promise<TransmissionListDTO | void> {
    return this.request({ url: api.tx.root, params: query });
  }

  getById(id: number): Promise<ITransmissionDTO | void> {
    return this.request({ url: api.tx.id(id) });
  }

  add(data: TransmissionDTOPartial): Promise<ITransmissionDTO | void> {
    return this.request({ url: api.tx.root, method: 'post', data });
  }

  update(id: number, data: TransmissionDTOPartial): Promise<ITransmissionDTO | void> {
    return this.request({ url: api.tx.id(id), method: 'patch', data });
  }

  delete(id: number): Promise<void> {
    return this.request({ url: api.tx.id(id), method: 'delete' });
  }

  import(file: File): Promise<any | void> {
    const formData = new FormData();
    formData.append('file', file);

    return this.request({ url: api.tx.import, method: 'post', data: formData });
  }

  export(data: TransmissionListQueryDTO): Promise<any | void> {
    return this.request({ url: api.tx.export, method: 'post', params: data });
  }

  cancelMove(id: number): Promise<any | void> {
    return this.request({ url: api.tx.movingСancel(id), method: 'post' });
  }

  getFiberById(id: number): Promise<TransmissionFiberDTO | void> {
    return this.request({ url: api.tx.fiberId(id) });
  }

  addFiber(data: FiberDTO): Promise<FiberDTO | void> {
    return this.request({ url: api.tx.fiber, method: 'post', data });
  }

  updateFiber(transmissionFiberId: number, data: FiberDTO): Promise<FiberDTO | void> {
    return this.request({ url: api.tx.fiberId(transmissionFiberId), method: 'patch', data });
  }

  dismantle(transmissionFiberId: number): Promise<ITransmissionDTO | void> {
    return this.request({ url: api.tx.dismantled(transmissionFiberId), method: 'post' });
  }
}

export default new TransmissionAPIService(`${env.API}api/`);
