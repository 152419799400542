import { useMemo, SyntheticEvent, useEffect, useState, useRef } from 'react';
import { useFormik, FormikValues, FormikHelpers } from 'formik';
import * as yup from 'yup';

// Utils

import prepareValuesForSubmit from './utils/prepareValuesForSubmit/prepareValuesForSubmit';
import createFormikConfig from './utils/createFormikConfig/createFormikConfig';

// Hooks

import useI18n from '../../hooks/useI18n/useI18n';

// Config

import { i18n, validate, app } from '../../config';

// -------- Types --------

export interface IConfig<V> {
  initialValues?: Partial<V>;
  saveLocally?: boolean;
  onUnmount?: (values: FormikValues) => void;
  validate?: (values: FormikValues) => object;
  onSubmit: (values: V, utils: IPublicUtils<V>, formikHelpers: FormikHelpers<V>) => void;
  fields: Array<IField<V>>;
  name: string;
}

export interface IField<V = {}> {
  prepareValueForSubmit?: (value: V) => any;
  defaultValue: any;
  nullValue?: any;
  validate?: (standard: validate.StandardValidations, yupObj: typeof yup, t: i18n.T['common']['validate']) => yup.SchemaOf<any>;
  name: keyof V;
}

interface IPublicUtils<V> {
  setGlobalError: (err: string | null) => void;
  clearStorage: () => void;
  setErrors: (errors: Partial<Record<keyof V, string[]>>, keysMap?: Record<string, string>) => void;
}

export type SelectValue = string | null;

// ----------------

/**
 * This hook is wrap over Formik.
 *
 * @param config - Form config object
 * @typeParam V  - Values type
 */

const useForm = <V extends object>(config: IConfig<V>) => {
  const { saveLocally = false } = config;
  const localStorageFormName = `${app.localStorageKeys.form}${config.name}`;

  const {
    common: { validate: t },
  } = useI18n();

  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [globalError, _setGlobalError] = useState<string | null>(null);
  const formikRef = useRef<any>();

  const formikConfig = useMemo(() => {
    const standardValidations = validate.createStandardValidations(t);
    let storageValues;

    if (saveLocally) {
      storageValues = localStorage.getItem(localStorageFormName);

      if (storageValues) {
        try {
          storageValues = JSON.parse(storageValues);
        } catch (err) {
          storageValues = null;
          localStorage.removeItem(localStorageFormName);
        }
      }
    }

    return createFormikConfig<V>(config.fields, standardValidations, t, config.initialValues, storageValues);
  }, [t, config.initialValues]);

  useEffect(() => {
    if (!isFirstRender) {
      /**
       * It is necessary to re-validate the form after changing the language
       * to display validation messages on the new language.
       */

      formik.validateForm();
    }
  }, [t]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (saveLocally) {
      interval = setInterval(saveToLocalStorage, 2000);
    }

    setIsFirstRender(false);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const formik = useFormik<V>({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: formikConfig.validationSchema,
    validateOnBlur: true,
    initialValues: formikConfig.initialValues,
    validate: config.validate,
    onSubmit: (values, formikHelpers) => {
      if (globalError) {
        setGlobalError(null);
      }

      const preparetedValues = prepareValuesForSubmit<V>(values, config.fields);

      config.onSubmit(preparetedValues, { setGlobalError, clearStorage, setErrors }, formikHelpers);
    },
  });

  formikRef.current = formik;

  // -------- Handlers --------

  const handleFocus = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
    const name = typeof e === 'string' ? e : e.currentTarget.name;

    if (!formik.touched[name]) {
      formik.setFieldTouched(name, true, false);
    }

    if (formik.errors[name]) {
      formik.setFieldError(name, undefined);
    }
  };

  const handleBlurUncontrolled = (e: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    formik.setFieldValue(e.currentTarget.name, e.currentTarget.value, true);
  };

  // -------- Utils --------

  const saveToLocalStorage = () => {
    localStorage.setItem(localStorageFormName, JSON.stringify(formikRef.current.values));
  };

  const setGlobalError = (err: string | null) => {
    _setGlobalError(err);
  };

  const clearStorage = () => {
    localStorage.removeItem(localStorageFormName);
  };

  const getUncontrolledTextFieldProps = (name: string) => ({
    defaultValue: formik.getFieldMeta(name).value,
    helperText: formik.getFieldMeta(name).touched && formik.getFieldMeta(name).error,
    onFocus: handleFocus,
    onBlur: handleBlurUncontrolled,
    error: Boolean(formik.getFieldMeta(name).touched && formik.getFieldMeta(name).error),
    name,
  });

  const getControlledTextFieldProps = (name: string) => ({
    helperText: formik.getFieldMeta(name).touched && formik.getFieldMeta(name).error,
    onChange: formik.handleChange,
    onFocus: handleFocus,
    onBlur: formik.handleBlur,
    error: Boolean(formik.getFieldMeta(name).touched && formik.getFieldMeta(name).error),
    value: formik.getFieldMeta(name).value,
    name,
  });

  const getMessagesProps = (name: string) => ({
    helperText: formik.getFieldMeta(name).touched && formik.getFieldMeta(name).error,
    error: Boolean(formik.getFieldMeta(name).touched && formik.getFieldMeta(name).error),
  });

  const setFieldValueAndTouched = (name: string, value: any) => {
    if (!formik.touched[name]) {
      formik.setFieldTouched(name, true, false);
    }

    formik.setFieldValue(name, value, true);
  };

  const setErrors = (errors: Partial<Record<keyof V, string[]>>, keysMap?: Record<string, string>) => {
    const errorObj = {};
    Object.keys(errors).forEach((key) => (errorObj[keysMap ? keysMap[key] : key] = errors[key][0]));

    formik.setErrors(errorObj);
  };

  return {
    formik,
    data: { globalError },
    getUncontrolledTextFieldProps,
    getControlledTextFieldProps,
    setFieldValueAndTouched,
    getMessagesProps,
    handleFocus,
  };
};

export default useForm;
