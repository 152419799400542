import { leasedLineActionCreators } from './leasedLineActionCreators';
import { leasedLineSlice as slice } from './leasedLineSlice';

const leasedLineSlice = {
  ...slice,
  actions: { ...slice.actions, ...leasedLineActionCreators },
};

export { leasedLineSlice };
export * from './leasedLineBranch';
export * from './saga';
