import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { historyAPIService, HistoryAPIService } from '../../../services/api/historyAPIService';

// Entities

import { HistoryDO } from '../../../entity/history';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/history/${actionPiece}`; // Create action type

export const historyActionCreators = {
  getPaginatedList$Req: createAction(
    cat('getPaginatedList$Req'),
    (props: {
      requestActionTypeData?: number;
      onSuccess?: (data: HistoryDO.PaginatedList) => void;
      onError?: (err: AxiosError) => void;
      queries?: HistoryAPIService.GetPaginatedListQueries;
    }): { payload: Partial<RequestSagaConfig<HistoryDO.PaginatedList>> } => {
      return {
        payload: {
          requestActionTypeData: props.requestActionTypeData,
          serviceArgs: [props.queries],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: historyAPIService.getPaginatedList,
          context: historyAPIService,
          timeout: 300,
        },
      };
    }
  ),
};
