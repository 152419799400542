import { FiberDO } from '../../../../entity/fiber';

// -------- Types --------

type Labels = Omit<Record<keyof FiberDO, string>, 'txID' | 'popa' | 'popb' | 'comments' | 'id'>;

// ----------------

// prettier-ignore
const labels: Labels = {
  fiberDeliveredEstimate: 'Fiber delivered estimated',
  civilWorksFinished    : 'Civil works finish',
  publicPermitRegion    : 'Public permit region',
  ringSendToEricsson    : 'Ring sent to Ericsson',
  fiberWorksFinished    : 'Fiber works finish',
  publicPermitCity      : 'Public permit city',
  deliveryForecast      : 'Delivery forecast',
  civilWorksStart       : 'Civil works start',
  fiberWorksStart       : 'Fiber works start',
  fiberDelivered        : 'Fiber delivered (real)',
  insertedInRing        : 'Inserted in a RING',
  atn5gSFPReady         : 'ATN 5G SFP Ready',
  splicePartner         : 'Splicing partner',
  subcontractor         : 'Subcontractor',
  builtReceived         : 'As-built received (civil)',
  builtSplice           : 'Built splice',
  ringAtnName           : 'ATN ring name',
  cancellation          : 'Cancellation',
  fiberDesign           : 'Fiber design',
  ownerAuth             : 'Owner authorization',
  orderDate             : 'Order date',
  network               : 'Network',
  safFile               : 'SAF',
  coccon                : 'Cocon',
  pmObe                 : 'PM OBE',
  ring                  : 'Ring name',
  ssv                   : 'SSV',
  rfb                   : 'RFB',
  fac                   : 'FAC',
};

// --------

const fiberEN = {
  labels,
};

export default fiberEN;
