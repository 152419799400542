/**
 * These actions are not intended for a specific slice and are global.
 */

import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../config/app';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/${actionPiece}`; // Create action type

export default {
  /**
   * Sets all branches to default state.
   */

  setDefaultState: createAction(cat('setDefaultState')),
};
