import { HistoryDO } from '../../../entity/history';

// -------- Types --------

export type HistoryBranch = {
  list: HistoryDO.$[];
  meta: {
    totalCount: number;
  };
};

// -------- Initial state of branch --------

export const historyBranchInitialState: HistoryBranch = {
  list: [],
  meta: { totalCount: 0 },
};
