import { ThemeOptions } from '@mui/material/styles';

// ----------------

// prettier-ignore
const breakpoints: ThemeOptions['breakpoints'] = {
  values: {
    xs: 0,    // Phone
    sm: 600,  // Small tablet
    md: 960,  // Big tablet
    lg: 1280, // Laptop
    xl: 1700  // Desktop
  }
};

export default breakpoints;
