import { all, takeEvery } from 'redux-saga/effects';

// Slice

import slice from '../index';

// Saga

import updateSaga from './updateSaga/updateSaga';
import addSaga from './addSaga/addSaga';

// ----------------

export default function* () {
  yield all([takeEvery(slice.actions.update$Req.type, updateSaga)]);
  yield all([takeEvery(slice.actions.add$Req.type, addSaga)]);
}
