import { coreActionCreators } from './coreActionCreators';
import { coreSlice as slice } from './coreSlice';

const coreSlice = {
  ...slice,
  actions: { ...slice.actions, ...coreActionCreators },
};

export { coreSlice };
export * from './coreBranch';
export * from './saga';
