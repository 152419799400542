import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { reconstructionSlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';

// ----------------

export function* reconstructionSaga() {
  yield all([
    takeEvery(
      [
        reconstructionSlice.actions.getPaginatedList$Req.type,
        reconstructionSlice.actions.getItem$Req.type,
        reconstructionSlice.actions.export$Req.type,
        reconstructionSlice.actions.create$Req.type,
        reconstructionSlice.actions.update$Req.type,
        reconstructionSlice.actions.delete$Req.type,
      ],
      requestActionSaga
    ),
  ]);
}
