import { historyActionCreators } from './historyActionCreators';
import { historySlice as slice } from './historySlice';

const historySlice = {
  ...slice,
  actions: { ...slice.actions, ...historyActionCreators },
};

export { historySlice };
export * from './historyBranch';
export * from './saga';
