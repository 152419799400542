import React from 'react';

// Components

import Typography from '../../../view/common/Typography';
import Stack from '@mui/material/Stack';

// ----------------

const AppMaintenance: React.FC = () => {
  return (
    <Stack width="100%" height="90vh" justifyContent="center" alignItems="center" spacing={2}>
      <Typography sx={{ fontSize: [30, 60], fontWeight: 600, textAlign: 'center' }}>We'll be back soon!</Typography>
      <Typography sx={{ fontSize: [18, 20], textAlign: 'center', color: 'grey.500' }}>
        Sorry for the inconvenience but we're performing some maintenance at the moment. We'll back online shortly!
      </Typography>
    </Stack>
  );
};

export default AppMaintenance;
