import { CoreDO } from '../../../entity/core';

// -------- Types --------

export type CoreBranch = {
  entityTypeList: CoreDO.EntityType[];
};

// -------- Initial state of branch --------

export const coreBranchInitialState: CoreBranch = {
  entityTypeList: [],
};
