/**
 * Creates relationship maps between `DTO` and `DO` keys.
 */

export function createKeysMaps<DTO extends object, DO extends object>(keys: Array<[keyof DTO, keyof DO]>) {
  const keysMaps = {
    getDTOKeysMap() {
      const dtoKeys = {} as Partial<Record<keyof DO, keyof DTO>>;

      keys.forEach((item) => {
        dtoKeys[item[1]] = item[0];
      });

      return dtoKeys;
    },

    // --------

    getDOKeysMap() {
      const doKeys = {} as Partial<Record<keyof DTO, keyof DO>>;

      keys.forEach((item) => {
        doKeys[item[0]] = item[1];
      });

      return doKeys;
    },
  };

  return keysMaps;
}
