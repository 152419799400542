import { NotificationDTO, NotificationDO, NotificationCommon } from './';
import transmissionMapper from '../transmission/mapper';

// ----------------

export default {
  toDO(data: NotificationDTO.NotificationDTO): NotificationDO.NotificationDO {
    const dataTypeDO: NotificationDO.DataType = {} as NotificationDO.DataType;

    switch (data.type) {
      case NotificationCommon.NotificationType.updateTransmission: {
        const dataTypeDTO = data.data as NotificationDTO.NotificationUpdateTransmissionDataDTO;

        dataTypeDO.targetUsi = dataTypeDTO.update_target_usi;
        dataTypeDO.targetId = dataTypeDTO.update_target_id;
        // @ts-ignore
        dataTypeDO.changes = dataTypeDTO.changes.map((item) => ({
          from: item.from,
          to: item.to,
          key: transmissionMapper.DTOKeyToEquallyDOKey(item.key),
        }));

        break;
      }

      case NotificationCommon.NotificationType.actionUpdateTransmission: {
        const dataTypeDTO = data.data as NotificationDTO.NotificationActionUpdateTransmissionDataDTO;

        dataTypeDO.targetUsi = dataTypeDTO.update_target_usi;
        dataTypeDO.targetId = dataTypeDTO.update_target_id;
        // @ts-ignore
        dataTypeDO.changes = dataTypeDTO.changes.map((item) => ({
          from: item.from,
          to: item.to,
          key: transmissionMapper.DTOKeyToEquallyDOKey(item.key),
        }));

        break;
      }

      case NotificationCommon.NotificationType.updateFiber: {
        const dataTypeDTO = data.data as NotificationDTO.NotificationUpdateFiberDataDTO;

        dataTypeDO.targetUsi = dataTypeDTO.update_target_usi;
        dataTypeDO.targetId = dataTypeDTO.update_target_id;
        // @ts-ignore
        dataTypeDO.changes = dataTypeDTO.changes.map((item) => ({
          from: item.from,
          to: item.to,
          key: transmissionMapper.DTOKeyToEquallyDOKey(item.key),
        }));

        break;
      }

      case NotificationCommon.NotificationType.actionUpdateFiber: {
        const dataTypeDTO = data.data as NotificationDTO.NotificationActionUpdateFiberDataDTO;

        dataTypeDO.targetUsi = dataTypeDTO.update_target_usi;
        dataTypeDO.targetId = dataTypeDTO.update_target_id;
        // @ts-ignore
        dataTypeDO.changes = dataTypeDTO.changes.map((item) => ({
          from: item.from,
          to: item.to,
          key: transmissionMapper.DTOKeyToEquallyDOKey(item.key),
        }));

        break;
      }
    }

    const notification: NotificationDO.NotificationDO = {
      isDeleted: data.is_closed,
      submitter: data.submitter,
      dataType: dataTypeDO,
      created: data.created,
      isSeen: data.is_seen,
      type: data.type,
      id: data.id,
    };

    return notification;
  },
};
