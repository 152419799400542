// -------- Types --------

import { SmallWorksDO } from '../../../../entity/smallWorks';

type Labels = Omit<Record<keyof SmallWorksDO.$, string>, 'id' | 'isArchived'>;

// ----------------

// prettier-ignore
const labels: Labels = {
  swNumber: 'SW Number',
  swOwner: 'SW Owner',
  description: 'Description',
  startDatePlanned: 'Start Date Planned',
  startDateActual: 'Start Date Actual',
  finalCompletionDate: 'Final Completion Date',
  permits: 'Permits',
  subcontractor: 'Subcontractor',
  fssr: 'FSSR',
  boqInitial: 'BOQ Initial',
  boqFinal: 'BOQ Final',
  ber: 'BER',
  bep: 'BEP',
  comments: 'Comments',
  commentBuilt: 'Comment As Built',
  checkBuilt: 'Check As Built',
  coconEncoding: 'Cocon Encoding'
};

// --------

const smallWorksEN = {
  labels
};

export default smallWorksEN;
