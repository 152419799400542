import { TXMicrowaveSynopsisDO } from '../../../entity/txMicrowaveSynopsis';

// -------- Types --------

export type TXMicrowaveSynopsisBranch = {
  list: TXMicrowaveSynopsisDO[];
};

// -------- Initial state of branch --------

const txMicrowaveSynopsisBranchInitialState: TXMicrowaveSynopsisBranch = {
  list: [],
};

export default txMicrowaveSynopsisBranchInitialState;
