import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { coreBranchInitialState } from './coreBranch';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { CoreDO } from '../../../entity/core';

// ----------------

export const coreSlice = createSlice({
  name: `${reduxActionTypePrefix}/core`,
  initialState: coreBranchInitialState,

  // -------- Reducers --------

  reducers: {
    setEntityType: (state, action: PayloadAction<{ list: CoreDO.EntityType[] }>) => {
      state.entityTypeList = action.payload.list;
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return coreBranchInitialState;
    });
  },
});
