import { GlossaryCommon } from './';

// ----------------

export namespace GlossaryDO {
  export type $ = {
    value: number;
    label: string;
    code: string;
  };

  export type List = $[];

  export type ListByType = Partial<Record<GlossaryCommon.Type.Item, List>>;
}
