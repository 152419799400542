import { call, put } from 'redux-saga/effects';
import userSlice from '../../index';
import userService from '../../../../../services/api/user';
import router from '../../../../../router';
import { localStorageKeys, redirectRoutes } from '../../../../../config/app';

export default function* verifyOtpSaga(action) {
  try {
    const response = yield call([userService, userService.verifyOtp], {
      otp: action.payload.otp,
      email: action.payload.email
    });
    if (response.is_valid) {
      localStorage.setItem(localStorageKeys.otpValidated, "true");
      localStorage.setItem(localStorageKeys.token, response.access_token);
      yield put(userSlice.actions.verifyOtpSuccess());
      yield put(
        userSlice.actions.getBaseData$Req(
          () => {
            router.navigate(redirectRoutes.defaultForAuth);
          },
          (err) => {
            if (err.response?.status === 503) {
              window.location.reload();
            }
          }
        )
      );
    } else {
      yield put(userSlice.actions.verifyOtpFail('OTP verification failed'));
    }
  } catch (error) {
    yield put(userSlice.actions.verifyOtpError('OTP verification failed'));
  }
}
