import { NoteDTO, NoteDO } from './';

// ----------------

export const noteMapper = {
  toDO(data: NoteDTO.$): NoteDO.$ {
    return {
      entityTypeId: data.content_type,
      entityId: data.object_id,
      updated: data.updated_at,
      created: data.created_at,
      text: data.text,
      id: data.id,
      creator: {
        email: data.created_by.email,
        id: data.created_by.id,
      },
    };
  },

  // --------

  toDTO(data: Partial<NoteDO.$>): Partial<NoteDTO.$> {
    return {
      content_type: data.entityTypeId,
      object_id: data.entityId,
      text: data.text,
    };
  },
};
