import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { glossarySlice } from '../index';

// Saga

import { getListByTypeSaga } from './getListByTypeSaga';
import getGlossariesSaga from './getGlossariesSaga';

// ----------------

export function* glossarySaga() {
  yield all([
    takeEvery(glossarySlice.actions.getGlossaries$Req.type, getGlossariesSaga),
    takeEvery(glossarySlice.actions.getListByType$Req.type, getListByTypeSaga),
  ]);
}
