import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Entities

import { TXMicrowaveSynopsisDO } from '../../../entity/txMicrowaveSynopsis';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/txMicrowaveSynopsis/${actionPiece}`; // Create action type

const txMicrowaveSynopsisActionCreators = {
  getList$Req: createAction<{
    onSuccess?: (data: TXMicrowaveSynopsisDO[]) => void;
    onError?: (err: AxiosError) => void;
    txId: number;
  }>(cat('getList$Req')),
};

export default txMicrowaveSynopsisActionCreators;
