import { useSelector, useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';
import deepcopy from 'deepcopy';
import React from 'react';

// Components

import AdvancedFiltersAddPresetForm, { AdvancedFiltersAddPresetFormProps } from './components/AdvancedFiltersAddPresetForm';
import Select, { SelectValue, selectDefaultValue } from '../../../../common/Select';
import AdvancedFiltersValueField from './components/AdvancedFiltersValueField';
import Popup, { PopupProps } from '../../../../common/Popup';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import SectionList from '../../../../common/SectionList';
import DynamicRow from '../../../../common/DynamicRow';
import FieldsRow from '../../../../common/FieldsRow';
import AddIcon from '@mui/icons-material/Add';
import Button from '../../../../common/Button';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';

// Redux

import { RootState, userSlice_n } from '../../../../../../redux';

// Hooks

import useForm, { IConfig } from '../../../../../../hooks/useForm/useForm';
import usePopupState from '../../../../../../hooks/usePopupState/usePopupState';

// Utils

import { getConditionOptions } from './utils/getConditionOptions';
import { getDefaultValue } from './utils/getDefaultValue';
import hasPermissions from '../../../../../../utils/hasPermissions/hasPermissions';

// Entities

import { userMapper, UserCommon } from '../../../../../../entity/user';
import { GlossaryDO } from '../../../../../../entity/glossary';
import { EnumDO } from '../../../../../../entity/enum';

// Types

import { DefaultFieldType } from './components/AdvancedFiltersValueField';
import { Condition } from '../../../../../../services/queryService';
import { Option } from '../../../../../../types/component';

// -------- Types --------

type Props<FilterName extends string = string> = {
  uiStorageEntityKey: string;
  uiStorageTableKey: string;
  initialValues?: Partial<Values>;
  hiddenFields?: Array<FilterName>;
  popupProps?: Partial<PopupProps>;
  onSubmit: IConfig<Values>['onSubmit'];
  presets: Preset[];
  config: Array<Config<FilterName> | undefined>;
};

type Config<FilterName> = {
  defaultFieldType: DefaultFieldType;
  permissions?: UserCommon.PermissionList;
  placeholder?: string;
  glossary?: GlossaryDO[];
  options?: Option[];
  label: string;
  enum?: EnumDO.List;
  name: FilterName;
};

export type Values = {
  filters: Array<Filter>;
  preset: SelectValue;
  mode: FilterMode;
};

type Filter = {
  condition: SelectValue;
  value: any;
  name: SelectValue;
};

enum FilterMode {
  AND = 'and',
  OR = 'or',
}

export type Preset = {
  filters: Array<Filter>;
  name: string;
  id: string;
};

// ----------------

function AdvancedFilters<FilterName extends string = string>(props: Props<FilterName>): React.ReactElement {
  const filteredConfig = props.config.filter(
    (item) => item !== undefined && hasPermissions(item.permissions) && !props.hiddenFields?.includes(item.name)
  ) as Array<Config<FilterName>>;
  const uiStorage = useSelector((state: RootState) => state.user_n.data.uiStorage);
  const updateReq = useSelector((state: RootState) => state.request[userSlice_n.actions.update$Req.type]);
  const dispatch = useDispatch();
  const popup = usePopupState<'add-preset-form'>();

  // -------- Preparation --------

  const { formik, setFieldValueAndTouched, getControlledTextFieldProps, getMessagesProps } = useForm<Values>({
    initialValues: props.initialValues,
    onSubmit: props.onSubmit,
    name: 'AdvancedFilters',
    fields: [
      {
        name: 'filters',
        defaultValue: [],
        validate: (s, y, t) =>
          y.array().of(
            y.object().shape({
              condition: s.selectRequired,
              value: y.mixed().required(t.required),
              name: s.selectRequired,
            })
          ),
      },
      { name: 'mode', defaultValue: FilterMode.AND },
      { name: 'preset', defaultValue: selectDefaultValue },
    ],
  });

  const nameOptions: Option[] = React.useMemo(() => filteredConfig.map((config) => ({ value: config.name, label: config.label })), []);

  // -------- Handlers --------

  const handleToggleChange = (e: React.MouseEvent, value) => {
    if (value) {
      setFieldValueAndTouched('mode', value);
    }
  };

  // --------

  const handleAdd = () => {
    formik.setFieldValue('filters', [
      ...formik.values.filters,
      {
        condition: selectDefaultValue,
        value: null,
        name: selectDefaultValue,
      },
    ]);

    if (formik.values.preset) {
      formik.setFieldValue('preset', selectDefaultValue);
    }
  };

  // --------

  const handleDelete = (e: React.MouseEvent) => {
    const id = Number(e.currentTarget.id);

    const filters = [...formik.values.filters];
    filters.splice(id, 1);

    formik.setValues({ ...formik.values, filters });

    if (formik.touched.filters && formik.touched.filters.length) {
      const filterTouched = [...formik.touched.filters];
      filterTouched.splice(id, 1);
      formik.setTouched({ ...formik.touched, filters: filterTouched });
    }

    if (formik.values.preset) {
      formik.setFieldValue('preset', selectDefaultValue);
    }
  };

  // --------

  const handleNameChange = (value: SelectValue, index: number) => {
    const defaultFieldType = filteredConfig.find((item) => item.name === value)?.defaultFieldType;
    let conditionValue = formik.values.filters[index].condition as Condition;

    setFieldValueAndTouched(`filters.${index}.name`, value);

    if (defaultFieldType) {
      const nextConditions = getConditionOptions(defaultFieldType).map((item) => item.value);

      if (!conditionValue || !nextConditions.includes(conditionValue)) {
        conditionValue = 'equal';
        formik.setFieldValue(`filters.${index}.condition`, 'equal');
      }

      formik.setFieldValue(`filters.${index}.value`, getDefaultValue(conditionValue, defaultFieldType || null).defaultValue);
    } else {
      formik.setFieldValue(`filters.${index}.condition`, null);
      formik.setFieldValue(`filters.${index}.value`, null);
    }

    if (formik.values.preset) {
      formik.setFieldValue('preset', selectDefaultValue);
    }
  };

  // --------

  const handleConditionChange = (value: SelectValue, index: number) => {
    const defaultFieldType = filteredConfig.find((item) => item.name === formik.values.filters[index].name)?.defaultFieldType!;
    const { fieldType } = getDefaultValue(formik.values.filters[index].condition as Condition, defaultFieldType);
    const { defaultValue: newDefaultValue, fieldType: newFieldType } = getDefaultValue(value as Condition, defaultFieldType);

    setFieldValueAndTouched(`filters.${index}.condition`, value);

    if (fieldType !== newFieldType) {
      formik.setFieldValue(`filters.${index}.value`, newDefaultValue);
    }

    if (formik.values.preset) {
      formik.setFieldValue('preset', selectDefaultValue);
    }
  };

  // --------

  const handleValueTextChange = (e: React.ChangeEvent) => {
    formik.handleChange(e);

    if (formik.values.preset) {
      formik.setFieldValue('preset', selectDefaultValue);
    }
  };

  // --------

  const handleClearClick = () => {
    formik.setFieldValue('filters', []);

    if (formik.values.preset) {
      formik.setFieldValue('preset', selectDefaultValue);
    }
  };

  // --------

  const handleSaveClick = () => {
    formik.validateForm(formik.values).then((errors) => {
      if (Object.keys(errors).length) {
        formik.submitForm();
      } else {
        popup.setOpenPopup({ name: 'add-preset-form' });
      }
    });
  };

  // --------

  const handleAddPresetSubmit: AdvancedFiltersAddPresetFormProps['onSubmit'] = (values) => {
    const preset: Preset = { name: values.name, filters: formik.values.filters, id: nanoid() };
    const uiStorageCopy = deepcopy(uiStorage);

    uiStorageCopy.entity[props.uiStorageEntityKey].table[props.uiStorageTableKey].filters.presets = [
      ...uiStorageCopy.entity[props.uiStorageEntityKey].table[props.uiStorageTableKey].filters.presets,
      preset,
    ];

    dispatch(
      userSlice_n.actions.update$Req({
        data: userMapper.toPartialDTO({ uiStorage: uiStorageCopy }),
        onSuccess: (data) => {
          dispatch(userSlice_n.actions.update({ data }));
          formik.setFieldValue('preset', preset.id);
          popup.closePopup();
        },
      })
    );
  };

  // --------

  const handlePresetItemDelete: React.MouseEventHandler = (e) => {
    e.stopPropagation();

    const uiStorageCopy = deepcopy(uiStorage);

    if (formik.values.preset === e.currentTarget.id) {
      formik.setFieldValue('preset', selectDefaultValue);
    }

    uiStorageCopy.entity[props.uiStorageEntityKey].table[props.uiStorageTableKey].filters.presets = uiStorageCopy.entity[
      props.uiStorageEntityKey
    ].table[props.uiStorageTableKey].filters.presets.filter((preset) => preset.id !== e.currentTarget.id);

    dispatch(userSlice_n.actions.update({ data: { uiStorage: uiStorageCopy } }));

    dispatch(
      userSlice_n.actions.update$Req({
        data: userMapper.toPartialDTO({ uiStorage: uiStorageCopy }),
      })
    );
  };

  // --------

  const handlePresetChange = (value: SelectValue) => {
    setFieldValueAndTouched('preset', value);

    if (value) {
      const preset = props.presets.find((preset) => preset.id === value);

      formik.setFieldValue('filters', preset?.filters);
      formik.setErrors({});
      formik.setTouched({});
    } else {
      formik.setFieldValue('filters', []);
    }
  };

  // --------

  return (
    <>
      <Popup
        titleEndAdornment={<Badge badgeContent={formik.values.filters.length} color="secondary" sx={{ ml: '20px' }} />}
        footerStackProps={{ justifyContent: 'space-between' }}
        maxWidth={1000}
        onClose={() => {}}
        title="Advanced filters"
        footerComponents={[
          <Stack key="1" direction="row-reverse" spacing={2}>
            <Button onClick={formik.submitForm}>Apply</Button>
            <Button variant="outlined" disabled={!formik.values.filters.length || !!formik.values.preset} onClick={handleSaveClick}>
              Save as
            </Button>
          </Stack>,
          <Button key="2" variant="outlined" onClick={handleClearClick} disabled={!formik.values.filters.length}>
            Clear
          </Button>,
        ]}
        {...props.popupProps}
      >
        <form onSubmit={formik.handleSubmit}>
          <SectionList
            sectionProps={{ typographyProps: { variant: 'title2' } }}
            gap={3}
            list={[
              {
                border: true,
                title: 'Preset filters',
                children: (
                  <Select
                    textFieldProps={{ label: 'Preset' }}
                    onItemDelete={handlePresetItemDelete}
                    onChange={handlePresetChange}
                    options={props.presets.map((preset) => ({ value: preset.id, label: preset.name }))}
                    value={formik.values.preset}
                  />
                ),
              },

              {
                border: true,
                title: 'Filter mode',
                children: (
                  <ToggleButtonGroup exclusive value={formik.values.mode} color="primary" onChange={handleToggleChange}>
                    <ToggleButton value={FilterMode.AND} sx={{ width: 70 }}>
                      AND
                    </ToggleButton>
                    <ToggleButton value={FilterMode.OR} sx={{ width: 70 }}>
                      OR
                    </ToggleButton>
                  </ToggleButtonGroup>
                ),
              },

              // --------

              {
                title: 'Active filters',
                children: formik.values.filters.map((filter, index) => {
                  const configItem = filteredConfig.find((item) => item.name === filter.name);

                  return (
                    <FieldsRow
                      stretchChildren
                      sx={{ borderBottom: (t) => ({ xs: `1px dashed ${t.palette.grey[300]}`, md: 'none' }), pb: { xs: 2, md: '0' } }}
                      key={index}
                    >
                      <DynamicRow id={String(index)} onDelete={handleDelete} stackProps={{ width: '100%' }}>
                        {/* name */}

                        <Select
                          textFieldProps={{ ...getMessagesProps(`filters.${index}.name`), label: 'Field' }}
                          options={nameOptions}
                          value={filter.name}
                          onChange={(value) => {
                            handleNameChange(value, index);
                          }}
                        />

                        {/* condition */}

                        {filter.name && (
                          <Select
                            // @ts-ignore
                            autocompleteProps={{ disableClearable: true }}
                            textFieldProps={{ label: 'Operator' }}
                            options={getConditionOptions(configItem?.defaultFieldType!)}
                            value={filter.condition}
                            sort={false}
                            onChange={(value) => {
                              handleConditionChange(value, index);
                            }}
                          />
                        )}

                        {/* value */}

                        {filter.name && (
                          <AdvancedFiltersValueField
                            defaultFieldType={configItem?.defaultFieldType!}
                            datePickerProps={{
                              textFieldProps: { label: 'Value', ...getMessagesProps(`filters.${index}.value`) },
                              onChange: (value) => {
                                setFieldValueAndTouched(`filters.${index}.value`, value);
                                if (formik.values.preset) {
                                  formik.setFieldValue('preset', selectDefaultValue);
                                }
                              },
                              value: filter.value,
                            }}
                            textFieldProps={{
                              ...getControlledTextFieldProps(`filters.${index}.value`),
                              onChange: handleValueTextChange,
                              label: 'Value',
                              ...(configItem && configItem.placeholder ? { placeholder: configItem.placeholder } : {}),
                            }}
                            selectProps={{
                              onChange: (value) => {
                                setFieldValueAndTouched(`filters.${index}.value`, value);
                                if (formik.values.preset) {
                                  formik.setFieldValue('preset', selectDefaultValue);
                                }
                              },
                              textFieldProps: { ...getMessagesProps(`filters.${index}.value`), label: 'Value' },
                              options: configItem?.options || configItem?.glossary || configItem?.enum || [],
                              value: filter.value,
                            }}
                            multiSelectProps={{
                              onChange: (value) => {
                                setFieldValueAndTouched(`filters.${index}.value`, value.length ? value : null);
                                if (formik.values.preset) {
                                  formik.setFieldValue('preset', selectDefaultValue);
                                }
                              },
                              textFieldProps: { ...getMessagesProps(`filters.${index}.value`), label: 'Value' },
                              options: configItem?.options || configItem?.glossary || configItem?.enum || [],
                              value: filter.value || [],
                            }}
                            switchProps={{
                              onChange: (event, checked) => {
                                setFieldValueAndTouched(`filters.${index}.value`, checked);
                                if (formik.values.preset) {
                                  formik.setFieldValue('preset', selectDefaultValue);
                                }
                              },
                              checked: filter.value,
                            }}
                            condition={filter.condition as Condition}
                          />
                        )}
                      </DynamicRow>
                    </FieldsRow>
                  );
                }),
              },
            ]}
          />
        </form>

        <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAdd} sx={{ mt: formik.values.filters.length === 0 ? '0' : 3 }}>
          Add filter
        </Button>
      </Popup>

      {/* Popups */}

      {popup.openPopup?.name === 'add-preset-form' && (
        <AdvancedFiltersAddPresetForm popupProps={{ onClose: popup.closePopup }} onSubmit={handleAddPresetSubmit} loading={updateReq === true} />
      )}
    </>
  );
}

export default AdvancedFilters;
export { Props as AdvancedFiltersProps };
