import { FamilyGroup, ITransmissionDO } from '../../entity/transmission/DO';
import { globalObj } from '../../config/app';
import { RootState } from '../../redux';

/**
 * Returns the name of the group that TX belongs to.
 */

export default function getTXFamilyGroup(data: ITransmissionDO, familyKey?: 'family' | 'familyMpProjected' | 'familyTemp'): FamilyGroup {
  const reduxState: RootState = globalObj.reduxStore?.getState();
  const familyGlossary = reduxState._glossary.data.family;

  const family: Array<{ value: number; familyGroup: FamilyGroup }> = [];
  let ongoingId;

  familyGlossary.forEach((item) => {
    switch (true) {
      case item.label === 'FTTN' || item.label === 'FTTS': {
        family.push({ value: item.value, familyGroup: 'fiber' });

        break;
      }

      case item.label === 'Leased Line': {
        family.push({ value: item.value, familyGroup: 'leasedLine' });

        break;
      }

      case item.label === 'Microwave': {
        family.push({ value: item.value, familyGroup: 'microwave' });

        break;
      }

      case item.label === 'ONGOING': {
        ongoingId = item.value;
        break;
      }
    }
  });

  let key: 'family' | 'familyMpProjected' | 'familyTemp' = 'family';

  if (data.family === ongoingId) {
    key = 'familyMpProjected';
  }
  if (familyKey) {
    key = familyKey;
  }

  return family.find((item) => item.value === data[key])?.familyGroup || null;
}
