import { put, call, delay } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

// Redux

import requestSlice from '../../slices/request';

// -------- Types --------

type Config<R> = {
  requestActionTypeData?: number;
  requestActionType: string;
  serviceArgs?: any[];
  successMess?: string;
  errorMess?: string;
  onSuccess?: (res: R) => void;
  onError?: (err: AxiosError) => void;
  service: (...args: any[]) => Promise<R | void>;
  timeout?: number;
  context: object;
};

/**
 * The saga that contains the logic of a typical server request.
 *
 * @param config - Config object
 */

export default function* requestSaga<R>(config: Config<R>) {
  const { requestActionTypeData, requestActionType, serviceArgs, successMess, errorMess, onSuccess, onError, service, context, timeout } = config;

  // Add information about the new request action to the request branch

  yield put(requestSlice.actions.add(requestActionType, requestActionTypeData));

  try {
    if (timeout) {
      yield delay(timeout);
    }

    const res = yield call([context, service], ...(serviceArgs || []));

    if (onSuccess) {
      yield onSuccess(res);
    }

    if (successMess) {
      toast.success(successMess);
    }
  } catch (err) {
    if (onError) {
      yield onError(err as AxiosError);
    }

    if (errorMess) {
      toast.error(errorMess);
    }
  } finally {
    // Delete information about the request action from the request branch

    yield put(requestSlice.actions.delete(requestActionType));
  }
}

export { Config as RequestSagaConfig };
