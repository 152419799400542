import { put } from 'redux-saga/effects';

// Redux

import txSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* updateTXSaga(action: ReturnType<typeof txSlice.actions.updateTX$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    successMess: 'Site successfully updated',
    serviceArgs: [action.payload.id, action.payload.data],
    service: txService.updateTX,
    context: txService,
    onSuccess: function* (data) {
      if (action.meta.updateSelectedData) {
        yield put(txSlice.actions.setSelected(data));
      }

      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
    onError: (err) => {
      if (action.meta.onError) {
        action.meta.onError(err?.response?.data);
      }
    },
  });
}
