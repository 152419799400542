import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Entity

import { MicrowaveDO, MicrowaveDTO } from '../../../entity/microwave';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/microwave/${actionPiece}`; // Create action type

export default {
  add$Req: createAction(
    cat('add$Req'),
    (props: {
      onSuccess?: (data: MicrowaveDO.MicrowaveDO) => void;
      onError?: (data: any) => void;
      message?: string;
      data: Partial<MicrowaveDTO.MicrowaveDTO>;
    }) => ({
      payload: {
        data: props.data,
      },
      meta: {
        onSuccess: props.onSuccess,
        onError: props.onError,
        message: props.message,
      },
    })
  ),

  // --------

  update$Req: createAction(
    cat('update$Req'),
    (props: {
      onSuccess?: (data: MicrowaveDO.MicrowaveDO) => void;
      onError?: (data: any) => void;
      message?: string;
      data: Partial<MicrowaveDTO.MicrowaveDTO>;
      id: number;
    }) => ({
      payload: {
        data: props.data,
        id: props.id,
      },
      meta: {
        onSuccess: props.onSuccess,
        onError: props.onError,
        message: props.message,
      },
    })
  ),
};
