import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { coreAPIService } from '../../../services/api/coreAPIService';

// Entities

import { CoreDO } from '../../../entity/core';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/core/${actionPiece}`; // Create action type

export const coreActionCreators = {
  getEntityTypeList$Req: createAction(
    cat('getEntityTypeList$Req'),
    (props: {
      onSuccess?: (data: CoreDO.EntityType[]) => void;
      onError?: (err: AxiosError) => void;
    }): {
      payload: Partial<RequestSagaConfig<CoreDO.EntityType[]>>;
    } => {
      return {
        payload: {
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: coreAPIService.getEntityTypeList,
          context: coreAPIService,
        },
      };
    }
  ),
};
