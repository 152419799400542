import { defApiVersion as v } from './app';

// ----------------

export const core = {
  contentTypes: `${v}/core/content-types/`,
  export: `${v}/core/export/`,
};

// --------

export const user = {
  resetPasswordValidateToken: `${v}/users/password_reset/validate_token/`,
  resetPasswordConfirm: `${v}/users/password_reset/confirm/`,
  resetPassword: `${v}/users/password_reset/`,
  refreshToken: `${v}/users/token/refresh/`,
  token: `${v}/users/token/`,
  me: `${v}/users/me/`,
  generateOtpSecret: `${v}/users/generate_otp_secret/`,
  verifyOtp: `${v}/users/verify_otp/`
};

// --------

export const tx = {
  technologiesDistributionGraph: `${v}/transmissions/graph/technologies-distribution-rate/`,
  transmissionsGraphSnapshots: `${v}/transmissions/graph/shapshots/`,
  blueMoonFiberizationGraph: `${v}/transmissions/graph/blue-moon-fiberization-rate/`,
  bulkUpdateBlueMoon: `${v}/transmissions/blue-moon-bulk-update/`,
  fiberizationGraph: `${v}/transmissions/graph/fiberization-rate/`,
  futureRemainGraph: `${v}/transmissions/graph/future-remain/`,
  cartographyGraph: `${v}/transmissions/graph/cartography/`,
  bulkUpdateFiber: `${v}/transmissions/fiber-data-bulk-update/`,
  importExample: `${v}/transmissions/import/example/`,
  leasedLineId: (id: number) => `${v}/transmissions/leased-line/${id}/`,
  movingСancel: (id: number) => `${v}/transmissions/moving-cancel/${id}/`,
  microwaveId: (id: number) => `${v}/transmissions/microwave/${id}/`,
  bulkUpdate: `${v}/transmissions/bulk-update/`,
  dismantled: (id: number) => `${v}/transmissions/set-dismantled/${id}/`,
  leasedLine: `${v}/transmissions/leased-line/`,
  microwave: `${v}/transmissions/microwave/`,
  filesId: (id: number) => `${v}/transmissions/files/${id}/`,
  zipCode: `${v}/transmissions/zip-codes/`,
  notesId: (id: number) => `${v}/transmissions/notes/${id}/`,
  fiberId: (id: number) => `${v}/transmissions/fiber-data/${id}/`,
  export: `${v}/transmissions/export/`,
  import: `${v}/transmissions/import/`,
  upload: `${v}/transmissions/bulk-file-update/`,
  fiber: `${v}/transmissions/fiber-data/`,
  notes: `${v}/transmissions/notes/`,
  files: `${v}/transmissions/files/`,
  root: `${v}/transmissions/items/`,
  id: (id: number) => `${v}/transmissions/items/${id}/`,
};

// --------

export const glossary = {
  root: `${v}/glossaries/`,
};

// --------

export const notifications = {
  deleteAll: `${v}/notifications/delete-all/`,
  seeAll: `${v}/notifications/mark-all-seen/`,
  root: `${v}/notifications/`,
  id: (id: number) => `${v}/notifications/${id}/`,
};

// --------

export const txMicrowaveSynopsis = {
  txId: (txId: number) => `${v}/transmissions/microwave-synopsis/${txId}/`,
};

// --------

export const timeline = {
  txId: (txId: number) => `${v}/transmissions/history/${txId}/`,
};

// --------

export const b2b = {
  root: `${v}/b2b/items/`,
  id: (id: number) => `${v}/b2b/items/${id}/`,
};

// --------

export const reconstruction = {
  root: `${v}/reconstruction/items/`,
  id: (id: number) => `${v}/reconstruction/items/${id}/`,
};


export const smallWorks = {
  root: `${v}/smallWorks/items/`,
  id: (id: number) => `${v}/smallWorks/items/${id}/`,
};


// --------

export const cartography = {
  applicableId: (id: number) => `${v}/cartography/applicable/${id}/`,
  applicable: `${v}/cartography/applicable/`,
  comparing: `${v}/cartography/comparing/`,
  export: `${v}/cartography/applicable-export/`,
};

// --------

export const note = {
  root: `${v}/notes/items/`,
  id: (id: number) => `${v}/notes/items/${id}/`,
};

// --------

export const document = {
  root: `${v}/documents/items/`,
  id: (id: number) => `${v}/documents/items/${id}/`,
};

// --------

export const blueMoon = {
  root: `${v}/transmissions/blue-moons/`,
  id: (id: number) => `${v}/transmissions/blue-moons/${id}/`,
};

// --------

export const chart = {
  averageTimeBetweenFiberWorksStartAndFinish: `${v}/dashboard/average-time-between-fiber-works-start-and-finish/`,
  b2bStatusData: `${v}/dashboard/b2b-status-data/`,
  averageTimeBetweenFiberCivilStartAndFinish: `${v}/dashboard/average-time-between-fiber-civil-start-and-finish/`,
  targetStreamFttnDistributionSubcontractor: `${v}/dashboard/target-stream-fttn-distribution-per-subcontractor/`,
  averageTimeBetweenFiberInsertedRing: `${v}/dashboard/average-time-between-fiber-inserted-in-a-ring-and-sent-to-e/`,
  targetStreamFttnDistributionNetwork: `${v}/dashboard/target-stream-fttn-distribution-per-network/`,
  technologiesDistributionHistory: `${v}/transmissions/graph/technologies-distribution-rate-with-snapshots/`,
  ranSharingBackhaulEvolution: `${v}/transmissions/ran-sharing-backhaul-evolution-graph`,
  fttnDSOngoingSubcontractor: `${v}/dashboard/fttn-double-stream-ongoing-per-subcontractor/`,
  txTargetDeployedMicrowave: (year: number) => `${v}/transmissions/target-deployed-graph/microwave/${year}`,
  splicePartnerRatioFttn: `${v}/dashboard/splice-partner-ratio-for-fttn/`,
  subcontractorRatioFttn: `${v}/dashboard/subcontractor-ratio-for-fttn/`,
  txTargetDeployedFiber: (year: number) => `${v}/transmissions/target-deployed-graph/fiber/${year}`,
  currenToDSFttnChange: `${v}/dashboard/current-to-ds-fttn-change/`,
  txReadinessMicrowave: (year: number) => `${v}/transmissions/todo-name/tx_readiness_microwave/${year}`,
  fttnDSOngoingNetwork: `${v}/dashboard/fttn-double-stream-ongoing-per-network/`,
  txReadinessFiber: (year: number) => `${v}/transmissions/todo-name/tx_readiness_fiber/${year}`,
  networkRatioFttn: `${v}/dashboard/network-ratio-for-fttn/`,
  targetTxDeployed: `${v}/dashboard/target-tx-deployed/`,
  swappedSites: `${v}/dashboard/swapped-sites/`,
  fiberRing: (txId: number) => `${v}/transmissions/fiber-ring/${txId}`,

  exportAverageTimeBetweenFiberWorksStartAndFinish: `${v}/dashboard/export/average-time-between-fiber-works-start-and-finish`,
  exportAverageTimeBetweenFiberCivilStartAndFinish: `${v}/dashboard/export/average-time-between-fiber-civil-start-and-finish`,
  exportTargetStreamFttnDistributionSubcontractor: `${v}/dashboard/export/target-stream-fttn-distribution-per-subcontractor`,
  exportAverageTimeBetweenFiberInsertedRing: `${v}/dashboard/export/average-time-between-fiber-inserted-in-a-ring-and-sent-to-e`,
  exportTargetStreamFttnDistributionNetwork: `${v}/dashboard/export/target-stream-fttn-distribution-per-network`,
  exportFttnDSOngoingSubcontractor: `${v}/dashboard/export/fttn-ds-ongoing-per-subcontractor`,
  exportSplicePartnerRatioFttn: `${v}/dashboard/export/splice-partner-ratio-for-fttn`,
  exportSubcontractorRatioFttn: `${v}/dashboard/export/subcontractor-ratio-for-fttn`,
  exportCurrenToDSFttnChange: `${v}/dashboard/export/current-to-ds-fttn-change`,
  exportFttnDSOngoingNetwork: `${v}/dashboard/export/fttn-ds-ongoing-per-network`,
  exportNetworkRatioFttn: `${v}/dashboard/export/network-ratio-for-fttn`,
  exportTargetTxDeployed: `${v}/dashboard/export/target-tx-deployed`,
  exportSwappedSites: `${v}/dashboard/export/swapped-sites`,
};

// --------

export const history = {
  root: `${v}/observer/differences/`,
};
