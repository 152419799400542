import React from 'react';

// Components

import Popup, { PopupProps } from '../../../../../../common/Popup';
import TextField from '../../../../../../common/TextField';
import Button from '../../../../../../common/Button';

// Hooks

import useForm, { IConfig } from '../../../../../../../../hooks/useForm/useForm';

// -------- Types --------

export type Props = {
  popupProps?: Partial<PopupProps>;
  onSubmit: IConfig<Values>['onSubmit'];
  loading?: boolean;
};

type Values = {
  name: string;
};

// ----------------

const AdvancedFiltersAddPresetForm: React.FC<Props> = (props) => {
  const { formik, getControlledTextFieldProps } = useForm<Values>({
    name: 'AdvancedFiltersAddPresetForm',
    onSubmit: props.onSubmit,
    fields: [{ name: 'name', defaultValue: '', validate: (s) => s.stringRequired }],
  });

  return (
    <Popup
      maxWidth="small"
      onClose={() => {}}
      title="Save filters as"
      footerComponents={[
        <Button key={1} onClick={formik.submitForm} loading={props.loading}>
          Save
        </Button>,
      ]}
      {...props.popupProps}
    >
      <form onSubmit={formik.handleSubmit}>
        <TextField label="Name of filters" {...getControlledTextFieldProps('name')} />
      </form>
    </Popup>
  );
};

export default AdvancedFiltersAddPresetForm;
export { Props as AdvancedFiltersAddPresetFormProps };
