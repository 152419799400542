// Utils

import hasPermissions from '../../utils/hasPermissions/hasPermissions';

// Entities

import { ReconstructionDO } from './';
import { UserCommon } from '../user';

// ----------------

export const reconstructioAnalyzer = {
  canEdit(data: ReconstructionDO.$) {
    return hasPermissions([UserCommon.Permission.RECONSTRUCTION_EDIT]);
  },

  // --------

  canDelete(data: ReconstructionDO.$) {
    return hasPermissions([UserCommon.Permission.RECONSTRUCTION_DELETE]);
  },

  // --------

  canArchive(data: ReconstructionDO.$) {
    return !data.isArchived && hasPermissions([UserCommon.Permission.RECONSTRUCTION_ARCHIVE]);
  },

  // --------

  canUnarchive(data: ReconstructionDO.$) {
    return data.isArchived && hasPermissions([UserCommon.Permission.RECONSTRUCTION_UNARCHIVE]);
  },
};
