import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { noteSlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';

// ----------------

export function* noteSaga() {
  yield all([
    takeEvery(
      [
        noteSlice.actions.getPaginatedList$Req.type,
        noteSlice.actions.create$Req.type,
        noteSlice.actions.update$Req.type,
        noteSlice.actions.delete$Req.type,
      ],
      requestActionSaga
    ),
  ]);
}
