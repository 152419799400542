import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialState, { ITXBranch } from './branch';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';
import AC from './actionCreators';

// Saga

import txSaga from './saga';

// Types

import { TX } from '../../../types';

// ----------------

const txSlice = createSlice({
  name: `${reduxActionTypePrefix}/tx`,
  initialState,

  // -------- Reducers --------

  reducers: {
    setList: {
      prepare: (list: TX.Base.ITX[], totalPages: number, count: number) => ({
        payload: {
          totalPages,
          count,
          list,
        },
      }),

      reducer: (state, action: PayloadAction<{ list: TX.Base.ITX[]; totalPages: number; count: number }>) => {
        state.totalPages = action.payload.totalPages;
        state.count = action.payload.count;
        state.list = action.payload.list;
      },
    },

    setSelected: {
      prepare: (data: TX.Base.ITX) => ({
        payload: {
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: TX.Base.ITX }>) => {
        state.selected = action.payload.data;
      },
    },

    // --------

    deleteFromList: {
      prepare: (id: number) => ({
        payload: {
          id,
        },
      }),

      reducer: (state, action: PayloadAction<{ id: number }>) => {
        state.list = state.list.filter((item) => item.id !== action.payload.id);
      },
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      return initialState;
    });
  },
});

export { initialState, txSaga };
export type { ITXBranch };
export default { ...txSlice, actions: { ...txSlice.actions, ...AC } };
