// Slice

import slice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import notificationService from '../../../../../services/api/notification';

// ----------------

export default function* getListSaga(action: ReturnType<typeof slice.actions.getList$Req>) {
  yield requestSaga({
    ...(action.meta.requestActionTypeData && { requestActionTypeData: action.meta.requestActionTypeData }),
    requestActionType: action.type,
    serviceArgs: [action.payload.query],
    service: notificationService.getList,
    context: notificationService,
    onSuccess: (data) => {
      if (action.meta.onSuccess) {
        action.meta.onSuccess(data);
      }
    },
  });
}
