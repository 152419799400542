import { B2BDO } from '../../../../entity/b2b';

// -------- Types --------

type Labels = Omit<Record<keyof B2BDO, string>, 'id' | 'isArchived'>;

// ----------------

// prettier-ignore
const labels: Labels = {
  projectDescription: 'Project description',
  contactPersonEmail: 'Contact person email',
  contactPersonPhone: 'Contact person phone',
  pmServiceDelivery : 'PM service delivery',
  fiberAreaComment  : 'Fiber area comment',
  contactPerson     : 'Contact person',
  subcontractor     : 'Subcontractor',
  ebofAccepted      : 'Ebof accepted',
  receivingBep      : 'Receiving BEF',
  requestedRfs      : 'Requested RFS',
  builtSplice       : 'As built splice',
  builtCivil        : 'As built civil',
  sentFusion        : 'Sent fusion',
  fiberArea         : 'Fiber area',
  equipment         : 'Equipment',
  nisCode           : 'NIS code',
  pmFiber           : 'PM fiber',
  network           : 'Network',
  address           : 'Address',
  status            : 'Status',
  budget            : 'Budget',
  datePo            : 'Date PO',
  amount            : 'Amount',
  xCode             : 'X code',
  fiber             : 'Fiber',
  newHh             : 'New HH',
  civil             : 'Civil',
  feasN             : 'Feas N',
  cocon             : 'Cocon',
  site              : 'Site',
  bofN              : 'BOF N',
  bof               : 'BOF',
  rft               : 'RFT',
  ssv               : 'SSV',
  ber               : 'BER',
  bep               : 'BEP',
  odf               : 'ODF',
  fac               : 'FAC',
  vt                : 'VT',
};

// --------

const b2bEN = {
  labels,
};

export default b2bEN;
