import { Theme, alpha } from '@mui/material/styles';
import { SxProps } from '@mui/system';

// ----------------

type Elements = 'root' | 'paper' | 'header' | 'body' | 'footer';

export const getSx: (t: Theme) => Record<Elements, SxProps<Theme>> = (t) => ({
  root: {
    backdropFilter: 'blur(5px)',
    background: alpha(t.palette.common.black, 0.65),
    animation: '0.2s popupRootAnim',
    position: 'fixed',
    bottom: 0,
    zIndex: t.zIndex.modal,
    right: 0,
    left: 0,
    top: 0,

    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',

    '@keyframes popupRootAnim': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
  },

  paper: {
    flexDirection: 'column',
    borderRadius: '16px',
    animation: 'paperAnim 0.3s 0.2s forwards',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    opacity: 0,
    m: 2,

    maxHeight: '95vh',
    width: '100%',

    [t.breakpoints.only('xs')]: {
      animation: 'paperAnimMobile 0.34s 0.2s forwards',
      position: 'absolute',
      maxWidth: '100%',
      bottom: 0,
      right: 0,
      left: 0,
      m: '0px',

      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '16px',
      borderTopLeftRadius: '16px',
    },

    '@keyframes paperAnim': {
      '0%': {
        transform: 'translateY(40px)',
        opacity: 0,
      },

      '80%': {
        opacity: 1,
      },

      '100%': {
        transform: 'translateY(0px)',
        opacity: 1,
      },
    },

    '@keyframes paperAnimMobile': {
      '0%': {
        transform: 'translateY(80px)',
        opacity: 0,
      },

      '80%': {
        opacity: 1,
      },

      '100%': {
        transform: 'translateY(0px)',
        opacity: 1,
      },
    },
  },

  header: {
    borderBottom: `1px solid ${t.palette.grey[300]}`,
    alignItems: 'center',
    display: 'flex',
    py: [1, 2],
    px: [2, 3],
  },

  body: {
    overflow: 'auto',
    px: [2, 3],
    pt: [2, 3],
    pb: 4,
  },

  footer: {
    background: t.palette.grey[50],
    alignItems: 'center',
    borderTop: `1px solid ${t.palette.grey[300]}`,
    position: 'absolute',
    display: 'flex',
    bottom: 0,
    height: [80, 90],
    right: 0,
    left: 0,
    px: [2, 3],
  },
});
