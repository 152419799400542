import { createPortal } from 'react-dom';
import React from 'react';

// Components

import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '../Typography/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

// Hooks

import { useTheme } from '@mui/material/styles';
import useIsXS from '../../../../hooks/useIsXS/useIsXS';

// Styles

import { getSx } from './Popup.styles';

// -------- Types --------

export type PopupProps = {
  /**
   * If `true`, the body of the pop-up will be without spacing
   */
  bodyWithoutSpacing?: boolean;

  /**
   * Additional component that is displayed after the `title`
   */
  titleEndAdornment?: React.ReactNode;

  /**
   * List of components displayed in the footer
   */
  footerComponents?: React.ReactNode[];

  /**
   * Props for `Stack` component
   */
  footerStackProps?: StackProps;

  /**
   * Max pop-up width
   */
  maxWidth?: MaxWidth;

  /**
   * Title of popup
   */
  title: string;

  children?: React.ReactNode;

  onClose: () => void;
};

type MaxWidth = 'auto' | 'small' | 'medium' | 'large' | number;

// ----------------

const Popup: React.FC<PopupProps> = (props) => {
  const isXS = useIsXS();
  const t = useTheme();
  const sx = getSx(t);

  // prettier-ignore
  const maxWidthValues: Record<MaxWidth, string> = {
    auto  : 'auto',
    small : '400px',
    medium: '600px',
    large : '800px'
  };

  /**
   * Make the body not scroll when the popup is open
   */

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return createPortal(
    <Box sx={sx.root} onClick={isXS ? props.onClose : undefined}>
      <Paper
        elevation={0}
        onClick={(e) => e.stopPropagation()}
        sx={{ ...sx.paper, maxWidth: typeof props.maxWidth === 'string' ? maxWidthValues[props.maxWidth!] : props.maxWidth }}
      >
        {/* Header */}

        <Box component="header" sx={sx.header}>
          <Typography variant={isXS ? 'body1' : 'title2'} weight={700}>
            {props.title}
          </Typography>

          {props.titleEndAdornment && props.titleEndAdornment}

          <IconButton onClick={props.onClose} sx={{ ml: 'auto', p: 1, mr: -1 }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        {/* Body */}

        <Box
          sx={{
            ...sx.body,
            ...(props.footerComponents && { mb: isXS ? '80px' : '90px' }),
            ...(props.bodyWithoutSpacing && { pt: '0', px: '0', pb: '0' }),
          }}
        >
          {props.children}
        </Box>

        {/* Footer */}

        {props.footerComponents && (
          <Box component="footer" sx={sx.footer}>
            <Stack spacing={2} direction="row-reverse" alignItems="center" width="100%" {...props.footerStackProps}>
              {props.footerComponents}
            </Stack>
          </Box>
        )}
      </Paper>
    </Box>,
    document.body
  );
};

Popup.defaultProps = {
  bodyWithoutSpacing: false,
  maxWidth: 'medium',
};

export default Popup;
