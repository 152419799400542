import { put } from 'redux-saga/effects';

// Redux

import txFilesSlice from '../../index';
import snackbar from '../../../snackbar';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* deleteTXFileSaga(action: ReturnType<typeof txFilesSlice.actions.deleteTXFile$Req>) {
  yield put(txFilesSlice.actions.deleteFromList(action.payload.id));

  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.id],
    service: txService.deleteTXFile,
    context: txService,
    onSuccess: function* () {
      yield put(snackbar.actions.show('success', 'Document successfully deleted'));
    },
  });
}
