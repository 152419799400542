import initialState, { ISnackbarBranch } from './branch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';

// Types

// import {} from 'src/modern/types';

// ----------------

const snackbarSlice = createSlice({
  name: `${reduxActionTypePrefix}/snackbar`,
  initialState,

  // -------- Reducers --------

  reducers: {
    show: {
      prepare: (type: ISnackbarBranch['type'], text?: string) => ({
        payload: {
          type,
          text: text || 'Standard text',
        },
      }),

      reducer: (state, action: PayloadAction<{ type: ISnackbarBranch['type']; text: string }>) => {
        state.isOpen = true;
        state.type = action.payload.type;
        state.text = action.payload.text;
      },
    },

    // --------

    hide: (state) => {
      state.isOpen = false;
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      return initialState;
    });
  },
});

export { initialState };
export type { ISnackbarBranch };
export default snackbarSlice;
