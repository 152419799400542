import { CartographyDTO, CartographyDO } from './';
import { txMapper } from '../tx';

// ----------------

export const cartographyMapper = {
  changeToDO(data: CartographyDTO.Change): CartographyDO.Change {
    return {
      element: cartographyMapper.elementToDO(data.element),
      created: data.created_at,
      updated: data.updated_at,
      status: data.status,
      type: data.type,
      id: data.id,
    };
  },

  // --------

  changeToDTO(data: Partial<CartographyDO.Change>): Partial<CartographyDTO.Change> {
    return {
      status: data.status,
      type: data.type,
      id: data.id,
    };
  },

  // --------

  elementToDO(data: CartographyDTO.Element): CartographyDO.Element {
    const keysMap = txMapper.getDTOKeysMap();
    const changes = {} as CartographyDO.Element['changes'];

    Object.keys(data.changes).forEach((key) => {
      if (keysMap[key]) {
        changes[keysMap[key]] = data.changes[key];
      }
    });

    return {
      comparing: cartographyMapper.comparingToDO(data.comparing),
      changes,
      siteId: data.site_id,
      tx: data.transmission ? txMapper.toDO(data.transmission) : null,
      id: data.id,
    };
  },

  // --------

  comparingToDO(data: CartographyDTO.Comparing): CartographyDO.Comparing {
    return {
      previousReport: { periodEnd: data.report_previous.period_end, id: data.report_previous.id },
      nextReport: { periodEnd: data.report_next.period_end, id: data.report_next.id },
      id: data.id,
    };
  },
};
