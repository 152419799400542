import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { chartAPIService } from '../../../services/api/chartAPIService';

// Entities

import { ChartDO } from '../../../entity/chart';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/chart/${actionPiece}`; // Create action type

export const chartActionCreators = {
  getFiberRing$Req: createAction(
    cat('getFiberRing$Req'),
    (props: {
      onSuccess?: (data: ChartDO.FiberRing) => void;
      onError?: (err: AxiosError) => void;
      id: number;
    }): { payload: Partial<RequestSagaConfig<ChartDO.FiberRing>> } => {
      return {
        payload: {
          serviceArgs: [props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: chartAPIService.getFiberRing,
          context: chartAPIService,
        },
      };
    }
  ),
};
