import * as yup from 'yup';
import * as i18n from '../config/i18n';

// ----------------

const stringMaxLength = 100;
const textMaxLength = 1000;

const createStandardValidations = (t: i18n.T['common']['validate']) => ({
  stringRequired: yup.string().max(stringMaxLength, t.stringMaxLength(stringMaxLength)).required(t.required),
  selectRequired: yup.mixed().required(t.required),
  floatRequired: yup.number().typeError(t.digits).required(t.required),
  textRequired: yup.string().max(textMaxLength, t.stringMaxLength(textMaxLength)).required(t.required),
  intRequired: yup.number().integer(t.int).typeError(t.digits).required(t.required),
  password: yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, t.password),
  string: yup.string().max(stringMaxLength, t.stringMaxLength(stringMaxLength)),
  float: yup.number().typeError(t.digits),
  email: yup.string().email(t.email),
  text: yup.string().max(textMaxLength, t.stringMaxLength(textMaxLength)),
  int: yup.number().integer(t.int).typeError(t.digits),
});

export type StandardValidations = ReturnType<typeof createStandardValidations>;
export { createStandardValidations };
