import { GlossaryCommon } from './';

// ----------------

export namespace GlossaryDTO {
  export type $ = {
    value: string;
    code: string;
    id: number;
  };

  export type List = $[];

  export type ListByType = Partial<Record<GlossaryCommon.Type.Item, List>>;
}
