import { all, takeEvery } from 'redux-saga/effects';

// Slice

import glossarySlice from '../index';

// Saga

import getListSaga from './getListSaga/getListSaga';

// ----------------

export default function* () {
  yield all([takeEvery(glossarySlice.actions.getList$Req.type, getListSaga)]);
}
