import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// ----------------

const useIsXS = (): boolean => {
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: window.matchMedia(theme.breakpoints.only('xs').split(' ')[1]).matches,
    noSsr: true,
  });

  return isXS;
};

export default useIsXS;
