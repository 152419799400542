import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Entities

import { GlossariesDO } from '../../../entity/glossary';
import { GlossaryDO } from '../../../entity/glossary_n';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/glossary/${actionPiece}`; // Create action type

export const glossaryActionCreators = {
  getGlossaries$Req: createAction<{
    onSuccess?: (data: Partial<GlossariesDO>) => void;
  }>(cat('getGlossaries$Req')),

  // --------

  getListByType$Req: createAction<{
    onSuccess?: (data: Partial<GlossaryDO.ListByType>) => void;
  }>(cat('getListByType$Req')),
};
