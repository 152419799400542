import { NotificationDO } from '../../../entity/notification';

// -------- Types --------

export interface INotificationsBranch {
  groups: Record<GroupName, Group>;
}

export type GroupName = 'general' | 'action';

type Group = {
  loadedItems: number;
  unreadCount: number;
  totalItems: number;
  list: NotificationDO.NotificationDO[];
};

// -------- Initial state of branch --------

const notificationsBranchInitialState: INotificationsBranch = {
  groups: {
    general: { loadedItems: 0, unreadCount: 0, totalItems: 0, list: [] },
    action: { loadedItems: 0, unreadCount: 0, totalItems: 0, list: [] },
  },
};

export default notificationsBranchInitialState;
