import { GlossariesDO } from '../../../entity/glossary';
import { GlossaryDO } from '../../../entity/glossary_n';

// -------- Types --------

export type GlossaryBranch = {
  glossaries_: GlossaryDO.ListByType;
  glossaries: GlossariesDO;
};

// -------- Initial state of branch --------

export const glossaryBranchInitialState: GlossaryBranch = {
  glossaries_: {},
  glossaries: {} as GlossariesDO,
};
