import { TX } from '../../../types';

// -------- Types --------

export interface ITXFilesBranch {
  totalPages: number;
  list: TX.Base.ITXFile[];
}

// -------- Initial state of branch --------

const txFilesBranchInitialState: ITXFilesBranch = {
  totalPages: 0,
  list: [],
};

export default txFilesBranchInitialState;
