import { reconstructionBranchInitialState, ReconstructionBranch } from './reconstructionBranch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { ReconstructionDO } from '../../../entity/reconstruction';

// ----------------

export const reconstructionSlice = createSlice({
  name: `${reduxActionTypePrefix}/reconstruction`,
  initialState: reconstructionBranchInitialState,

  // -------- Reducers --------

  reducers: {
    set: (state, action: PayloadAction<{ list?: ReconstructionDO.$[]; meta?: Partial<ReconstructionBranch['meta']> }>) => {
      if (action.payload.list) {
        state.list = action.payload.list;
      }

      if (action.payload.meta) {
        state.meta = { ...state.meta, ...action.payload.meta };
      }
    },

    // --------

    setItem: (state, action: PayloadAction<{ data: ReconstructionDO.$ }>) => {
      state.item = action.payload.data;
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return reconstructionBranchInitialState;
    });
  },
});
