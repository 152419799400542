import React from 'react';

// Components

import Stack, { StackProps } from '@mui/material/Stack';

// -------- Types --------

export type FieldsRowProps = {
  /**
   * Gap between rows in mobile mode
   */
  mobileModeGap?: number | string;

  /**
   * If `true`, then in the mobile version, there will be only one field in row
   */
  mobileMode?: boolean;

  /**
   * If `true`, adds special styles.
   * Typically, uses as footer in form, that contains one or more buttons
   */
  footer?: boolean;

  /**
   * Gap between fields in row.
   * Not work in mobile mode
   */
  gapX?: number;

  /**
   * Gap between rows.
   * Not work in mobile mode
   */
  gapY?: number;

  /**
   * If `true`, then children will occupy all available space
   */
  stretchChildren?: boolean;

  justifyContent?: StackProps['justifyContent'];
  alignItems?: StackProps['alignItems'];
  children?: React.ReactNode;
  sx?: StackProps['sx'];
};

// ----------------

const FieldsRow: React.FC<FieldsRowProps> = (props) => {
  return props.children && (Array.isArray(props.children) ? !!props.children.length : true) ? (
    <Stack
      justifyContent={props.footer ? 'flex-end' : props.justifyContent}
      alignItems={props.alignItems}
      direction={
        props.footer
          ? 'row'
          : {
              xs: props.mobileMode! ? 'column' : 'row',
              md: 'row',
            }
      }
      spacing={
        props.footer
          ? 2
          : {
              xs: props.mobileMode! ? props.mobileModeGap! : props.gapX!,
              md: props.gapX!,
            }
      }
      mt={
        props.footer
          ? 4
          : {
              xs: props.mobileMode! ? props.mobileModeGap : props.gapY!,
              md: props.gapY!,
            }
      }
      sx={[
        { '&:first-of-type': { mt: '0' } },
        props.stretchChildren! && { '& > *': { flex: 1 } },
        ...(props.sx ? (Array.isArray(props.sx) ? props.sx : [props.sx]) : []),
      ]}
    >
      {props.children}
    </Stack>
  ) : null;
};

FieldsRow.defaultProps = {
  stretchChildren: false,
  justifyContent: 'stretch',
  mobileModeGap: '24px',
  mobileMode: true,
  alignItems: 'flex-start',
  footer: false,
  gapX: 2,
  gapY: 3,
};

export default FieldsRow;
