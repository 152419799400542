import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Entities

import { EnumDO } from '../../../entity/enum';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/enum/${actionPiece}`; // Create action type

export const enumActionCreators = {
  getEnums$Req: createAction(cat('getEnums$Req'), (props: { entities: Array<keyof EnumDO.FieldsByEntities> }): {
    payload: { entities: Array<keyof EnumDO.FieldsByEntities> };
  } => {
    return {
      payload: {
        entities: props.entities,
      },
    };
  }),
};
