import { all, takeEvery } from 'redux-saga/effects';

// Slice

import transmissionSlice from '../index';

// Saga

import getFiberByIdSaga from './getFiberByIdSaga/getFiberByIdSaga';
import getClearListSaga from './getClearListSaga/getClearListSaga';
import updateFiberSaga from './updateFiberSaga/updateFiberSaga';
import cancelMoveSaga from './cancelMoveSaga/cancelMoveSaga';
import dismantleSaga from './dismantleSaga/dismantleSaga';
import addFiberSaga from './addFiberSaga/addFiberSaga';
import getListSaga from './getListSaga/getListSaga';
import getByIdSaga from './getByIdSaga/getByIdSaga';
import importSaga from './importSaga/importSaga';
import exportSaga from './exportSaga/exportSaga';
import updateSaga from './updateSaga/updateSaga';
import deleteSaga from './deleteSaga/deleteSaga';
import addSaga from './addSaga/addSaga';

// ----------------

export default function* () {
  yield all([takeEvery(transmissionSlice.actions.getClearList$Req.type, getClearListSaga)]);
  yield all([takeEvery(transmissionSlice.actions.getFiberById$Req.type, getFiberByIdSaga)]);
  yield all([takeEvery(transmissionSlice.actions.updateFiber$Req.type, updateFiberSaga)]);
  yield all([takeEvery(transmissionSlice.actions.cancelMove$Req.type, cancelMoveSaga)]);
  yield all([takeEvery(transmissionSlice.actions.dismantle$Req.type, dismantleSaga)]);
  yield all([takeEvery(transmissionSlice.actions.addFiber$Req.type, addFiberSaga)]);
  yield all([takeEvery(transmissionSlice.actions.getList$Req.type, getListSaga)]);
  yield all([takeEvery(transmissionSlice.actions.getById$Req.type, getByIdSaga)]);
  yield all([takeEvery(transmissionSlice.actions.delete$Req.type, deleteSaga)]);
  yield all([takeEvery(transmissionSlice.actions.import$Req.type, importSaga)]);
  yield all([takeEvery(transmissionSlice.actions.export$Req.type, exportSaga)]);
  yield all([takeEvery(transmissionSlice.actions.update$Req.type, updateSaga)]);
  yield all([takeEvery(transmissionSlice.actions.add$Req.type, addSaga)]);
}
