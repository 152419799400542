import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { documentAPIService, DocumentAPIService } from '../../../services/api/documentAPIService';

// Entities

import { DocumentDO } from '../../../entity/document';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/document/${actionPiece}`; // Create action type

export const documentActionCreators = {
  getPaginatedList$Req: createAction(
    cat('getPaginatedList$Req'),
    (props: {
      requestActionTypeData?: number;
      onSuccess?: (data: DocumentDO.PaginatedList) => void;
      onError?: (err: AxiosError) => void;
      queries?: DocumentAPIService.GetPaginatedListQueries;
    }): { payload: Partial<RequestSagaConfig<DocumentDO.PaginatedList>> } => {
      return {
        payload: {
          requestActionTypeData: props.requestActionTypeData,
          serviceArgs: [props.queries],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: documentAPIService.getPaginatedList,
          context: documentAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  create$Req: createAction(
    cat('create$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: DocumentDO.$) => void;
      onError?: (err: AxiosError<DocumentAPIService.SendDataErr>) => void;
      data: DocumentAPIService.SendData;
    }): {
      payload: Partial<RequestSagaConfig<DocumentDO.$>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: documentAPIService.create,
          context: documentAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  update$Req: createAction(
    cat('update$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: DocumentDO.$) => void;
      onError?: (err: AxiosError<DocumentAPIService.SendDataErr>) => void;
      data: DocumentAPIService.SendData;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<DocumentDO.$>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data, props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: documentAPIService.update,
          context: documentAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  delete$Req: createAction(
    cat('delete$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<void>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: documentAPIService.delete,
          context: documentAPIService,
          timeout: 300,
        },
      };
    }
  ),
};
