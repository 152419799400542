import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import qs from 'qs';

// Config

import { localStorageKeys, globalObj } from '../../config/app';
import * as api from '../../config/api';

// Redux

import { userSlice } from '../../redux';

// ----------------

export default class HTTP {
  private axiosInstance: AxiosInstance;
  private isTokenRefreshing: boolean = false;

  constructor(baseURL) {
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    this.axiosInstance.interceptors.request.use(this.beforeRequest);
  }

  /**
   * Wrapper for the general request function.
   *
   * @param config - Axios config object
   */

  protected request<T>(config: AxiosRequestConfig) {
    const token = localStorage.getItem(localStorageKeys.token);

    if (token && this.isTokenRefreshing === false) {
      // @ts-ignore
      const minutesBeforeExpire = dayjs(jwtDecode(token)?.exp * 1000).diff(new Date(), 'minute');

      if (minutesBeforeExpire < 20) {
        const refreshToken = localStorage.getItem(localStorageKeys.refreshToken);
        this.isTokenRefreshing = true;

        this.axiosInstance
          .request({ url: api.user.refreshToken, method: 'post', data: { refresh: refreshToken } })
          .then((r) => {
            localStorage.setItem(localStorageKeys.token, r.data.access);
            localStorage.setItem(localStorageKeys.refreshToken, r.data.refresh);
          })
          .finally(() => {
            this.isTokenRefreshing = false;
          });
      }
    }

    return this.axiosInstance
      .request<T>(config)
      .then((r) => r.data)
      .catch((err) => {
        this.errorHandler(err);
      });
  }

  /**
   * This method will call before each request.
   *
   * @param config - Axios config object
   */

  private beforeRequest(config: AxiosRequestConfig): AxiosRequestConfig {
    // Header

    const jwtToken = localStorage.getItem(localStorageKeys.token);
    const headers = {
      language: 'en', // @ToDo Need to get this value from state
      ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
    };

    config.headers = { ...config.headers, ...headers };

    // Params

    const params = {};

    if (config.params) {
      Object.keys(config.params).forEach((key) => {
        if (typeof config.params[key] === 'string' && config.params[key].search(/,/g) !== -1) {
          params[`${key}[]`] = config.params[key];
        } else {
          params[key] = config.params[key];
        }
      });
    }

    config.params = params;

    config.paramsSerializer = (params) => {
      return qs.stringify(config.params, { arrayFormat: 'repeat' });
    };

    return config;
  }

  /**
   * This method will call on response if status code falls outside the range of 2xx.
   *
   * @param err
   */

  private errorHandler(err: AxiosError) {
    switch (err.response?.status) {
      case 400: {
        throw err;
      }

      case 401: {
        if (localStorage.getItem(localStorageKeys.token)) {
          globalObj.reduxStore?.dispatch(userSlice.actions.logout());
        }

        break;
      }

      case 403: {
        // @add Show mess
        break;
      }

      case 404: {
        // @add Show 404 error view
        break;
      }

      case 500: {
        toast.error('Error 500. Please try again in 5 minutes or contact the developers');
        break;
      }

      default: {
      }
    }

    throw err;
  }
}
