import HTTP from '../http';

// Config

import { env, api } from '../../config';

// Entity

import { IGraphDTO, GraphGroupDTO } from '../../entity/graph/DTO';

// ----------------

class GraphAPIService extends HTTP {
  getTechnologiesDistribution(): Promise<IGraphDTO | void> {
    return this.request({ url: api.tx.technologiesDistributionGraph });
  }

  getFiberization(): Promise<IGraphDTO | void> {
    return this.request({ url: api.tx.fiberizationGraph });
  }

  getBlueMoonFiberization(): Promise<IGraphDTO | void> {
    return this.request({ url: api.tx.blueMoonFiberizationGraph });
  }

  getFutureRemain(): Promise<IGraphDTO | void> {
    return this.request({ url: api.tx.futureRemainGraph });
  }

  getCartography(): Promise<GraphGroupDTO | void> {
    return this.request({ url: api.tx.cartographyGraph });
  }

  getSnapshot(query: object): Promise<any | void> {
    return this.request({ url: api.tx.transmissionsGraphSnapshots, params: query });
  }
}

export default new GraphAPIService(`${env.API}api/`);
