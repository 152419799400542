import { all, takeEvery } from 'redux-saga/effects';

// Slice

import slice from '../index';

// Saga

import uploadTXDocumentSaga from './uploadTXDocumentSaga/uploadTXDocumentSaga';
import exportTXListSaga from './exportTXListSaga/exportTXListSaga';
import getTXListSaga from './getTXListSaga/getTXListSaga';
import getTXByIdSaga from './getTXByIdSaga/getTXByIdSaga';
import archiveTXSaga from './archiveTXSaga/archiveTXSaga';
import deleteTXSaga from './deleteTXSaga/deleteTXSaga';
import updateTXSaga from './updateTXSaga/updateTXSaga';
import addTXSaga from './addTXSaga/addTXSaga';

// ----------------

export default function* () {
  yield all([
    takeEvery(slice.actions.uploadTXDocument$Req.type, uploadTXDocumentSaga),
    takeEvery(slice.actions.exportTXList$Req.type, exportTXListSaga),
    takeEvery(slice.actions.getTXList$Req.type, getTXListSaga),
    takeEvery(slice.actions.getTXById$Req.type, getTXByIdSaga),
    takeEvery(slice.actions.archiveTX$Req.type, archiveTXSaga),
    takeEvery(slice.actions.deleteTX$Req.type, deleteTXSaga),
    takeEvery(slice.actions.updateTX$Req.type, updateTXSaga),
    takeEvery(slice.actions.addTX$Req.type, addTXSaga),
  ]);
}
