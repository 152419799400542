import { userActionCreators } from './userActionCreators';
import { userSlice as slice } from './userSlice';

const userSlice = {
  ...slice,
  actions: { ...slice.actions, ...userActionCreators },
};

export { userSlice };
export * from './userBranch';
export * from './saga';
