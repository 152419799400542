import { BaseDTO, DateTime } from '../base';

// ----------------

export namespace DocumentDTO {
  export type $ = {
    content_type: number;
    updated_at: DateTime;
    created_at: DateTime;
    object_id: number;
    file: string | File;
    id: number;
    created_by: {
      email: string;
      id: number;
    };
  };

  export type PaginatedList = BaseDTO.PaginatedList<$>;
}
