import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { userSlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';

// ----------------

export function* userSaga() {
  yield all([takeEvery([userSlice.actions.update$Req.type], requestActionSaga)]);
}
