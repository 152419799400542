import { defLan, defPalette, localStorageKeys } from '../../../config/app';
import { Palette, Lan } from '../../../types';

// -------- Types --------

export interface IAppBranch {
  palette: Palette;
  lan: Lan;
}

// -------- Initial state of branch --------

const appBranchInitialState: IAppBranch = {
  palette: defPalette,
  lan: (localStorage.getItem(localStorageKeys.lan) as Lan) || defLan,
};

export default appBranchInitialState;
