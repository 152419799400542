import deepcopy from 'deepcopy';

// Entities

import { UIStorage, TableStorage } from './userDO';

// ----------------

const tableStorageInit: TableStorage = {
  simpleFilters: {
    values: {},
  },
  filters: {
    presets: [],
    values: {},
  },
  filterType: 'simple',
  columns: {},
  search: '',
};

// --------

const uiStorageInit: UIStorage = {
  version: 1,
  entity: {
    reconstruction: { table: { base: { ...deepcopy(tableStorageInit), isArchived: false } } },
    smallWorks: { table: { base: { ...deepcopy(tableStorageInit), isArchived: false } } },
    b2b: { table: { base: { ...deepcopy(tableStorageInit), isArchived: false } } },
    tx: {
      table: {
        leasedLine: deepcopy(tableStorageInit),
        microwave: deepcopy(tableStorageInit),
        candidate: deepcopy(tableStorageInit),
        blueMoon: deepcopy(tableStorageInit),
        archive: deepcopy(tableStorageInit),
        fiber: deepcopy(tableStorageInit),
        base: { ...deepcopy(tableStorageInit), isArchived: false },
      },
    },
  },
};

const uiStorageEntityList = ['reconstruction', 'b2b', 'smallWorks', 'tx'];

// --------

export const userData = {
  uiStorageEntityList,
  uiStorageInit,
};
