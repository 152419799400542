import { DocumentDTO, DocumentDO } from '.';

// ----------------

export const documentMapper = {
  toDO(data: DocumentDTO.$): DocumentDO.$ {
    return {
      entityTypeId: data.content_type,
      entityId: data.object_id,
      updated: data.updated_at,
      created: data.created_at,
      file: data.file,
      id: data.id,
      creator: {
        email: data.created_by.email,
        id: data.created_by.id,
      },
    };
  },

  // --------

  toDTO(data: Partial<DocumentDO.$>): Partial<DocumentDTO.$> {
    return {
      content_type: data.entityTypeId,
      object_id: data.entityId,
      file: data.file,
    };
  },
};
