import { put, call, all, delay } from 'redux-saga/effects';

// Slice

import requestSlice from '../../../request';
import slice from '../../index';

// Services

import graphService from '../../../../../services/api/graph';

// Entity

import { IGraphDTO } from '../../../../../entity/graph/DTO';
import { IGraphDO } from '../../../../../entity/graph/DO';
import graphMapper from '../../../../../entity/graph/mapper';

// Types

import { DataKeys } from '../../branch';

// ----------------

export default function* getDashboardDataSaga(action: ReturnType<typeof slice.actions.getDashboardData$Req>) {
  // Add information about the new request action to the request branch

  yield put(requestSlice.actions.add(action.type));

  try {
    yield delay(300);

    const res: IGraphDTO[] = yield all(
      [
        graphService.getTechnologiesDistribution,
        graphService.getBlueMoonFiberization,
        graphService.getFutureRemain,
        // graphService.getFiberization,
        // graphService.getCartography,
      ].map((m) => call([graphService, m]))
    );

    const dataDO: Partial<Record<DataKeys, IGraphDO>> = {
      technologiesDistribution: graphMapper.toDO(res[0]),
      blueMoonFiberization: graphMapper.toDO(res[1]),
      futureRemain: graphMapper.toDO(res[2]),
      //  fiberization: graphMapper.toDO(res[2]),
      // cartography: res[3],
    };

    if (action.meta.onSuccess) {
      action.meta.onSuccess(dataDO);
    }
  } catch (err) {
  } finally {
    // Delete information about the request action from the request branch

    yield put(requestSlice.actions.delete(action.type));
  }
}
