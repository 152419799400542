import { HistoryDTO, HistoryDO } from './';

// ----------------

export const historyMapper = {
  toDO(data: HistoryDTO.$): HistoryDO.$ {
    return {
      contentTypeModel: data.content_type.model,
      contentTypeId: data.content_type.id,
      changedBy: {
        email: data.changed_by.email,
        id: data.changed_by.id,
      },
      objectId: data.object_id,
      changed: data.changed_at,
      changes: Object.keys(data.changes).map((key) => ({ key, old: data.changes[key].old, new: data.changes[key].new })),
      id: data.id,
    };
  },
};
