import { MicrowaveDO } from '../../../entity/microwave';

// -------- Types --------

export interface IMicrowaveBranch {
  data: MicrowaveDO.MicrowaveDO | null;
}

// -------- Initial state of branch --------

const microwaveBranchInitialState: IMicrowaveBranch = {
  data: null,
};

export default microwaveBranchInitialState;
