// Slice

import slice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import notificationService from '../../../../../services/api/notification';

// ----------------

export default function* seeAllSaga(action: ReturnType<typeof slice.actions.seeAll$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.query],
    successMess: action.meta.message,
    service: notificationService.seeAll,
    context: notificationService,
    onSuccess: () => {
      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
  });
}
