import { TimelineDO } from '../../../entity/timeline';

// -------- Types --------

export type TimelineBranch = {
  list: TimelineDO[];
  meta: {
    totalCount: number;
  };
};

// -------- Initial state of branch --------

export const timelineBranchInitialState: TimelineBranch = {
  list: [],
  meta: { totalCount: 0 },
};
