import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { BlueMoonDTO, BlueMoonDO, blueMoonMapper } from '../../entity/blueMoon';
import { baseMapper } from '../../entity/base';

// Utils

import { objectToFormData } from '../../utils/objectToFormData';

// Types

import { PaginatedListQueries, FreeQueries } from '../queryService';

export namespace BlueMoonAPIService {
  // -------- Types --------

  export type GetPaginatedListQueries = PaginatedListQueries | FreeQueries;

  export type SendData = Partial<BlueMoonDO.$>;
  export type SendDataErr = Partial<Record<keyof BlueMoonDTO.$, [string]>>;

  // ----------------

  class BlueMoonAPIService extends HTTP {
    getPaginatedList(queries: GetPaginatedListQueries) {
      return this.request<BlueMoonDTO.PaginatedList>({
        params: queries,
        url: api.blueMoon.root,
      }).then((data) => baseMapper.paginatedListToDO(data!, blueMoonMapper.toDO));
    }

    // --------

    getItem(id: number) {
      return this.request<BlueMoonDTO.$>({
        url: api.blueMoon.id(id),
      }).then((data) => {
        return blueMoonMapper.toDO(data!);
      });
    }

    // --------

    export(fields: Array<keyof BlueMoonDO.$>) {
      const keysMap = blueMoonMapper.getDOKeysMap();

      return this.request<null>({
        method: 'post',
        data: {
          fields: fields.map((field) => keysMap[field]),
          model: 'transmissions.models.BlueMoon',
        },
        url: api.core.export,
      });
    }

    // --------

    create(data: SendData) {
      return this.request<BlueMoonDTO.$>({
        method: 'post',
        data: blueMoonMapper.toDTO(data),
        url: api.blueMoon.root,
      }).then((data) => {
        return blueMoonMapper.toDO(data!);
      });
    }

    // --------

    update(data: SendData, id: number) {
      return this.request<BlueMoonDTO.$>({
        method: 'patch',
        data: blueMoonMapper.toDTO(data),
        url: api.blueMoon.id(id),
      }).then((data) => {
        return blueMoonMapper.toDO(data!);
      });
    }

    // --------

    delete(id: number) {
      return this.request<void>({
        method: 'delete',
        url: api.blueMoon.id(id),
      });
    }
  }

  export const blueMoonAPIService = new BlueMoonAPIService(`${env.API}api/`);
}

export const blueMoonAPIService = BlueMoonAPIService.blueMoonAPIService;
