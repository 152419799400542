import { BaseDO, GlossaryId, Number, Date } from '../base';

// ----------------

export namespace ReconstructionDO {
  // prettier-ignore
  export type $ = {
    financialStatus: GlossaryId;
    crPlannedDate  : Date;
    subcontractor  : GlossaryId;
    projectNumber  : string;
    commentBuilt   : string;
    projectName    : string;
    isArchived     : boolean;
    checkBuilt     : GlossaryId;
    finishDate     : Date;
    startDate      : Date;
    crNumber       : string;
    comments       : string;
    bepDate        : Date;
    asBuilt        : Date;
    address        : string;
    status         : GlossaryId;
    ber2           : string;
    bep2           : string;
    city           : string;
    fboq           : Number;
    boq            : Number;
    zip            : Number;
    fac            : Date;
    ber            : string;
    bep            : string;
    id             : number
  };

  export type PaginatedList = BaseDO.PaginatedList<$>;
}
