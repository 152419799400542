import React from 'react';

// Components

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '../TextField';
import Paper, { PaperProps } from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';

// Types

import { Option } from '../../../../types/component';

// -------- Types --------

export type SelectProps = {
  /**
   *  Props for MUI `Autocomplete` component
   */
  autocompleteProps?: Partial<AutocompleteProps<Option, false, false, false>>;

  /**
   *  Props for `TextField` component
   */
  textFieldProps?: TextFieldProps;

  /**
   * @deprecated Use 'textFieldProps'
   */
  textField?: TextFieldProps;

  /**
   *  List of options
   */
  options?: Option[];

  /**
   *  Selected value
   */
  value: SelectValue;

  /**
   *  If `true`, sort select options by alphabet
   */
  sort?: boolean;

  /**
   *  If `true`, disable select
   */
  disabled?: boolean;

  onChange: (value: SelectValue) => void;

  onItemDelete?: React.MouseEventHandler;
};

export type SelectValue = Option['value'] | null;

// ----------------

const Select: React.FC<SelectProps> = (props) => {
  const sortedOptions = React.useMemo(() => {
    const options = props.options ? [...props.options] : [];

    if (props.sort) {
      options.sort((a, b) => {
        const firstValue = a.label.toLocaleLowerCase();
        const secondValue = b.label.toLocaleLowerCase();

        if (firstValue > secondValue) {
          return 1;
        }
        if (firstValue < secondValue) {
          return -1;
        }
        return 0;
      });

      return options;
    } else {
      return options;
    }
  }, [props.options, props.sort]);

  return (
    <Autocomplete<Option, false, false, false>
      noOptionsText={'It looks like there are no such options'}
      renderInput={(p) => (
        <TextField {...p} placeholder={'Select one option from the list'} label="Select" {...props.textField} {...props.textFieldProps} />
      )}
      renderOption={(p, option) => (
        <li {...p} style={{ padding: '8px 16px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={option.value}>
          {option.label}
          {props.onItemDelete && (
            <IconButton sx={{ p: 1, m: -1 }} id={String(option.value)} onClick={props.onItemDelete}>
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          )}
        </li>
      )}
      onChange={(e, option) => {
        props.onChange(option ? option.value : null);
      }}
      PaperComponent={PaperComponent}
      popupIcon={<ExpandMoreIcon />}
      disabled={props.disabled}
      options={sortedOptions}
      value={props.value && sortedOptions.length ? sortedOptions.find((option) => option.value === props.value) : null}
      fullWidth
      {...props.autocompleteProps}
    />
  );
};

Select.defaultProps = {
  sort: true,
};

export default Select;

export { Select as SelectWithoutMemo };

export const selectDefaultValue: SelectValue = null;

// -------- Additional components --------

const PaperComponent = (props: PaperProps) => (
  <Grow in timeout={300} style={{ transformOrigin: '50% 0 0' }}>
    <Paper {...props} variant="outlined" sx={{ my: 1, bgcolor: 'rgb(253,253,253)' }} />
  </Grow>
);
