import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { userAPIService } from '../../../services/api/userAPIService';

// Entities

import { UserDO, UserDTO } from '../../../entity/user';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/user/${actionPiece}`; // Create action type

export const userActionCreators = {
  update$Req: createAction(
    cat('update$Req'),
    (props: {
      onSuccess?: (data: UserDO) => void;
      onError?: (err: AxiosError) => void;
      data: Partial<UserDTO>;
    }): {
      payload: Partial<RequestSagaConfig<UserDO>>;
    } => {
      return {
        payload: {
          serviceArgs: [props.data],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: userAPIService.update,
          context: userAPIService,
          timeout: 300,
        },
      };
    }
  ),
};
