import { UserDO } from '../../../entity/user';

// -------- Types --------

export type UserBranch = {
  data: UserDO;
};

// -------- Initial state of branch --------

export const userBranchInitialState: UserBranch = {
  data: {} as UserDO,
};
