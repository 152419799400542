import { noteActionCreators } from './noteActionCreators';
import { noteSlice as slice } from './noteSlice';

const noteSlice = {
  ...slice,
  actions: { ...slice.actions, ...noteActionCreators },
};

export { noteSlice };
export * from './noteBranch';
export * from './saga';
