import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { LeasedLineDTO, LeasedLineDO, leasedLineMapper } from '../../entity/leasedLine';

export namespace LeasedLineAPIService {
  // -------- Types --------

  export type SendData = Partial<LeasedLineDO.$>;
  export type SendDataErr = Partial<Record<keyof LeasedLineDTO.$, [string]>>;

  // ----------------

  class LeasedLineAPIService extends HTTP {
    create(data: SendData) {
      return this.request<LeasedLineDTO.$>({
        method: 'post',
        data: leasedLineMapper.toDTO(data),
        url: api.tx.leasedLine,
      }).then((data) => leasedLineMapper.toDO(data!));
    }

    // --------

    update(data: SendData, id: number) {
      return this.request<LeasedLineDTO.$>({
        method: 'patch',
        data: leasedLineMapper.toDTO(data),
        url: api.tx.leasedLineId(id),
      }).then((data) => leasedLineMapper.toDO(data!));
    }
  }

  export const leasedLineAPIService = new LeasedLineAPIService(`${env.API}api/`);
}

export const leasedLineAPIService = LeasedLineAPIService.leasedLineAPIService;
