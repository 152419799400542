import { Options } from '../../../utils/format';

/**
 * Returns mapping functions that map DTO and DO keys.
 */

export function createKeysMappers<DTO extends object, DO extends object>(keys: Array<[keyof DTO, keyof DO, Options?]>) {
  function getDTOKeysMap() {
    const dtoKeysMap = {} as Partial<Record<keyof DTO, keyof DO>>;

    keys.forEach((item) => {
      dtoKeysMap[item[0]] = item[1];
    });

    return dtoKeysMap;
  }

  // --------

  function getDTOKeysMapWithFormatOptions() {
    const dtoKeysMap = {} as Partial<Record<keyof DTO, { value: keyof DO; options: Options | null }>>;

    keys.forEach((item) => {
      dtoKeysMap[item[0]] = { value: item[1], options: item[2] || null };
    });

    return dtoKeysMap;
  }

  // --------

  function getDOKeysMap() {
    const doKeysMap = {} as Partial<Record<keyof DO, keyof DTO>>;

    keys.forEach((item) => {
      doKeysMap[item[1]] = item[0];
    });

    return doKeysMap;
  }

  // --------

  function getDOKeysMapWithFormatOptions() {
    const doKeysMap = {} as Partial<Record<keyof DO, { value: keyof DTO; options: Options | null }>>;

    keys.forEach((item) => {
      doKeysMap[item[1]] = { value: item[0], options: item[2] || null };
    });

    return doKeysMap;
  }

  return { getDTOKeysMap, getDOKeysMap, getDTOKeysMapWithFormatOptions, getDOKeysMapWithFormatOptions };
}
