// Entities

import { FiberDO, FiberDTO, fiberMapper } from './';

// ----------------

export const fiberAnalyzer = {
  getHiddenFields(permissions: string[]) {
    const hiddenFields: Array<keyof FiberDTO> = [
      'fiber_delivered_estimate',
      'ring_send_to_ericsson',
      'fiber_works_finished',
      'public_permit_region',
      'civil_works_finished',
      'public_permit_city',
      'as_built_received',
      'fiber_works_start',
      'delivery_forecast',
      'civil_works_start',
      'atn_5g_sfp_ready',
      'inserted_in_ring',
      'as_built_splice',
      'fiber_delivered',
      'splice_partner',
      'subcontractor',
      'ring_atn_name',
      'fiber_design',
      'order_date',
      'owner_auth',
      'saf_file',
      'comments',
      'network',
      'pm_obe',
      'coccon',
      'ring',
      'popa',
      'popb',
      'fac',
      'ssv',
      'rfb',
    ];

    const dtoKeysMap = fiberMapper.getDTOKeysMap();

    return hiddenFields
      .filter((field) => !permissions.some((permission) => permission === `can_user_view_fiber_column_${field}`))
      .map((field) => dtoKeysMap[field])
      .filter((field) => field) as Array<keyof FiberDO>;
  },
};
