import { PayloadAction } from '@reduxjs/toolkit';

// Saga

import requestSaga, { RequestSagaConfig } from './request';

// ----------------

export function* requestActionSaga(action: PayloadAction<RequestSagaConfig<any>>) {
  yield requestSaga({
    ...action.payload,
    requestActionType: action.type,
  });
}
