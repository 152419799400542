import { cartographyBranchInitialState, CartographyBranch } from './cartographyBranch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { CartographyDO } from '../../../entity/cartography';

// ----------------

export const cartographySlice = createSlice({
  name: `${reduxActionTypePrefix}/cartography`,
  initialState: cartographyBranchInitialState,

  // -------- Reducers --------

  reducers: {
    setChange: (state, action: PayloadAction<{ list?: CartographyDO.Change[]; meta?: Partial<CartographyBranch['change']['meta']> }>) => {
      if (action.payload.list) {
        state.change.list = action.payload.list;
      }

      if (action.payload.meta) {
        state.change.meta = { ...state.change.meta, ...action.payload.meta };
      }
    },

    // --------

    addManyChange: (state, action: PayloadAction<{ list: CartographyDO.Change[]; meta?: Partial<CartographyBranch['change']['meta']> }>) => {
      state.change.list.push(...action.payload.list);

      if (action.payload.meta) {
        state.change.meta = { ...state.change.meta, ...action.payload.meta };
      }
    },

    // --------

    deleteChange: (state, action: PayloadAction<{ changeId: number }>) => {
      state.change.list = state.change.list.filter((change) => action.payload.changeId !== change.id);

      state.change.meta.totalCount = state.change.meta.totalCount - 1;
    },

    // --------

    setPendingCount: (state, action: PayloadAction<{ count: number }>) => {
      state.pendingCount = action.payload.count;
    },

    // --------

    setComparing: (state, action: PayloadAction<{ list?: CartographyDO.Comparing[]; meta?: Partial<CartographyBranch['comparing']['meta']> }>) => {
      if (action.payload.list) {
        state.comparing.list = action.payload.list;
      }

      if (action.payload.meta) {
        state.comparing.meta = { ...state.comparing.meta, ...action.payload.meta };
      }
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return cartographyBranchInitialState;
    });
  },
});
