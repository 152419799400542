export default class WS {
  protected socket: WebSocket = {} as WebSocket;
  private onOpen?: (socket: WebSocket) => void;
  private url: string;

  constructor(url: string, onOpen?: (socket: WebSocket) => void) {
    this.url = url;
    this.onOpen = onOpen;

    this.handleError = this.handleError.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  private handleOpen() {
    if (this.onOpen) {
      this.onOpen(this.socket);
    }
  }

  private handleError(e) {
    console.log('WebSocket error - ', e);
  }

  start() {
    this.socket = new WebSocket(this.url);

    this.socket.onerror = this.handleError;
    this.socket.onopen = this.handleOpen;
  }

  stop() {
    if (this.socket.OPEN) {
      this.socket.close(1000); // 1000 - Normal closure
    }
  }
}
