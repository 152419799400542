import { Option } from '../../types/component';

export namespace BlueMoonCommon {
  export const options: Record<'fctE2e', Option[]> = {
    fctE2e: [
      { value: 'Ready for RAN Sharing', label: 'Ready for RAN Sharing' },
      { value: 'Fiber delivered', label: 'Fiber delivered' },
      { value: 'RFS', label: 'RFS' },
      { value: 'TBD', label: 'TBD' },
    ],
  };
}
