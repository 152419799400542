import React from 'react';

// Components

import Section, { SectionProps } from '../Section';
import Stack from '@mui/material/Stack';

// -------- Types --------

export type SectionListProps = {
  /**
   * Props for each `Section` component in list
   */
  sectionProps?: Partial<SectionProps>;

  /**
   * List of sections
   */
  list: Array<SectionProps & { children?: React.ReactNode | undefined }>;

  /**
   * Gap between sections
   */
  gap?: number;
};

// ----------------

const SectionList: React.FC<SectionListProps> = (props) => {
  return (
    <Stack spacing={props.gap}>
      {props.list.map((item) => (
        <Section key={item.title} {...props.sectionProps} {...item} />
      ))}
    </Stack>
  );
};

SectionList.defaultProps = {
  gap: 3,
};

export default SectionList;
