// Redux

import requestSaga from '../../../../baseSaga/request';
import userSlice from '../../index';

// Services

import userService from '../../../../../services/api/user';

// ----------------

export default function* resetPasswordValidateTokenSaga(action: ReturnType<typeof userSlice.actions.resetPasswordValidateToken$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.data],
    service: userService.resetPasswordValidateToken,
    context: userService,
    timeout: 1000,
    onSuccess: () => {
      action.meta.onSuccess();
    },
    onError: () => {
      action.meta.onError();
    },
  });
}
