export namespace CartographyCommon {
  export enum ChangeType {
    removeService = 'remove_service',
    changeTechno = 'change_techno',
    addService = 'add_service',
    remove = 'remove',
    add = 'add',
  }

  // --------

  export enum ChangeStatus {
    appliedBySystem = 'applied_by_system',
    ignoredBySystem = 'ignored_by_system',
    rejected = 'rejected',
    pending = 'pending',
    applied = 'applied',
    ignored = 'ignored',
    denied = 'denied',
  }
}
