import { IListDTO, ListDO, PaginatedListDTO, PaginatedListDO, GlossaryDOValue } from './';
import { Option } from '../../types/component';

// ----------------

const commonMapper = {
  /**
   * @deprecated
   */
  listToDO<F = any, T = any>(data: IListDTO<F>, mapper: (data: F) => T): ListDO<T> {
    return {
      pages: data.pages,
      limit: data.limit,
      count: data.count,
      list: data.results.map((item) => mapper(item)),
    };
  },

  // --------

  paginatedListToDO<ListItemDTO, ListItemDO>(
    data: PaginatedListDTO<ListItemDTO>,
    listItemMapper: (ListItemDTO) => ListItemDO
  ): PaginatedListDO<ListItemDO> {
    return {
      count: data.count,
      limit: data.limit,
      pages: data.pages,
      list: data.results.map((item) => listItemMapper(item)),
    };
  },

  // --------

  optionValueToGlossaryValue(value: Option['value']): GlossaryDOValue {
    return typeof value === 'string' ? null : value;
  },

  // --------

  optionValueListToGlossaryValueList(valueList: Array<Option['value']>): Array<GlossaryDOValue> {
    return valueList.map((value) => (typeof value === 'string' ? null : value));
  },
};

export default commonMapper;
