import { enumActionCreators } from './enumActionCreators';
import { enumSlice as slice } from './enumSlice';

const enumSlice = {
  ...slice,
  actions: { ...slice.actions, ...enumActionCreators },
};

export { enumSlice };
export * from './enumBranch';
export * from './saga';
