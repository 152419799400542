import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Utils

import { objectToFormData } from '../../utils/objectToFormData';

// Entities

import { DocumentDTO, DocumentDO, documentMapper } from '../../entity/document';
import { baseMapper } from '../../entity/base';

// Types

import { PaginatedListQueries } from '../queryService';

export namespace DocumentAPIService {
  // -------- Types --------

  export type GetPaginatedListQueries = PaginatedListQueries | Partial<{ content_type: number; object_id: number }>;

  export type SendData = Partial<DocumentDO.$>;
  export type SendDataErr = Partial<Record<keyof DocumentDTO.$, [string]>>;

  // ----------------

  class DocumentAPIService extends HTTP {
    getPaginatedList(queries: GetPaginatedListQueries) {
      return this.request<DocumentDTO.PaginatedList>({
        params: queries,
        url: api.document.root,
      }).then((data) => baseMapper.paginatedListToDO(data!, documentMapper.toDO));
    }

    // --------

    create(data: SendData) {
      const dataDTO = documentMapper.toDTO(data);

      return this.request<DocumentDTO.$>({
        method: 'post',
        data: objectToFormData(dataDTO),
        url: api.document.root,
      }).then((data) => documentMapper.toDO(data!));
    }

    // --------

    update(data: SendData, id: number) {
      return this.request<DocumentDTO.$>({
        method: 'patch',
        data: documentMapper.toDTO(data),
        url: api.document.id(id),
      }).then((data) => documentMapper.toDO(data!));
    }

    // --------

    delete(id: number) {
      return this.request<void>({
        method: 'delete',
        url: api.document.id(id),
      });
    }
  }

  export const documentAPIService = new DocumentAPIService(`${env.API}api/`);
}

export const documentAPIService = DocumentAPIService.documentAPIService;
