import React from 'react';

// Components

import MultiSelect, { multiSelectDefaultValue, MultiSelectProps } from '../../../MultiSelect';
import DatePicker, { datePickerDefaultValue, DatePickerProps } from '../../../DatePicker';
import Select, { selectDefaultValue, SelectProps } from '../../../Select';
import PopupForm, { PopupFormProps } from '../../../PopupForm';
import TextField, { TextFieldProps } from '../../../TextField';
import Switch, { SwitchProps } from '@mui/material/Switch';
import SectionList from '../../../SectionList';
import FieldsRow from '../../../FieldsRow';
import Button from '../../../Button';
import Badge from '@mui/material/Badge';

// Hooks

import useForm, { IConfig, IField } from '../../../../../../hooks/useForm/useForm';

// Utils

import hasPermissions from '../../../../../../utils/hasPermissions/hasPermissions';

// Entities

import { UserCommon } from '../../../../../../entity/user';
import { GlossaryDO } from '../../../../../../entity/glossary';
import { EnumDO } from '../../../../../../entity/enum';

// Types

import { Option } from '../../../../../../types/component';

// -------- Types --------

type Props<FieldName extends string = string> = {
  popupFormProps?: Partial<PopupFormProps>;
  initialValues?: Partial<Values>;
  hiddenFields?: Array<FieldName>;
  onSubmit: IConfig<Values>['onSubmit'];
  config: Array<SectionConfig<FieldName> | undefined>;
};

type FieldConfig<FieldName> = {
  placeholder?: string;
  permissions?: UserCommon.PermissionList;
  fieldType: FieldType;
  validate?: IField['validate'];
  glossary?: GlossaryDO[];
  options?: Option[];
  label: string;
  enum?: EnumDO.List;
  name: FieldName;
};

type SectionConfig<FieldName> = {
  title: string;
  rows: Array<FieldConfig<FieldName>[]>;
};

type FieldType = 'text-field' | 'select' | 'multi-select' | 'date-picker' | 'switch';

export type Values<FieldName extends string = string> = Record<FieldName, any>;

// ----------------

function Filters<FieldName extends string = string>(props: Props<FieldName>) {
  const configs = React.useMemo(() => {
    return props.config.filter(
      (config) => config && config.rows.some((row) => row.some((field) => !props.hiddenFields?.includes(field.name)))
    ) as Array<SectionConfig<FieldName>>;
  }, []);

  const { formik, setFieldValueAndTouched, getControlledTextFieldProps, getMessagesProps } = useForm<Values>({
    initialValues: props.initialValues,
    onSubmit: props.onSubmit,
    name: 'TableToolsFilters',
    fields: configs
      .map((config) =>
        config.rows.flatMap((row) =>
          row
            .filter((item) => hasPermissions(item.permissions) && !props.hiddenFields?.includes(item.name))
            .map((field) => {
              let defaultValue;

              switch (field.fieldType) {
                case 'text-field': {
                  defaultValue = '';
                  break;
                }
                case 'select': {
                  defaultValue = selectDefaultValue;
                  break;
                }
                case 'multi-select': {
                  defaultValue = multiSelectDefaultValue;
                  break;
                }
                case 'date-picker': {
                  defaultValue = datePickerDefaultValue;
                  break;
                }
                case 'switch': {
                  defaultValue = false;
                  break;
                }
              }

              return { name: field.name, defaultValue: defaultValue, validate: field.validate };
            })
        )
      )
      .flat(),
  });

  let filtersCount = 0;

  Object.keys(formik.values).forEach((key) => {
    const value = formik.values[key];

    if (Array.isArray(value)) {
      if (value.length) {
        filtersCount++;
      }
    } else if (value) {
      filtersCount++;
    }
  });

  return (
    <PopupForm
      form={
        <form onSubmit={formik.handleSubmit}>
          <SectionList
            list={configs.map((config, i) => ({
              typographyProps: { variant: 'title2', sx: { mb: -2 } },
              border: props.config.length - 1 > i,
              title: config.title,
              children: config.rows.map((row, i) => {
                return (
                  <FieldsRow key={i}>
                    {row
                      .filter((item) => hasPermissions(item.permissions) && !props.hiddenFields?.includes(item.name))
                      .map((field) => (
                        <Field
                          key={field.name}
                          type={field.fieldType}
                          textFieldProps={{ label: field.label, placeholder: field.placeholder, ...getControlledTextFieldProps(field.name) }}
                          selectProps={{
                            textFieldProps: { label: field.label, ...getMessagesProps(field.name) },
                            onChange: (value) => {
                              setFieldValueAndTouched(field.name, value);
                            },
                            options: field.options || field.glossary || field.enum || [],
                            value: formik.values[field.name],
                          }}
                          multiSelectProps={{
                            textFieldProps: { label: field.label, ...getMessagesProps(field.name) },
                            onChange: (value) => {
                              setFieldValueAndTouched(field.name, value);
                            },
                            options: field.options || field.glossary || field.enum || [],
                            value: formik.values[field.name],
                          }}
                          datePickerProps={{
                            textFieldProps: { label: field.label, ...getMessagesProps(field.name) },
                            onChange: (value) => {
                              setFieldValueAndTouched(field.name, value);
                            },
                            value: formik.values[field.name],
                          }}
                          switchProps={{
                            onChange: (e, checked) => {
                              setFieldValueAndTouched(field.name, checked);
                            },
                            checked: formik.values[field.name],
                          }}
                        />
                      ))}
                  </FieldsRow>
                );
              }),
            }))}
          />
          <button type="submit" style={{ display: 'none' }} />
        </form>
      }
      onSubmit={formik.handleSubmit}
      leftButtons={{
        create: [
          <Button
            variant="outlined"
            onClick={() => {
              const values = {} as any;

              configs.forEach((config) => {
                const rows = config.rows.flat().filter((item) => hasPermissions(item.permissions) && !props.hiddenFields?.includes(item.name));

                rows.forEach((field) => {
                  let defaultValue;

                  switch (field.fieldType) {
                    case 'text-field': {
                      defaultValue = '';
                      break;
                    }
                    case 'select': {
                      defaultValue = selectDefaultValue;
                      break;
                    }
                    case 'multi-select': {
                      defaultValue = multiSelectDefaultValue;
                      break;
                    }
                    case 'date-picker': {
                      defaultValue = datePickerDefaultValue;
                      break;
                    }
                    case 'switch': {
                      defaultValue = false;
                      break;
                    }
                  }

                  values[field.name] = defaultValue;
                });
              });

              formik.setValues(values);
            }}
          >
            Clear
          </Button>,
        ],
      }}
      {...props.popupFormProps}
      popupProps={{
        titleEndAdornment: <Badge badgeContent={filtersCount} color="secondary" sx={{ ml: '20px' }} />,
        maxWidth: 'large',
        title: 'Filters',
        ...props.popupFormProps?.popupProps,
      }}
    />
  );
}

export default Filters;
export { Props as FiltersProps };

// ----------------

const Field: React.FC<{
  multiSelectProps: MultiSelectProps;
  datePickerProps: DatePickerProps;
  textFieldProps: TextFieldProps;
  selectProps: SelectProps;
  switchProps: SwitchProps;
  type: FieldType;
}> = (props) => {
  switch (props.type) {
    case 'text-field': {
      return <TextField {...props.textFieldProps} />;
    }

    case 'select': {
      return <Select {...props.selectProps} />;
    }

    case 'multi-select': {
      return <MultiSelect {...props.multiSelectProps} />;
    }

    case 'date-picker': {
      return <DatePicker {...props.datePickerProps} />;
    }

    case 'switch': {
      return <Switch {...props.switchProps} />;
    }

    default: {
      return null;
    }
  }
};
