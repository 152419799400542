import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { timelineAPIService } from '../../../services/api/timelineAPIService';

// Entities

import { TimelinePaginatedListDO } from '../../../entity/timeline';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/timeline/${actionPiece}`; // Create action type

export const timelineActionCreators = {
  getPaginatedList$Req: createAction(
    cat('getPaginatedList$Req'),
    (props: {
      onSuccess?: (data: TimelinePaginatedListDO) => void;
      onError?: (err: AxiosError) => void;
      txId: number;
    }): { payload: Partial<RequestSagaConfig<TimelinePaginatedListDO>> } => {
      return {
        payload: {
          serviceArgs: [props.txId],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: timelineAPIService.getPaginatedList,
          context: timelineAPIService,
          timeout: 300,
        },
      };
    }
  ),
};
