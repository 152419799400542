import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Redux

import { DataKeys } from '../../../redux/slices/graph/branch';

// Entity

import { IGraphDO } from '../../../entity/graph/DO';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/graph/${actionPiece}`; // Create action type

export default {
  getDashboardData$Req: createAction(cat('getDashboardData$Req'), (props?: { onSuccess: (data: Partial<Record<DataKeys, IGraphDO>>) => void }) => ({
    payload: {},
    meta: {
      onSuccess: props?.onSuccess,
    },
  })),

  // --------

  getSnapshot$Req: createAction(cat('getSnapshot$Req'), (props: { query: object; onSuccess: (data: any) => void }) => ({
    payload: {
      query: props.query,
    },
    meta: {
      onSuccess: props?.onSuccess,
    },
  })),
};
