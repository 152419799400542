import { put } from 'redux-saga/effects';

// Slice

import glossarySlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import { glossaryAPIService } from '../../../../../services/api/glossaryAPIService';

// ----------------

export default function* getListSaga(action: ReturnType<typeof glossarySlice.actions.getList$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    service: glossaryAPIService.getList,
    context: glossaryAPIService,
    onSuccess: function* (data) {
      yield put(glossarySlice.actions.setData(data));

      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
  });
}
