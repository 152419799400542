// Slice

import transmissionSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import transmissionService from '../../../../../services/api/transmission';

// ----------------

export default function* cancelMoveSaga(action: ReturnType<typeof transmissionSlice.actions.cancelMove$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    successMess: action.meta.message,
    serviceArgs: [action.payload.id],
    service: transmissionService.cancelMove,
    context: transmissionService,
    onSuccess: function* () {
      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
    onError: function () {
      if (action.meta.onError) {
        action.meta.onError();
      }
    },
  });
}
