import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { txSlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';
import { bulkActionsSaga } from './bulkActionsSaga';

// ----------------

export function* txSaga() {
  yield all([
    takeEvery(
      [
        txSlice.actions.getCandidateCount$Req.type,
        txSlice.actions.getPaginatedList$Req.type,
        txSlice.actions.uploadCandidates$Req.type,
        txSlice.actions.bulkUpdateFiber$Req.type,
        txSlice.actions.bulkUpdateBlueMoon$Req,
        txSlice.actions.dismantled$Req.type,
        txSlice.actions.cancelMove$Req.type,
        txSlice.actions.getItem$Req.type,
        txSlice.actions.create$Req.type,
        txSlice.actions.update$Req.type,
        txSlice.actions.delete$Req.type,
        txSlice.actions.export$Req.type,
        txSlice.actions.upload$Req.type,
      ],
      requestActionSaga
    ),
    takeEvery(txSlice.actions.bulkActions$Req, bulkActionsSaga),
  ]);
}
