const routesEN = {

  'auth.login': {
    title: 'Login to your account'
  },

  // --------

  'auth.mfa': {
    title: 'Google Authenticator Multi-factor Authentication'
  },

  'auth.forgotPassword': {
    title: 'Forgot password'
  },

  // --------

  'auth.resetPassword': {
    title: 'Create new password'
  },

  // --------

  main: {
    nav: {
      reconstruction: 'Reconstruction',
      smallWorks: 'SmallWorks',
      cartography: 'Cartography',
      leasedLine: 'Leased line',
      highlights: 'Highlights',
      microwave: 'Microwave',
      dashboard: 'Dashboard',
      fiber: 'Fiber',
      todo: 'Todo',
      b2b: 'B2B',
      tx: 'All TX'
    }
  },

  // --------

  'main.dashboard': {
    title: 'Dashboard'
  },

  // --------

  'main.transmission.all': {
    title: 'All TX'
  },

  // --------

  'main.transmission.fiber': {
    title: 'Fiber'
  },

  // --------

  'main.fiber-dashboard': {
    title: 'Fiber dashboard'
  },


  // --------

  'main.transmission.leasedLine': {
    title: 'Leased line'
  },

  // --------

  'main.transmission.microwave': {
    title: 'Microwave'
  },

  // --------

  'main.transmission.candidate': {
    title: 'Candidates'
  },

  // --------

  'main.transmission.archive': {
    title: 'Archive'
  },

  // --------

  'main.tx.singleTX': {
    title: 'TX'
  },

  // --------

  'main.b2b': {
    title: 'B2B'
  },


  'main.b2b-dashboard': {
    title: 'B2B dashboard'
  },

  // --------

  'main.b2b-details': {
    title: 'B2B details'
  },

  // --------

  'main.reconstruction': {
    title: 'Reconstruction'
  },

  // --------

  'main.reconstruction-details': {
    title: 'Reconstruction details'
  },

  'main.smallWorks': {
    title: 'SmallWorks'
  },

  // --------

  'main.smallWorks-details': {
    title: 'SmallWorks details'
  },

  // --------

  'main.transmission.cartography': {
    title: 'Cartography'
  },

  // --------

  'main.transmission.blue-moon': {
    title: 'BlueMoon'
  }
};

export default routesEN;
