// Redux

import requestSaga from '../../../../baseSaga/request';
import userSlice from '../../index';

// Services

import userService from '../../../../../services/api/user';

// ----------------

export default function* resetPasswordConfirmSaga(action: ReturnType<typeof userSlice.actions.resetPasswordConfirm$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.data],
    service: userService.resetPasswordConfirm,
    context: userService,
    onSuccess: () => {
      action.meta.onSuccess();
    },
    onError: () => {
      action.meta.onError();
    },
  });
}
