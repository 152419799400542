import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { cartographyAPIService, GetChangePaginatedListQueries, GetComparingPaginatedListQueries } from '../../../services/api/cartographyAPIService';

// Entities

import { CartographyDO } from '../../../entity/cartography';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/cartography/${actionPiece}`; // Create action type

export const cartographyActionCreators = {
  getChangePaginatedList$Req: createAction(
    cat('getChangePaginatedList$Req'),
    (props: {
      requestActionTypeData?: number;
      onSuccess?: (data: CartographyDO.ChangePaginatedList) => void;
      onError?: (err: AxiosError) => void;
      queries?: GetChangePaginatedListQueries;
    }): { payload: Partial<RequestSagaConfig<CartographyDO.ChangePaginatedList>> } => {
      return {
        payload: {
          requestActionTypeData: props.requestActionTypeData,
          serviceArgs: [props.queries],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: cartographyAPIService.getChangePaginatedList,
          context: cartographyAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  updateChange$Req: createAction(
    cat('updateChange$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: CartographyDO.Change) => void;
      onError?: (err: AxiosError) => void;
      data: Partial<CartographyDO.Change>;
      id: number;
    }): { payload: Partial<RequestSagaConfig<CartographyDO.Change>> } => {
      return {
        payload: {
          serviceArgs: [props.data, props.id],
          successMess: props.successMess,
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: cartographyAPIService.updateChange,
          context: cartographyAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  getPendingCount$Req: createAction(
    cat('getPendingCount$Req'),
    (props: { onSuccess?: (pendingCount: number) => void; onError?: (err: AxiosError) => void }): { payload: Partial<RequestSagaConfig<number>> } => {
      return {
        payload: {
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: cartographyAPIService.getPendingCount,
          context: cartographyAPIService,
        },
      };
    }
  ),

  // --------

  getComparingPaginatedList$Req: createAction(
    cat('getComparingPaginatedList$Req'),
    (props: {
      onSuccess?: (data: CartographyDO.ComparingPaginatedList) => void;
      onError?: (err: AxiosError) => void;
      queries?: GetComparingPaginatedListQueries;
    }): { payload: Partial<RequestSagaConfig<CartographyDO.ComparingPaginatedList>> } => {
      return {
        payload: {
          serviceArgs: [props.queries],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: cartographyAPIService.getComparingPaginatedList,
          context: cartographyAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  export$Req: createAction(
    cat('export$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      queries: GetChangePaginatedListQueries;
    }): {
      payload: Partial<RequestSagaConfig<null>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.queries],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: cartographyAPIService.export,
          context: cartographyAPIService,
        },
      };
    }
  ),
};
