import { put, delay } from 'redux-saga/effects';

// Redux

import snackbarSlice from '../../../snackbar';
import txFilesSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* addTXFileSaga(action: ReturnType<typeof txFilesSlice.actions.addTXFile$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.data],
    service: txService.addTXFile,
    context: txService,
    onSuccess: function* (data) {
      yield put(txFilesSlice.actions.addToList(data));
      action.meta.onSuccess();

      yield delay(500);
      yield put(snackbarSlice.actions.show('success', 'Document successfully uploaded'));
    },
  });
}
