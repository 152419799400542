import { i18n } from '../../../config';
import React from 'react';

// ----------------

export const I18nContext = React.createContext<i18n.FullI18N>(i18n.fullI18n);

const I18nProvider: React.FC<{ children?: React.ReactNode }> = (props) => {
  const value: i18n.FullI18N = i18n.fullI18n;

  return <I18nContext.Provider value={value}>{props.children}</I18nContext.Provider>;
};

export default I18nProvider;
