// ----------------

/**
 *  @deprecated
 */
const fiberEN = {
  fiberDeliveredEstimated: 'Fiber delivered estimated',
  publicPermitRegion: 'Public permit region',
  ownerAuthorization: 'Owner authorization',
  ringSentEricsson: 'Ring sent to Ericsson',
  publicPermitCity: 'Public permit city',
  civilWorksFinish: 'Civil works finish',
  fiberWorksFinish: 'Fiber works finish',
  deliveryForecast: 'Delivery forecast',
  civilWorksStart: 'Civil works start',
  fiberWorksStart: 'Fiber works start',
  splicingPartner: 'Splicing partner',
  fiberDelivered: 'Fiber delivered (real)',
  subcontractor: 'Subcontractor',
  builtReceived: 'As-built received (civil)',
  insertedRING: 'Inserted in a RING',
  ATNRingName: 'ATN ring name',
  orderDate: 'Order date',
  ringName: 'Ring name',
  network: 'Network',
  PMOBE: 'PM OBE',
  cocon: 'Cocon',
  SAF: 'SAF',
  SSV: 'SSV',
  RFB: 'RFB',
  FAC: 'FAC',
};

export default fiberEN;
