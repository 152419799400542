import { BaseDO, DateTime } from '../base';

// ----------------

export namespace HistoryDO {
  export type $ = {
    contentTypeModel: string;
    contentTypeId: number;
    changedBy: {
      email: string;
      id: number;
    };
    objectId: number;
    changed: DateTime;
    changes: Array<{ new: number; old: number; key: string }>;
    id: number;
  };

  export type PaginatedList = BaseDO.PaginatedList<$>;
}
