import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { chartSlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';

// ----------------

export function* chartSaga() {
  yield all([takeEvery([chartSlice.actions.getFiberRing$Req.type], requestActionSaga)]);
}
