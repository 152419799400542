import { put } from 'redux-saga/effects';

// Redux

import txSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* getTXListSaga(action: ReturnType<typeof txSlice.actions.getTXList$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.query],
    service: txService.getTXList,
    context: txService,
    timeout: 200,
    onSuccess: function* (data) {
      yield put(txSlice.actions.setList(data.results, data.pages, data.count));
    },
  });
}
