import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumBranchInitialState } from './enumBranch';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { EnumDO } from '../../../entity/enum';

// ----------------

export const enumSlice = createSlice({
  name: `${reduxActionTypePrefix}/enum`,
  initialState: enumBranchInitialState,

  // -------- Reducers --------

  reducers: {
    merge: (state, action: PayloadAction<{ data: EnumDO.FieldsByEntities }>) => {
      state.field = { ...state.field, ...action.payload.data };
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return enumBranchInitialState;
    });
  },
});
