import { LoadingBarRef } from 'react-top-loading-bar';
import { Store } from 'redux';

import * as Component from './component';
import * as Glossary from './glossary';
import * as Router from './router';
import * as User from './user';
import * as API from './api';
import * as TX from './tx';

// ----------------

/**
 * Language.
 */

export type Lan = 'en' | 'fr' | 'nl';

/**
 * Palette.
 */

export type Palette = 'light' | 'dark';

/**
 * Global object.
 */

export interface IGlobalObj {
  reduxStore: Store;
  loadingBar: LoadingBarRef | null;
}

export enum LoadType {
  Base,
  More,
  Invisible,
}

export { Component, Glossary, Router, User, API, TX };
export * from './TD';
export * from './DD';
