import { ReconstructionDO } from '../../../../entity/reconstruction';

// -------- Types --------

type Labels = Omit<Record<keyof ReconstructionDO.$, string>, 'id' | 'isArchived'>;

// ----------------

// prettier-ignore
const labels: Labels = {
  financialStatus: 'Financial status',
  crPlannedDate  : 'Cr planned date',
  subcontractor  : 'Subcontractor',
  projectNumber  : 'Project number',
  commentBuilt   : 'Comment as built',
  projectName    : 'Project name',
  checkBuilt     : 'Check as built',
  finishDate     : 'Finish date',
  startDate      : 'Start date',
  crNumber       : 'Cr number',
  comments       : 'Comments',
  bepDate        : 'Bep date',
  asBuilt        : 'As built',
  address        : 'Address',
  status         : 'Status',
  ber2           : 'Bep',
  bep2           : 'Bep 2',
  city           : 'City',
  fboq           : 'Fboq',
  boq            : 'Boq',
  zip            : 'Zip',
  fac            : 'Fac',
  ber            : 'Ber',
  bep            : 'Bep',
};

// --------

const reconstructionEN = {
  labels,
};

export default reconstructionEN;
