// Slice

import { glossarySlice } from '../';

// Saga

import requestSaga from '../../../baseSaga/request';

// Services

import { glossaryAPIService } from '../../../../services/api/glossaryAPIService';

// ----------------

export default function* getGlossariesSaga(action: ReturnType<typeof glossarySlice.actions.getGlossaries$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    service: glossaryAPIService.getGlossaries,
    context: glossaryAPIService,

    onSuccess: (data) => {
      if (action.payload.onSuccess) {
        action.payload.onSuccess(data[0]);
      }
    },
  });
}
