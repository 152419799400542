/**
 * `TD` is short for `Transfer data`
 *
 * Provides a set of types to representation data (values), that will
 * be received by the system from outside or sent outside of it.
 */

export namespace TD {
  export type String = string;

  export type StringList = string[];

  export type Number = number | null;

  export type NumberList = number[];

  export type Boolean = boolean;

  export type BooleanList = boolean[];

  /**
   * Standard - ISO 8601,
   * Format   - YYYY-MM-DD
   */

  export type Date = string | null;

  export type DateList = string[];

  /**
   * Standard - ISO 8601,
   * Format   - HH:mm:ss
   */

  export type Time = string | null;

  export type TimeList = string[];

  /**
   * Standard - ISO 8601,
   * Format   - YYYY-MM-DDTHH:mm:ss±hh:mm
   */

  export type DateTime = string | null;

  export type DateTimeList = string[];

  export type Id = number | null;

  export type IdList = number[];

  export type GlossaryId = number | null;

  export type GlossaryIdList = number[];

  export type UUId = string | null;

  export type UUIdList = string[];

  export type URL = string | null;

  export type URLList = string[];

  export type Enum = string | null;

  export type EnumList = string[];
}
