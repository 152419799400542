import { EnumDTO, EnumDO } from './';

// ----------------

export const enumMapper = {
  toDO(data: EnumDTO.$): EnumDO.$ {
    return {
      label: data.display_name,
      value: data.value,
    };
  },

  // --------

  optionsResponseToListByFields<DTOKey extends string, DOKey extends string>(
    data: EnumDTO.OptionsResponse<DTOKey>,
    doKeysMap: Partial<Record<DTOKey, DOKey>>
  ): EnumDO.ListByFields<DOKey> {
    const field = {} as EnumDO.ListByFields<DOKey>;

    Object.keys(data.actions.POST).forEach((dtoKey) => {
      const choices = data.actions.POST[dtoKey].choices;

      if (choices) {
        field[doKeysMap[dtoKey]] = choices.map((item) => enumMapper.toDO(item));
      }
    });

    return field;
  },
};
