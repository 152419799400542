import { GlossaryDTO, GlossaryDO, GlossaryCommon } from './';

// ----------------

export const glossaryMapper = {
  toDO(data: GlossaryDTO.$): GlossaryDO.$ {
    return {
      value: data.id,
      label: data.value,
      code: data.code,
    };
  },

  // --------

  listByTypeToDO(data: GlossaryDTO.ListByType): GlossaryDO.ListByType {
    const glossaries = {} as GlossaryDO.ListByType;
    const types = Object.keys(data) as GlossaryCommon.Type.List;

    types.forEach((type) => (glossaries[type] = data[type]?.map((glossary) => this.toDO(glossary))));

    return glossaries;
  },
};
