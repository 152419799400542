import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Types

import { TX } from '../../../types';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/txFiles/${actionPiece}`; // Create action type

export default {
  addTXFile$Req: createAction(cat('addTXFile$Req'), (data: TX.Api.TXFiles$Req, onSuccess: () => void) => ({
    payload: {
      data,
    },
    meta: {
      onSuccess,
    },
  })),

  getTXFiles$Req: createAction(cat('getTXFiles$Req'), (query: TX.Api.TXFiles$ReqQuery) => ({
    payload: {
      query,
    },
  })),

  deleteTXFile$Req: createAction(cat('deleteTXFile$Req'), (id: number) => ({
    payload: {
      id,
    },
  })),
};
