// Utils

import hasPermissions from '../../utils/hasPermissions/hasPermissions';

// Entities

import { UserCommon } from '../user';
import { SmallWorksDO } from './SmallWorksDO';

// ----------------

export const smallWorksAnalyzer = {
  canEdit(data: SmallWorksDO.$) {
    return hasPermissions([UserCommon.Permission.SMALL_WORKS_EDIT]);
  },

  // --------

  canDelete(data: SmallWorksDO.$) {
    return hasPermissions([UserCommon.Permission.SMALL_WORKS_DELETE]);
  },

  // --------

  canArchive(data: SmallWorksDO.$) {
    return !data.isArchived && hasPermissions([UserCommon.Permission.SMALL_WORKS_ARCHIVE]);
  },

  // --------

  canUnarchive(data: SmallWorksDO.$) {
    return data.isArchived && hasPermissions([UserCommon.Permission.SMALL_WORKS_UNARCHIVE]);
  },
};
