import { all, takeEvery } from 'redux-saga/effects';

// Slice

import userSlice from '../index';

// Saga

import resetPasswordValidateTokenSaga from './resetPasswordValidateTokenSaga/resetPasswordValidateTokenSaga';
import resetPasswordConfirmSaga from './resetPasswordConfirmSaga/resetPasswordConfirmSaga';
import resetPasswordSaga from './resetPasswordSaga/resetPasswordSaga';
import getBaseDataSaga from './getBaseDataSaga/getBaseDataSaga';
import updateDataSaga from './updateDataSaga/updateDataSaga';
import getTokenSaga from './getTokenSaga/getTokenSaga';
import logoutSaga from './logoutSaga/logoutSaga';
import verifyOtpSaga from './verifyOtpSaga/verifyOtpSaga';
import generateQrCodeSaga from './generateQrCodeSaga/generateQrCodeSaga';

// ----------------

export default function* () {
  yield all([takeEvery(userSlice.actions.resetPasswordValidateToken$Req.type, resetPasswordValidateTokenSaga)]);
  yield all([takeEvery(userSlice.actions.resetPasswordConfirm$Req.type, resetPasswordConfirmSaga)]);
  yield all([takeEvery(userSlice.actions.resetPassword$Req.type, resetPasswordSaga)]);
  yield all([takeEvery(userSlice.actions.updateData$Req.type, updateDataSaga)]);
  yield all([takeEvery(userSlice.actions.getBaseData$Req, getBaseDataSaga)]);
  yield all([takeEvery(userSlice.actions.getToken$Req.type, getTokenSaga)]);
  yield all([takeEvery(userSlice.actions.logout.type, logoutSaga)]);
  yield all([takeEvery(userSlice.actions.verifyOtp$Req.type, verifyOtpSaga)]);
  yield all([takeEvery(userSlice.actions.generateOtp$Req.type, generateQrCodeSaga)]);


}
