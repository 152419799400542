/**
 * Converts value to number.
 * If value is undefined, then returns undefined value.
 *
 * @param value - Value to convert
 */

export default function toNumberOrUndefined(value: any): number | undefined {
  return value !== undefined ? Number(value) : undefined;
}
