import { TX } from '../../../types';

// Transformers

import apiTransformer from '../api';

// ----------------

const txTransformer = {
  // ======== Base ========

  // TX(data: TX.Base.ITXAPI): TX.Base.ITX {
  //   // prettier-ignore
  //   return {
  //     candidate2g : data.candidate_2g,
  //     candidate3g : data.candidate_3g,
  //     candidate4g : data.candidate_4g,
  //     site2g      : data.site_2g,
  //     site3g      : data.site_3g,
  //     site4g      : data.site_4g,
  //     targetTechno: data.target_techno,
  //     doubleStream: data.double_stream,
  //     radioStatus : data.radio_status,
  //     longitude   : data.longitude,
  //     latitude    : data.latitude,
  //     siteType    : data.radio_site_type,
  //     address     : data.address,
  //     family      : data.family,
  //     siteId      : data.site_id,
  //     techno      : data.current_techno,
  //     status      : data.status,
  //     city        : data.city,
  //     zip         : data.zip,
  //     id          : data.id,
  //   };
  // },

  TXFileFromApi(data: TX.Base.ITXFileAPI): TX.Base.ITXFile {
    const splittedName = data.name.split('.');
    const name = splittedName.splice(0, splittedName.length - 1).join('.');

    return {
      created: data.created_at,
      user: {
        firstName: data.user.first_name,
        lastLame: data.user.last_name,
        email: data.user.email,
      },
      txId: data.transmission,
      type: data.extension,
      name,
      url: data.file,
      id: data.id,
    };
  },

  TXNoteFromApi(data: TX.Base.ITXNoteAPI): TX.Base.ITXNote {
    return {
      created: data.created_at,
      txId: data.transmission,
      text: data.text,
      user: data.user,
      id: data.id,
    };
  },

  // ======== API ========

  // -------- tx.root --------

  // TXRoot$Get$ResponseAPI(data: TX.Api.TXRoot$Get$ResponseAPI): TX.Api.TXRoot$Get$Response {
  //   return { list: data.results.map((item) => this.TX(item)), totalPages: data.pages };
  // },

  // -------- tx.files --------

  TXFiles$ReqQueryToApi(query: TX.Api.TXFiles$ReqQuery): TX.Api.TXFiles$ReqQueryAPI {
    return { ...apiTransformer.withPagination$ReqQueryToApi({ page: query.page, count: query.count }), transmission: query.txId };
  },

  TXFiles$ReqToApi(data: TX.Api.TXFiles$Req): TX.Api.TXFiles$ReqAPI {
    const formData = new FormData();

    formData.append('transmission', data.txId + '');
    formData.append('file', data.file);

    return formData;
  },

  TXFiles$ResFromApi(data: TX.Api.TXFiles$ResAPI): TX.Api.TXFiles$Res {
    return this.TXFileFromApi(data);
  },

  TXFiles$ResFromApi$Get(res: TX.Api.TXFiles$ResAPI$Get, limit?: number): TX.Api.TXFiles$Res$Get {
    return apiTransformer.withPagination$ResFromApi(res, this.TXFileFromApi, limit);
  },

  // -------- tx.notes --------

  TXNotes$ReqQueryToApi(query: TX.Api.TXNotes$ReqQuery): TX.Api.TXNotes$ReqQueryAPI {
    return { ...apiTransformer.withPagination$ReqQueryToApi({ page: query.page, count: query.count }), transmission: query.txId };
  },

  TXNotes$ReqBodyToApi(data: TX.Api.TXNotes$ReqBody): TX.Api.TXNotes$ReqBodyAPI {
    return {
      transmission: data.txId,
      text: data.text,
    };
  },

  TXNotes$ResFromApi(data: TX.Api.TXNotes$ResAPI): TX.Api.TXNotes$Res {
    return this.TXNoteFromApi(data);
  },

  TXNotes$ResFromApi$Get(res: TX.Api.TXNotes$ResAPI$Get, limit?: number): TX.Api.TXNotes$Res$Get {
    return apiTransformer.withPagination$ResFromApi(res, this.TXNoteFromApi, limit);
  },
};

export default txTransformer;
