import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { b2bAPIService, GetPaginatedListQueries, SendData, SendDataErr } from '../../../services/api/b2bAPIService';

// Entities

import { B2BPaginatedListDO, B2BDO } from '../../../entity/b2b';

// Types

import { FreeQueries } from '../../../services/queryService';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/b2b/${actionPiece}`; // Create action type

export const b2bActionCreators = {
  getPaginatedList$Req: createAction(
    cat('getPaginatedList$Req'),
    (props: {
      onSuccess?: (data: B2BPaginatedListDO) => void;
      onError?: (err: AxiosError) => void;
      queries?: GetPaginatedListQueries;
    }): { payload: Partial<RequestSagaConfig<B2BPaginatedListDO>> } => {
      return {
        payload: {
          serviceArgs: [props.queries],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: b2bAPIService.getPaginatedList,
          context: b2bAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  getItem$Req: createAction(
    cat('getItem$Req'),
    (props: {
      onSuccess?: (data: B2BDO) => void;
      onError?: (err: AxiosError) => void;
      timeout?: number;
      id?: number;
    }): {
      payload: Partial<RequestSagaConfig<B2BDO>>;
    } => {
      return {
        payload: {
          serviceArgs: [props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: b2bAPIService.getItem,
          context: b2bAPIService,
          timeout: props.timeout,
        },
      };
    }
  ),

  // --------

  export$Req: createAction(
    cat('export$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      filters?: FreeQueries;
      fields: Array<keyof B2BDO>;
    }): {
      payload: Partial<RequestSagaConfig<null>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.fields, props.filters],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: b2bAPIService.export,
          context: b2bAPIService,
        },
      };
    }
  ),

  // --------

  create$Req: createAction(
    cat('create$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: B2BDO) => void;
      onError?: (err: AxiosError<SendDataErr>) => void;
      data: SendData;
    }): {
      payload: Partial<RequestSagaConfig<B2BDO>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: b2bAPIService.create,
          context: b2bAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  update$Req: createAction(
    cat('update$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: B2BDO) => void;
      onError?: (err: AxiosError<SendDataErr>) => void;
      data: SendData;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<B2BDO>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data, props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: b2bAPIService.update,
          context: b2bAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  delete$Req: createAction(
    cat('delete$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<void>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: b2bAPIService.delete,
          context: b2bAPIService,
          timeout: 300,
        },
      };
    }
  ),
};
