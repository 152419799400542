import { all, takeEvery } from 'redux-saga/effects';

// Slice

import txNotesSlice from '../index';

// Saga

import deleteTXNoteSaga from './deleteTXNoteSaga/deleteTXNoteSaga';
import updateTXNoteSaga from './updateTXNoteSaga/updateTXNoteSaga';
import getTXNotesSaga from './getTXNotesSaga/getTXNotesSaga';
import addTXNoteSaga from './addTXNoteSaga/addTXNoteSaga';

// ----------------

export default function* () {
  yield all([takeEvery(txNotesSlice.actions.deleteTXNote$Req.type, deleteTXNoteSaga)]);
  yield all([takeEvery(txNotesSlice.actions.updateTXNote$Req.type, updateTXNoteSaga)]);
  yield all([takeEvery(txNotesSlice.actions.getTXNotes$Req.type, getTXNotesSaga)]);
  yield all([takeEvery(txNotesSlice.actions.addTXNote$Req.type, addTXNoteSaga)]);
}
