import { glossaryActionCreators } from './glossaryActionCreators';
import { glossarySlice as slice } from './glossarySlice';

const glossarySlice = {
  ...slice,
  actions: { ...slice.actions, ...glossaryActionCreators },
};

export { glossarySlice };
export * from './glossaryBranch';
export * from './saga';
