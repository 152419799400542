import { call, put, all } from 'redux-saga/effects';

// Slice

import { enumSlice } from '../';

// Services

import { enumAPIService } from '../../../../services/api/enumAPIService';

// Entities

import { EnumDO } from '../../../../entity/enum';

// ----------------

const usedEntities = new Set<keyof EnumDO.FieldsByEntities>();

export function* getEnumsSaga(action: ReturnType<typeof enumSlice.actions.getEnums$Req>) {
  const entities = action.payload.entities.filter((entity) => !usedEntities.has(entity));

  if (!entities.length) {
    return;
  }

  entities.forEach((entity) => usedEntities.add(entity));

  const config: Array<{ name: keyof EnumDO.FieldsByEntities; service: any }> = [
    {
      name: 'leasedLine',
      service: enumAPIService.getLeasedLine,
    },
    {
      name: 'tx',
      service: enumAPIService.getTx,
    },
  ];

  const filteredConfig = config.filter((item) => entities.includes(item.name));

  const res = yield all(filteredConfig.map((item) => call([enumAPIService, item.service])));

  const fieldsByEntities: EnumDO.FieldsByEntities = {};

  res.forEach((item, index) => {
    fieldsByEntities[filteredConfig[index].name] = item;
  });

  yield put(enumSlice.actions.merge({ data: fieldsByEntities }));
}
