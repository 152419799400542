import { BaseDO, DateTime } from '../base';

// ----------------

export namespace NoteDO {
  export type $ = {
    entityTypeId: number;
    entityId: number;
    updated: DateTime;
    created: DateTime;
    text: string;
    id: number;
    creator: {
      email: string;
      id: number;
    };
  };

  export type PaginatedList = BaseDO.PaginatedList<$>;
}
