import { FiberDO } from '../../../entity/fiber';

// -------- Types --------

export type FiberBranch = {
  item: FiberDO | null;
};

// -------- Initial state of branch --------

export const fiberBranchInitialState: FiberBranch = {
  item: null,
};
