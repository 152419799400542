import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import { all, fork } from 'redux-saga/effects';
import { Store } from 'redux';

// Slices

import txMicrowaveSynopsisSlice, { TXMicrowaveSynopsisBranch, txMicrowaveSynopsisSaga } from './slices/txMicrowaveSynopsis';
import _glossarySlice, { IGlossaryBranch, glossarySaga as _glossarySaga } from './slices/_glossary';
import { reconstructionSlice, ReconstructionBranch, reconstructionSaga } from './slices/reconstruction';
import { smallWorksSlice, SmallWorksBranch, smallWorksSaga } from './slices/smallWorks';
import { userSlice as userSlice_n, UserBranch, userSaga as userSaga_n } from './slices/user_n';
import notificationsSlice, { INotificationsBranch, notificationsSaga } from './slices/notifications';
import transmissionSlice, { ITransmissionBranch, transmissionSaga } from './slices/transmission';
import { cartographySlice, CartographyBranch, cartographySaga } from './slices/cartography';
import { leasedLineSlice, leasedLineSaga, LeasedLineBranch } from './slices/leasedLine';
import { txSlice as _txSlice, TXBranch, txSaga as _txSaga } from './slices/_tx';
import microwaveSlice, { IMicrowaveBranch, microwaveSaga } from './slices/microwave';
import { timelineSlice, TimelineBranch, timelineSaga } from './slices/timeline';
import { glossarySlice, GlossaryBranch, glossarySaga } from './slices/glossary';
import { documentSlice, DocumentBranch, documentSaga } from './slices/document';
import { blueMoonSlice, BlueMoonBranch, blueMoonSaga } from './slices/blueMoon';
import txFilesSlice, { ITXFilesBranch, txFilesSaga } from './slices/txFiles';
import txNotesSlice, { ITXNotesBranch, txNotesSaga } from './slices/txNotes';
import { historySlice, HistoryBranch, historySaga } from './slices/history';
import graphSlice, { IGraphBranch, graphSaga } from './slices/graph';
import userSlice, { IUserBranch, userSaga } from './slices/user';
import { noteSlice, NoteBranch, noteSaga } from './slices/note';
import { enumSlice, EnumBranch, enumSaga } from './slices/enum';
import { coreSlice, CoreBranch, coreSaga } from './slices/core';
import snackbarSlice, { ISnackbarBranch } from './slices/snackbar';
import { b2bSlice, B2BBranch, b2bSaga } from './slices/b2b';
import requestSlice, { IRequestBranch } from './slices/request';
import txSlice, { ITXBranch, txSaga } from './slices/tx';
import { fiberSlice, FiberBranch } from './slices/fiber';
import { chartSlice, chartSaga } from './slices/chart';
import appSlice, { IAppBranch } from './slices/app';

// -------- Types --------

export type RootState = {
  txMicrowaveSynopsis: TXMicrowaveSynopsisBranch;
  reconstruction: ReconstructionBranch;
  smallWorks: SmallWorksBranch;
  notifications: INotificationsBranch;
  transmission: ITransmissionBranch;
  cartography: CartographyBranch;
  leasedLine: LeasedLineBranch;
  microwave: IMicrowaveBranch;
  _glossary: IGlossaryBranch;
  snackbar: ISnackbarBranch;
  blueMoon: BlueMoonBranch;
  glossary: GlossaryBranch;
  timeline: TimelineBranch;
  document: DocumentBranch;
  request: IRequestBranch;
  txFiles: ITXFilesBranch;
  txNotes: ITXNotesBranch;
  history: HistoryBranch;
  user_n: UserBranch;
  graph: IGraphBranch;
  fiber: FiberBranch;
  enum: EnumBranch;
  user: IUserBranch;
  note: NoteBranch;
  core: CoreBranch;
  app: IAppBranch;
  b2b: B2BBranch;
  _tx: TXBranch;
  tx: ITXBranch;
};

// ----------------

const reducer = {
  txMicrowaveSynopsis: txMicrowaveSynopsisSlice.reducer,
  reconstruction: reconstructionSlice.reducer,
  smallWorks: smallWorksSlice.reducer,
  notifications: notificationsSlice.reducer,
  transmission: transmissionSlice.reducer,
  cartography: cartographySlice.reducer,
  leasedLine: leasedLineSlice.reducer,
  microwave: microwaveSlice.reducer,
  _glossary: _glossarySlice.reducer,
  snackbar: snackbarSlice.reducer,
  blueMoon: blueMoonSlice.reducer,
  glossary: glossarySlice.reducer,
  document: documentSlice.reducer,
  timeline: timelineSlice.reducer,
  request: requestSlice.reducer,
  txFiles: txFilesSlice.reducer,
  history: historySlice.reducer,
  txNotes: txNotesSlice.reducer,
  user_n: userSlice_n.reducer,
  graph: graphSlice.reducer,
  fiber: fiberSlice.reducer,
  enum: enumSlice.reducer,
  note: noteSlice.reducer,
  user: userSlice.reducer,
  core: coreSlice.reducer,
  app: appSlice.reducer,
  b2b: b2bSlice.reducer,
  _tx: _txSlice.reducer,
  tx: txSlice.reducer,
};

// --------

function* rootSaga() {
  yield all([fork(txMicrowaveSynopsisSaga)]);
  yield all([fork(reconstructionSaga)]);
  yield all([fork(smallWorksSaga)]);
  yield all([fork(notificationsSaga)]);
  yield all([fork(transmissionSaga)]);
  yield all([fork(cartographySaga)]);
  yield all([fork(leasedLineSaga)]);
  yield all([fork(microwaveSaga)]);
  yield all([fork(_glossarySaga)]);
  yield all([fork(glossarySaga)]);
  yield all([fork(timelineSaga)]);
  yield all([fork(documentSaga)]);
  yield all([fork(blueMoonSaga)]);
  yield all([fork(txNotesSaga)]);
  yield all([fork(txFilesSaga)]);
  yield all([fork(historySaga)]);
  yield all([fork(userSaga_n)]);
  yield all([fork(graphSaga)]);
  yield all([fork(chartSaga)]);
  yield all([fork(noteSaga)]);
  yield all([fork(userSaga)]);
  yield all([fork(coreSaga)]);
  yield all([fork(enumSaga)]);
  yield all([fork(b2bSaga)]);
  yield all([fork(_txSaga)]);
  yield all([fork(txSaga)]);
}

// --------

export default function createStore(preloadedState?: Partial<RootState>): Store {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
    reducer,
    middleware: [sagaMiddleware],
  });

  sagaMiddleware.run(rootSaga);

  return store;
}

export {
  txMicrowaveSynopsisSlice,
  reconstructionSlice,
  smallWorksSlice,
  notificationsSlice,
  transmissionSlice,
  cartographySlice,
  leasedLineSlice,
  microwaveSlice,
  _glossarySlice,
  snackbarSlice,
  blueMoonSlice,
  glossarySlice,
  timelineSlice,
  documentSlice,
  requestSlice,
  txFilesSlice,
  historySlice,
  txNotesSlice,
  userSlice_n,
  graphSlice,
  chartSlice,
  fiberSlice,
  noteSlice,
  userSlice,
  enumSlice,
  coreSlice,
  appSlice,
  b2bSlice,
  _txSlice,
  txSlice,
};
