import { all, takeEvery } from 'redux-saga/effects';

// Slice

import slice from '../index';

// Saga

import getDashboardDataSaga from './getDashboardDataSaga/getDashboardDataSaga';
import getSnapshotSaga from './getSnapshotSaga/getSnapshotSaga';

// ----------------

export default function* () {
  yield all([
    takeEvery(slice.actions.getDashboardData$Req.type, getDashboardDataSaga),
    takeEvery(slice.actions.getSnapshot$Req.type, getSnapshotSaga),
  ]);
}
