import { CartographyCommon } from '../../../../entity/cartography';

// -------- Types --------

type ChangeStatusLabels = Record<keyof typeof CartographyCommon.ChangeStatus, string>;

type ChangeTypeLabels = Record<keyof typeof CartographyCommon.ChangeType, string>;

// ----------------

const changeStatusLabels: ChangeStatusLabels = {
  appliedBySystem: 'Applied by system',
  ignoredBySystem: 'Ignored by system',
  rejected: 'Rejected',
  pending: 'Pending',
  applied: 'Applied',
  ignored: 'Ignored',
  denied: 'Denied',
};

const changeTypeLabels: ChangeTypeLabels = {
  removeService: 'Remove service',
  changeTechno: 'Update techno',
  addService: 'Add new service',
  remove: 'Remove USI',
  add: 'Add new USI',
};

// --------

const cartographyEN = {
  changeStatusLabels,
  changeTypeLabels,
};

export default cartographyEN;
