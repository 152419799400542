/**
 * Creates a FormData instance based on object
 */

export function objectToFormData(data: object): FormData {
  const formData = new FormData();

  Object.keys(data).forEach(key => {
    let name = key;
    let value = data[key];

    if (Array.isArray(data[key])) {
      name += '[]';

      if (!data[key].length) {
        value = '';
      }
    }

    if (value === null) {
      value = '';
    }

    formData.append(name, value);
  });

  return formData;
}
