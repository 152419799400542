import HTTP from '../http';

// Config

import { tx as txApi } from '../../config/api';
import env from '../../config/env';

// Transformers

import txTransformer from '../transformers/tx';

// Types

import { TX } from '../../types';

// ----------------

class TXAPIService extends HTTP {
  getTXList(query: TX.Api.TXRoot$Get$Request): Promise<TX.Api.TXRoot$Get$Response | void> {
    return this.request<TX.Api.TXRoot$Get$Response>({ url: txApi.root, params: query });
  }

  getTXById(id: number): Promise<TX.Api.TXId$Get$Response | void> {
    return this.request<TX.Api.TXId$Get$Response>({ url: txApi.id(id) });
  }

  addTX(data: TX.Api.TXRoot$Post$Req): Promise<TX.Api.TXRoot$Post$Res | void> {
    return this.request<TX.Api.TXRoot$Post$Res>({ url: txApi.root, method: 'post', data });
  }

  uploadTXDocument(file: File): Promise<any | void> {
    const formData = new FormData();
    formData.append('file', file);

    return this.request({ url: txApi.import, method: 'post', data: formData });
  }

  updateTX(id: number, data: TX.Api.TXRoot$Patch$Req): Promise<TX.Api.TXRoot$Patch$Res | void> {
    return this.request<TX.Api.TXRoot$Patch$Res>({ url: txApi.id(id), method: 'patch', data });
  }

  deleteTX(id: number): Promise<any | void> {
    return this.request({ url: txApi.id(id), method: 'delete' });
  }

  exportTXList(): Promise<any | void> {
    return this.request({ url: txApi.export, method: 'post' });
  }

  addTXFile(data: TX.Api.TXFiles$Req): Promise<TX.Api.TXFiles$Res | void> {
    return this.request<TX.Api.TXFiles$ResAPI>({ method: 'post', url: txApi.files, data: txTransformer.TXFiles$ReqToApi(data) }).then((data) => {
      if (data) {
        return txTransformer.TXFiles$ResFromApi(data);
      }
    });
  }

  getTXFiles(query: TX.Api.TXFiles$ReqQuery): Promise<TX.Api.TXFiles$Res$Get | void> {
    return this.request<TX.Api.TXFiles$ResAPI$Get>({ url: txApi.files, params: txTransformer.TXFiles$ReqQueryToApi(query) }).then((data) => {
      if (data) {
        return txTransformer.TXFiles$ResFromApi$Get(data, query.count);
      }
    });
  }

  deleteTXFile(id: number): Promise<any> {
    return this.request({ url: txApi.filesId(id), method: 'delete' });
  }

  getTXNotes(query: TX.Api.TXNotes$ReqQuery): Promise<TX.Api.TXNotes$Res$Get | void> {
    return this.request<TX.Api.TXNotes$ResAPI$Get>({ url: txApi.notes, params: txTransformer.TXNotes$ReqQueryToApi(query) }).then((data) => {
      if (data) {
        return txTransformer.TXNotes$ResFromApi$Get(data, query.count);
      }
    });
  }

  addTXNote(data: TX.Api.TXNotes$ReqBody): Promise<TX.Api.TXNotes$Res | void> {
    return this.request<TX.Api.TXNotes$ResAPI>({ method: 'post', url: txApi.notes, data: txTransformer.TXNotes$ReqBodyToApi(data) }).then((data) => {
      if (data) {
        return txTransformer.TXNotes$ResFromApi(data);
      }
    });
  }

  updateTXNote(data: TX.Api.TXNotes$Patch$ReqAPI, noteId: number): Promise<TX.Api.TXNotes$Patch$Res | void> {
    return this.request<TX.Api.TXNotes$Patch$ResAPI>({ method: 'patch', url: txApi.notesId(noteId), data }).then((data) => {
      if (data) {
        return txTransformer.TXNoteFromApi(data);
      }
    });
  }

  deleteTXNote(id: number): Promise<any> {
    return this.request({ url: txApi.notesId(id), method: 'delete' });
  }
}

export default new TXAPIService(`${env.API}api/`);
