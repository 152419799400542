import { ReconstructionDTO, ReconstructionDO } from './';

// Utils

import { createKeysMappers } from '../base/utils/createKeysMappers';

// ----------------

export const reconstructionMapper = {
  toDO(data: ReconstructionDTO.$): ReconstructionDO.$ {
    // prettier-ignore
    return {
      financialStatus: data.financial_status,
      crPlannedDate  : data.cr_planned_date,
      subcontractor  : data.subcontractor,
      projectNumber  : data.project_number,
      commentBuilt   : data.comment_as_built,
      projectName    : data.project_name,
      isArchived     : data.is_archived,
      checkBuilt     : data.check_as_built,
      finishDate     : data.finish_date,
      startDate      : data.start_date,
      crNumber       : data.cr_number,
      comments       : data.comments,
      bepDate        : data.bep_date,
      asBuilt        : data.as_built,
      address        : data.address,
      status         : data.status,
      ber2           : data.ber_2,
      bep2           : data.bep_2,
      city           : data.city,
      fboq           : data.fboq,
      boq            : data.boq,
      zip            : data.zip,
      fac            : data.fac,
      ber            : data.ber,
      bep            : data.bep,
      id             : data.id
    };
  },

  // --------

  toDTO(data: Partial<ReconstructionDO.$>): Partial<ReconstructionDTO.$> {
    // prettier-ignore
    return {
      financial_status: data.financialStatus,
      comment_as_built: data.commentBuilt,
      cr_planned_date : data.crPlannedDate,
      check_as_built  : data.checkBuilt,
      project_number  : data.projectNumber,
      subcontractor   : data.subcontractor,
      project_name    : data.projectName,
      finish_date     : data.finishDate,
      is_archived     : data.isArchived,
      start_date      : data.startDate,
      cr_number       : data.crNumber,
      comments        : data.comments,
      bep_date        : data.bepDate,
      as_built        : data.asBuilt,
      address         : data.address,
      status          : data.status,
      ber_2           : data.ber2,
      bep_2           : data.bep2,
      city            : data.city,
      fboq            : data.fboq,
      boq             : data.boq,
      zip             : data.zip,
      fac             : data.fac,
      ber             : data.ber,
      bep             : data.bep
    };
  },

  // --------

  ...createKeysMappers<ReconstructionDTO.$, ReconstructionDO.$>([
    ['comment_as_built', 'commentBuilt'],
    ['financial_status', 'financialStatus', { type: 'glossary', glossaryType: 'reconstructionFinancialStatus' }],
    ['cr_planned_date', 'crPlannedDate', { type: 'date' }],
    ['project_number', 'projectNumber'],
    ['check_as_built', 'checkBuilt', { type: 'glossary', glossaryType: 'reconstructionCheckAsBuilt' }],
    ['subcontractor', 'subcontractor', { type: 'glossary', glossaryType: 'reconstructionSubcontractor' }],
    ['project_name', 'projectName'],
    ['finish_date', 'finishDate', { type: 'date' }],
    ['is_archived', 'isArchived'],
    ['start_date', 'startDate', { type: 'date' }],
    ['cr_number', 'crNumber'],
    ['comments', 'comments'],
    ['bep_date', 'bepDate', { type: 'date' }],
    ['as_built', 'asBuilt', { type: 'date' }],
    ['address', 'address'],
    ['status', 'status', { type: 'glossary', glossaryType: 'reconstructionStatus' }],
    ['bep_2', 'bep2'],
    ['ber_2', 'ber2'],
    ['city', 'city'],
    ['fboq', 'fboq', { type: 'number' }],
    ['boq', 'boq', { type: 'number' }],
    ['zip', 'zip'],
    ['fac', 'fac', { type: 'date' }],
    ['ber', 'ber'],
    ['bep', 'bep'],
  ]),
};
