import { smallWorksActionCreators } from './smallWorksActionCreators';
import { smallWorksSlice as slice } from './smallWorksSlice';

const smallWorksSlice = {
  ...slice,
  actions: { ...slice.actions, ...smallWorksActionCreators },
};

export { smallWorksSlice };
export * from './smallWorksBranch';
export * from './saga';
