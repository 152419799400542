import { B2BDO, B2BDTO } from './';

// Utils

import { createKeysMappers } from '../base/utils/createKeysMappers';

// ----------------

export const b2bMapper = {
  toDO(data: B2BDTO): B2BDO {
    // prettier-ignore
    return {
      projectDescription: data.project_description,
      contactPersonEmail: data.contact_person_email,
      contactPersonPhone: data.contact_person_phone,
      pmServiceDelivery : data.pm_service_delivery,
      fiberAreaComment  : data.fiber_area_comment,
      contactPerson     : data.contact_person,
      subcontractor     : data.subcontractor,
      ebofAccepted      : data.ebof_accepted,
      receivingBep      : data.receiving_bep,
      requestedRfs      : data.requested_rfs,
      builtSplice       : data.as_built_splice,
      builtCivil        : data.as_built_civil,
      sentFusion        : data.sent_fusion,
      isArchived        : data.is_archived,
      fiberArea         : data.fiber_area,
      equipment         : data.equipment,
      nisCode           : data.nis_code,
      pmFiber           : data.pm_fiber,
      network           : data.network,
      address           : data.adres,
      status            : data.status,
      budget            : data.budget,
      datePo            : data.date_po,
      amount            : data.amount,
      xCode             : data.x_code,
      fiber             : data.fiber,
      newHh             : data.new_hh,
      civil             : data.civil,
      feasN             : data.feas_n,
      cocon             : data.cocon,
      site              : data.site,
      bofN              : data.bof_n,
      bof               : data.bof,
      rft               : data.rft,
      ssv               : data.ssv,
      ber               : data.ber,
      bep               : data.bep,
      odf               : data.odf,
      fac               : data.fac,
      vt                : data.vt,
      id                : data.id,
    };
  },

  // --------

  toDTO(data: Partial<B2BDO>): Partial<B2BDTO> {
    // prettier-ignore
    return {
      contact_person_email: data.contactPersonEmail,
      contact_person_phone: data.contactPersonPhone,
      project_description : data.projectDescription,
      pm_service_delivery : data.pmServiceDelivery,
      fiber_area_comment  : data.fiberAreaComment,
      as_built_splice     : data.builtSplice,
      as_built_civil      : data.builtCivil,
      contact_person      : data.contactPerson,
      subcontractor       : data.subcontractor,
      ebof_accepted       : data.ebofAccepted,
      receiving_bep       : data.receivingBep,
      requested_rfs       : data.requestedRfs,
      sent_fusion         : data.sentFusion,
      is_archived         : data.isArchived,
      fiber_area          : data.fiberArea,
      equipment           : data.equipment,
      nis_code            : data.nisCode,
      pm_fiber            : data.pmFiber,
      network             : data.network,
      date_po             : data.datePo,
      status              : data.status,
      budget              : data.budget,
      amount              : data.amount,
      x_code              : data.xCode,
      new_hh              : data.newHh,
      feas_n              : data.feasN,
      fiber               : data.fiber,
      civil               : data.civil,
      adres               : data.address,
      bof_n               : data.bofN,
      cocon               : data.cocon,
      site                : data.site,
      bof                 : data.bof,
      rft                 : data.rft,
      ssv                 : data.ssv,
      ber                 : data.ber,
      bep                 : data.bep,
      odf                 : data.odf,
      fac                 : data.fac,
      vt                  : data.vt,
    };
  },

  // --------

  getKeysMap(): Record<keyof Omit<B2BDO, 'id'>, keyof B2BDTO> {
    // prettier-ignore
    return {
      projectDescription: 'project_description',
      contactPersonEmail: 'contact_person_email',
      contactPersonPhone: 'contact_person_phone',
      pmServiceDelivery : 'pm_service_delivery',
      fiberAreaComment  : 'fiber_area_comment',
      contactPerson     : 'contact_person',
      subcontractor     : 'subcontractor',
      ebofAccepted      : 'ebof_accepted',
      receivingBep      : 'receiving_bep',
      requestedRfs      : 'requested_rfs',
      builtSplice       : 'as_built_splice',
      isArchived        : 'is_archived',
      builtCivil        : 'as_built_civil',
      sentFusion        : 'sent_fusion',
      fiberArea         : 'fiber_area',
      equipment         : 'equipment',
      nisCode           : 'nis_code',
      pmFiber           : 'pm_fiber',
      network           : 'network',
      address           : 'adres',
      status            : 'status',
      budget            : 'budget',
      datePo            : 'date_po',
      amount            : 'amount',
      xCode             : 'x_code',
      fiber             : 'fiber',
      newHh             : 'new_hh',
      civil             : 'civil',
      feasN             : 'feas_n',
      cocon             : 'cocon',
      site              : 'site',
      bofN              : 'bof_n',
      bof               : 'bof',
      rft               : 'rft',
      ssv               : 'ssv',
      ber               : 'ber',
      bep               : 'bep',
      odf               : 'odf',
      fac               : 'fac',
      vt                : 'vt',
    };
  },

  // --------

  ...createKeysMappers<B2BDTO, B2BDO>([
    ['contact_person_email', 'contactPersonEmail'],
    ['contact_person_phone', 'contactPersonPhone'],
    ['project_description', 'projectDescription'],
    ['pm_service_delivery', 'pmServiceDelivery'],
    ['fiber_area_comment', 'fiberAreaComment'],
    ['as_built_splice', 'builtSplice', { type: 'date' }],
    ['contact_person', 'contactPerson'],
    ['as_built_civil', 'builtCivil', { type: 'date' }],
    ['subcontractor', 'subcontractor', { type: 'glossary', glossaryType: 'b2bSubcontractor' }],
    ['ebof_accepted', 'ebofAccepted', { type: 'date' }],
    ['receiving_bep', 'receivingBep', { type: 'date' }],
    ['requested_rfs', 'requestedRfs', { type: 'date' }],
    ['sent_fusion', 'sentFusion', { type: 'date' }],
    ['fiber_area', 'fiberArea'],
    ['equipment', 'equipment', { type: 'glossary', glossaryType: 'b2bEquipment' }],
    ['nis_code', 'nisCode'],
    ['pm_fiber', 'pmFiber', { type: 'glossary', glossaryType: 'b2bPmfiber' }],
    ['network', 'network', { type: 'glossary', glossaryType: 'b2bNetwork' }],
    ['date_po', 'datePo', { type: 'date' }],
    ['status', 'status', { type: 'glossary', glossaryType: 'b2bStatus' }],
    ['amount', 'amount', { type: 'number' }],
    ['budget', 'budget', { type: 'number' }],
    ['x_code', 'xCode'],
    ['new_hh', 'newHh'],
    ['adres', 'address'],
    ['fiber', 'fiber'],
    ['civil', 'civil', { type: 'bool' }],
    ['cocon', 'cocon', { type: 'bool' }],
    ['bof_n', 'bofN'],
    ['site', 'site'],
    ['bof', 'bof'],
    ['rft', 'rft', { type: 'date' }],
    ['ssv', 'ssv', { type: 'date' }],
    ['ber', 'ber'],
    ['bep', 'bep'],
    ['odf', 'odf'],
    ['fac', 'fac', { type: 'date' }],
    ['vt', 'vt'],
  ]),
};
