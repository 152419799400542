// Slice

import transmissionSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import transmissionService from '../../../../../services/api/transmission';

// Entity

// import { mapper as transmissionMapper } from '../../../../../entity/transmission';

// ----------------

export default function* importSaga(action: ReturnType<typeof transmissionSlice.actions.import$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.file],
    successMess: action.meta.message,
    service: transmissionService.import,
    context: transmissionService,
    onSuccess: function* () {
      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
  });
}
