import { put } from 'redux-saga/effects';

// Redux

import txNotesSlice from '../../index';
import snackbar from '../../../snackbar';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* deleteTXNoteSaga(action: ReturnType<typeof txNotesSlice.actions.deleteTXNote$Req>) {
  yield put(txNotesSlice.actions.deleteFromList(action.payload.id));

  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.id],
    service: txService.deleteTXNote,
    context: txService,
    onSuccess: function* () {
      yield put(snackbar.actions.show('success', 'Note successfully deleted'));
    },
  });
}
