import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { UserDTO, userMapper } from '../../entity/user';

// ----------------

class UserAPIService extends HTTP {
  update(data: Partial<UserDTO>) {
    return this.request<UserDTO>({
      method: 'PATCH',
      data,
      url: api.user.me,
    }).then((data) => {
      if (data) {
        return userMapper.toDO(data);
      }
    });
  }
}

export const userAPIService = new UserAPIService(`${env.API}api/`);
