// Redux

import requestSaga from '../../../../baseSaga/request';
import userSlice from '../../index';

// Services

import userService from '../../../../../services/api/user';

// ----------------

export default function* resetPasswordSaga(action: ReturnType<typeof userSlice.actions.resetPassword$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.data],
    service: userService.resetPassword,
    context: userService,
    onSuccess: () => {
      action.meta.onSuccess();
    },
    onError: (err) => {
      if (err?.response?.status === 400) {
        action.meta.onError();
      }
    },
  });
}
