import { all, takeEvery } from 'redux-saga/effects';

// Slice

import slice from '../index';

// Saga

import deleteAllSaga from './deleteAllSaga/deleteAllSaga';
import getListSaga from './getListSaga/getListSaga';
import updateSaga from './updateSaga/updateSaga';
import deleteSaga from './deleteSaga/deleteSaga';
import seeAllSaga from './seeAllSaga/seeAllSaga';

// ----------------

export default function* () {
  yield all([takeEvery(slice.actions.deleteAll$Req.type, deleteAllSaga)]);
  yield all([takeEvery(slice.actions.getList$Req.type, getListSaga)]);
  yield all([takeEvery(slice.actions.seeAll$Req.type, seeAllSaga)]);
  yield all([takeEvery(slice.actions.update$Req.type, updateSaga)]);
  yield all([takeEvery(slice.actions.delete$Req.type, deleteSaga)]);
}
