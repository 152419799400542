import { put } from 'redux-saga/effects';

// Redux

import txSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* exportTXListSaga(action: ReturnType<typeof txSlice.actions.exportTXList$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    successMess: 'The export file has been sent to your email.',
    service: txService.exportTXList,
    context: txService,
  });
}
