import initialState, { ITXFilesBranch } from './branch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';
import AC from './actionCreators';

// Saga

import txFilesSaga from './saga';

// Types

import { TX } from '../../../types';

// ----------------

const txFilesSlice = createSlice({
  name: `${reduxActionTypePrefix}/txFilesSlice`,
  initialState,

  // -------- Reducers --------

  reducers: {
    setList: {
      prepare: (list: TX.Base.ITXFile[], totalPages: number) => ({
        payload: {
          totalPages,
          list,
        },
      }),

      reducer: (state, action: PayloadAction<{ list: TX.Base.ITXFile[]; totalPages: number }>) => {
        state.totalPages = action.payload.totalPages;
        state.list = action.payload.list;
      },
    },

    // --------

    addToList: {
      prepare: (file: TX.Base.ITXFile) => ({
        payload: {
          file,
        },
      }),

      reducer: (state, action: PayloadAction<{ file: TX.Base.ITXFile }>) => {
        state.list.unshift(action.payload.file);
      },
    },

    // --------

    deleteFromList: {
      prepare: (id: number) => ({
        payload: {
          id,
        },
      }),

      reducer: (state, action: PayloadAction<{ id: number }>) => {
        state.list = state.list.filter((item) => item.id !== action.payload.id);
      },
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      return initialState;
    });
  },
});

export { initialState, txFilesSaga };
export type { ITXFilesBranch };
export default { ...txFilesSlice, actions: { ...txFilesSlice.actions, ...AC } };
