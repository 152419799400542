// Slice

import transmissionSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import transmissionService from '../../../../../services/api/transmission';

// ----------------

export default function* exportSaga(action: ReturnType<typeof transmissionSlice.actions.export$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    successMess: action.meta.message || 'The exported file has been sent to your mail',
    serviceArgs: [action.payload.data],
    service: transmissionService.export,
    context: transmissionService,
    onSuccess: function* (data) {
      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
    onError: function (err) {
      if (action.meta.onError) {
        action.meta.onError();
      }
    },
  });
}
