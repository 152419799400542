// Slice

import slice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import graphService from '../../../../../services/api/graph';

// Entity

import { IGraphDO } from '../../../../../entity/graph/DO';
import graphMapper from '../../../../../entity/graph/mapper';

// Types

import { DataKeys } from '../../branch';

// ----------------

export default function* getSnapshotSaga(action: ReturnType<typeof slice.actions.getSnapshot$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.query],
    service: graphService.getSnapshot,
    context: graphService,
    timeout: 300,
    onSuccess: (data) => {
      const dataDO: Partial<Record<DataKeys, IGraphDO | null>> = {
        technologiesDistribution: null,
        blueMoonFiberization: null,
        fiberization: null,
        futureRemain: null,
        cartography: null,
      };

      data.forEach((item) => {
        switch (item.graph_name) {
          case 'technologies-distribution-rate': {
            dataDO.technologiesDistribution = graphMapper.toDO(item.snapshot);
            break;
          }

          case 'fiberization-rate': {
            dataDO.fiberization = graphMapper.toDO(item.snapshot);
            break;
          }

          case 'blue-moon-fiberization-rate': {
            dataDO.blueMoonFiberization = graphMapper.toDO(item.snapshot);
            break;
          }

          case 'future-remain': {
            dataDO.futureRemain = graphMapper.toDO(item.snapshot);
            break;
          }

          case 'cartography': {
            dataDO.cartography = item.snapshot;
            break;
          }
        }
      });

      if (action.meta.onSuccess) {
        action.meta.onSuccess(dataDO);
      }
    },
  });
}
