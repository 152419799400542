import { BaseDTO, DateTime, Date } from '../base';
import { CartographyCommon } from './';
import { TXDTO } from '../tx';

// ----------------

export namespace CartographyDTO {
  export type Change = {
    created_at: DateTime;
    updated_at: DateTime;
    element: Element;
    status: CartographyCommon.ChangeStatus;
    type: CartographyCommon.ChangeType;
    id: number;
  };

  export type ChangePaginatedList = BaseDTO.PaginatedList<CartographyDTO.Change>;

  // --------

  export type Element = {
    transmission: TXDTO.$ | null;
    comparing: Comparing;
    site_id: string;
    changes: Record<keyof TXDTO.$, [any, any]>;
    id: number;
  };

  // --------

  export type Comparing = {
    report_previous: Report;
    report_next: Report;
    id: number;
  };

  export type ComparingPaginatedList = BaseDTO.PaginatedList<CartographyDTO.Comparing>;

  // --------

  export type Report = {
    period_end: Date;
    id: number;
  };
}
