import {middlewareData, topLevelRoutes} from '../../../';
import jwtDecode from 'jwt-decode';

// Config
import {localStorageKeys, redirectRoutes, globalObj} from '../../../../config/app';

// Utils
import hasPermissions from '../../../../utils/hasPermissions/hasPermissions';

// Types
import {Router as Router5, State} from 'router5';
import {Router} from '../../../../types';

// Define the expected structure of the decoded JWT
interface DecodedToken {
    exp: number; // Assuming exp is a Unix timestamp in seconds
    // Add other fields that you expect in the token
}

/**
 * Checks if the user has access to a specific route.
 *
 * @param access    - Access config object
 * @param router    - router5 instance
 * @param toState   - Next state of router
 * @param fromState - Previous state of router
 * @param done      - This function can allow or deny access to the route
 */
export default function accessActivator(access: Router.IRouteAccess, router: Router5, toState: State, fromState: State, done: Router.DoneFN): void {
    (async () => {

        if (access.auth !== undefined) {
            let tokenIsValid = true;
            let token = localStorage.getItem(localStorageKeys.token);
            let mfaValidated = localStorage.getItem(localStorageKeys.otpValidated) === 'true';

            if (!token) {
                tokenIsValid = false;
            } else {
                try {
                    const jwt = jwtDecode<DecodedToken>(token);

                    if (new Date(jwt.exp * 1000) <= new Date()) {
                        console.log('accessActivator - Token expired');
                        tokenIsValid = false;
                    }
                } catch (err) {
                    tokenIsValid = false;
                    console.log('accessActivator - Not valid token');
                }
            }

            if (!tokenIsValid) {
                // Si le token n'est pas valide, rediriger vers la page de connexion
                if (toState.name !== 'auth.login') {
                    done({ redirect: { name: redirectRoutes.defaultForNonAuth } });
                    return;
                }
            } else if (tokenIsValid && !mfaValidated) {
                // Si le token est valide mais le MFA n'est pas validé, rediriger vers la page MFA
                if (toState.name !== 'auth.mfa') {
                    done({ redirect: { name: 'auth.mfa' } });
                    return;
                }
            } else if (tokenIsValid && mfaValidated) {
                // Si le token est valide et le MFA est validé, interdire l'accès aux pages de connexion et MFA
                if (toState.name === 'auth.login' || toState.name === 'auth.mfa') {
                    done({ redirect: { name: redirectRoutes.defaultForAuth } });
                    return;
                }
            }
        }

        if (access.reqQuery && access.reqQuery.length) {
            const {params} = toState;

            const hasAccess = access.reqQuery.every((query) => params[query]);

            if (!hasAccess) {
                done({redirect: {name: 'auth.login'}});
                return;
            }
        }

        if (access.permissions || access.test) {
            let hasAccess: boolean = true;

            if (access.permissions) {
                hasAccess = hasPermissions(access.permissions);
            }

            if (hasAccess && access.test) {
                hasAccess = access.test(globalObj.reduxStore!, router, toState, fromState);
            }

            if (!hasAccess) {
                let availableRoute;

                if (access.forwardTo && access.forwardTo.length) {
                    availableRoute = access.forwardTo.find((route) => {
                        let isAvailable = hasPermissions(middlewareData[route].access?.permissions);

                        if (isAvailable && middlewareData[route].access?.test) {
                            // @ts-ignore
                            isAvailable = middlewareData[route].access?.test(globalObj.reduxStore!, router, toState, fromState)!;
                        }

                        return isAvailable;
                    });
                } else {
                    availableRoute = topLevelRoutes.find((route) => {
                        let isAvailable = hasPermissions(middlewareData[route].access?.permissions);

                        if (isAvailable && middlewareData[route].access?.test) {
                            // @ts-ignore
                            isAvailable = middlewareData[route].access?.test(globalObj.reduxStore!, router, toState, fromState)!;
                        }

                        return isAvailable;
                    });
                }

                if (availableRoute) {
                    done({redirect: {name: availableRoute, params: toState.params}});
                } else {
                    done('access');
                }
                return;
            }
        }

        if (access.beforeEnter) {
            await new Promise((res) => {
                access.beforeEnter!(globalObj.reduxStore!, router, toState, fromState, done, res);
            });
        }

        done();
    })();
}
