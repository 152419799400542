import { FiltersQueriesConfig } from '../../../../../../services/queryService';
import { Values } from './';

// ----------------

export const filtersMapper = {
  toFiltersQueriesConfig<QueryName extends string = string>(values: Partial<Values>): FiltersQueriesConfig<QueryName>[] {
    const queriesConfig: FiltersQueriesConfig<QueryName>[] = [];

    Object.keys(values).forEach((key) => {
      if (Array.isArray(values[key])) {
        if (!values[key].length) {
          return;
        }
      } else if (!values[key]) {
        return;
      }

      queriesConfig.push({ name: key as QueryName, value: values[key], type: 'simple' });
    });

    return queriesConfig;
  },
};
