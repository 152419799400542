import { documentActionCreators } from './documentActionCreators';
import { documentSlice as slice } from './documentSlice';

const documentSlice = {
  ...slice,
  actions: { ...slice.actions, ...documentActionCreators },
};

export { documentSlice };
export * from './documentBranch';
export * from './saga';
