import React from 'react';

// Components

import MUITextField, { TextFieldProps } from '@mui/material/TextField';

// ----------------

const TextField: React.FC<TextFieldProps> = (props) => {
  return (
    <MUITextField
      autoComplete="off"
      placeholder={props.multiline ? 'Enter text' : 'Enter value'}
      variant="outlined"
      minRows={4}
      maxRows={8}
      label="Label"
      size="medium"
      fullWidth
      {...props}
      sx={{
        '& .MuiFormHelperText-contained': {
          whiteSpace: 'nowrap',
          position: 'absolute',
          bottom: -20,
          right: 5,
          mr: '0px',
        },

        '& .MuiOutlinedInput-root': {
          borderRadius: '6px',
        },

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#7a7a7a',
        },

        ...props.sx,
      }}
    />
  );
};

export default TextField;
