import { BaseDO, DateTime, Date } from '../base';
import { CartographyCommon } from './';
import { TXDO } from '../tx';

// ----------------

export namespace CartographyDO {
  export type Change = {
    created: DateTime;
    updated: DateTime;
    element: Element;
    status: CartographyCommon.ChangeStatus;
    type: CartographyCommon.ChangeType;
    id: number;
  };

  export type ChangePaginatedList = BaseDO.PaginatedList<CartographyDO.Change>;

  // --------

  export type Element = {
    comparing: Comparing;
    changes: Record<keyof TXDO.$, [any, any]>;
    siteId: string;
    tx: TXDO.$ | null;
    id: number;
  };

  // --------

  export type Comparing = {
    previousReport: Report;
    nextReport: Report;
    id: number;
  };

  export type ComparingPaginatedList = BaseDO.PaginatedList<CartographyDO.Comparing>;

  // --------

  export type Report = {
    periodEnd: Date;
    id: number;
  };
}
