import { LeasedLineDTO, LeasedLineDO } from './';
import { createKeysMaps } from '../../mappers/createKeysMaps';
import { createDDMapper } from '../../mappers/createDDMapper';
import { tdMapper } from '../../mappers/tdMapper';

// ----------------

export const leasedLineMapper = {
  toDO(data: LeasedLineDTO.$): LeasedLineDO.$ {
    const ddMapper = createDDMapper(data);

    // prettier-ignore
    return {
      siteReadyForInstallationTarget: data.site_ready_for_installation_target,
      siteReadyForInstallation      : ddMapper.date('site_ready_for_installation'),
      lineTerminationCompleted      : ddMapper.date('line_termination_completed'),
      lineTerminationPlanned        : ddMapper.date('line_termination_planned'),
      authorizationReceived         : ddMapper.date('authorization_received'),
      fiberWorksCompleted           : ddMapper.date('fiber_works_completed'),
      targetAccessProfile           : ddMapper.glossaryId('target_access_profile'),
      activeAccessProfile           : ddMapper.glossaryId('active_access_profile'),
      finalTestCompleted            : ddMapper.date('final_test_completed'),
      trenchingCompleted            : ddMapper.date('trenching_completed'),
      activeAccessSpeed             : ddMapper.glossaryId('active_access_speed'),
      fiberWorksPlanned             : ddMapper.date('fiber_works_planned'),
      targetAccessSpeed             : ddMapper.glossaryId('target_access_speed'),
      deliveryForecast              : ddMapper.date('delivery_forecast'),
      provisioningType              : ddMapper.enum('provisioning_type'),
      trenchingPlanned              : ddMapper.date('trenching_planned'),
      readyForBilling               : ddMapper.date('ready_for_billing'),
      omsActionStatus               : ddMapper.enum('oms_action_status'),
      activeAccessCPE               : ddMapper.glossaryId('active_access_cpe'),
      targetAccessCPE               : ddMapper.glossaryId('target_access_cpe'),
      npsAppointment                : ddMapper.date('nps_appointment'),
      pmRFBForecast                 : ddMapper.date('pm_rfb_forecast'),
      omsActionType                 : ddMapper.enum('oms_action_type'),
      siteScenario                  : ddMapper.enum('site_scenario'),
      ssvCompleted                  : ddMapper.date('ssv_completed'),
      pmExtraCost                   : ddMapper.number('pm_extra_cost'),
      projectName                   : ddMapper.glossaryId('project_name'),
      statusList                    : ddMapper.string('status_list'),
      ssvPlanned                    : ddMapper.date('ssv_planned'),
      pmRemarks                     : ddMapper.string('pm_remarks'),
      siteReady                     : ddMapper.date('site_ready'),
      omsStatus                     : ddMapper.enum('oms_status'),
      pmProject                     : ddMapper.enum('pm_project'),
      siteName                      : ddMapper.string('site_name'),
      omsOrder                      : ddMapper.number('oms_order'),
      pmStatus                      : ddMapper.enum('pm_status'),
      dsid                          : ddMapper.number('dsid'),
      tx                            : ddMapper.id('transmission'),
    };
  },

  // --------

  toDTO(data: Partial<LeasedLineDO.$>): Partial<LeasedLineDTO.$> {
    // prettier-ignore
    return {
      site_ready_for_installation_target: data.siteReadyForInstallationTarget,
      site_ready_for_installation       : data.siteReadyForInstallation,
      line_termination_completed        : data.lineTerminationCompleted,
      line_termination_planned          : data.lineTerminationPlanned,
      authorization_received            : data.authorizationReceived,
      fiber_works_completed             : data.fiberWorksCompleted,
      target_access_profile             : tdMapper.glossaryId(data.targetAccessProfile),
      active_access_profile             : tdMapper.glossaryId(data.activeAccessProfile),
      final_test_completed              : data.finalTestCompleted,
      trenching_completed               : data.trenchingCompleted,
      active_access_speed               : tdMapper.glossaryId(data.activeAccessSpeed),
      fiber_works_planned               : data.fiberWorksPlanned,
      target_access_speed               : tdMapper.glossaryId(data.targetAccessSpeed),
      delivery_forecast                 : data.deliveryForecast,
      provisioning_type                 : tdMapper.enum(data.provisioningType),
      trenching_planned                 : data.trenchingPlanned,
      ready_for_billing                 : data.readyForBilling,
      oms_action_status                 : tdMapper.enum(data.omsActionStatus),
      active_access_cpe                 : tdMapper.glossaryId(data.activeAccessCPE),
      target_access_cpe                 : tdMapper.glossaryId(data.targetAccessCPE),
      nps_appointment                   : data.npsAppointment,
      pm_rfb_forecast                   : data.pmRFBForecast,
      oms_action_type                   : tdMapper.enum(data.omsActionType),
      site_scenario                     : tdMapper.enum(data.siteScenario),
      ssv_completed                     : data.ssvCompleted,
      pm_extra_cost                     : tdMapper.number(data.pmExtraCost),
      transmission                      : tdMapper.id(data.tx),
      project_name                      : tdMapper.glossaryId(data.projectName),
      ssv_planned                       : data.ssvPlanned,
      status_list                       : tdMapper.string(data.statusList),
      pm_remarks                        : tdMapper.string(data.pmRemarks),
      site_ready                        : data.siteReady,
      oms_status                        : tdMapper.enum(data.omsStatus),
      pm_project                        : tdMapper.enum(data.pmProject),
      site_name                         : tdMapper.string(data.siteName),
      oms_order                         : tdMapper.number(data.omsOrder),
      pm_status                         : tdMapper.enum(data.pmStatus),
      dsid                              : tdMapper.number(data.dsid),
    };
  },

  // --------

  // prettier-ignore
  ...createKeysMaps<LeasedLineDTO.$, LeasedLineDO.$>([
    ['site_ready_for_installation_target', 'siteReadyForInstallationTarget'],
    ['site_ready_for_installation'       , 'siteReadyForInstallation'],
    ['line_termination_completed'        , 'lineTerminationCompleted'],
    ['line_termination_planned'          , 'lineTerminationPlanned'],
    ['authorization_received'            , 'authorizationReceived'],
    ['fiber_works_completed'             , 'fiberWorksCompleted'],
    ['target_access_profile'             , 'targetAccessProfile'],
    ['active_access_profile'             , 'activeAccessProfile'],
    ['final_test_completed'              , 'finalTestCompleted'],
    ['trenching_completed'               , 'trenchingCompleted'],
    ['active_access_speed'               , 'activeAccessSpeed'],
    ['fiber_works_planned'               , 'fiberWorksPlanned'],
    ['target_access_speed'               , 'targetAccessSpeed'],
    ['delivery_forecast'                 , 'deliveryForecast'],
    ['provisioning_type'                 , 'provisioningType'],
    ['trenching_planned'                 , 'trenchingPlanned'],
    ['ready_for_billing'                 , 'readyForBilling'],
    ['oms_action_status'                 , 'omsActionStatus'],
    ['active_access_cpe'                 , 'activeAccessCPE'],
    ['target_access_cpe'                 , 'targetAccessCPE'],
    ['nps_appointment'                   , 'npsAppointment'],
    ['pm_rfb_forecast'                   , 'pmRFBForecast'],
    ['oms_action_type'                   , 'omsActionType'],
    ['site_scenario'                     , 'siteScenario'],
    ['ssv_completed'                     , 'ssvCompleted'],
    ['pm_extra_cost'                     , 'pmExtraCost'],
    ['transmission'                      , 'tx'],
    ['project_name'                      , 'projectName'],
    ['ssv_planned'                       , 'ssvPlanned'],
    ['status_list'                       , 'statusList'],
    ['pm_remarks'                        , 'pmRemarks'],
    ['site_ready'                        , 'siteReady'],
    ['oms_status'                        , 'omsStatus'],
    ['pm_project'                        , 'pmProject'],
    ['site_name'                         , 'siteName'],
    ['oms_order'                         , 'omsOrder'],
    ['pm_status'                         , 'pmStatus'],
    ['dsid'                              , 'dsid'],
  ]),
};
