import { TXDO } from '../../../../entity/tx';

// -------- Types --------

type Labels = Omit<
  Record<keyof TXDO.$, string>,
  | 'leasedLineData'
  | 'microwaveData'
  | 'updateDate'
  | 'isDeleted'
  | 'deletedAt'
  | 'fiberData'
  | 'deletedBy'
  | 'blueMoon'
  | 'category'
  | 'target'
  | 'id'
>;

// ----------------

// prettier-ignore
const labels: Labels = {
  isProductionPlanReadinessLocked: 'Locked',
  productionYearEstimation: 'Production planned',
  productionPlanReadiness: 'Production plan readiness',
  deliveryForecastPublic: 'Delivery Forecast',
  mwingzCodeFromCarto: 'Mwingz site swapped',
  familyMpProjected: 'Family MP projected',
  familyBmProjected: 'Family BM projected',
  overallReadiness : 'Overall readiness',
  consoYearMwingz  : 'Conso year mwingz',
  familyBmLegacy   : 'Family BM legacy',
  radioSiteType    : 'Site type',
  clusterMwingz    : 'Cluster mwingz',
  currentTechno    : 'Current techno',
  doubleStream     : 'Double stream',
  targetTechno     : 'Target techno',
  radioStatus      : 'Radio status',
  isArchived       : 'Archived',
  tempTechno       : 'Temp techno',
  mwingzCode       : 'Mwingz code',
  familyTemp       : 'Family temp',
  longitude        : 'X coord',
  microwave        : 'MW HUB/POP',
  originals        : 'Originals',
  bmStatus         : 'BM status',
  latitude         : 'Y coord',
  isDraft          : 'Draft',
  address          : 'Address',
  siteId           : 'USI',
  status           : 'TX status',
  family           : 'Family',
  region           : "Region",
  city             : 'City',
  zip              : 'Zip',

  candidate2g      : '2G Candidate',
  candidate3g      : '3G Candidate',
  candidate4g      : '4G Candidate',
  candidate5g      : '5G Candidate',

  site2g           : '2G Code',
  site3g           : '3G Code',
  site4g           : '4G Code',
  site5g           : '5G Code',

  decommRequestDate: 'DECOMM request date',
  decommActualDate : 'DECOMM actual date',

  swapForecastDate : 'SWAP forecast date',
  swapActualDate : 'SWAP actual date',
  consolidationDate : 'Consolidation date',
  physicalAcceptanceDate : 'Physical acceptance date',
  nocFlmTransferDate : 'NOC FLM transfer date',
};

const tableSelectedRowActionConfirms: Record<TXDO.TableSelectedRowAction, string> = {
  productionPlanReadinessUnlock: 'Unlock these records',
  productionPlanReadinessLock: 'Lock these records',
};

const txEN = {
  tableSelectedRowActionConfirms,
  labels,
};

export default txEN;
