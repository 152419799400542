// Slice

import transmissionSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import transmissionService from '../../../../../services/api/transmission';

// Entity

import { mapper as transmissionMapper } from '../../../../../entity/transmission';

// ----------------

export default function* getClearListSaga(action: ReturnType<typeof transmissionSlice.actions.getClearList$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    successMess: action.meta.message,
    serviceArgs: [action.payload.query],
    service: transmissionService.getList,
    context: transmissionService,
    onSuccess: function* (data) {
      const results = data.results.map((data) => transmissionMapper.toDO(data));

      if (action.meta.onSuccess) {
        action.meta.onSuccess(results);
      }
    },
    onError: function (err) {
      if (action.meta.onError) {
        action.meta.onError();
      }
    },
  });
}
