import { ChartDTO, ChartDO } from './';
import { createDDMapper } from '../../mappers/createDDMapper';

// ----------------

export const chartMapper = {
  txBaselineToDO(data: ChartDTO.TXBaseline): ChartDO.TXBaseline {

    const ddMapperBaseLine = createDDMapper(data.base_line);

    const monthNumbers: Record<ChartDTO.TXBaselineMonthName, number> = {
      september: 8,
      february: 1,
      december: 11,
      november: 10,
      october: 9,
      january: 0,
      august: 7,
      march: 2,
      april: 3,
      june: 5,
      july: 6,
      may: 4,
    };

    return {
      baseLine: {
        created: ddMapperBaseLine.dateTime('created_at'),
        updated: ddMapperBaseLine.dateTime('updated_at'),
        months: Object.keys(data.base_line.months).map((key) => ({ value: data.base_line.months[key], month: monthNumbers[key] })),
        year: ddMapperBaseLine.number('year'),
        type: data.base_line.type,
        id: ddMapperBaseLine.id('id'),
      },
      actual: data.data,
    };
  },


  txActualsToDO(data: ChartDTO.TXActuals): ChartDO.TXActuals {

    const monthNumbers: Record<ChartDTO.TXBaselineMonthName, number> = {
      september: 8,
      february: 1,
      december: 11,
      november: 10,
      october: 9,
      january: 0,
      august: 7,
      march: 2,
      april: 3,
      june: 5,
      july: 6,
      may: 4,
    };

    return {
      actual: data.data,
    };
  },

  // --------

  fiberRingToDO(data: ChartDTO.FiberRing): ChartDO.FiberRing {
    return data.map((item) => ({
      siteCodeB: item.site_code_b,
      siteCodeA: item.site_code_a,
      fiberName: item.fiber_name,
      ringName: item.ring_name,
      importId: item.import_id,
      created: item.created_at,
      updated: item.updated_at,
      target: item.target,
      parent: item.parent,
      status: item.status,
      id: item.id,
    }));
  },

  // --------

  technologiesDistributionHistoryToDO(data: ChartDTO.TechnologiesDistributionHistory): ChartDO.TechnologiesDistributionHistory {
    return data.map((item) => ({
      pieces: item.pieces,
      total: item.total,
      date: item.date,
    }));
  },

  // --------

  ranSharingBackhaulEvolutionToDO(data: ChartDTO.RanSharingBackhaulEvolution): ChartDO.RanSharingBackhaulEvolution {
    return {
      '2023': data.fct_eoy_2023,
      '2024': data.fct_eoy_2024,
      '2025': data.fct_eoy_2025,
      '2026': data.fct_eoy_2026,
      actual: data.actual,
    };
  },
};
