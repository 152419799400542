import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { timelineSlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';

// ----------------

export function* timelineSaga() {
  yield all([takeEvery([timelineSlice.actions.getPaginatedList$Req.type], requestActionSaga)]);
}
