import { all, takeEvery } from 'redux-saga/effects';

// Slice

import { coreSlice } from '../index';

// Saga

import { requestActionSaga } from '../../../baseSaga/requestActionSaga';

// ----------------

export function* coreSaga() {
  yield all([takeEvery([coreSlice.actions.getEntityTypeList$Req.type], requestActionSaga)]);
}
