import { TDTypeError } from '../errors/TDTypeError';
import { DD, TD } from '../types';
import { check } from '../services/typeCheckService';
import { app } from '../config';
import dayjs from 'dayjs';

// ----------------

export function createDDMapper<T extends Object>(object: T) {
  const ddMapper = {
    string(key: keyof T): DD.String {
      let ddValue: DD.String = '';
      const tdValue = object[key] as TD.String;
      const { isValid, type } = check.string(tdValue);

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'String', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    stringList(key: keyof T): DD.StringList {
      let ddValue: DD.StringList = [];
      const tdValue = object[key] as TD.StringList;
      const { isValid, type } = check.array<string>(tdValue, 'string');

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'StringList', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    number(key: keyof T): DD.Number {
      let ddValue: DD.Number = null;
      const tdValue = object[key] as TD.Number;
      const { isValid, type } = check.numberOrNull(tdValue);

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'Number', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    numberList(key: keyof T): DD.NumberList {
      let ddValue: DD.NumberList = [];
      const tdValue = object[key] as TD.NumberList;
      const { isValid, type } = check.array<number>(tdValue, 'number');

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'NumberList', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    boolean(key: keyof T): DD.Boolean {
      let ddValue: DD.Boolean = false;
      const tdValue = object[key] as TD.Boolean;
      const { isValid, type } = check.boolean(tdValue);

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'Boolean', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    booleanList(key: keyof T): DD.BooleanList {
      let ddValue: DD.BooleanList = [];
      const tdValue = object[key] as TD.BooleanList;
      const { isValid, type } = check.array<boolean>(tdValue, 'boolean');

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'BooleanList', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    glossaryId(key: keyof T): DD.GlossaryId {
      let ddValue: DD.GlossaryId = null;
      const tdValue = object[key] as TD.GlossaryId;
      const { isValid, type } = check.numberOrNull(tdValue);

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'GlossaryId', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    glossaryIdList(key: keyof T): DD.GlossaryIdList {
      let ddValue: DD.GlossaryIdList = [];
      const tdValue = object[key] as TD.GlossaryIdList;
      const { isValid, type } = check.array<number>(tdValue, 'number');

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'GlossaryIdList', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    date(key: keyof T): DD.Date {
      let ddValue: DD.Date = null;
      const tdValue = object[key] as TD.Date;
      const { isValid, type } = check.stringOrNull(tdValue);

      if (isValid) {
        if (tdValue !== null) {
          try {
            ddValue = dayjs(tdValue).format(app.ISODateFormat);
          } catch (err) {
            console.error(err);
          }
        }
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'Date', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    dateList(key: keyof T): DD.DateList {
      let ddValue: DD.DateList = [];
      const tdValue = object[key] as TD.DateList;
      const { isValid, type } = check.array<string>(tdValue, 'string');

      if (isValid) {
        try {
          ddValue = tdValue.map((item) => dayjs(item).format(app.ISODateFormat));
        } catch (err) {
          console.error(err);
        }
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'DateList', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    time(key: keyof T): DD.Time {
      let ddValue: DD.Time = null;
      const tdValue = object[key] as TD.Time;
      const { isValid, type } = check.stringOrNull(tdValue);

      if (isValid) {
        if (tdValue !== null) {
          try {
            ddValue = dayjs(tdValue).format(app.ISOTimeFormat);
          } catch (err) {
            console.error(err);
          }
        }
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'Time', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    timeList(key: keyof T): DD.TimeList {
      let ddValue: DD.TimeList = [];
      const tdValue = object[key] as TD.TimeList;
      const { isValid, type } = check.array<string>(tdValue, 'string');

      if (isValid) {
        try {
          ddValue = tdValue.map((item) => dayjs(item).format(app.ISOTimeFormat));
        } catch (err) {
          console.error(err);
        }
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'TimeList', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    dateTime(key: keyof T): DD.DateTime {
      let ddValue: DD.DateTime = null;
      const tdValue = object[key] as TD.DateTime;
      const { isValid, type } = check.stringOrNull(tdValue);

      if (isValid) {
        if (tdValue !== null) {
          try {
            ddValue = dayjs(tdValue).format(app.ISODateTimeFormat);
          } catch (err) {
            console.error(err);
          }
        }
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'DateTime', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    dateTimeList(key: keyof T): DD.DateTimeList {
      let ddValue: DD.DateTimeList = [];
      const tdValue = object[key] as TD.DateTimeList;
      const { isValid, type } = check.array<string>(tdValue, 'string');

      if (isValid) {
        try {
          ddValue = tdValue.map((item) => dayjs(item).format(app.ISODateTimeFormat));
        } catch (err) {
          console.error(err);
        }
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'DateTimeList', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    id(key: keyof T): DD.Id {
      let ddValue: DD.Id = null;
      const tdValue = object[key] as TD.Id;
      const { isValid, type } = check.numberOrNull(tdValue);

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'Id', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    idList(key: keyof T): DD.IdList {
      let ddValue: DD.IdList = [];
      const tdValue = object[key] as TD.IdList;
      const { isValid, type } = check.array<number>(tdValue, 'number');

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'IdList', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    uuId(key: keyof T): DD.UUId {
      let ddValue: DD.UUId = null;
      const tdValue = object[key] as TD.UUId;
      const { isValid, type } = check.stringOrNull(tdValue);

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'UUId', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    uuIdList(key: keyof T): DD.UUIdList {
      let ddValue: DD.UUIdList = [];
      const tdValue = object[key] as TD.UUIdList;
      const { isValid, type } = check.array<string>(tdValue, 'string');

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'UUIdList', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    url(key: keyof T): DD.URL {
      let ddValue: DD.URL = null;
      const tdValue = object[key] as TD.URL;
      const { isValid, type } = check.stringOrNull(tdValue);

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'URL', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    urlList(key: keyof T): DD.URLList {
      let ddValue: DD.URLList = [];
      const tdValue = object[key] as TD.URLList;
      const { isValid, type } = check.array<string>(tdValue, 'string');

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'URLList', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    enum(key: keyof T): DD.Enum {
      let ddValue: DD.Enum = null;
      const tdValue = object[key] as TD.Enum;
      const { isValid, type } = check.stringOrNull(tdValue);

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'Enum', receivedType: type }));
      }

      return ddValue;
    },

    // --------

    enumList(key: keyof T): DD.EnumList {
      let ddValue: DD.EnumList = [];
      const tdValue = object[key] as TD.EnumList;
      const { isValid, type } = check.array<string>(tdValue, 'string');

      if (isValid) {
        ddValue = tdValue;
      } else {
        console.warn(new TDTypeError({ field: String(key), expectedTDType: 'EnumList', receivedType: type }));
      }

      return ddValue;
    },
  };

  return ddMapper;
}
