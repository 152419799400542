import { blueMoonActionCreators } from './blueMoonActionCreators';
import { blueMoonSlice as slice } from './blueMoonSlice';

const blueMoonSlice = {
  ...slice,
  actions: { ...slice.actions, ...blueMoonActionCreators },
};

export { blueMoonSlice };
export * from './blueMoonBranch';
export * from './saga';
