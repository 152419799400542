// Activators

import accessActivator from '../../activators/access';

// Types

import { Router as Router5, Route } from 'router5';
import { Router } from '../../../../types';

/**
 * Add routes and sets other parameters to the router5 object.
 *
 * @param routes - Array of routes
 * @param router - Router5 object
 *
 * @returns Additional data for the middlewares
 */

export default function initRouter(routes: Router.IRoute[], router: Router5): Router.IMiddlewareData {
  const middlewaresData = {} as Router.IMiddlewareData;

  const init = (routesPiece: Router.IRoute[], parentName: string = '') => {
    routesPiece.forEach((route) => {
      const route5Config = {} as Route;

      const name = parentName ? `${parentName}.${route.name}` : route.name;

      route5Config.name = name;
      route5Config.path = route.path;

      if (route.defParams) {
        route5Config.defaultParams = route.defParams;
      }

      if (route.forwardTo) {
        route5Config.forwardTo = route.forwardTo;
      }

      if (route.access) {
        router.canActivate(name, (router) => (toState, fromState, done) => {
          accessActivator(route.access!, router, toState, fromState, done);
        });
      }

      if (route.canDeactivate) {
        router.canDeactivate(name, (router) => (toState, fromState, done) => {
          route.canDeactivate!(router, toState, fromState, done);
        });
      }

      router.add(route5Config);

      middlewaresData[name] = {
        onEnterOrChangeQuery: route.onEnterOrChangeQuery,
        onChangeQuery: route.onChangeQuery,
        branches: route.branches,
        onEnter: route.onEnter,
        access: route.access,
        props: route.props,
      };

      if (route.children && route.children.length) {
        init(route.children, name);
      }
    });
  };

  init(routes);

  return middlewaresData;
}
