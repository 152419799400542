import { TXDO } from '../../../entity/tx';

// -------- Types --------

export type TXBranch = {
  list: TXDO.$[];
  item: TXDO.$;
  meta: {
    candidateCount: number;
    totalCount: number;
  };
};

// -------- Initial state of branch --------

export const txBranchInitialState: TXBranch = {
  list: [],
  item: {} as TXDO.$,
  meta: {
    candidateCount: 0,
    totalCount: 0,
  },
};
