import { ThemeOptions } from '@mui/material/styles';

// ----------------

const components: ThemeOptions['components'] = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        lineHeight: 1,

        '& .dateRangePickerPopper .MuiPaper-root': {
          transformOrigin: '0 0 0',
          marginTop: '12px',
        },
      },
    },
  },

  MuiPaper: {
    styleOverrides: {
      rounded: {
        borderRadius: '6px',
      },
      elevation1: {
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.08)',
      },
      elevation2: {
        boxShadow: '0 0 10px 0 rgba(0,0,0,0.1)',
      },
      elevation3: {
        boxShadow: '0 0 12px 0 rgba(0,0,0,0.1)',
      },
      elevation4: {
        boxShadow: '0 0 12px 0 rgba(0,0,0,0.12)',
      },
      elevation5: {
        boxShadow: '0 0 14px 0 rgba(0,0,0,0.12)',
      },
      elevation6: {
        boxShadow: '0 0 14px 0 rgba(0,0,0,0.14)',
      },
      elevation7: {
        boxShadow: '0 0 16px 0 rgba(0,0,0,0.14)',
      },
      elevation8: {
        boxShadow: '0 0 16px 0 rgba(0,0,0,0.16)',
      },
      elevation9: {
        boxShadow: '0 0 18px 0 rgba(0,0,0,0.16)',
      },
      elevation10: {
        boxShadow: '0 0 18px 0 rgba(0,0,0,0.28)',
      },
    },
  },

  MuiIconButton: {
    styleOverrides: {
      root: {
        color: '#969aab',
      },
    },
  },

  MuiChip: {
    styleOverrides: {
      label: {
        fontWeight: 500,
      },

      labelSmall: {
        marginTop: '1px',
      },
    },
  },

  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgb(173 173 173 / 11%);',
        '&::after': {
          animationDuration: [['1s'], '!important'],
          animationDelay: [['0.2s'], '!important'],
        },
      },
    },
  },

  MuiTableSortLabel: {
    styleOverrides: {
      root: {
        alignItems: 'flex-start',
      },
    },
  },

  MuiButton: {
    styleOverrides: {
      sizeSmall: { padding: '8px 10px' },
      sizeMedium: { padding: '11px 16px' },
      sizeLarge: { padding: '0 20px', height: 42 },
    },
  },
};

export default components;
