import { ITransmissionDO } from '../../../entity/transmission/DO';

// -------- Types --------

export interface ITransmissionBranch {
  totalPages: number;
  totalItems: number;
  data: ITransmissionDO;
  list: ITransmissionDO[];
}

// -------- Initial state of branch --------

const transmissionBranchInitialState: ITransmissionBranch = {
  totalPages: 0,
  totalItems: 0,
  data: {} as ITransmissionDO,
  list: [],
};

export default transmissionBranchInitialState;
