import { User } from '../../../types';

// -------- Types --------

export interface IUserBranch {
  data: User.Base.IUser;
  isOtpConfigured: boolean;
  otpAuthUrl: string | null;
  loading:boolean;
  otpError:string;
}

// -------- Initial state of branch --------

const userBranchInitialState: IUserBranch = {
  // prettier-ignore
  data: {
    dynamic_permissions: [],
    user_permissions: [],
    is_superuser    : false,
    phone_number    : '',
    is_verified     : false,
    ui_storage      : null,
    first_name      : '',
    last_name       : '',
    is_active       : false,
    language        : '',
    is_staff        : false,
    zip_code        : '',
    address         : '',
    country         : '',
    gender          : 0,
    groups          : [],
    email           : '',
    city            : '',
    id              : 0,
  },
  isOtpConfigured: false,
  otpAuthUrl: null,
  loading:false,
  otpError:''
};

export default userBranchInitialState;
