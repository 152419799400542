import { BlueMoonDO } from '../../../entity/blueMoon';

// -------- Types --------

export type BlueMoonBranch = {
  list: BlueMoonDO.$[];
  item: BlueMoonDO.$ | null;
  meta: {
    totalCount: number;
  };
};

// -------- Initial state of branch --------

export const blueMoonBranchInitialState: BlueMoonBranch = {
  list: [],
  item: null,
  meta: { totalCount: 0 },
};
