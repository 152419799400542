import initialState, { IUserBranch } from './branch';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';
import AC from './actionCreators';

// Saga

import userSaga from './saga';

// Types

import { User } from '../../../types';

// ----------------

const userSlice = createSlice({
  name: `${reduxActionTypePrefix}/user`,
  initialState,

  // -------- Reducers --------

  reducers: {
    verifyOtpSuccess: (state) => {
      state.isOtpConfigured = true;
    },
    verifyOtpFail: (state, action) => {
      state.isOtpConfigured = false;
      state.otpError = action.payload;
      state.otpAuthUrl = null;
    },

    verifyOtpError: (state, action) => {
      state.isOtpConfigured = false;
      state.otpError = action.payload;
      state.otpAuthUrl = null;
    },
    generateOtpSuccess: (state, action) => {
      state.otpAuthUrl = action.payload.otpAuthUrl;
      state.isOtpConfigured = true;
    },
    generateOtpFailure: (state, action) => {
      state.loading = false;
    },

    setData: {
      prepare: (data: User.Base.IUser) => ({
        payload: {
          data,
        },
      }),
      reducer: (state, action: PayloadAction<{ data: User.Base.IUser }>) => {
        state.data = action.payload.data;
      },
    },

    // --------

    updateData: {
      prepare: (data: Partial<User.Base.IUser>) => ({
        payload: {
          data,
        },
      }),
      reducer: (state, action: PayloadAction<{ data: Partial<User.Base.IUser> }>) => {
        state.data = { ...state.data, ...action.payload.data };
      },
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      return initialState;
    });
  },
});



export type { IUserBranch };
export { initialState, userSaga };
export default { ...userSlice, actions: { ...userSlice.actions, ...AC } };
export const { generateOtpSuccess, generateOtpFailure } = userSlice.actions;

