import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { noteAPIService, NoteAPIService } from '../../../services/api/noteAPIService';

// Entities

import { NoteDO } from '../../../entity/note';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/note/${actionPiece}`; // Create action type

export const noteActionCreators = {
  getPaginatedList$Req: createAction(
    cat('getPaginatedList$Req'),
    (props: {
      requestActionTypeData?: number;
      onSuccess?: (data: NoteDO.PaginatedList) => void;
      onError?: (err: AxiosError) => void;
      queries?: NoteAPIService.GetPaginatedListQueries;
    }): { payload: Partial<RequestSagaConfig<NoteDO.PaginatedList>> } => {
      return {
        payload: {
          requestActionTypeData: props.requestActionTypeData,
          serviceArgs: [props.queries],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: noteAPIService.getPaginatedList,
          context: noteAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  create$Req: createAction(
    cat('create$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: NoteDO.$) => void;
      onError?: (err: AxiosError<NoteAPIService.SendDataErr>) => void;
      data: NoteAPIService.SendData;
    }): {
      payload: Partial<RequestSagaConfig<NoteDO.$>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: noteAPIService.create,
          context: noteAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  update$Req: createAction(
    cat('update$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: NoteDO.$) => void;
      onError?: (err: AxiosError<NoteAPIService.SendDataErr>) => void;
      data: NoteAPIService.SendData;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<NoteDO.$>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data, props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: noteAPIService.update,
          context: noteAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  delete$Req: createAction(
    cat('delete$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<void>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: noteAPIService.delete,
          context: noteAPIService,
          timeout: 300,
        },
      };
    }
  ),
};
