import { BaseDO } from '../base';
import { DD } from '../../types';

// ----------------

export namespace LeasedLineDO {
  // prettier-ignore
  export type $ = {
    siteReadyForInstallationTarget: DD.Date;
    siteReadyForInstallation      : DD.Date;
    lineTerminationCompleted      : DD.Date;
    lineTerminationPlanned        : DD.Date;
    authorizationReceived         : DD.Date;
    fiberWorksCompleted           : DD.Date;
    targetAccessProfile           : DD.GlossaryId;
    activeAccessProfile           : DD.GlossaryId;
    finalTestCompleted            : DD.Date;
    trenchingCompleted            : DD.Date;
    activeAccessSpeed             : DD.GlossaryId;
    fiberWorksPlanned             : DD.Date;
    targetAccessSpeed             : DD.GlossaryId;
    deliveryForecast              : DD.Date;
    provisioningType              : DD.Enum;
    trenchingPlanned              : DD.Date;
    readyForBilling               : DD.Date;
    omsActionStatus               : DD.Enum;
    activeAccessCPE               : DD.GlossaryId;
    targetAccessCPE               : DD.GlossaryId;
    npsAppointment                : DD.Date;
    pmRFBForecast                 : DD.Date;
    omsActionType                 : DD.Enum;
    siteScenario                  : DD.Enum;
    ssvCompleted                  : DD.Date;
    pmExtraCost                   : DD.Number;
    projectName                   : DD.GlossaryId;
    statusList                    : DD.String;
    ssvPlanned                    : DD.Date;
    pmRemarks                     : DD.String;
    siteReady                     : DD.Date;
    omsStatus                     : DD.Enum;
    pmProject                     : DD.Enum;
    siteName                      : DD.String;
    omsOrder                      : DD.Number;
    pmStatus                      : DD.Enum;
    dsid                          : DD.Number;
    tx                            : DD.Id; 
  };

  export type PaginatedList = BaseDO._PaginatedList<$>;
}
