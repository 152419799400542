import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { NoteDTO, NoteDO, noteMapper } from '../../entity/note';
import { baseMapper } from '../../entity/base';

// Types

import { PaginatedListQueries } from '../queryService';

export namespace NoteAPIService {
  // -------- Types --------

  export type GetPaginatedListQueries = PaginatedListQueries | Partial<{ content_type: number; object_id: number }>;

  export type SendData = Partial<NoteDO.$>;
  export type SendDataErr = Partial<Record<keyof NoteDTO.$, [string]>>;

  // ----------------

  class NoteAPIService extends HTTP {
    getPaginatedList(queries: GetPaginatedListQueries) {
      return this.request<NoteDTO.PaginatedList>({
        params: queries,
        url: api.note.root,
      }).then((data) => baseMapper.paginatedListToDO(data!, noteMapper.toDO));
    }

    // --------

    create(data: SendData) {
      return this.request<NoteDTO.$>({
        method: 'post',
        data: noteMapper.toDTO(data),
        url: api.note.root,
      }).then((data) => noteMapper.toDO(data!));
    }

    // --------

    update(data: SendData, id: number) {
      return this.request<NoteDTO.$>({
        method: 'patch',
        data: noteMapper.toDTO(data),
        url: api.note.id(id),
      }).then((data) => noteMapper.toDO(data!));
    }

    // --------

    delete(id: number) {
      return this.request<void>({
        method: 'delete',
        url: api.note.id(id),
      });
    }
  }

  export const noteAPIService = new NoteAPIService(`${env.API}api/`);
}

export const noteAPIService = NoteAPIService.noteAPIService;
