import ui from '../../common/ui';

// Entity

import { MicrowaveDOKeys } from '../../../../../entity/microwave/DO';

// ----------------

type MicrowaveT = {
  [MicrowaveDOKeys.physicalLocationsAggregated]: string;
  [MicrowaveDOKeys.maxEthUtilization]: string;
  [MicrowaveDOKeys.linkCapacity]: string;
  [MicrowaveDOKeys.linkHops]: string;
  [MicrowaveDOKeys.bEndNode]: string;
  [MicrowaveDOKeys.aEndNode]: string;
  [MicrowaveDOKeys.gneCode]: string;
  [MicrowaveDOKeys.linkId]: string;
  [MicrowaveDOKeys.length]: string;
  [MicrowaveDOKeys.band]: string;
  [MicrowaveDOKeys.xpic]: string;
};

// ----------------

const microwaveEN: MicrowaveT = {
  physicalLocationsAggregated: 'Aggregated physical locations',
  maxEthUtilization: 'Max eth utilization',
  linkCapacity: 'Link capacity',
  linkHops: 'Link hops',
  bEndNode: 'B end node',
  aEndNode: 'A end node',
  gneCode: 'Gne code',
  linkId: 'Link id',
  length: 'Length',
  band: 'Band',
  xpic: 'Xpic',
};

export default microwaveEN;
