import makeStyles from '@mui/styles/makeStyles';
import { alpha } from '@mui/material/styles';

// ----------------

const useToastStyles = makeStyles(
  (t) => ({
    root: {
      '& .Toastify__toast-container': {
        maxWidth: 500,
        padding: 0,
        width: 'auto',
      },

      '& .Toastify__toast-container--bottom-left': {
        bottom: t.spacing(2),
        left: 114,
      },

      '& .Toastify__toast-container--top-center': {
        [t.breakpoints.only('xs')]: {
          transform: 'translateX(-50%)',
          width: '90%',
          left: '50%',
          top: t.spacing(2),
        },
      },

      '& .Toastify__toast': {
        borderRadius: '6px',
        alignItems: 'center',
        minHeight: 'auto',
        padding: t.spacing(1, '0'),
        margin: 0,
        marginTop: t.spacing(2),

        [t.breakpoints.only('xs')]: {
          marginBottom: t.spacing(2),
          marginTop: 0,
        },
      },

      '& .Toastify__toast--success': {
        background: t.palette.success.main,
        color: t.palette.success.contrastText,
      },

      '& .Toastify__toast--error': {
        background: t.palette.error.main,
        color: t.palette.error.contrastText,
      },

      '& .Toastify__toast--info': {
        background: t.palette.info.main,
        color: t.palette.info.contrastText,
      },

      '& .Toastify__toast--warning': {
        background: t.palette.warning.main,
        color: t.palette.warning.contrastText,
      },

      '& .Toastify__toast-body': {
        fontFamily: 'Apercu Pro',
        paddingRight: t.spacing(2),
        paddingLeft: 22,
        fontWeight: 500,
        lineHeight: 1.3,
        margin: 0,
      },
    },

    closeButton: {
      marginRight: t.spacing(1),
      color: alpha(t.palette.common.white, 0.5),
    },

    showAnim: {
      animation: '$showToastAnim 0.3s 0.5s both',

      [t.breakpoints.down('lg')]: {
        animationName: '$showMobileToastAnim',
      },
    },

    hideAnim: {
      animation: '$hideToastAnim 0.3s 0.2s both',

      [t.breakpoints.down('lg')]: {
        animationName: '$hideMobileToastAnim',
      },
    },

    '@keyframes showToastAnim': {
      '0%': { opacity: '0', transform: 'translateY(100px)' },
      '100%': { opacity: '1', transform: 'translateY(0px)' },
    },

    '@keyframes showMobileToastAnim': {
      '0%': { opacity: '0', transform: 'translateY(-100px)' },
      '100%': { opacity: '1', transform: 'translateY(0px)' },
    },

    '@keyframes hideToastAnim': {
      '0%': { opacity: '1', transform: 'translateY(0px)' },
      '100%': { opacity: '0.5', transform: 'translateY(100px)' },
    },

    '@keyframes hideMobileToastAnim': {
      '0%': { opacity: '1', transform: 'translateY(0px)' },
      '100%': { opacity: '0.5', transform: 'translateY(-100px)' },
    },
  }),
  { name: 'Toast' }
);

export default useToastStyles;
