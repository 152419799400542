import React from 'react';
import cn from 'classnames';

// Components

import TypographyMU, { TypographyProps as MUITypographyProps } from '@mui/material/Typography';

// Styles

import useStyles from './Typography.styles';

// -------- Types --------

export interface TypographyProps extends MUITypographyProps {
  lineHeight?: number;
  component?: React.ElementType;
  ellipsis?: boolean;
  weight?: 400 | 500 | 600 | 700;
}

// ----------------

const Typography: React.FC<TypographyProps> = (props) => {
  const classes = useStyles(props);

  const typographyMUProps = { ...props };
  delete typographyMUProps.lineHeight;
  delete typographyMUProps.ellipsis;
  delete typographyMUProps.weight;

  return <TypographyMU {...typographyMUProps} className={cn(classes.typography, typographyMUProps.className)} />;
};

export default Typography;
