import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import initialState, { IAppBranch } from './branch';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';

// Types

import { Palette, Lan } from '../../../types';

// ----------------

const appSlice = createSlice({
  name: `${reduxActionTypePrefix}/app`,
  initialState,

  // -------- Reducers --------

  reducers: {
    setPalette: {
      prepare: (palette: Palette) => ({
        payload: {
          palette,
        },
      }),

      reducer: (state, action: PayloadAction<{ palette: Palette }>) => {
        state.palette = action.payload.palette;
      },
    },

    // --------

    setLan: {
      prepare: (lan: Lan) => ({
        payload: {
          lan,
        },
      }),

      reducer: (state, action: PayloadAction<{ lan: Lan }>) => {
        state.lan = action.payload.lan;
      },
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      state = initialState;
    });
  },
});

export type { IAppBranch };
export { initialState };
export default appSlice;
