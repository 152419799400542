import { PaletteOptions } from '@mui/material/styles';

// ----------------

export type Palette = Record<PaletteMode, PaletteOptions>;

export type PaletteMode = 'light' | 'dark';

declare module '@mui/material/styles' {
  interface Palette {
    lightText?: Palette['text'];
  }
  interface PaletteOptions {
    lightText?: PaletteOptions['text'];
  }
}

// ----------------

const palette: Palette = {
  // prettier-ignore
  light: {
    background: { paper: '#ffffff', default: '#F7F7FA' },
    secondary : { main: '#ff8c29', contrastText: '#ffffff' },
    warning   : { main: '#ff9800', contrastText: '#ffffff' },
    success   : { main: '#5fb886', contrastText: '#ffffff'  },
    primary   : { main: '#053BFF', contrastText: '#ffffff', light: '#C4CFF9' },
    common    : { white: '#ffffff', black: '#000000' },
    error     : { main: '#FF6565', contrastText: '#ffffff' },
    info      : { main: '#2f9fc4', dark: '#fbe99b' },
    text      : { primary: '#424759', secondary: '#7B7F8C' },
    divider   : '#DFE3F6',
    lightText : { primary: '#C7C9D8' },
  },

  // --------

  // prettier-ignore
  dark: {
    background: { paper: '#ffffff', default: '#f3f4f9' },
    secondary : { main: '#ff8c29', contrastText: '#ffffff' },
    warning   : { main: '#ff9800', contrastText: '#ffffff' },
    success   : { main: '#5fb886', contrastText: '#ffffff'  },
    primary   : { main: '#3765E1', contrastText: '#ffffff' },
    common    : { white: '#ffffff', black: '#000000' },
    error     : { main: '#f54e4e', contrastText: '#ffffff' },
    info      : { main: '#2f9fc4', dark: '#fbe99b' },
    text      : { primary: '#474747', secondary: '#969aab' },
  },
};

export default palette;
