import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Services

import glossaryTransformer from '../transformers/glossary';

// Types

import { Glossary } from '../../types';

// Entities

import { GlossaryDTO, glossaryMapper as glossaryMapper_ } from '../../entity/glossary_n';
import { GlossariesDTO, glossaryMapper } from '../../entity/glossary';

// ----------------

class GlossaryAPIService extends HTTP {
  /**
   * @deprecated
   */
  getList(): Promise<Glossary.Api.GlossaryRoot$Get$Response | void> {
    return this.request<Glossary.Api.GlossaryRoot$Get$Response$API>({ url: api.glossary.root }).then((data) => {
      if (data) {
        return glossaryTransformer.glossaryRoot$Get$Response$API(data);
      }
    });
  }

  /**
   * @deprecated
   */
  getGlossaries() {
    return this.request<GlossariesDTO>({ url: api.glossary.root }).then((data) => {
      return [glossaryMapper.glossariesToPartialDO(data!), glossaryMapper_.listByTypeToDO(data!)];
    });
  }

  // --------

  getListByType() {
    return this.request<GlossaryDTO.ListByType>({
      url: api.glossary.root,
    }).then((data) => {
      return glossaryMapper_.listByTypeToDO(data!);
    });
  }
}

export const glossaryAPIService = new GlossaryAPIService(`${env.API}api/`);
