import { BaseDTO, GlossaryId, Number, Date } from '../base';

// ----------------

export namespace ReconstructionDTO {
  // prettier-ignore
  export type $ = {
    comment_as_built: string;
    financial_status: GlossaryId;
    cr_planned_date : Date;
    project_number  : string;
    check_as_built  : GlossaryId;
    subcontractor   : GlossaryId;
    project_name    : string;
    finish_date     : Date;
    is_archived     : boolean;
    start_date      : Date;
    cr_number       : string;
    comments        : string;
    bep_date        : Date;
    as_built        : Date;
    address         : string;
    status          : GlossaryId;
    ber_2           : string;
    bep_2           : string;
    city            : string;
    fboq            : Number;
    boq             : Number;
    zip             : Number;
    fac             : Date;
    ber             : string;
    bep             : string;
    id              : number
  };

  export type PaginatedList = BaseDTO.PaginatedList<$>;
}
