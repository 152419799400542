import { MicrowaveDO, MicrowaveDTO } from './';
import { createKeysMappers } from '../base/utils/createKeysMappers';

// ----------------

export const microwaveMapper = {
  toDO(data: MicrowaveDTO.MicrowaveDTO): MicrowaveDO.MicrowaveDO {
    return {
      physicalLocationsAggregated: data.mw_physical_locations_aggregated,
      maxEthUtilization: data.max_eth_utilization,
      linkCapacity: data.link_capacity,
      transmission: data.transmission,
      linkHops: data.mw_link_hops,
      bEndNode: data.b_end_node,
      aEndNode: data.a_end_node,
      gneCode: data.gne_code,
      linkId: data.link_id,
      length: data.length,
      band: data.band,
      xpic: data.xpic,
    };
  },

  // --------

  toPartialDTO(data: Partial<MicrowaveDO.MicrowaveDO>): Partial<MicrowaveDTO.MicrowaveDTO> {
    return {
      mw_physical_locations_aggregated: data.physicalLocationsAggregated,
      max_eth_utilization: data.maxEthUtilization,
      link_capacity: data.linkCapacity,
      transmission: data.transmission,
      mw_link_hops: data.linkHops,
      b_end_node: data.bEndNode,
      a_end_node: data.aEndNode,
      gne_code: data.gneCode,
      link_id: data.linkId,
      length: data.length,
      band: data.band,
      xpic: data.xpic,
    };
  },

  // --------

  DTOKeyToEquallyDOKey(DTOKey: keyof typeof MicrowaveDTO.MicrowaveDTOKeys): keyof typeof MicrowaveDO.MicrowaveDOKeys {
    const equally: Record<keyof typeof MicrowaveDTO.MicrowaveDTOKeys, keyof typeof MicrowaveDO.MicrowaveDOKeys> = {
      mw_physical_locations_aggregated: 'physicalLocationsAggregated',
      max_eth_utilization: 'maxEthUtilization',
      link_capacity: 'linkCapacity',
      transmission: 'transmission',
      mw_link_hops: 'linkHops',
      b_end_node: 'bEndNode',
      a_end_node: 'aEndNode',
      gne_code: 'gneCode',
      link_id: 'linkId',
      length: 'length',
      band: 'band',
      xpic: 'xpic',
    };

    return equally[DTOKey];
  },

  // --------

  ...createKeysMappers<typeof MicrowaveDTO.MicrowaveDTOKeys, typeof MicrowaveDO.MicrowaveDOKeys>([
    ['mw_physical_locations_aggregated', 'physicalLocationsAggregated'],
    ['max_eth_utilization', 'maxEthUtilization'],
    ['link_capacity', 'linkCapacity'],
    ['mw_link_hops', 'linkHops'],
    ['b_end_node', 'bEndNode'],
    ['a_end_node', 'aEndNode'],
    ['gne_code', 'gneCode'],
    ['link_id', 'linkId'],
    ['length', 'length'],
    ['band', 'band'],
    ['xpic', 'xpic'],
  ]),
};
