import { DD } from '../../types/DD';

// ----------------

export namespace ChartDO {
  export type TXBaseline = {
    baseLine: {
      created: DD.DateTime;
      updated: DD.DateTime;
      months: { value: number; month: DD.Number }[];
      year: DD.Number;
      type: string;
      id: DD.Id;
    };
    actual: Array<number | null>;
  };


  export type TXActuals = {
    actual: Array<number | null>;
  };


  // --------

  export type FiberRing = {
    siteCodeB: DD.String;
    siteCodeA: DD.String;
    fiberName: DD.String;
    ringName: DD.String;
    importId: DD.UUId;
    created: DD.DateTime;
    updated: DD.DateTime;
    target: DD.String;
    parent: DD.Id;
    status: DD.String;
    id: DD.Id;
  }[];

  // --------

  export type TechnologiesDistributionHistory = {
    pieces: Record<string, { value: DD.Number }>;
    total: DD.Number;
    date: DD.Date;
  }[];

  // --------

  export type RanSharingBackhaulEvolution = {
    actual: DD.Number;
    2023: DD.Number;
    2024: DD.Number;
    2025: DD.Number;
    2026: DD.Number;
  };
}
