import { fiberSlice as slice } from './fiberSlice';

const fiberSlice = {
  ...slice,
  actions: { ...slice.actions },
};

export { fiberSlice };
export * from './fiberBranch';
export * from './saga';
