import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Entities

import { UserDO } from '../../../entity/user';

// Types

import { AxiosError } from 'axios';
import { User } from '../../../types';
import router from '../../../router';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/user/${actionPiece}`; // Create action type

export default {
  getToken$Req: createAction(cat('getToken$Req'), (data: User.Api.IUserToken$BodyReqInner, onError: (data: any) => void) => ({
    payload: {
      data
    },
    meta: {
      onError
    }
  })),

  logout: createAction(cat('logout')),

  resetPassword$Req: createAction(
    cat('resetPassword$Req'),
    (config: { data: User.Api.IUserResetPassword$BodyReq; onSuccess: () => void; onError: () => void }) => ({
      payload: {
        data: config.data
      },
      meta: {
        onSuccess: config.onSuccess,
        onError: config.onError
      }
    })
  ),

  resetPasswordValidateToken$Req: createAction(
    cat('resetPasswordValidateToken$Req'),
    (config: { data: User.Api.IResetPasswordValidateToken$BodyReq; onSuccess: () => void; onError: () => void }) => ({
      payload: {
        data: config.data
      },
      meta: {
        onSuccess: config.onSuccess,
        onError: config.onError
      }
    })
  ),

  resetPasswordConfirm$Req: createAction(
    cat('resetPasswordConfirm$Req'),
    (config: { data: User.Api.IResetPasswordConfirm$BodyReq; onSuccess: () => void; onError: () => void }) => ({
      payload: {
        data: config.data
      },
      meta: {
        onSuccess: config.onSuccess,
        onError: config.onError
      }
    })
  ),

  getBaseData$Req: createAction(cat('getBaseData$Req'), (onSuccess?: (data: UserDO) => void, onError?: (err: AxiosError) => void) => ({
    payload: {},
    meta: {
      onSuccess,
      onError
    }
  })),

  updateData$Req: createAction(cat('updateData$Req'), (data: User.Api.UserMe$Patch$Req, optimistic?: boolean) => ({
    payload: {
      data
    },
    meta: {
      optimistic
    }
  })),

  verifyOtp$Req: createAction(
    'verifyOtp$Req',
    (otp: string, email: string, onSuccess: () => void, onError: (message: string) => void) => ({
      payload: { otp, email, onSuccess, onError }
    })
  ),

  generateOtp$Req: createAction(
    cat('generateOtp$Req'),
    (email: string) => ({
      payload: { email }
    })
  )

};
