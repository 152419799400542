import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { smallWorksAPIService, GetPaginatedListQueries, SendData, SendDataErr } from '../../../services/api/smallWorksAPIService';

// Entities

import { SmallWorksDO } from '../../../entity/smallWorks';

// Types

import { FreeQueries } from '../../../services/queryService';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/smallWorks/${actionPiece}`; // Create action type

export const smallWorksActionCreators = {
  getPaginatedList$Req: createAction(
    cat('getPaginatedList$Req'),
    (props: {
      onSuccess?: (data: SmallWorksDO.PaginatedList) => void;
      onError?: (err: AxiosError) => void;
      queries?: GetPaginatedListQueries;
    }): { payload: Partial<RequestSagaConfig<SmallWorksDO.PaginatedList>> } => {
      return {
        payload: {
          serviceArgs: [props.queries],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: smallWorksAPIService.getPaginatedList,
          context: smallWorksAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  getItem$Req: createAction(
    cat('getItem$Req'),
    (props: {
      onSuccess?: (data: SmallWorksDO.$) => void;
      onError?: (err: AxiosError) => void;
      timeout?: number;
      id?: number;
    }): {
      payload: Partial<RequestSagaConfig<SmallWorksDO.$>>;
    } => {
      return {
        payload: {
          serviceArgs: [props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: smallWorksAPIService.getItem,
          context: smallWorksAPIService,
          timeout: props.timeout,
        },
      };
    }
  ),

  // --------

  export$Req: createAction(
    cat('export$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      filters?: FreeQueries;
      fields: Array<keyof SmallWorksDO.$>;
    }): {
      payload: Partial<RequestSagaConfig<null>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.fields, props.filters],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: smallWorksAPIService.export,
          context: smallWorksAPIService,
        },
      };
    }
  ),

  // --------

  create$Req: createAction(
    cat('create$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: SmallWorksDO.$) => void;
      onError?: (err: AxiosError<SendDataErr>) => void;
      data: SendData;
    }): {
      payload: Partial<RequestSagaConfig<SmallWorksDO.$>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: smallWorksAPIService.create,
          context: smallWorksAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  update$Req: createAction(
    cat('update$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: SmallWorksDO.$) => void;
      onError?: (err: AxiosError<SendDataErr>) => void;
      data: SendData;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<SmallWorksDO.$>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data, props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: smallWorksAPIService.update,
          context: smallWorksAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  delete$Req: createAction(
    cat('delete$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<void>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: smallWorksAPIService.delete,
          context: smallWorksAPIService,
          timeout: 300,
        },
      };
    }
  ),
};
