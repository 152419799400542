// Slice

import slice from '../';

// Saga

import requestSaga from '../../../baseSaga/request';

// Services

import txMicrowaveSynopsisService from '../../../../services/api/txMicrowaveSynopsis';

// ----------------

export default function* getListSaga(action: ReturnType<typeof slice.actions.getList$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.txId],
    service: txMicrowaveSynopsisService.getList,
    context: txMicrowaveSynopsisService,
    onSuccess: (data) => {
      if (action.payload.onSuccess) {
        action.payload.onSuccess(data);
      }
    },
  });
}
