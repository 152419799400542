import { noteBranchInitialState, NoteBranch } from './noteBranch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { NoteDO } from '../../../entity/note';

// ----------------

export const noteSlice = createSlice({
  name: `${reduxActionTypePrefix}/note`,
  initialState: noteBranchInitialState,

  // -------- Reducers --------

  reducers: {
    set: (state, action: PayloadAction<{ list?: NoteDO.$[]; meta?: Partial<NoteBranch['meta']> }>) => {
      if (action.payload.list) {
        state.list = action.payload.list;
      }

      if (action.payload.meta) {
        state.meta = { ...state.meta, ...action.payload.meta };
      }
    },

    // --------

    addMany: (state, action: PayloadAction<{ list: NoteDO.$[]; meta?: NoteBranch['meta'] }>) => {
      state.list.push(...action.payload.list);

      if (action.payload.meta) {
        state.meta = action.payload.meta;
      }
    },

    // --------

    update: (state, action: PayloadAction<{ data: NoteDO.$ }>) => {
      state.list = state.list.map((item) => (item.id !== action.payload.data?.id ? item : action.payload.data));
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return noteBranchInitialState;
    });
  },
});
