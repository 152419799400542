import reconstruction from './reconstructionEN';
import cartography from './cartographyEN';
import leasedLine from './leasedLineEN';
import microwave from './microwave';
import blueMoon from './blueMoonEN';
import fiber_d from './fiber_d';
import fiber from './fiberEN';
import auth from './auth';
import b2b from './b2bEN';
import tx from './txEN';
import smallWorks from './smallWorksEN';

// ----------------

const entity = { smallWorks,reconstruction, cartography, leasedLine, microwave, fiber, blueMoon, fiber_d, auth, b2b, tx };

export default entity;
