import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userBranchInitialState } from './userBranch';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { UserDO } from '../../../entity/user';

// ----------------

export const userSlice = createSlice({
  name: `${reduxActionTypePrefix}/user`,
  initialState: userBranchInitialState,

  // -------- Reducers --------

  reducers: {
    set: (state, action: PayloadAction<{ data: UserDO }>) => {
      state.data = action.payload.data;
    },

    // --------

    update: (state, action: PayloadAction<{ data: Partial<UserDO> }>) => {
      state.data = { ...state.data, ...action.payload.data };
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return userBranchInitialState;
    });
  },
});
