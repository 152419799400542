// Slice

import transmissionSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import transmissionService from '../../../../../services/api/transmission';

// Entity

import { mapper as transmissionMapper } from '../../../../../entity/transmission';

// ----------------

export default function* addSaga(action: ReturnType<typeof transmissionSlice.actions.add$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.data],
    successMess: action.meta.message,
    service: transmissionService.add,
    context: transmissionService,
    onSuccess: function* (data) {
      const dataDO = transmissionMapper.toDO(data);

      if (action.meta.onSuccess) {
        action.meta.onSuccess(dataDO);
      }
    },
    onError: (err) => {
      if (action.meta.onError) {
        action.meta.onError(err?.response?.data);
      }
    },
  });
}
