import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { HistoryDTO, historyMapper } from '../../entity/history';
import { baseMapper } from '../../entity/base';

// Types

import { PaginatedListQueries } from '../queryService';

export namespace HistoryAPIService {
  // -------- Types --------

  export type GetPaginatedListQueries = PaginatedListQueries | Partial<{ content_type: number; object_id: number }>;

  // ----------------

  class HistoryAPIService extends HTTP {
    getPaginatedList(queries: GetPaginatedListQueries) {
      return this.request<HistoryDTO.PaginatedList>({
        params: queries,
        url: api.history.root,
      }).then((data) => baseMapper.paginatedListToDO(data!, historyMapper.toDO));
    }
  }

  export const historyAPIService = new HistoryAPIService(`${env.API}api/`);
}

export const historyAPIService = HistoryAPIService.historyAPIService;
