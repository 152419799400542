import { TD } from '../../types/TD';

// ----------------

export namespace ChartDTO {
  export type TXBaseline = {
    base_line: {
      created_at: TD.DateTime;
      updated_at: TD.DateTime;
      months: Record<TXBaselineMonthName, number>;
      year: TD.Number;
      type: string;
      id: TD.Id;
    };
    data: Array<number | null>;
  };

  export type TXActuals= {
    data: Array<number | null>;
  };

  export type TXBaselineMonthName =
    | 'september'
    | 'february'
    | 'december'
    | 'november'
    | 'january'
    | 'october'
    | 'august'
    | 'march'
    | 'april'
    | 'july'
    | 'june'
    | 'may';

  // --------

  export type FiberRing = {
    site_code_b: TD.String;
    site_code_a: TD.String;
    created_at: TD.DateTime;
    fiber_name: TD.String;
    updated_at: TD.DateTime;
    ring_name: TD.String;
    import_id: TD.UUId;
    target: TD.String;
    parent: TD.Id;
    status: TD.String;
    id: TD.Id;
  }[];

  // --------

  export type TechnologiesDistributionHistory = {
    pieces: Record<string, { value: TD.Number }>;
    total: TD.Number;
    date: TD.Date;
  }[];

  // --------

  export type RanSharingBackhaulEvolution = {
    fct_eoy_2023: TD.Number;
    fct_eoy_2024: TD.Number;
    fct_eoy_2025: TD.Number;
    fct_eoy_2026: TD.Number;
    actual: TD.Number;
  };
}
