/**
 * Creates an array of separate route names from splitted original route name.
 *
 * @param splittedName - Splitted original route name
 */

export default function createSeparateRoutesNames(splittedName: string[]): string[] {
  const separateRoutes: string[] = [];

  for (let i = 0, currentName = ''; i < splittedName.length; i++) {
    let nextCurrentName = '';

    if (i === 0) {
      nextCurrentName = splittedName[i];
    } else {
      nextCurrentName = `${currentName}.${splittedName[i]}`;
    }

    separateRoutes.push(nextCurrentName);
    currentName = nextCurrentName;
  }

  return separateRoutes;
}
