import { LeasedLineDO } from '../leasedLine';
import { TXDO } from '../tx';

// ----------------

export namespace EnumDO {
  export type $ = {
    value: string;
    label: string;
  };

  export type List = $[];

  export type ListByFields<DOKey extends string> = Partial<Record<DOKey, List>>;

  export type FieldsByEntities = Partial<{ leasedLine: ListByFields<keyof LeasedLineDO.$>; tx: ListByFields<keyof TXDO.$> }>;
}
