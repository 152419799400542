import { ReconstructionDO } from '../../../entity/reconstruction';

// -------- Types --------

export type ReconstructionBranch = {
  list: ReconstructionDO.$[];
  item: ReconstructionDO.$;
  meta: {
    totalCount: number;
  };
};

// -------- Initial state of branch --------

export const reconstructionBranchInitialState: ReconstructionBranch = {
  list: [],
  item: {} as ReconstructionDO.$,
  meta: { totalCount: 0 },
};
