import { b2bActionCreators } from './b2bActionCreators';
import { b2bSlice as slice } from './b2bSlice';

const b2bSlice = {
  ...slice,
  actions: { ...slice.actions, ...b2bActionCreators },
};

export { b2bSlice };
export * from './b2bBranch';
export * from './saga';
