import { TX } from '../../../types';

// -------- Types --------

export interface ITXNotesBranch {
  totalPages: number;
  list: TX.Base.ITXNote[];
}

// -------- Initial state of branch --------

const txNotesBranchInitialState: ITXNotesBranch = {
  totalPages: 0,
  list: [],
};

export default txNotesBranchInitialState;
