// Slice

import transmissionSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import transmissionService from '../../../../../services/api/transmission';

// ----------------

export default function* deleteSaga(action: ReturnType<typeof transmissionSlice.actions.delete$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.id],
    successMess: action.meta.message,
    service: transmissionService.delete,
    context: transmissionService,
    onSuccess: function* (data) {
      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
  });
}
