import HTTP from '../http';

// Config

import { api, env } from '../../config';

// Entities

import { CoreDTO, coreMapper } from '../../entity/core';

export namespace CoreAPIService {
  class CoreAPIService extends HTTP {
    getEntityTypeList() {
      return this.request<CoreDTO.EntityType[]>({
        url: api.core.contentTypes,
      }).then((data) => data!.map((item) => coreMapper.entityTypeToDO(item)));
    }
  }

  export const coreAPIService = new CoreAPIService(`${env.API}api/`);
}

export const coreAPIService = CoreAPIService.coreAPIService;
