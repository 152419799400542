import { Type } from '../services/typeCheckService';

type ExpectedTDType =
  | 'GlossaryIdList'
  | 'DateTimeList'
  | 'BooleanList'
  | 'StringList'
  | 'NumberList'
  | 'GlossaryId'
  | 'UUIdList'
  | 'DateList'
  | 'TimeList'
  | 'DateTime'
  | 'EnumList'
  | 'Boolean'
  | 'URLList'
  | 'String'
  | 'Number'
  | 'IdList'
  | 'Date'
  | 'UUId'
  | 'Enum'
  | 'Time'
  | 'URL'
  | 'Id';

type ConstructorProps = {
  expectedTDType: ExpectedTDType;
  receivedType: Type;
  field: string;
};

/**
 * This class creates an object that describes an error that occurs
 * when data received from outside (`TD`), does not match the expected type.
 */

export class TDTypeError extends Error {
  expectedTDType: ExpectedTDType;
  receivedType: Type;
  field: string;

  constructor(props: ConstructorProps) {
    super();

    this.expectedTDType = props.expectedTDType;
    this.receivedType = props.receivedType;
    this.field = props.field;
    this.name = 'TDTypeError';

    let displayExpectedTDType = '';

    switch (this.expectedTDType) {
      case 'String': {
        displayExpectedTDType = 'TD.String = string';
        break;
      }

      case 'StringList': {
        displayExpectedTDType = 'TD.StringList = string[]';
        break;
      }

      case 'Number': {
        displayExpectedTDType = 'TD.Number = number | null';
        break;
      }

      case 'NumberList': {
        displayExpectedTDType = 'TD.NumberList = number[]';
        break;
      }

      case 'Boolean': {
        displayExpectedTDType = 'TD.Boolean = boolean';
        break;
      }

      case 'BooleanList': {
        displayExpectedTDType = 'TD.BooleanList = boolean[]';
        break;
      }

      case 'GlossaryId': {
        displayExpectedTDType = 'TD.GlossaryId = number | null';
        break;
      }

      case 'GlossaryIdList': {
        displayExpectedTDType = 'TD.GlossaryIdList = number[]';
        break;
      }

      case 'Id': {
        displayExpectedTDType = 'TD.Id = number | null';
        break;
      }

      case 'IdList': {
        displayExpectedTDType = 'TD.IdList = number[]';
        break;
      }

      case 'Date': {
        displayExpectedTDType = 'TD.Date = string | null';
        break;
      }

      case 'DateList': {
        displayExpectedTDType = 'TD.DateList = string[]';
        break;
      }

      case 'Time': {
        displayExpectedTDType = 'TD.Time = string | null';
        break;
      }

      case 'TimeList': {
        displayExpectedTDType = 'TD.TimeList = string[]';
        break;
      }

      case 'DateTime': {
        displayExpectedTDType = 'TD.DateTime = string | null';
        break;
      }

      case 'DateTimeList': {
        displayExpectedTDType = 'TD.DateTimeList = string[]';
        break;
      }

      case 'UUId': {
        displayExpectedTDType = 'TD.UUId = string | null';
        break;
      }

      case 'UUIdList': {
        displayExpectedTDType = 'TD.UUIdList = string[]';
        break;
      }

      case 'URL': {
        displayExpectedTDType = 'TD.URL = string | null';
        break;
      }

      case 'URLList': {
        displayExpectedTDType = 'TD.URLList = string[]';
        break;
      }

      case 'Enum': {
        displayExpectedTDType = 'TD.Enum = string | null';
        break;
      }

      case 'EnumList': {
        displayExpectedTDType = 'TD.EnumList = string[]';
        break;
      }
    }

    this.message = `The transfer data does not match the expected type. For the \`${this.field}\` field, the expected type is \`${displayExpectedTDType}\`, but got \`${this.receivedType}\``;
  }
}
