import { BaseDTO, DateTime } from '../base';
import { CoreDTO } from '../core';

// ----------------

export namespace HistoryDTO {
  export type $ = {
    content_type: CoreDTO.EntityType;
    changed_at: DateTime;
    changed_by: {
      email: string;
      id: number;
    };
    object_id: number;
    changes: Record<string, { new: number; old: number }>;
    id: number;
  };

  export type PaginatedList = BaseDTO.PaginatedList<$>;
}
