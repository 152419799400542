// Slice

import slice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import microwaveService from '../../../../../services/api/microwave';

// ----------------

export default function* addSaga(action: ReturnType<typeof slice.actions.add$Req>) {
  yield requestSaga({
    requestActionType: action.type,
    serviceArgs: [action.payload.data],
    successMess: action.meta.message,
    service: microwaveService.add,
    context: microwaveService,
    onSuccess: (data) => {
      if (action.meta.onSuccess) {
        action.meta.onSuccess(data);
      }
    },
    onError: (err) => {
      if (action.meta.onError) {
        action.meta.onError(err?.response?.data);
      }
    },
  });
}
