import { BaseDTO, BaseDO } from './';
import { createDDMapper } from '../../mappers/createDDMapper';

// ----------------

export const baseMapper = {
  /**
   * @param data @deprecated
   */
  paginatedListToDO<ListItemDTO, ListItemDO>(
    data: BaseDTO.PaginatedList<ListItemDTO>,
    listItemMapper: (ListItemDTO) => ListItemDO
  ): BaseDO.PaginatedList<ListItemDO> {
    return {
      count: data.count,
      limit: data.limit,
      pages: data.pages,
      list: data.results.map((item) => listItemMapper(item)),
    };
  },

  // --------

  _paginatedListToDO<ListItemDTO, ListItemDO>(
    data: BaseDTO._PaginatedList<ListItemDTO>,
    listItemMapper: (ListItemDTO) => ListItemDO
  ): BaseDO._PaginatedList<ListItemDO> {
    const ddMapper = createDDMapper(data);

    return {
      count: ddMapper.number('count'),
      limit: ddMapper.number('limit'),
      pages: ddMapper.number('pages'),
      list: data.results.map((item) => listItemMapper(item)),
    };
  },
};
