import makeStyles from '@mui/styles/makeStyles';
import { TypographyProps } from './Typography';

// ----------------

const useTypographyStyles = makeStyles((t) => ({
  typography: (props: TypographyProps) => ({
    ...(props.ellipsis
      ? {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }
      : {}),

    ...(props.lineHeight ? { lineHeight: props.lineHeight } : {}),
    ...(props.weight ? { fontWeight: props.weight } : {}),
  }),
}));

export default useTypographyStyles;
