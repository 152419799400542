import { put } from 'redux-saga/effects';

// Redux

import txSlice from '../../index';

// Saga

import requestSaga from '../../../../baseSaga/request';

// Services

import txService from '../../../../../services/api/tx';

// ----------------

export default function* deleteTXSaga(action: ReturnType<typeof txSlice.actions.deleteTX$Req>) {
  yield put(txSlice.actions.deleteFromList(action.payload.id));

  yield requestSaga({
    requestActionType: action.type,
    successMess: 'Site successfully deleted',
    serviceArgs: [action.payload.id],
    errorMess: 'Something went wrong. Item not deleted',
    service: txService.deleteTX,
    context: txService,
    onSuccess: () => {
      if (action.meta.onSuccess) {
        action.meta.onSuccess();
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
}
