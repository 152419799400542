import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

// ----------------

const useIsMobile = (): boolean => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: window.matchMedia(theme.breakpoints.down('md').split(' ')[1]).matches,
  });

  return isMobile;
};

export default useIsMobile;
