import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { reconstructionAPIService, GetPaginatedListQueries, SendData, SendDataErr } from '../../../services/api/reconstructionAPIService';

// Entities

import { ReconstructionDO } from '../../../entity/reconstruction';

// Types

import { FreeQueries } from '../../../services/queryService';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/reconstruction/${actionPiece}`; // Create action type

export const reconstructionActionCreators = {
  getPaginatedList$Req: createAction(
    cat('getPaginatedList$Req'),
    (props: {
      onSuccess?: (data: ReconstructionDO.PaginatedList) => void;
      onError?: (err: AxiosError) => void;
      queries?: GetPaginatedListQueries;
    }): { payload: Partial<RequestSagaConfig<ReconstructionDO.PaginatedList>> } => {
      return {
        payload: {
          serviceArgs: [props.queries],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: reconstructionAPIService.getPaginatedList,
          context: reconstructionAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  getItem$Req: createAction(
    cat('getItem$Req'),
    (props: {
      onSuccess?: (data: ReconstructionDO.$) => void;
      onError?: (err: AxiosError) => void;
      timeout?: number;
      id?: number;
    }): {
      payload: Partial<RequestSagaConfig<ReconstructionDO.$>>;
    } => {
      return {
        payload: {
          serviceArgs: [props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: reconstructionAPIService.getItem,
          context: reconstructionAPIService,
          timeout: props.timeout,
        },
      };
    }
  ),

  // --------

  export$Req: createAction(
    cat('export$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      filters?: FreeQueries;
      fields: Array<keyof ReconstructionDO.$>;
    }): {
      payload: Partial<RequestSagaConfig<null>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.fields, props.filters],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: reconstructionAPIService.export,
          context: reconstructionAPIService,
        },
      };
    }
  ),

  // --------

  create$Req: createAction(
    cat('create$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: ReconstructionDO.$) => void;
      onError?: (err: AxiosError<SendDataErr>) => void;
      data: SendData;
    }): {
      payload: Partial<RequestSagaConfig<ReconstructionDO.$>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: reconstructionAPIService.create,
          context: reconstructionAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  update$Req: createAction(
    cat('update$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: ReconstructionDO.$) => void;
      onError?: (err: AxiosError<SendDataErr>) => void;
      data: SendData;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<ReconstructionDO.$>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data, props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: reconstructionAPIService.update,
          context: reconstructionAPIService,
          timeout: 300,
        },
      };
    }
  ),

  // --------

  delete$Req: createAction(
    cat('delete$Req'),
    (props: {
      successMess?: string;
      onSuccess?: () => void;
      onError?: (err: AxiosError) => void;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<void>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: reconstructionAPIService.delete,
          context: reconstructionAPIService,
          timeout: 300,
        },
      };
    }
  ),
};
