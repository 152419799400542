const authEN = {
  forgotPassword: 'Forgot password?',
  login: 'Back to login',
  loginError: 'Wrong email or password. Please try again',
  mfa :'Enable Mfa',
  forgotPasswordDes: 'Please write your email and click on send button. After that, you will receive an email with a link to reset your password.',
  resetPasswordDes: `Please create a new password that will contain a minimum 8 characters, and include at least one lowercase letter,
  one uppercase letter, one number and one special character.`,
  resetPasswordNotValid: ['Sorry, but it looks like your link is not correct or is no longer valid.', 'Try sending your password reset email again.'],
  resetPasswordSuccess: 'You have successfully created a new password. Use it for login to the app.',
};

export default authEN;
