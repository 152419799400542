import { timelineActionCreators } from './timelineActionCreators';
import { timelineSlice as slice } from './timelineSlice';

const timelineSlice = {
  ...slice,
  actions: { ...slice.actions, ...timelineActionCreators },
};

export { timelineSlice };
export * from './timelineBranch';
export * from './saga';
