import { useRouteNode } from 'react-router5';
import { provider } from '../../index';
import { Router } from 'router5';
import React from 'react';

// -------- Types --------

export interface IProps {
  base: string;
  routes: IRoute[];
}

export interface IRoute {
  subRoutes?: React.FC;
  branch?: string;
  router: Router;
  props?: { [key: string]: any };
  name: string;
}

// ----------------

const Switch: React.FC<IProps> = (props) => {
  const { route: currentRoute } = useRouteNode(props.base);

  const splittedFullRouteName = currentRoute.name.split('.');
  let partOfNameForComparison;

  if (props.base === '') {
    partOfNameForComparison = splittedFullRouteName[0];
  } else {
    const baseMatches = props.base.match(/\./g);

    if (baseMatches === null) {
      partOfNameForComparison = splittedFullRouteName[1];
    } else {
      partOfNameForComparison = splittedFullRouteName[baseMatches.length + 1];
    }
  }

  const route = props.routes.find((route) => partOfNameForComparison === route.name);

  if (!route) {
    return null;
  }

  const Branch = route.branch ? provider.branch[route.branch] : null;

  return Branch ? (
    <Branch
      // @ts-ignore
      additionalProps={route.props}
      routeName={props.base === '' ? partOfNameForComparison : `${props.base}.${partOfNameForComparison}`}
      subRoutes={route.subRoutes}
      router={route.router}
    />
  ) : route.subRoutes ? (
    <route.subRoutes />
  ) : null;
};

export default Switch;
