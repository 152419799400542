import { RequestSagaConfig } from '../../baseSaga/request';
import { createAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Services

import { leasedLineAPIService, LeasedLineAPIService } from '../../../services/api/leasedLineAPIService';

// Entities

import { LeasedLineDO } from '../../../entity/leasedLine';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/leasedLine/${actionPiece}`; // Create action type

export const leasedLineActionCreators = {
  create$Req: createAction(
    cat('create$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: LeasedLineDO.$) => void;
      onError?: (err: AxiosError<LeasedLineAPIService.SendDataErr>) => void;
      data: LeasedLineAPIService.SendData;
    }): {
      payload: Partial<RequestSagaConfig<LeasedLineDO.$>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: leasedLineAPIService.create,
          context: leasedLineAPIService,
        },
      };
    }
  ),

  // --------

  update$Req: createAction(
    cat('update$Req'),
    (props: {
      successMess?: string;
      onSuccess?: (data: LeasedLineDO.$) => void;
      onError?: (err: AxiosError<LeasedLineAPIService.SendDataErr>) => void;
      data: LeasedLineAPIService.SendData;
      id: number;
    }): {
      payload: Partial<RequestSagaConfig<LeasedLineDO.$>>;
    } => {
      return {
        payload: {
          successMess: props.successMess,
          serviceArgs: [props.data, props.id],
          onSuccess: props.onSuccess,
          onError: props.onError,
          service: leasedLineAPIService.update,
          context: leasedLineAPIService,
        },
      };
    }
  ),
};
