import React from 'react';
import dayjs from 'dayjs';

// Components

import { MobileDatePicker, DatePickerProps as MUIDatePickerProps } from '@mui/x-date-pickers';
import TextField, { TextFieldProps } from '../TextField';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import { DialogActions } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

// -------- Types --------

export type Props = {
  // @ts-ignore
  muiDatePickerProps?: Partial<MUIDatePickerProps<DatePickerValue>>;
  textFieldProps?: TextFieldProps;
  onChange: (date: DatePickerValue) => void;
  value: DatePickerValue;
};

export type DatePickerValue = string /* YYYY-MM-DD */ | null;

// ----------------

const DatePicker: React.FC<Props> = (props) => {
  // @ts-ignore
  const commonPickerProps: MUIDatePickerProps<DatePickerValue> = {
    // renderInput: (p) => (
    //   <TextField
    //     {...p}
    //     sx={{
    //       '& .MuiButtonBase-root': {
    //         mr: '-4px',
    //       },
    //     }}
    //     {...props.textFieldProps}
    //     InputProps={{
    //       endAdornment: <InsertInvitationIcon sx={{ color: 'grey.400' }} />,
    //     }}
    //   />
    // ),
    renderInput: (p) => (
        <TextField
            {...p}
            {...props.textFieldProps}
            InputProps={{
              endAdornment: (
                  <Stack direction='row' spacing={1} alignItems='center'>
                    {props.value && (
                        <IconButton
                            className='close-button'
                            onClick={(e) => {
                              e.stopPropagation();
                              props.onChange(null);
                            }}
                            sx={{ mt: '1px', p: 0 }}
                        >
                          <CloseIcon fontSize='small' sx={{ color: 'text.secondary' }} />
                        </IconButton>
                    )}
                    <InsertInvitationIcon sx={{ color: 'text.secondary' }} />
                  </Stack>
              )
            }}
            sx={{ '& .close-button': { display: 'none' }, '&:hover': { '& .close-button': { display: 'inline-flex' } } }}
        />
    ),
    label: 'Date',
    ...props.muiDatePickerProps,
    onChange: (value) => {
      const formattedValue = value ? dayjs(value).format('YYYY-MM-DD') : null;
      props.onChange(formattedValue);
    },
    clearable: true,
    value: props.value ? new Date(props.value) : null,
    inputFormat: 'DD-MM-YYYY',
    showToolbar: false
  };

  return (
    <MobileDatePicker<DatePickerValue>
      {...commonPickerProps}
      components={{
        ActionBar: (actionBarProps) => {
          return (
            <DialogActions>
              <Button color='primary' onClick={() => props.onChange(dayjs().format('YYYY-MM-DD'))}>
                Today
              </Button>
              <Button onClick={actionBarProps.onCancel}>Cancel</Button>
              <Button onClick={actionBarProps.onAccept}>OK</Button>
            </DialogActions>
          );
        }
      }}
      OpenPickerButtonProps={{
        'aria-label': 'change date'
      }}
      showTodayButton
    />
  );
};

export default DatePicker;
export { Props as DatePickerProps };
export const datePickerDefaultValue: DatePickerValue = null;
