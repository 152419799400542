import { CoreDTO, CoreDO } from '.';

// ----------------

export const coreMapper = {
  entityTypeToDO(data: CoreDTO.EntityType): CoreDO.EntityType {
    return {
      label: data.app_label,
      model: data.model,
      id: data.id,
    };
  },
};
